import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Button } from "../../Components/Button.js";
import Calendar_Add from "../../../public/assets/icons/Calendar_Add.svg";
import { RouterModule_encodeParts, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { empty as empty_1, ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { ReservationTable } from "../../Widgets/Table/PlanningTable.js";
import { OnTableRowClick$1 } from "../../Widgets/Table/Table.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function ReservationsContent(props) {
    let elems_3, elems_2, xs_7;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "pb-2"])], (elems_2 = toList(delay(() => append(singleton(createElement("div", {
        className: join(" ", ["grow"]),
    })), delay(() => {
        let elems, Label, elems_1, Label_1;
        const matchValue = props.UserData.Role;
        let matchResult;
        switch (matchValue) {
            case "user": {
                if (props.CustomerConfiguration.IsSelfServiceReservationForUserActive) {
                    matchResult = 1;
                }
                else {
                    matchResult = 2;
                }
                break;
            }
            default:
                matchResult = 0;
        }
        switch (matchResult) {
            case 0:
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-3"])], (elems = [createElement(Button, (Label = t("dialog.reservation.new_title"), {
                    ComponentState: "enabled",
                    Icon: Calendar_Add(),
                    Label: Label,
                    OnClick: () => {
                        RouterModule_nav(ofArray(["reservations", "new"]), 1, 1);
                    },
                    TestId: "add-new-reservation-button",
                    Variant: "primary",
                }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))));
            case 1:
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-3"])], (elems_1 = [createElement(Button, (Label_1 = t("dialog.reservation.new_title"), {
                    ComponentState: "enabled",
                    Icon: Calendar_Add(),
                    Label: Label_1,
                    OnClick: () => {
                        RouterModule_nav(ofArray(["reservations", "new"]), 1, 1);
                    },
                    TestId: "add-new-reservation-button",
                    Variant: "primary",
                }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
            default: {
                return empty();
            }
        }
    })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), (xs_7 = [createElement(ReservationTable, {
        Options: {
            Controls: empty_1(),
            DisplayReceiverName: true,
            IsReservationStateVisible: true,
            OnRowClick: new OnTableRowClick$1(1, [(dto) => {
                let matchValue_3;
                return RouterModule_encodeParts(ofArray(["reservations", (matchValue_3 = dto.GroupId, (matchValue_3 == null) ? dto.Id : matchValue_3)]), 1);
            }]),
            RowSelection: "disabled",
        },
        Reservations: props.Reservations,
        UserData: props.UserData,
    })], react.createElement(react.Fragment, {}, ...xs_7))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function ReservationView(props) {
    let patternInput;
    const init = init_1(props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Reservations;
    const matchValue_1 = state_1.CustomerConfiguration;
    let matchResult, customerConfiguration, reservations;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        customerConfiguration = matchValue_1.fields[0];
        reservations = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ReservationsContent, {
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                Reservations: reservations,
                UserData: props.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "table",
            });
    }
}

