import { CustomerConfiguration, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { updateCustomerConfiguration, getCustomerConfiguration } from "../../Requests/Configuration.js";
import { ofArray, singleton } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";

export const initialState = new State(new RequestedValue$1(0, []), undefined);

export function init() {
    return [initialState, Cmd_batch(singleton(getCustomerConfiguration((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(4, [Item_1])))))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const matchValue_1 = state.CustomerConfiguration;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, updateCustomerConfiguration(matchValue_1.fields[0], (Item_1) => (new Msg(3, [Item_1])), (Item_2) => (new Msg(4, [Item_2])))];
            }
        }
        case 2:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Message), Cmd_none()];
        case 3:
            return [state, (msg.fields[0].Result.tag === 1) ? Cmd_batch(ofArray([Cmd_errorToast("settings.receiver_failed_deactivate"), getCustomerConfiguration((Item_5) => (new Msg(2, [Item_5])), (Item_6) => (new Msg(4, [Item_6])))])) : Cmd_batch(ofArray([Cmd_successToast("settings.permissions_changed_successful"), getCustomerConfiguration((Item_3) => (new Msg(2, [Item_3])), (Item_4) => (new Msg(4, [Item_4])))]))];
        case 4:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.CustomerConfiguration;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const configuration = matchValue.fields[0];
                return [new State(new RequestedValue$1(1, [new CustomerConfiguration(configuration.Staging, configuration.IsGlobalAppSearchActiveForUserRole, configuration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, configuration.IsSelfServiceReservationForUserActive, msg.fields[0], configuration.NotificationEmailsOnlyToAdministrators, configuration.DemoDataImported, configuration.CanUserSeeAllTools, configuration.CanUserWriteNotices, configuration.AppVisibilityPermissionReceiver, configuration.GlobalSelfServiceStorageActive, configuration.CustomI18nextNamespace, configuration.Tenants, configuration.TenantName, configuration.ReceiverCreateReservationAllowedConfiguration, configuration.AllowUserToUserAssignmentViaUserSelection, configuration.IsReleaseDateEnabled)]), state.Message), singleton((dispatch) => {
                    dispatch(new Msg(1, []));
                })];
            }
        }
    }
}

