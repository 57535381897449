import { CustomerConfiguration, RequestedValue$1 } from "../../Shared/Shared.js";
import { Msg, State } from "./Types.js";
import { updateCustomerConfiguration, getCustomerConfiguration } from "../../Requests/Configuration.js";
import { Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";

export const initialState = new State(new RequestedValue$1(0, []), undefined, false);

export function init() {
    return [initialState, getCustomerConfiguration((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(5, [Item_1])))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 2: {
            const matchValue_1 = state.CustomerConfiguration;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, updateCustomerConfiguration(matchValue_1.fields[0], (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(5, [Item_1])))];
            }
        }
        case 0:
            return [state, updateCustomerConfiguration(msg.fields[0], (Item_2) => (new Msg(4, [Item_2])), (Item_3) => (new Msg(5, [Item_3])))];
        case 3:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Message, state.IsValidated), Cmd_none()];
        case 4:
            return [state, (msg.fields[0].Result.tag === 1) ? Cmd_batch(ofArray([Cmd_errorToast("settings.receiver_failed_deactivate"), getCustomerConfiguration((Item_6) => (new Msg(3, [Item_6])), (Item_7) => (new Msg(5, [Item_7])))])) : Cmd_batch(ofArray([Cmd_successToast("settings.permissions_changed_successful"), getCustomerConfiguration((Item_4) => (new Msg(3, [Item_4])), (Item_5) => (new Msg(5, [Item_5])))]))];
        case 5:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.CustomerConfiguration;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const customerConfiguration = matchValue.fields[0];
                return [new State(new RequestedValue$1(1, [new CustomerConfiguration(customerConfiguration.Staging, customerConfiguration.IsGlobalAppSearchActiveForUserRole, customerConfiguration.IsStorageSelfServiceUserAuthorisedToReturnToolsWithoutNotification, customerConfiguration.IsSelfServiceReservationForUserActive, msg.fields[0], customerConfiguration.NotificationEmailsOnlyToAdministrators, customerConfiguration.DemoDataImported, customerConfiguration.CanUserSeeAllTools, customerConfiguration.CanUserWriteNotices, customerConfiguration.AppVisibilityPermissionReceiver, customerConfiguration.GlobalSelfServiceStorageActive, customerConfiguration.CustomI18nextNamespace, customerConfiguration.Tenants, customerConfiguration.TenantName, customerConfiguration.ReceiverCreateReservationAllowedConfiguration, customerConfiguration.AllowUserToUserAssignmentViaUserSelection, customerConfiguration.IsReleaseDateEnabled)]), state.Message, state.IsValidated), Cmd_none()];
            }
        }
    }
}

