import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { OnTableRowClick$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { ofArray, empty, filter, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { singleton, append, delay, toArray as toArray_1 } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Helper_unitToTranslationKey } from "../../Shared/Consumable.js";
import { Table_customPropertyValueDtoColumnDefinitions } from "../../Widgets/CustomProperties.js";
import { RouterModule_encodeParts } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";

export function ReportView(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["id", "switch-storage-report-id"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Report), {
        ColumnDefinitions: toArray_1(delay(() => append(singleton(new ColumnDefinition$1("date", "", t("general.date"), (row) => (new CellContent(5, [row.Date])), "text", "date")), delay(() => append(singleton(new ColumnDefinition$1("displayName", "", t("general.tool"), (row_1) => (new CellContent(0, [row_1.DisplayName])), "text", "button")), delay(() => append(singleton(new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_5) => (new CellContent(0, [row_5.InventoryNumber])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("quantity", "", t("general.quantity"), (row_6) => (new CellContent(0, [row_6.Quantity])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("unit", "", t("general.unit"), (row_7) => (new CellContent(0, [t(Helper_unitToTranslationKey(row_7.Unit))])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("sourceStorageName", "", t("tool.switch_storage_source"), (row_8) => (new CellContent(0, [row_8.SourceStorage])), "text", "text")), delay(() => append(singleton(new ColumnDefinition$1("targetStorageName", "", t("tool.switch_storage_target"), (row_9) => (new CellContent(0, [row_9.TargetStorage])), "text", "text")), delay(() => Table_customPropertyValueDtoColumnDefinitions(filter((propertyConfiguration) => {
            const matchValue = propertyConfiguration.Visibility;
            switch (matchValue.tag) {
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    return false;
                default:
                    return true;
            }
        }, props.PropertyConfigurations), t("tool.custom_properties"), (dto) => dto.CustomProperties, t))))))))))))))))))))))),
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["releaseDate", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(row_10) => RouterModule_encodeParts(row_10.EntityInformation.OnClickPath, 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

