import { Record, Union } from "../../fable_modules/fable-library-js.4.19.2/Types.js";
import { bool_type, record_type, union_type, option_type, list_type, lambda_type, unit_type, class_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Label_$reflection } from "../../Shared/Shared.js";
import { mapIndexed as mapIndexed_1, collect, concat, append as append_1, tryFindIndex, insertAt, item, contains, tryFind, map } from "../../fable_modules/fable-library-js.4.19.2/Array.js";
import { comparePrimitives, structuralHash, createObj, equals as equals_1, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { reduce, length, isEmpty, contains as contains_1, append as append_2, mapIndexed, empty as empty_1, toArray, ofArray, cons, singleton, filter, tryFind as tryFind_1, map as map_1, exists } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { fromDateTime } from "../../fable_modules/fable-library-js.4.19.2/DateOnly.js";
import { toString, compare, equals, toLocalTime, parse } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { ofNullable, map as map_3, defaultArg, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { RouterModule_encodeQueryString, RouterModule_encodeParts, RouterModule_nav, RouterModule_urlSegments } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { toArray as toArray_1, singleton as singleton_1, empty, append, map as map_2, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { isNullOrEmpty, isNullOrWhiteSpace, join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { useTranslation } from "react-i18next";
import { useDrop, useDrag } from "../../Bindings/Fable.ReactDnD.js";
import Hamburger_Menu_1 from "../../../public/assets/icons/Hamburger_Menu_1.svg";
import Arrow_Up_3 from "../../../public/assets/icons/Arrow_Up_3.svg";
import Arrow_Down_3 from "../../../public/assets/icons/Arrow_Down_3.svg";
import { IconInput, Input } from "../../Components/Input.js";
import { FSharpMap__TryFind, ofArray as ofArray_1, tryFind as tryFind_2, FSharpMap__get_Item } from "../../fable_modules/fable-library-js.4.19.2/Map.js";
import { SelectFilter } from "./SelectFilter.js";
import { Array_distinct } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { RangeDatePicker } from "../../Components/DatePicker.js";
import { Checkbox } from "../../Components/Checkbox.js";
import { Badge } from "../../Components/Badge.js";
import { ImageDecorator, CommonProps } from "../../SharedComponents/ReactImageViewer.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import react_viewer from "react-viewer";
import { TextButton } from "../../Components/Button.js";
import { TableConfiguration } from "../../Shared/User.js";
import { getSortedRowModel as getSortedRowModel_1, getFilteredRowModel as getFilteredRowModel_1, getCoreRowModel as getCoreRowModel_1, useReactTable } from "@tanstack/react-table";
import Magnifying_Glass from "../../../public/assets/icons/Magnifying_Glass.svg";
import { ColumnSelect } from "./ColumnSelect.js";
import Download_File from "../../../public/assets/icons/Download_File.svg";
import { reactApi as reactApi_1 } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { CSVDownload } from "react-csv";

export class CellContent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Text", "Component", "ButtonCell", "Labels", "Image", "Date", "DateTime"];
    }
}

export function CellContent_$reflection() {
    return union_type("Widgets.Table.CellContent", [], CellContent, () => [[["Item", string_type]], [["Item", class_type("Fable.React.ReactElement")]], [["label", string_type], ["onClick", lambda_type(unit_type, unit_type)]], [["Item", list_type(Label_$reflection())]], [["imageUrl", string_type]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", class_type("System.DateTime")]]]);
}

export class OnTableRowClick$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Action", "URL"];
    }
}

export function OnTableRowClick$1_$reflection(gen0) {
    return union_type("Widgets.Table.OnTableRowClick`1", [gen0], OnTableRowClick$1, () => [[["Item", lambda_type(gen0, unit_type)]], [["Item", lambda_type(gen0, string_type)]]]);
}

export class ColumnDefinition$1 extends Record {
    constructor(Id, Group, Header, AccessorFunction, Filter, ColumnType) {
        super();
        this.Id = Id;
        this.Group = Group;
        this.Header = Header;
        this.AccessorFunction = AccessorFunction;
        this.Filter = Filter;
        this.ColumnType = ColumnType;
    }
}

export function ColumnDefinition$1_$reflection(gen0) {
    return record_type("Widgets.Table.ColumnDefinition`1", [gen0], ColumnDefinition$1, () => [["Id", string_type], ["Group", string_type], ["Header", string_type], ["AccessorFunction", lambda_type(gen0, CellContent_$reflection())], ["Filter", string_type], ["ColumnType", string_type]]);
}

export function createColumnDefinition(columnDefinitions, columnFilters) {
    return map((columnDefinition) => {
        let matchValue_1, matchValue_5;
        return {
            accessorFn: (row) => {
                const matchValue = columnDefinition.AccessorFunction(row);
                switch (matchValue.tag) {
                    case 1:
                        return matchValue.fields[0];
                    case 2:
                        return matchValue.fields[0];
                    case 3:
                        return matchValue.fields[0];
                    case 4:
                        return matchValue.fields[0];
                    case 5:
                        return matchValue.fields[0];
                    case 6:
                        return matchValue.fields[0];
                    default:
                        return matchValue.fields[0];
                }
            },
            enableSorting: columnDefinition.Id !== "image",
            filterFn: (matchValue_1 = columnDefinition.Filter, (matchValue_1 === "text") ? "includesString" : ((matchValue_1 === "select") ? ((a) => {
                const filterValue = tryFind((columnFilter) => (columnFilter.id === columnDefinition.Id), columnFilters);
                const matchValue_2 = columnDefinition.AccessorFunction(a.original);
                switch (matchValue_2.tag) {
                    case 1:
                        return true;
                    case 2:
                        return (filterValue == null) ? true : contains(matchValue_2.fields[0], filterValue.value, {
                            Equals: (x_1, y_1) => (x_1 === y_1),
                            GetHashCode: stringHash,
                        });
                    case 4:
                        return true;
                    case 3:
                        if (filterValue == null) {
                            return true;
                        }
                        else {
                            const filterValues_2 = filterValue.value;
                            return exists((x_3) => x_3, map_1((label_3) => contains(label_3, filterValues_2, {
                                Equals: (x_2, y_2) => (x_2 === y_2),
                                GetHashCode: stringHash,
                            }), map_1((label_2) => label_2.Name, matchValue_2.fields[0])));
                        }
                    case 5:
                        return true;
                    case 6:
                        return true;
                    default:
                        return (filterValue == null) ? true : contains(matchValue_2.fields[0], filterValue.value, {
                            Equals: (x, y) => (x === y),
                            GetHashCode: stringHash,
                        });
                }
            }) : ((matchValue_1 === "dateRange") ? ((row_1) => {
                const matchValue_3 = columnDefinition.AccessorFunction(row_1.original);
                switch (matchValue_3.tag) {
                    case 1:
                        return true;
                    case 2:
                        return true;
                    case 4:
                        return true;
                    case 3:
                        return true;
                    case 0:
                        return true;
                    case 6:
                        return true;
                    default:
                        if (matchValue_3.fields[0] == null) {
                            return false;
                        }
                        else {
                            const value_1 = matchValue_3.fields[0];
                            const filterValue_4 = tryFind((columnFilter_1) => (columnFilter_1.id === columnDefinition.Id), columnFilters);
                            if (filterValue_4 == null) {
                                return false;
                            }
                            else {
                                const filterValues_3 = map((arg) => fromDateTime(parse(arg)), filterValue_4.value);
                                const dateValue = fromDateTime(toLocalTime(value_1));
                                const matchValue_4 = filterValues_3.length | 0;
                                return (matchValue_4 === 0) ? false : ((matchValue_4 === 1) ? equals(item(0, filterValues_3), dateValue) : ((matchValue_4 === 2) && ((compare(item(0, filterValues_3), dateValue) <= 0) && (compare(dateValue, item(1, filterValues_3)) <= 0))));
                            }
                        }
                }
            }) : "includesString"))),
            header: columnDefinition.Header,
            id: columnDefinition.Id,
            size: unwrap((columnDefinition.ColumnType === "image") ? 75 : undefined),
            sortingFn: (matchValue_5 = columnDefinition.ColumnType, (matchValue_5 === "image") ? "alphanumeric" : ((matchValue_5 === "button") ? "alphanumeric" : ((matchValue_5 === "date") ? "datetime" : ((matchValue_5 === "dateTime") ? "datetime" : "alphanumeric")))),
        };
    }, columnDefinitions);
}

export function setQueryParams(key, value) {
    const matchValue = tryFind_1((segment) => (segment.indexOf("?") === 0), RouterModule_urlSegments(window.location.hash, 1));
    if (matchValue == null) {
        RouterModule_nav(singleton(RouterModule_encodeParts(filter((segment_3) => !(segment_3.indexOf("?") === 0), RouterModule_urlSegments(window.location.hash, 1)), 1) + RouterModule_encodeQueryString(singleton([key, value]))), 2, 1);
    }
    else {
        const queryParams = matchValue;
        const oldQueryParamsList = toList(delay(() => map_2((entry) => [item(0, entry), item(1, entry)], (new URLSearchParams(queryParams)).entries())));
        const updatedQueryParamsList = map_1((tupledArg) => {
            const queryParamId = tupledArg[0];
            if (queryParamId === key) {
                return [queryParamId, value];
            }
            else {
                return [queryParamId, tupledArg[1]];
            }
        }, oldQueryParamsList);
        const updatedQueryParamsList_1 = equals_1(oldQueryParamsList, updatedQueryParamsList) ? cons([key, value], oldQueryParamsList) : updatedQueryParamsList;
        RouterModule_nav(singleton(RouterModule_encodeParts(filter((segment_1) => !(segment_1.indexOf("?") === 0), RouterModule_urlSegments(window.location.hash, 1)), 1) + RouterModule_encodeQueryString(updatedQueryParamsList_1)), 2, 1);
    }
}

export function BaseTableHeader(props) {
    const dependencies_1 = [props.Children];
    return reactApi.useMemo(() => createElement("div", createObj(toList(delay(() => {
        let matchValue;
        return append((matchValue = props.DropRef, (matchValue == null) ? (empty()) : singleton_1(["ref", matchValue])), delay(() => append(singleton_1(["style", {
            minWidth: props.Width,
            width: props.Width,
        }]), delay(() => append(singleton_1(["className", join(" ", toList(delay(() => append(singleton_1("grow"), delay(() => {
            let matchValue_1;
            return append((matchValue_1 = props.BorderStyle, (matchValue_1 === "full") ? singleton_1("border") : ((matchValue_1 === "row") ? (empty()) : (empty()))), delay(() => append(singleton_1("border-border"), delay(() => append(singleton_1("py-2.5"), delay(() => append(singleton_1("px-4"), delay(() => append(singleton_1("h-full"), delay(() => append(props.StickyHeader ? append(singleton_1("sticky"), delay(() => singleton_1("top-0"))) : empty(), delay(() => append(singleton_1("bg-white"), delay(() => append(singleton_1("relative"), delay(() => props.Classes))))))))))))))));
        })))))]), delay(() => {
            let elems_1;
            return singleton_1((elems_1 = toList(delay(() => append(singleton_1(createElement("div", createObj(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = props.PreviewRef, (matchValue_2 == null) ? (empty()) : singleton_1(["ref", matchValue_2])), delay(() => append(singleton_1(["className", join(" ", ["flex", "flex-col", "justify-between", "gap-3", "h-full"])]), delay(() => {
                    let elems;
                    return singleton_1((elems = toList(delay(() => props.Children)), ["children", reactApi.Children.toArray(Array.from(elems))]));
                }))));
            }))))), delay(() => {
                const matchValue_3 = props.Header;
                if (matchValue_3 == null) {
                    return empty();
                }
                else {
                    const header = matchValue_3;
                    return singleton_1(createElement("div", {
                        onMouseDown: header.getResizeHandler(),
                        className: join(" ", toList(delay(() => append(singleton_1("absolute"), delay(() => append(singleton_1("right-0"), delay(() => append(singleton_1("top-0"), delay(() => append(singleton_1("h-full"), delay(() => {
                            let matchValue_4;
                            return append((matchValue_4 = props.BorderStyle, (matchValue_4 === "row") ? (empty()) : ((matchValue_4 === "none") ? (empty()) : singleton_1("border-r"))), delay(() => append(singleton_1("bg-border"), delay(() => append(singleton_1("border-border"), delay(() => singleton_1("cursor-col-resize")))))));
                        }))))))))))),
                    }));
                }
            })))), ["children", reactApi.Children.toArray(Array.from(elems_1))]));
        }))))));
    })))), dependencies_1);
}

export function TableHeader(props) {
    let elems_4, elems_5;
    const patternInput = useTranslation();
    const columnOrder = props.Table.getState().columnOrder;
    const column = props.Header.column;
    const patternInput_1 = reactApi.useState("");
    const selectSearchValue = patternInput_1[0];
    const patternInput_2 = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: (_arg) => column,
        type: "column",
    });
    const previewRef = patternInput_2[2];
    const patternInput_3 = useDrop({
        accept: "column",
        drop: (draggedColumn) => {
            let arg;
            const draggedColumnId = draggedColumn.id;
            const targetColumnId = column.id;
            const columnOrder_1 = columnOrder;
            const newOrder = columnOrder_1.filter((columnId) => (columnId !== draggedColumnId));
            arg = defaultArg(map_3((index) => insertAt(index + 1, draggedColumnId, newOrder), tryFindIndex((columnId_1) => (columnId_1 === targetColumnId), newOrder)), columnOrder_1);
            props.Table.setColumnOrder(arg);
        },
    });
    return createElement(BaseTableHeader, {
        BorderStyle: props.BorderStyle,
        Children: ofArray([createElement("div", createObj(ofArray([["ref", previewRef], ["className", join(" ", ["flex", "gap-x-2", "items-center"])], ["data-test-id", `table-column-header-${props.ColumnId}`], (elems_4 = toList(delay(() => {
            let elems;
            return append(props.IsDraggable ? singleton_1(createElement("div", createObj(ofArray([["ref", patternInput_2[1]], ["className", join(" ", ["h-3", "w-3"])], (elems = [Hamburger_Menu_1()], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty(), delay(() => append(singleton_1(createElement("div", {
                className: join(" ", ["flex", "text-sm", "font-semibold", "text-left"]),
                children: props.Text,
            })), delay(() => {
                let elems_3;
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["h-3", "ml-auto"])], (elems_3 = toList(delay(() => {
                    let elems_1, elems_2;
                    const matchValue = props.Header.column.getIsSorted();
                    switch (matchValue) {
                        case "asc":
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["ml-2.5", "h-3", "w-3", "stroke-2", "text-primary"])], (elems_1 = [Arrow_Up_3()], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
                        case "desc":
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["ml-2.5", "h-3", "w-3", "stroke-2", "text-primary"])], (elems_2 = [Arrow_Down_3()], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
                        default: {
                            return empty();
                        }
                    }
                })), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
            }))));
        })), ["children", reactApi.Children.toArray(Array.from(elems_4))]), ["onClick", props.Header.column.getToggleSortingHandler()]]))), createElement("div", createObj(ofArray([["className", join(" ", ["print:hidden"])], (elems_5 = toList(delay(() => {
            let dependencies_1, dependencies_1_1, dependencies_1_2;
            const matchValue_1 = props.ColumnFilter;
            switch (matchValue_1) {
                case "text":
                    return singleton_1((dependencies_1 = [props.FilterValue], reactApi.useMemo(() => createElement(Input, {
                        ComponentState: "enabled",
                        OnChange: (value_22) => {
                            if (props.SetQueryParams) {
                                setQueryParams(props.ColumnId, value_22);
                            }
                            props.OnFilter(value_22);
                        },
                        TestId: `table-column-filter-${props.Key}-input`,
                        Value: defaultArg(ofNullable(props.FilterValue), ""),
                    }), dependencies_1)));
                case "select": {
                    const columnDefinition = FSharpMap__get_Item(props.ColumnDefinitionMap, props.ColumnId);
                    const tableColumn = props.Table.getColumn(props.ColumnId);
                    const filterValues = ofNullable(tableColumn.getFilterValue());
                    return singleton_1((dependencies_1_1 = [filterValues, props.Table.getCoreRowModel().rows, selectSearchValue], reactApi.useMemo(() => {
                        let array_4;
                        return createElement(SelectFilter, {
                            ButtonLabel: patternInput[0]("table.selection"),
                            ComponentState: "enabled",
                            Items: ofArray(map((value_26) => ({
                                Checked: (filterValues == null) ? false : contains(value_26.Name, filterValues, {
                                    Equals: (x_1, y_1) => (x_1 === y_1),
                                    GetHashCode: stringHash,
                                }),
                                Id: value_26.Name,
                                OnCheck: (_arg_3) => {
                                    let newFilterValue;
                                    if (filterValues == null) {
                                        newFilterValue = [value_26.Name];
                                    }
                                    else {
                                        const filterValues_2 = filterValues;
                                        newFilterValue = (contains(value_26.Name, filterValues_2, {
                                            Equals: (x_2, y_2) => (x_2 === y_2),
                                            GetHashCode: stringHash,
                                        }) ? filterValues_2.filter((filterValue) => (filterValue !== value_26.Name)) : append_1([value_26.Name], filterValues_2));
                                    }
                                    if (newFilterValue.length === 0) {
                                        props.OnFilter(undefined);
                                    }
                                    else {
                                        if (props.SetQueryParams) {
                                            setQueryParams(props.ColumnId, newFilterValue.reduce((a, b) => (`${a},${b}`)));
                                        }
                                        props.OnFilter(newFilterValue);
                                    }
                                },
                            }), (array_4 = Array_distinct(concat(map((row) => {
                                const matchValue_2 = columnDefinition.AccessorFunction(row.original);
                                switch (matchValue_2.tag) {
                                    case 1:
                                        return [];
                                    case 2:
                                        return [{
                                            Name: matchValue_2.fields[0],
                                        }];
                                    case 4:
                                        return [];
                                    case 3:
                                        return toArray(map_1((l) => ({
                                            Name: l.Name,
                                        }), matchValue_2.fields[0]));
                                    case 5:
                                        return [];
                                    case 6:
                                        return [];
                                    default:
                                        return [{
                                            Name: matchValue_2.fields[0],
                                        }];
                                }
                            }, props.Table.getCoreRowModel().rows)), {
                                Equals: equals_1,
                                GetHashCode: structuralHash,
                            }), array_4.filter((value_25) => {
                                if (isNullOrWhiteSpace(value_25.Name) ? true : isNullOrEmpty(value_25.Name)) {
                                    return true;
                                }
                                else {
                                    return value_25.Name.toLocaleLowerCase().indexOf(selectSearchValue.toLocaleLowerCase()) >= 0;
                                }
                            })))),
                            OnResetFilter: () => {
                                props.OnFilter(undefined);
                            },
                            OnSearch: patternInput_1[1],
                            SearchValue: selectSearchValue,
                            TestId: `${props.Key}-search-value-input`,
                        });
                    }, dependencies_1_1)));
                }
                case "dateRange": {
                    const tableColumn_1 = props.Table.getColumn(props.ColumnId);
                    const filterValues_3 = map_3((array_14) => map(parse, array_14), ofNullable(tableColumn_1.getFilterValue()));
                    let patternInput_4;
                    if (filterValues_3 == null) {
                        patternInput_4 = [undefined, undefined];
                    }
                    else {
                        const values = filterValues_3;
                        const matchValue_3 = values.length | 0;
                        patternInput_4 = ((matchValue_3 === 0) ? [undefined, undefined] : ((matchValue_3 === 1) ? [item(0, values), undefined] : ((matchValue_3 === 2) ? [item(0, values), item(1, values)] : [undefined, undefined])));
                    }
                    const startDate = patternInput_4[0];
                    const endDate = patternInput_4[1];
                    return singleton_1((dependencies_1_2 = [props.FilterValue, startDate, endDate], reactApi.useMemo(() => createElement(RangeDatePicker, {
                        ComponentState: "enabled",
                        EndDate: unwrap(endDate),
                        IsClearable: true,
                        OnChange: (value_30) => {
                            if (value_30 == null) {
                                if (props.SetQueryParams) {
                                    setQueryParams(props.ColumnId, "");
                                }
                                props.OnFilter(undefined);
                            }
                            else {
                                const dateRange = value_30;
                                const matchValue_4 = dateRange.EndDate;
                                if (matchValue_4 == null) {
                                    const filterValue_2 = toString(dateRange.StartDate, "yyyy-MM-dd");
                                    if (props.SetQueryParams) {
                                        setQueryParams(props.ColumnId, filterValue_2);
                                    }
                                    props.OnFilter([filterValue_2]);
                                }
                                else {
                                    const endDate_1 = matchValue_4;
                                    const startDate_1 = toString(dateRange.StartDate, "yyyy-MM-dd");
                                    const endDate_2 = toString(endDate_1, "yyyy-MM-dd");
                                    if (props.SetQueryParams) {
                                        setQueryParams(props.ColumnId, `${startDate_1},${endDate_2}`);
                                    }
                                    props.OnFilter([startDate_1, endDate_2]);
                                }
                            }
                        },
                        StartDate: unwrap(startDate),
                        TestId: `table-column-filter-${props.Key}-range-date-input`,
                    }), dependencies_1_2)));
                }
                default:
                    return singleton_1(createElement("div", {
                        className: join(" ", ["grow"]),
                    }));
            }
        })), ["children", reactApi.Children.toArray(Array.from(elems_5))])])))]),
        Classes: empty_1(),
        DropRef: patternInput_3[1],
        Header: props.Header,
        PreviewRef: previewRef,
        SetHeaderSize: props.SetHeaderSize,
        StickyHeader: props.StickyHeader,
        Table: props.Table,
        Width: props.Header.getSize(),
    });
}

export function CheckboxTableHeader(props) {
    const dependencies_1 = [props.Checked];
    return reactApi.useMemo(() => {
        let elems;
        return createElement(BaseTableHeader, {
            BorderStyle: props.BorderStyle,
            Children: singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "grow"])], (elems = toList(delay(() => (props.IsCheckboxVisible ? singleton_1(Checkbox({
                ComponentState: "enabled",
                IsChecked: props.Checked,
                Label: "",
                OnCheck: props.OnCheck,
                TestId: "",
            })) : empty()))), ["children", reactApi.Children.toArray(Array.from(elems))])])))),
            Classes: singleton("w-0"),
            SetHeaderSize: false,
            StickyHeader: props.StickyHeader,
            Table: props.Table,
            Width: 40,
        });
    }, dependencies_1);
}

export function BaseTableCellXX(props) {
    return createElement("div", createObj(toList(delay(() => append(singleton_1(["data-test-id", props.TestId]), delay(() => append(singleton_1(["className", join(" ", toList(delay(() => append(singleton_1("grow"), delay(() => {
        let matchValue;
        return append((matchValue = props.BorderStyle, (matchValue === "full") ? singleton_1("border") : ((matchValue === "row") ? singleton_1("border-y") : (empty()))), delay(() => append(singleton_1("border-border"), delay(() => append(singleton_1("py-2.5"), delay(() => append(singleton_1("px-4"), delay(() => singleton_1("group-hover:bg-[#F2F4FD]")))))))));
    })))))]), delay(() => append(singleton_1(["style", {
        minWidth: props.Width,
        width: props.Width,
    }]), delay(() => {
        let elems;
        return append(singleton_1((elems = toList(delay(() => {
            const matchValue_1 = props.OnClick;
            let matchResult, url;
            if (matchValue_1 == null) {
                matchResult = 1;
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                url = matchValue_1.fields[0];
            }
            switch (matchResult) {
                case 0:
                    return singleton_1(createElement("a", {
                        className: join(" ", ["block", "h-full", "w-full", "break-words"]),
                        href: url(props.Row.original),
                        children: props.Text,
                    }));
                default:
                    return singleton_1(createElement("div", {
                        children: props.Text,
                    }));
            }
        })), ["children", reactApi.Children.toArray(Array.from(elems))])), delay(() => {
            let url_1, onClick;
            const matchValue_2 = props.OnClick;
            if (matchValue_2 == null) {
                return empty();
            }
            else {
                return (matchValue_2.tag === 1) ? ((url_1 = matchValue_2.fields[0], singleton_1(["onClick", (_arg_1) => {
                    RouterModule_nav(singleton(url_1(props.Row.original)), 1, 1);
                }]))) : ((onClick = matchValue_2.fields[0], singleton_1(["onClick", (_arg) => {
                    onClick(props.Row.original);
                }])));
            }
        }));
    }))))))))));
}

export function BaseTableCell(props) {
    return createElement("div", createObj(toList(delay(() => append(singleton_1(["data-test-id", props.TestId]), delay(() => append(singleton_1(["className", join(" ", toList(delay(() => append(singleton_1("grow"), delay(() => {
        let matchValue;
        return append((matchValue = props.BorderStyle, (matchValue === "full") ? singleton_1("border") : ((matchValue === "row") ? singleton_1("border-y") : (empty()))), delay(() => append(singleton_1("border-border"), delay(() => append(singleton_1("py-2.5"), delay(() => append(singleton_1("px-4"), delay(() => singleton_1("group-hover:bg-[#F2F4FD]")))))))));
    })))))]), delay(() => append(singleton_1(["style", {
        minWidth: props.Width,
        width: props.Width,
    }]), delay(() => {
        let elems_1;
        return append(singleton_1((elems_1 = toList(delay(() => {
            const matchValue_1 = props.OnClick;
            let matchResult, url;
            if (matchValue_1 == null) {
                matchResult = 1;
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                url = matchValue_1.fields[0];
            }
            switch (matchResult) {
                case 0:
                    return singleton_1(createElement("a", {
                        className: join(" ", ["block", "h-full", "w-full", "break-words"]),
                        href: url(props.Row.original),
                        children: reactApi.Children.toArray([props.Content]),
                    }));
                default:
                    return singleton_1(createElement("div", {
                        children: reactApi.Children.toArray([props.Content]),
                    }));
            }
        })), ["children", reactApi.Children.toArray(Array.from(elems_1))])), delay(() => {
            let url_1, onClick;
            const matchValue_2 = props.OnClick;
            if (matchValue_2 == null) {
                return empty();
            }
            else {
                return (matchValue_2.tag === 1) ? ((url_1 = matchValue_2.fields[0], singleton_1(["onClick", (_arg_1) => {
                    RouterModule_nav(singleton(url_1(props.Row.original)), 1, 1);
                }]))) : ((onClick = matchValue_2.fields[0], singleton_1(["onClick", (_arg) => {
                    onClick(props.Row.original);
                }])));
            }
        }));
    }))))))))));
}

export function TextTableCell(props) {
    const dependencies_1 = [props.Text, props.Cell];
    return reactApi.useMemo(() => createElement(BaseTableCell, {
        BorderStyle: props.BorderStyle,
        Content: props.Text,
        OnClick: unwrap(props.OnClick),
        Row: props.Row,
        TestId: props.TestId,
        Width: props.Cell.column.getSize(),
    }), dependencies_1);
}

export function DateTimeTableCell(props) {
    const dependencies_1 = [props.Date, props.Cell];
    return reactApi.useMemo(() => {
        let Content, Width;
        return createElement(BaseTableCell, (Content = defaultArg(map_3((date) => toString(toLocalTime(date), "dd.MM.yyyy HH:mm"), props.Date), ""), (Width = (props.Cell.column.getSize() | 0), {
            BorderStyle: props.BorderStyle,
            Content: Content,
            OnClick: unwrap(props.OnClick),
            Row: props.Row,
            TestId: defaultArg(map_3((date_1) => toString(toLocalTime(date_1), "dd.MM.yyyy HH:mm"), props.Date), ""),
            Width: Width,
        })));
    }, dependencies_1);
}

export function DateTableCell(props) {
    const dependencies_1 = [props.Date, props.Cell];
    return reactApi.useMemo(() => createElement(BaseTableCell, {
        BorderStyle: props.BorderStyle,
        Content: defaultArg(map_3((date) => toString(toLocalTime(date), "dd.MM.yyyy"), props.Date), ""),
        OnClick: unwrap(props.OnClick),
        Row: props.Row,
        TestId: props.TestId,
        Width: props.Cell.column.getSize(),
    }), dependencies_1);
}

export function ComponentTableCell(props) {
    const dependencies_1 = [props.Component, props.Cell];
    return reactApi.useMemo(() => createElement(BaseTableCell, {
        BorderStyle: props.BorderStyle,
        Content: props.Component,
        OnClick: unwrap(props.OnClick),
        Row: props.Row,
        TestId: "label-table-cell",
        Width: props.Cell.column.getSize(),
    }), dependencies_1);
}

export function LabelTableCell(props) {
    const dependencies_1 = [props.Labels, props.Cell];
    return reactApi.useMemo(() => {
        let elems;
        return createElement(BaseTableCell, {
            BorderStyle: props.BorderStyle,
            Content: createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "items-center", "gap-2"])], (elems = toList(delay(() => mapIndexed((badgeIndex, label) => createElement(Badge, {
                Color: label.ColorHex,
                Label: label.Name,
                TestId: `${badgeIndex}-table-badge-test-id`,
            }), props.Labels))), ["children", reactApi.Children.toArray(Array.from(elems))])]))),
            OnClick: unwrap(props.OnClick),
            Row: props.Row,
            TestId: "label-table-cell",
            Width: props.Cell.column.getSize(),
        });
    }, dependencies_1);
}

export function ImageTableCell(props) {
    const patternInput = reactApi.useState(false);
    const setIsImageViewerOpen = patternInput[1];
    const dependencies_1 = [props.ImageUrl, props.Cell];
    return reactApi.useMemo(() => {
        let elems, props_2, props_3;
        return createElement(BaseTableCell, {
            BorderStyle: props.BorderStyle,
            Content: createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "items-center"])], (elems = [createElement("img", {
                src: props.ImageUrl,
                className: join(" ", ["max-w-[40px]", "z-10"]),
                onClick: (_arg_1) => {
                    setIsImageViewerOpen(true);
                },
            }), (props_2 = ofArray([new CommonProps(3, ["img-viewer z-10 relative"]), new CommonProps(2, [[new ImageDecorator(props.ImageUrl)]]), new CommonProps(0, [patternInput[0]]), new CommonProps(4, [() => {
                setIsImageViewerOpen(false);
            }])]), (props_3 = keyValueList(append_2(props_2, toList(delay(() => append(singleton_1(new CommonProps(6, [false])), delay(() => {
                const matchValue = tryFind_1((prop) => {
                    if (prop.tag === 4) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, props_2);
                let matchResult, onClose;
                if (matchValue != null) {
                    if (matchValue.tag === 4) {
                        matchResult = 0;
                        onClose = matchValue.fields[0];
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
                switch (matchResult) {
                    case 0:
                        return singleton_1(new CommonProps(5, [onClose]));
                    default: {
                        return empty();
                    }
                }
            }))))), 1), react.createElement(react_viewer, props_3)))], ["children", reactApi.Children.toArray(Array.from(elems))])]))),
            Row: props.Row,
            TestId: `${props.ImageUrl}-table-cell`,
            Width: props.Cell.column.getSize(),
        });
    }, dependencies_1);
}

export function ButtonTableCell(props) {
    const dependencies_1 = [props.ButtonContent, props.Cell];
    return reactApi.useMemo(() => createElement(BaseTableCell, {
        BorderStyle: props.BorderStyle,
        Content: createElement(TextButton, {
            ComponentState: "enabled",
            Label: props.ButtonContent.Label,
            OnClick: props.ButtonContent.OnClick,
            TestId: "",
            Variant: "blueButton",
        }),
        Row: props.Row,
        TestId: `${props.ButtonContent.Label}-table-cell`,
        Width: props.Cell.column.getSize(),
    }), dependencies_1);
}

export function CheckboxTableCell(props) {
    const dependencies_1 = [props.Checked, props.OnCheck];
    return reactApi.useMemo(() => {
        let elems_1, elems;
        return createElement("div", createObj(ofArray([["data-test-id", "checkbox-table-cell"], ["className", join(" ", toList(delay(() => append(singleton_1("grow"), delay(() => {
            let matchValue;
            return append((matchValue = props.BorderStyle, (matchValue === "full") ? singleton_1("border") : ((matchValue === "row") ? singleton_1("border-y") : (empty()))), delay(() => append(singleton_1("border-border"), delay(() => append(singleton_1("py-2.5"), delay(() => append(singleton_1("px-4"), delay(() => singleton_1("group-hover:bg-[#F2F4FD]")))))))));
        })))))], ["style", {
            minWidth: 40,
            width: 40,
        }], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["group-hover:bg-[#F2F4FD]", "flex", "items-center", "grow"])], (elems = [Checkbox({
            ComponentState: "enabled",
            IsChecked: props.Checked,
            Label: "",
            OnCheck: props.OnCheck,
            TestId: "",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
    }, dependencies_1);
}

export function BaseTable(props) {
    let elems_5;
    const dependencies = [props.GlobalSearch];
    reactApi.useEffect(() => {
        const matchValue = props.GlobalSearch;
        if (matchValue == null) {
        }
        else {
            const globalSearch = matchValue;
            props.Table.setGlobalFilter(globalSearch);
        }
    }, dependencies);
    let additionalColumns;
    const matchValue_1 = props.Options.RowSelection;
    switch (matchValue_1) {
        case "singleRow":
        case "multiRow": {
            additionalColumns = 2;
            break;
        }
        default:
            additionalColumns = 1;
    }
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("bg-white"), delay(() => append(singleton_1("rounded-md"), delay(() => {
        let matchValue_2;
        return append((matchValue_2 = props.Options.BorderStyle, (matchValue_2 === "row") ? (empty()) : ((matchValue_2 === "none") ? (empty()) : singleton_1("border"))), delay(() => append(singleton_1("border-border"), delay(() => append(singleton_1("w-full"), delay(() => append(singleton_1("grid"), delay(() => append(singleton_1("grid-rows-[auto,1fr]"), delay(() => {
            const matchValue_3 = props.Options.RowSelection;
            switch (matchValue_3) {
                case "singleRow":
                case "multiRow":
                    return singleton_1("grid-cols-[50px,repeat(fit-content,minmax(100px,1fr))]");
                default:
                    return singleton_1("grid-cols-[repeat(fit-content,minmax(100px,1fr))]");
            }
        }))))))))));
    })))))))], (elems_5 = toList(delay(() => {
        let matchValue_4, elems;
        return append((matchValue_4 = props.Options.RowSelection, (matchValue_4 === "singleRow") ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["col-start-1", "flex", "sticky", "top-0", "print:hidden"])], (elems = [createElement(CheckboxTableHeader, {
            BorderStyle: props.Options.BorderStyle,
            Checked: props.Table.getIsAllRowsSelected(),
            IsCheckboxVisible: props.Options.RowSelection === "multiRow",
            OnCheck: (arg) => {
                props.Table.toggleAllRowsSelected(arg);
            },
            StickyHeader: props.Options.StickyHeader,
            Table: props.Table,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : ((matchValue_4 === "multiRow") ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["col-start-1", "flex", "sticky", "top-0", "print:hidden"])], (elems = [createElement(CheckboxTableHeader, {
            BorderStyle: props.Options.BorderStyle,
            Checked: props.Table.getIsAllRowsSelected(),
            IsCheckboxVisible: props.Options.RowSelection === "multiRow",
            OnCheck: (arg) => {
                props.Table.toggleAllRowsSelected(arg);
            },
            StickyHeader: props.Options.StickyHeader,
            Table: props.Table,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : (empty()))), delay(() => append(ofArray(collect((x) => x, map((headerGroup) => mapIndexed_1((headerIndex, header) => {
            let elems_1, Key, Text$, ColumnFilter, matchValue_5, FilterValue, OnFilter, objectArg_1;
            return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "sticky", "top-0"])], ["style", {
                gridColumnStart: headerIndex + additionalColumns,
            }], (elems_1 = [createElement(TableHeader, (($value) => { $value.key = $value.Key; return $value; })((Key = header.id, (Text$ = header.column.columnDef.header, (ColumnFilter = ((matchValue_5 = tryFind_2(header.id, props.ColumnDefinitionsMap), (matchValue_5 == null) ? "none" : matchValue_5.Filter)), (FilterValue = header.column.getFilterValue(), (OnFilter = ((objectArg_1 = header.column, (arg_1) => {
                objectArg_1.setFilterValue(arg_1);
            })), {
                BorderStyle: props.Options.BorderStyle,
                ColumnDefinitionMap: props.ColumnDefinitionsMap,
                ColumnFilter: ColumnFilter,
                ColumnId: header.id,
                FilterValue: FilterValue,
                Header: header,
                IsDraggable: props.IsHeaderDraggable,
                Key: Key,
                OnFilter: OnFilter,
                SetHeaderSize: true,
                SetQueryParams: false,
                StickyHeader: props.Options.StickyHeader,
                Table: props.Table,
                Text: Text$,
            })))))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        }, headerGroup.headers), props.Table.getHeaderGroups()))), delay(() => ofArray(mapIndexed_1((rowIndex, row) => {
            let elems_4;
            return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("contents"), delay(() => append((props.Options.OnRowClick != null) ? singleton_1("hover:cursor-pointer") : empty(), delay(() => singleton_1("group"))))))))], (elems_4 = toList(delay(() => {
                let matchValue_6, elems_2;
                return append((matchValue_6 = props.Options.RowSelection, (matchValue_6 === "multiRow") ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["col-start-1", "flex", "group-hover:bg-[#F2F4FD]", "print:hidden"])], (elems_2 = [createElement(CheckboxTableCell, {
                    BorderStyle: props.Options.BorderStyle,
                    Checked: row.getIsSelected(),
                    OnCheck: (arg_2) => {
                        row.toggleSelected(arg_2);
                    },
                    Row: row,
                })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))) : ((matchValue_6 === "singleRow") ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["col-start-1", "flex", "group-hover:bg-[#F2F4FD]", "print:hidden"])], (elems_2 = [createElement(CheckboxTableCell, {
                    BorderStyle: props.Options.BorderStyle,
                    Checked: row.getIsSelected(),
                    OnCheck: (arg_2) => {
                        row.toggleSelected(arg_2);
                    },
                    Row: row,
                })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))) : (empty()))), delay(() => mapIndexed_1((cellIndex, cell) => {
                    let elems_3;
                    const columnDefinition_1 = FSharpMap__get_Item(props.ColumnDefinitionsMap, cell.column.id);
                    return createElement("div", createObj(ofArray([["className", join(" ", ["flex-1", "flex", "group-hover:bg-[#F2F4FD]"])], ["style", {
                        gridColumnStart: cellIndex + additionalColumns,
                    }], (elems_3 = toList(delay(() => {
                        const matchValue_7 = columnDefinition_1.AccessorFunction(row.original);
                        return (matchValue_7.tag === 5) ? singleton_1(createElement(DateTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            Date: unwrap(matchValue_7.fields[0]),
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                            TestId: `row-${rowIndex}-column-${columnDefinition_1.Id}`,
                        })) : ((matchValue_7.tag === 6) ? singleton_1(createElement(DateTimeTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            Date: matchValue_7.fields[0],
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                        })) : ((matchValue_7.tag === 1) ? singleton_1(createElement(ComponentTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            Component: matchValue_7.fields[0],
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                        })) : ((matchValue_7.tag === 3) ? singleton_1(createElement(LabelTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            Labels: matchValue_7.fields[0],
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                        })) : ((matchValue_7.tag === 4) ? singleton_1(createElement(ImageTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            ImageUrl: matchValue_7.fields[0],
                            Row: row,
                        })) : ((matchValue_7.tag === 2) ? singleton_1(createElement(ButtonTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            ButtonContent: {
                                Label: matchValue_7.fields[0],
                                OnClick: matchValue_7.fields[1],
                            },
                            Cell: cell,
                            Row: row,
                        })) : singleton_1(createElement(TextTableCell, {
                            BorderStyle: props.Options.BorderStyle,
                            Cell: cell,
                            OnClick: unwrap(props.Options.OnRowClick),
                            Row: row,
                            TestId: `row-${rowIndex}-column-${columnDefinition_1.Id}`,
                            Text: matchValue_7.fields[0],
                        })))))));
                    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
                }, row.getVisibleCells())));
            })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
        }, props.Table.getRowModel().rows))))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

export class TableControl$1 extends Record {
    constructor(Label, Icon, OnClick, EnabledOnlyOnSelection, EnabledOnlyOnSingleSelection, TestId) {
        super();
        this.Label = Label;
        this.Icon = Icon;
        this.OnClick = OnClick;
        this.EnabledOnlyOnSelection = EnabledOnlyOnSelection;
        this.EnabledOnlyOnSingleSelection = EnabledOnlyOnSingleSelection;
        this.TestId = TestId;
    }
}

export function TableControl$1_$reflection(gen0) {
    return record_type("Widgets.Table.TableControl`1", [gen0], TableControl$1, () => [["Label", string_type], ["Icon", option_type(class_type("Fable.React.ReactElement"))], ["OnClick", option_type(lambda_type(list_type(gen0), unit_type))], ["EnabledOnlyOnSelection", bool_type], ["EnabledOnlyOnSingleSelection", bool_type], ["TestId", string_type]]);
}

export function Table(props) {
    let getCoreRowModel, getFilteredRowModel, getSortedRowModel, state, enableRowSelection, matchValue_6, matchValue_7, elems_4;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const patternInput_2 = reactApi.useState("");
    const globalSearch = patternInput_2[0];
    const patternInput_3 = reactApi.useState(() => ({}));
    let patternInput_4;
    const initial_2 = defaultArg(map_3(toArray, props.Options.DefaultColumnFilter), []);
    patternInput_4 = reactApi.useState(initial_2);
    const columnFilters = patternInput_4[0];
    let patternInput_5;
    let initial_3;
    const matchValue = props.TableConfiguration;
    if (matchValue == null) {
        initial_3 = {};
    }
    else {
        const matchValue_1 = matchValue.TableConfiguration;
        if (matchValue_1 == null) {
            initial_3 = {};
        }
        else {
            const tableConfiguration_1 = matchValue_1;
            initial_3 = createObj(map((columndDefinition) => [columndDefinition.Id, false], props.ColumnDefinitions.filter((columnDefinition) => !contains_1(columnDefinition.Id, tableConfiguration_1.VisibleColumns, {
                Equals: (x, y) => (x === y),
                GetHashCode: stringHash,
            }))));
        }
    }
    patternInput_5 = reactApi.useState(initial_3);
    const columnVisibility = patternInput_5[0];
    let patternInput_6;
    let initial_4;
    const matchValue_2 = props.TableConfiguration;
    if (matchValue_2 == null) {
        initial_4 = map((column_1) => column_1.Id, props.ColumnDefinitions);
    }
    else {
        const matchValue_3 = matchValue_2.TableConfiguration;
        initial_4 = ((matchValue_3 == null) ? map((column) => column.Id, props.ColumnDefinitions) : toArray(matchValue_3.ColumnOrder));
    }
    patternInput_6 = reactApi.useState(initial_4);
    const columnOrder = patternInput_6[0];
    let patternInput_7;
    const initial_5 = [{
        desc: props.Options.DefaultSortColumn[1] === "desc",
        id: props.Options.DefaultSortColumn[0],
    }];
    patternInput_7 = reactApi.useState(initial_5);
    const isHeaderDraggable = props.TableConfiguration != null;
    const columnDefinitionMap = ofArray_1(map((columnDefinition_1) => [columnDefinition_1.Id, columnDefinition_1], props.ColumnDefinitions), {
        Compare: comparePrimitives,
    });
    const columnDefinition_2 = createColumnDefinition(props.ColumnDefinitions, columnFilters);
    const getColumnVisibilityConfiguration = (visibility) => {
        const hiddenColumns_1 = Array.from(Object.keys(visibility));
        return map((columndDefinition_1) => columndDefinition_1.Id, props.ColumnDefinitions.filter((columnDefinition_3) => !contains(columnDefinition_3.Id, hiddenColumns_1, {
            Equals: (x_2, y_2) => (x_2 === y_2),
            GetHashCode: stringHash,
        })));
    };
    const updateColumnVisibility = (visibility_1) => {
        const matchValue_4 = props.TableConfiguration;
        if (matchValue_4 == null) {
        }
        else {
            matchValue_4.OnSaveTableConfiguration(new TableConfiguration(ofArray(columnOrder), ofArray(getColumnVisibilityConfiguration(visibility_1))));
        }
    };
    const dependencies = [columnVisibility];
    reactApi.useEffect(() => {
        updateColumnVisibility(columnVisibility);
    }, dependencies);
    const table = useReactTable((getCoreRowModel = getCoreRowModel_1(), (getFilteredRowModel = getFilteredRowModel_1(), (getSortedRowModel = getSortedRowModel_1(), (state = {
        columnFilters: columnFilters,
        columnOrder: columnOrder,
        columnVisibility: columnVisibility,
        rowSelection: patternInput_3[0],
        sorting: patternInput_7[0],
    }, (enableRowSelection = ((matchValue_6 = props.Options.RowSelection, (matchValue_6 === "singleRow") ? true : (matchValue_6 === "multiRow"))), {
        columnResizeMode: "onChange",
        columns: columnDefinition_2,
        data: props.TableRows,
        debugTable: false,
        defaultColumn: {
            maxSize: 10000,
            minSize: 75,
            size: 200,
        },
        enableGlobalFilter: true,
        enableMultiRowSelection: (matchValue_7 = props.Options.RowSelection, (matchValue_7 === "singleRow") ? false : (matchValue_7 === "multiRow")),
        enableRowSelection: enableRowSelection,
        enableSortingRemoval: false,
        getCoreRowModel: getCoreRowModel,
        getFilteredRowModel: getFilteredRowModel,
        getSortedRowModel: getSortedRowModel,
        onColumnFiltersChange: patternInput_4[1],
        onColumnOrderChange: (newOrder) => {
            patternInput_6[1](newOrder);
            const matchValue_5 = props.TableConfiguration;
            if (matchValue_5 == null) {
            }
            else {
                matchValue_5.OnSaveTableConfiguration(new TableConfiguration(ofArray(newOrder), ofArray(getColumnVisibilityConfiguration(columnVisibility))));
            }
        },
        onColumnVisibilityChange: (visibility_2) => {
            updateColumnVisibility(visibility_2());
            patternInput_5[1](visibility_2);
        },
        onRowSelectionChange: patternInput_3[1],
        onSortingChange: patternInput_7[1],
        state: state,
    }))))));
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-[calc(100%-40px)]"])], (elems_4 = toList(delay(() => {
        let elems_2, elems, elems_1;
        return append((((!isEmpty(props.Controls) ? true : props.Options.ConfigurableColumns) ? true : props.Options.GlobalSearch) ? true : props.Options.CSVDownload) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("flex"), delay(() => append(singleton_1("px-6"), delay(() => append(singleton_1("top-[0px]"), delay(() => append(singleton_1("left-1"), delay(() => append(singleton_1("bg-[#fefefe]"), delay(() => append(singleton_1("z-10"), delay(() => {
            const matchValue_8 = props.Options.BorderStyle;
            switch (matchValue_8) {
                case "full":
                case "none": {
                    return empty();
                }
                default:
                    return append(singleton_1("border-b"), delay(() => singleton_1("border-border")));
            }
        })))))))))))))))], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "gap-5", "grow", "print:hidden"])], (elems = toList(delay(() => map_1((control) => {
            let OnClick, matchValue_9, onClick, array_14;
            return createElement(TextButton, (OnClick = ((matchValue_9 = control.OnClick, (matchValue_9 == null) ? (() => {
            }) : ((onClick = matchValue_9, () => {
                onClick(ofArray(map((row) => row.original, table.getSelectedRowModel().rows)));
                table.toggleAllRowsSelected(false);
            })))), {
                ComponentState: (control.EnabledOnlyOnSingleSelection && (props.Options.RowSelection === "multiRow")) ? ((table.getSelectedRowModel().rows.length === 1) ? "enabled" : "disabled") : (control.EnabledOnlyOnSelection ? (((array_14 = table.getSelectedRowModel().rows, array_14.length === 0)) ? "disabled" : "enabled") : "enabled"),
                Icon: unwrap(control.Icon),
                Label: control.Label,
                OnClick: OnClick,
                TestId: control.TestId,
                Variant: "default",
            }));
        }, props.Controls))), ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "gap-5", "py-5"])], (elems_1 = toList(delay(() => append(props.Options.GlobalSearch ? singleton_1(createElement(IconInput, {
            ComponentState: "enabled",
            LeftIcon: Magnifying_Glass(),
            OnChange: patternInput_2[1],
            TestId: "",
            Value: globalSearch,
        })) : empty(), delay(() => append(props.Options.ConfigurableColumns ? singleton_1(createElement(ColumnSelect, {
            ButtonLabel: patternInput[0]("table.columns"),
            ComponentState: "enabled",
            Items: ofArray(map((column_2) => {
                let matchValue_10;
                const Id = column_2.columnDef.id;
                return {
                    Checked: column_2.getIsVisible(),
                    Group: (matchValue_10 = FSharpMap__TryFind(columnDefinitionMap, column_2.id), (matchValue_10 == null) ? "" : matchValue_10.Group),
                    Id: Id,
                    Name: column_2.columnDef.header,
                    OnCheck: (_arg_2) => {
                        column_2.toggleVisibility(!column_2.getIsVisible());
                    },
                };
            }, table.getAllLeafColumns())),
            TestId: "",
        })) : empty(), delay(() => (props.Options.CSVDownload ? append(singleton_1(createElement(TextButton, {
            ComponentState: "enabled",
            Icon: Download_File(),
            Label: "",
            OnClick: () => {
                patternInput_1[1](true);
            },
            TestId: "download-csv-button",
            Variant: "default",
        })), delay(() => {
            if (patternInput_1[0]) {
                const csvData = toArray_1(delay(() => {
                    let array_17;
                    return append(singleton_1(map((column_4) => FSharpMap__get_Item(columnDefinitionMap, column_4.id).Header, (array_17 = table.getAllLeafColumns(), array_17.filter((column_3) => column_3.getIsVisible())))), delay(() => map((row_1) => map((cell) => {
                        const matchValue_11 = FSharpMap__get_Item(columnDefinitionMap, cell.column.id).AccessorFunction(row_1.original);
                        switch (matchValue_11.tag) {
                            case 3: {
                                const labels = matchValue_11.fields[0];
                                if (length(labels) === 0) {
                                    return "";
                                }
                                else {
                                    return reduce((labels_1, label_1) => (`${labels_1}, ${label_1}`), map_1((label) => label.Name, labels));
                                }
                            }
                            case 1:
                                return "";
                            case 2:
                                return matchValue_11.fields[0];
                            case 4:
                                return "";
                            case 5:
                                return defaultArg(map_3((date_1) => toString(toLocalTime(date_1), "dd.MM.yyyy"), matchValue_11.fields[0]), "");
                            case 6:
                                return toString(toLocalTime(matchValue_11.fields[0]), "dd.MM.yyyy HH:mm");
                            default:
                                return matchValue_11.fields[0];
                        }
                    }, row_1.getVisibleCells()), table.getRowModel().rows)));
                }));
                return singleton_1(reactApi_1.createElement(CSVDownload, {
                    data: csvData,
                    separator: ";",
                }));
            }
            else {
                return empty();
            }
        })) : empty()))))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))) : empty(), delay(() => {
            let elems_3;
            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["h-[calc(100%-80px)]", "overflow-x-auto", "bg-white", "grow", "print:overflow-visible", "print:w-full", "print:block"])], (elems_3 = [createElement(BaseTable, {
                ColumnDefinitions: props.ColumnDefinitions,
                ColumnDefinitionsMap: columnDefinitionMap,
                GlobalSearch: globalSearch,
                IsHeaderDraggable: isHeaderDraggable,
                Options: {
                    BorderStyle: props.Options.BorderStyle,
                    ConfigurableColumns: props.Options.ConfigurableColumns,
                    OnRowClick: unwrap(props.Options.OnRowClick),
                    RowSelection: props.Options.RowSelection,
                    StickyHeader: true,
                },
                Table: table,
                TableRows: props.TableRows,
            })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

