import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { contains, filter, map, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { UserId, FormState__setValidationResponse_5219762A, FormState__startLoading, StorageId__get_unwrap, UserId__get_unwrap, FormState_get_empty, RequestedValue$1, NotificationToken as NotificationToken_1, LoginInformation as LoginInformation_5 } from "../../Shared/Shared.js";
import { Requests_EditUserFormDto_$reflection, Requests_EditUserFormDto, Requests_CreateUserFormDto_$reflection, Requests_CreateUserFormDto, User_$reflection, User, UserConfiguration, QuickOverviewConfiguration, ActivatedEmailNotification, AllowUserToSubcontractorTransfer } from "../../Shared/User.js";
import { ofSeq } from "../../fable_modules/fable-library-js.4.19.2/Map.js";
import { equals, stringHash, comparePrimitives } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Msg, State, UserForm } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { Storage_$reflection } from "../../Shared/Storage.js";
import { string_type, list_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { PropertyConfigurationEnabledResponse, PropertyConfiguration_$reflection, PropertyConfigurationEnabledResponse_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { unwrapUserId } from "../../Shared/Helper.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export function ErrorMessage(errorMessageInputProps) {
    const messageKey = errorMessageInputProps.messageKey;
    return createElement("span", {
        children: [useTranslation()[0](messageKey)],
    });
}

export const emptyUser = new User("", "", "", "", empty(), empty(), new LoginInformation_5(0, [""]), undefined, "administrator", false, new NotificationToken_1(""), empty(), new AllowUserToSubcontractorTransfer(1, []), "", "de", new ActivatedEmailNotification(true, true, true, true, true, true), new UserConfiguration(ofSeq([], {
    Compare: comparePrimitives,
}), new QuickOverviewConfiguration(empty(), empty(), empty())), false, empty());

export const emptyUserForm = new UserForm(emptyUser, empty(), empty());

export const initialState = new State(undefined, new RequestedValue$1(1, [new UserForm(emptyUser, empty(), empty())]), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), FormState_get_empty());

export function initNew() {
    return [new State(initialState.UserId, new RequestedValue$1(1, [emptyUserForm]), initialState.Storages, initialState.CustomerConfiguration, initialState.DepartmentSuggestions, initialState.IsCustomPropertiesEnabled, initialState.PropertyConfigurations, initialState.FormState), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item) => (new Msg(19, [Item])), (Item_1) => (new Msg(24, [Item_1]))), getCustomerConfiguration((Item_2) => (new Msg(20, [Item_2])), (Item_3) => (new Msg(24, [Item_3]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(string_type)));
    })))), "/api/receivers/suggestions/department", (Item_4) => (new Msg(21, [Item_4])), (Item_5) => (new Msg(24, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_6) => (new Msg(22, [Item_6])), (Item_7) => (new Msg(24, [Item_7]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn_1) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_5 = _arg_1;
            return (result_5.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_5.fields[0]);
            })())) : (Promise.resolve(result_5.fields[0]));
        });
    })), "/api/property-configurations", (Item_8) => (new Msg(23, [Item_8])), (Item_9) => (new Msg(24, [Item_9])))]))];
}

export function initEdit(userId) {
    return [new State(UserId__get_unwrap(userId), initialState.Form, initialState.Storages, initialState.CustomerConfiguration, initialState.DepartmentSuggestions, initialState.IsCustomPropertiesEnabled, initialState.PropertyConfigurations, initialState.FormState), Cmd_batch(ofArray([Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), User_$reflection()));
    })))), `/api/receivers/users/${unwrapUserId(userId)}`, (Item) => (new Msg(17, [Item])), (Item_1) => (new Msg(24, [Item_1]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_2) => (new Msg(19, [Item_2])), (Item_3) => (new Msg(24, [Item_3]))), getCustomerConfiguration((Item_4) => (new Msg(20, [Item_4])), (Item_5) => (new Msg(24, [Item_5]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(string_type)));
    })))), "/api/receivers/suggestions/department", (Item_6) => (new Msg(21, [Item_6])), (Item_7) => (new Msg(24, [Item_7]))), Cmd_OfPromise_either((url_9) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_11;
        return ((url_11 = addPrefix_1(url_9), (refreshOn) => fetchWithDecoder_1(url_11, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_3) => {
            const pr_3 = response_3.arrayBuffer();
            return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), PropertyConfigurationEnabledResponse_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_4 = _arg;
            return (result_4.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_4.fields[0]);
            })())) : (Promise.resolve(result_4.fields[0]));
        });
    })), "/api/property-configurations/enabled", (Item_8) => (new Msg(22, [Item_8])), (Item_9) => (new Msg(24, [Item_9]))), Cmd_OfPromise_either((url_12) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_14;
        return ((url_14 = addPrefix_1(url_12), (refreshOn_1) => fetchWithDecoder_1(url_14, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
            const pr_4 = response_4.arrayBuffer();
            return pr_4.then((blob_4) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_4)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn_1)))(true).then((_arg_1) => {
            const result_6 = _arg_1;
            return (result_6.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_6.fields[0]);
            })())) : (Promise.resolve(result_6.fields[0]));
        });
    })), "/api/property-configurations", (Item_10) => (new Msg(23, [Item_10])), (Item_11) => (new Msg(24, [Item_11])))]))];
}

export function update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7, bind$0040_8, bind$0040_9, bind$0040_10, fullPath_1, xs, bind$0040;
    switch (msg.tag) {
        case 8: {
            const matchValue_1 = state.Form;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_1 = matchValue_1.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040_1 = user_1.User, new User(bind$0040_1.Id, bind$0040_1.ImageUrl, bind$0040_1.Name, bind$0040_1.PersonnelNumber, bind$0040_1.Events, bind$0040_1.QRCodeIds, bind$0040_1.LoginInformation, bind$0040_1.LocationId, bind$0040_1.Role, bind$0040_1.IsDeleted, bind$0040_1.NotificationToken, bind$0040_1.ShownWelcomeTourSteps, bind$0040_1.AllowUserToSubcontractorTransfer, bind$0040_1.Department, bind$0040_1.Language, msg.fields[0], bind$0040_1.UserConfiguration, bind$0040_1.IsArchived, bind$0040_1.CustomProperties)), user_1.SelectedAssignedStorages, user_1.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 0: {
            const matchValue_2 = state.Form;
            if (matchValue_2.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_2 = matchValue_2.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040_2 = user_2.User, new User(bind$0040_2.Id, bind$0040_2.ImageUrl, bind$0040_2.Name, msg.fields[0], bind$0040_2.Events, bind$0040_2.QRCodeIds, bind$0040_2.LoginInformation, bind$0040_2.LocationId, bind$0040_2.Role, bind$0040_2.IsDeleted, bind$0040_2.NotificationToken, bind$0040_2.ShownWelcomeTourSteps, bind$0040_2.AllowUserToSubcontractorTransfer, bind$0040_2.Department, bind$0040_2.Language, bind$0040_2.ActivatedEmailNotification, bind$0040_2.UserConfiguration, bind$0040_2.IsArchived, bind$0040_2.CustomProperties)), user_2.SelectedAssignedStorages, user_2.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 2: {
            const matchValue_3 = state.Form;
            if (matchValue_3.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_3 = matchValue_3.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040_3 = user_3.User, new User(bind$0040_3.Id, bind$0040_3.ImageUrl, bind$0040_3.Name, bind$0040_3.PersonnelNumber, bind$0040_3.Events, bind$0040_3.QRCodeIds, new LoginInformation_5(0, [msg.fields[0]]), bind$0040_3.LocationId, bind$0040_3.Role, bind$0040_3.IsDeleted, bind$0040_3.NotificationToken, bind$0040_3.ShownWelcomeTourSteps, bind$0040_3.AllowUserToSubcontractorTransfer, bind$0040_3.Department, bind$0040_3.Language, bind$0040_3.ActivatedEmailNotification, bind$0040_3.UserConfiguration, bind$0040_3.IsArchived, bind$0040_3.CustomProperties)), user_3.SelectedAssignedStorages, user_3.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 3: {
            const matchValue_4 = state.Form;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_4 = matchValue_4.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040_4 = user_4.User, new User(bind$0040_4.Id, bind$0040_4.ImageUrl, bind$0040_4.Name, bind$0040_4.PersonnelNumber, bind$0040_4.Events, bind$0040_4.QRCodeIds, new LoginInformation_5(1, [msg.fields[0]]), bind$0040_4.LocationId, bind$0040_4.Role, bind$0040_4.IsDeleted, bind$0040_4.NotificationToken, bind$0040_4.ShownWelcomeTourSteps, bind$0040_4.AllowUserToSubcontractorTransfer, bind$0040_4.Department, bind$0040_4.Language, bind$0040_4.ActivatedEmailNotification, bind$0040_4.UserConfiguration, bind$0040_4.IsArchived, bind$0040_4.CustomProperties)), user_4.SelectedAssignedStorages, user_4.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 4: {
            const matchValue_5 = state.Form;
            if (matchValue_5.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_5 = matchValue_5.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040_5 = user_5.User, new User(bind$0040_5.Id, bind$0040_5.ImageUrl, msg.fields[0], bind$0040_5.PersonnelNumber, bind$0040_5.Events, bind$0040_5.QRCodeIds, bind$0040_5.LoginInformation, bind$0040_5.LocationId, bind$0040_5.Role, bind$0040_5.IsDeleted, bind$0040_5.NotificationToken, bind$0040_5.ShownWelcomeTourSteps, bind$0040_5.AllowUserToSubcontractorTransfer, bind$0040_5.Department, bind$0040_5.Language, bind$0040_5.ActivatedEmailNotification, bind$0040_5.UserConfiguration, bind$0040_5.IsArchived, bind$0040_5.CustomProperties)), user_5.SelectedAssignedStorages, user_5.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 5: {
            const roleOption = msg.fields[0];
            const matchValue_6 = state.Form;
            if (matchValue_6.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_6 = matchValue_6.fields[0];
                if (roleOption == null) {
                    return [state, Cmd_none()];
                }
                else {
                    return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040_6 = user_6.User, new User(bind$0040_6.Id, bind$0040_6.ImageUrl, bind$0040_6.Name, bind$0040_6.PersonnelNumber, bind$0040_6.Events, bind$0040_6.QRCodeIds, bind$0040_6.LoginInformation, bind$0040_6.LocationId, roleOption.value, bind$0040_6.IsDeleted, bind$0040_6.NotificationToken, bind$0040_6.ShownWelcomeTourSteps, bind$0040_6.AllowUserToSubcontractorTransfer, bind$0040_6.Department, bind$0040_6.Language, bind$0040_6.ActivatedEmailNotification, bind$0040_6.UserConfiguration, bind$0040_6.IsArchived, bind$0040_6.CustomProperties)), user_6.SelectedAssignedStorages, user_6.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
                }
            }
        }
        case 6: {
            const matchValue_7 = state.Form;
            if (matchValue_7.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_7 = matchValue_7.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040_7 = user_7.User, new User(bind$0040_7.Id, bind$0040_7.ImageUrl, bind$0040_7.Name, bind$0040_7.PersonnelNumber, bind$0040_7.Events, bind$0040_7.QRCodeIds, bind$0040_7.LoginInformation, bind$0040_7.LocationId, bind$0040_7.Role, bind$0040_7.IsDeleted, bind$0040_7.NotificationToken, bind$0040_7.ShownWelcomeTourSteps, msg.fields[0] ? (new AllowUserToSubcontractorTransfer(0, [])) : (new AllowUserToSubcontractorTransfer(1, [])), bind$0040_7.Department, bind$0040_7.Language, bind$0040_7.ActivatedEmailNotification, bind$0040_7.UserConfiguration, bind$0040_7.IsArchived, bind$0040_7.CustomProperties)), user_7.SelectedAssignedStorages, user_7.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 7: {
            const matchValue_8 = state.Form;
            if (matchValue_8.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_8 = matchValue_8.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040_8 = user_8.User, new User(bind$0040_8.Id, bind$0040_8.ImageUrl, bind$0040_8.Name, bind$0040_8.PersonnelNumber, bind$0040_8.Events, bind$0040_8.QRCodeIds, bind$0040_8.LoginInformation, bind$0040_8.LocationId, bind$0040_8.Role, bind$0040_8.IsDeleted, bind$0040_8.NotificationToken, bind$0040_8.ShownWelcomeTourSteps, bind$0040_8.AllowUserToSubcontractorTransfer, bind$0040_8.Department, bind$0040_8.Language, bind$0040_8.ActivatedEmailNotification, bind$0040_8.UserConfiguration, bind$0040_8.IsArchived, msg.fields[0])), user_8.SelectedAssignedStorages, user_8.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 9: {
            const matchValue_9 = state.Form;
            if (matchValue_9.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_9 = matchValue_9.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm(user_9.User, msg.fields[0], user_9.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 10: {
            const matchValue_10 = state.Form;
            if (matchValue_10.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_10 = matchValue_10.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm(user_10.User, user_10.SelectedAssignedStorages, msg.fields[0])]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 11: {
            const matchValue_11 = state.Form;
            if (matchValue_11.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_11 = matchValue_11.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [msg.fields[0] ? (new UserForm((bind$0040_9 = user_11.User, new User(bind$0040_9.Id, bind$0040_9.ImageUrl, bind$0040_9.Name, bind$0040_9.PersonnelNumber, bind$0040_9.Events, bind$0040_9.QRCodeIds, new LoginInformation_5(1, [""]), bind$0040_9.LocationId, bind$0040_9.Role, bind$0040_9.IsDeleted, bind$0040_9.NotificationToken, bind$0040_9.ShownWelcomeTourSteps, bind$0040_9.AllowUserToSubcontractorTransfer, bind$0040_9.Department, bind$0040_9.Language, bind$0040_9.ActivatedEmailNotification, bind$0040_9.UserConfiguration, bind$0040_9.IsArchived, bind$0040_9.CustomProperties)), user_11.SelectedAssignedStorages, user_11.SelectedSelfServiceStorages)) : (new UserForm((bind$0040_10 = user_11.User, new User(bind$0040_10.Id, bind$0040_10.ImageUrl, bind$0040_10.Name, bind$0040_10.PersonnelNumber, bind$0040_10.Events, bind$0040_10.QRCodeIds, new LoginInformation_5(0, [""]), bind$0040_10.LocationId, bind$0040_10.Role, bind$0040_10.IsDeleted, bind$0040_10.NotificationToken, bind$0040_10.ShownWelcomeTourSteps, bind$0040_10.AllowUserToSubcontractorTransfer, bind$0040_10.Department, bind$0040_10.Language, bind$0040_10.ActivatedEmailNotification, bind$0040_10.UserConfiguration, bind$0040_10.IsArchived, bind$0040_10.CustomProperties)), user_11.SelectedAssignedStorages, user_11.SelectedSelfServiceStorages))]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
        case 12: {
            const matchValue_12 = state.Form;
            if (matchValue_12.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user_12 = matchValue_12.fields[0];
                const dto = new Requests_CreateUserFormDto(user_12.User.Name, user_12.User.PersonnelNumber, user_12.User.Department, user_12.User.ImageUrl, user_12.User.LoginInformation, user_12.User.Role, map((storage) => StorageId__get_unwrap(storage.value.Id), user_12.SelectedAssignedStorages), map((storage_1) => StorageId__get_unwrap(storage_1.value.Id), user_12.SelectedSelfServiceStorages), user_12.User.AllowUserToSubcontractorTransfer, user_12.User.ActivatedEmailNotification, user_12.User.CustomProperties);
                return [new State(state.UserId, state.Form, state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), ["/api/receivers/users", toString(0, Auto_generateBoxedEncoder_437914C6(Requests_CreateUserFormDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(18, [Item])), (Item_1) => (new Msg(24, [Item_1])))];
            }
        }
        case 13: {
            const matchValue_13 = state.UserId;
            const matchValue_14 = state.Form;
            let matchResult, user_14, userId;
            if (matchValue_13 == null) {
                matchResult = 1;
            }
            else if (matchValue_14.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                user_14 = matchValue_14.fields[0];
                userId = matchValue_13;
            }
            switch (matchResult) {
                case 0: {
                    const dto_1 = new Requests_EditUserFormDto(user_14.User.Name, user_14.User.PersonnelNumber, user_14.User.Department, user_14.User.ImageUrl, user_14.User.Role, map((storage_2) => StorageId__get_unwrap(storage_2.value.Id), user_14.SelectedAssignedStorages), map((storage_3) => StorageId__get_unwrap(storage_3.value.Id), user_14.SelectedSelfServiceStorages), user_14.User.AllowUserToSubcontractorTransfer, user_14.User.ActivatedEmailNotification, user_14.User.CustomProperties);
                    return [new State(state.UserId, state.Form, state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__startLoading(state.FormState)), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let url_5;
                        return ((url_5 = addPrefix_1(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder_1(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                            const pr_1 = response_1.arrayBuffer();
                            return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                        }, refreshOn_1)))(true);
                    })), [`/api/receivers/users/${userId}`, toString(0, Auto_generateBoxedEncoder_437914C6(Requests_EditUserFormDto_$reflection(), undefined, undefined, undefined)(dto_1))], (Item_2) => (new Msg(18, [Item_2])), (Item_3) => (new Msg(24, [Item_3])))];
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 14: {
            const matchValue_16 = state.Form;
            const matchValue_17 = state.Storages;
            let matchResult_1, form_1, storages;
            if (matchValue_16.tag === 1) {
                if (matchValue_17.tag === 1) {
                    matchResult_1 = 0;
                    form_1 = matchValue_16.fields[0];
                    storages = matchValue_17.fields[0];
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0:
                    return [new State(state.UserId, new RequestedValue$1(1, [new UserForm(form_1.User, map((storage_5) => ({
                        label: storage_5.Name,
                        value: storage_5,
                    }), filter((storage_4) => contains(form_1.User.Id, storage_4.AssignedUserIds, {
                        Equals: (x_2, y) => (x_2 === y),
                        GetHashCode: stringHash,
                    }), storages)), map((storage_7) => ({
                        label: storage_7.Name,
                        value: storage_7,
                    }), filter((storage_6) => contains(form_1.User.Id, storage_6.AccessByUser, {
                        Equals: (x_3, y_1) => (x_3 === y_1),
                        GetHashCode: stringHash,
                    }), storages)))]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 17:
            return [new State(state.UserId, new RequestedValue$1(1, [new UserForm(msg.fields[0], empty(), empty())]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), singleton((dispatch) => {
                dispatch(new Msg(14, []));
            })];
        case 18: {
            const response_2 = msg.fields[0];
            if (response_2.tag === 1) {
                return [new State(state.UserId, state.Form, state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, FormState__setValidationResponse_5219762A(state.FormState, response_2.fields[0])), Cmd_errorToast("validation.toast")];
            }
            else {
                return [state, Cmd_batch(ofArray([(fullPath_1 = RouterModule_encodeParts(ofArray(["receivers", response_2.fields[0]]), 1), Cmd_ofEffect((_arg) => {
                    RouterModule_nav(singleton(fullPath_1), 1, 1);
                })), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 19:
            return [new State(state.UserId, state.Form, new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), singleton((dispatch_1) => {
                dispatch_1(new Msg(14, []));
            })];
        case 20:
            return [new State(state.UserId, state.Form, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
        case 15: {
            const matchValue_19 = state.Form;
            if (matchValue_19.tag === 1) {
                return [state, Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_4) => {
                    const pr_2 = response_4.arrayBuffer();
                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), User_$reflection()));
                })))), `/api/receivers/users/${unwrapUserId(new UserId(matchValue_19.fields[0].User.Id))}`, (Item_4) => (new Msg(17, [Item_4])), (Item_5) => (new Msg(24, [Item_5])))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 16: {
            const matchValue_20 = state.Form;
            if (matchValue_20.tag === 1) {
                return [state, (xs = ["users", "view", "user", matchValue_20.fields[0].User.Id], Cmd_ofEffect((_arg_1) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                }))];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 21:
            return [new State(state.UserId, state.Form, state.Storages, state.CustomerConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
        case 22:
            return [new State(state.UserId, state.Form, state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, new RequestedValue$1(1, [equals(msg.fields[0], new PropertyConfigurationEnabledResponse(0, []))]), state.PropertyConfigurations, state.FormState), Cmd_none()];
        case 23:
            return [new State(state.UserId, state.Form, state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, new RequestedValue$1(1, [filter((propertyConfiguration) => {
                const matchValue_21 = propertyConfiguration.Visibility;
                switch (matchValue_21.tag) {
                    case 0:
                    case 1:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.FormState), Cmd_none()];
        case 24:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.Form;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const user = matchValue.fields[0];
                return [new State(state.UserId, new RequestedValue$1(1, [new UserForm((bind$0040 = user.User, new User(bind$0040.Id, bind$0040.ImageUrl, bind$0040.Name, bind$0040.PersonnelNumber, bind$0040.Events, bind$0040.QRCodeIds, bind$0040.LoginInformation, bind$0040.LocationId, bind$0040.Role, bind$0040.IsDeleted, bind$0040.NotificationToken, bind$0040.ShownWelcomeTourSteps, bind$0040.AllowUserToSubcontractorTransfer, msg.fields[0], bind$0040.Language, bind$0040.ActivatedEmailNotification, bind$0040.UserConfiguration, bind$0040.IsArchived, bind$0040.CustomProperties)), user.SelectedAssignedStorages, user.SelectedSelfServiceStorages)]), state.Storages, state.CustomerConfiguration, state.DepartmentSuggestions, state.IsCustomPropertiesEnabled, state.PropertyConfigurations, state.FormState), Cmd_none()];
            }
        }
    }
}

