import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "./fable_modules/Feliz.2.8.0/Interop.fs.js";
import { safeHash, equals, createObj } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "./fable_modules/fable-library-js.4.19.2/String.js";
import { singleton, empty, append, delay, toList } from "./fable_modules/fable-library-js.4.19.2/Seq.js";
import { OpenChangeTenantDialog } from "./Widgets/ChangeTenantDialog.js";
import { unwrap } from "./fable_modules/fable-library-js.4.19.2/Option.js";
import { contains, map, empty as empty_1, singleton as singleton_1, ofArray, cons } from "./fable_modules/fable-library-js.4.19.2/List.js";
import { Button, DropdownButton, IconButton } from "./Components/Button.js";
import Hamburger_Menu_1 from "../public/assets/icons/Hamburger_Menu_1.svg";
import Arrow_Right_3 from "../public/assets/icons/Arrow_Right_3.svg";
import { Notifications } from "./Widgets/Notification/Notification.js";
import { Feature, isFeatureEnabled } from "./FeatureFlip.js";
import { SupportButton } from "./Widgets/SupportDialog.js";
import { RouterModule_encodeParts, RouterModule_nav } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import User_Square_Single from "../public/assets/icons/User_Square_Single.svg";
import { defaultOf } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import Arrow_Up_3 from "../public/assets/icons/Arrow_Up_3.svg";
import Arrow_Down_3 from "../public/assets/icons/Arrow_Down_3.svg";
import { useFeliz_React__React_useDeferred_Static_Z241A641 } from "./fable_modules/Feliz.UseDeferred.2.0.0/UseDeferred.fs.js";
import { addPrefix, Async_fetchWithDecoder } from "./Communication.js";
import { HttpMethod } from "./fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { singleton as singleton_2 } from "./fable_modules/fable-library-js.4.19.2/AsyncBuilder.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "./fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { OnboardingAvailable_$reflection } from "./Shared/Onboarding.js";
import { utcNow, date, toLocalTime, compare } from "./fable_modules/fable-library-js.4.19.2/Date.js";
import { SearchQRCodeDialog } from "./Widgets/Dialogs/QRCodeDialog.js";
import { SearchQRCodeDialog as SearchQRCodeDialog_1 } from "./Widgets/Dialogs/QRCodeSearchDialog.js";
import { DemoDataImportDialog } from "./Widgets/Dialogs/DemoDataImportDialog.js";
import { Variant as Variant_8 } from "./Variant.js";
import Dashboard_Square from "../public/assets/icons/Dashboard_Square.svg";
import { AuthorizedUrl } from "./Routes.js";
import Shipping_Box_2 from "../public/assets/icons/Shipping_Box_2.svg";
import User_Multiple_Group from "../public/assets/icons/User_Multiple_Group.svg";
import Calendar_Add from "../public/assets/icons/Calendar_Add.svg";
import File_Report from "../public/assets/icons/File_Report.svg";
import Warehouse_1 from "../public/assets/icons/Warehouse_1.svg";
import Cog from "../public/assets/icons/Cog.svg";
import Multiple_File_1 from "../public/assets/icons/Multiple_File_1.svg";
import Qr_Code from "../public/assets/icons/Qr_Code.svg";
import Magnifying_Glass from "../public/assets/icons/Magnifying_Glass.svg";
import Shipment_Add from "../public/assets/icons/Shipment_Add.svg";

export function TopNavigation(props) {
    let elems_4;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsTenantDialogOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["col-start-3", "bg-white", "flex", "items-center", "justify-between", "border-b-[2px]", "border-border", "print:hidden"])], (elems_4 = toList(delay(() => {
        let matchValue, matchValue_1, tenants, userTenants;
        return append((matchValue = props.Configuration.Tenants, (matchValue_1 = props.UserData.Tenants, (matchValue == null) ? (empty()) : ((matchValue_1 == null) ? (empty()) : ((tenants = matchValue, (userTenants = matchValue_1, singleton(createElement(OpenChangeTenantDialog, {
            Customer: props.UserData.CustomerId,
            IsOpen: patternInput_1[0],
            OnClose: () => {
                setIsTenantDialogOpen(false);
            },
            TenantName: unwrap(props.Configuration.TenantName),
            Tenants: tenants,
            UserTenants: cons(props.UserData.MainTenant, userTenants),
        })))))))), delay(() => {
            let elems_2;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center"])], (elems_2 = toList(delay(() => {
                let elems;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["pl-7"])], (elems = [createElement(IconButton, {
                    ComponentState: "enabled",
                    Icon: Hamburger_Menu_1(),
                    OnClick: props.ToggleNavigationMinimized,
                    TestId: "",
                })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton(createElement("div", {
                    "data-test-id": props.TestId,
                    className: join(" ", toList(delay(() => append(singleton("font-ubuntu"), delay(() => append(singleton("font-medium"), delay(() => append(singleton("text-2xl"), delay(() => {
                        if (props.SubTitle == null) {
                            return empty();
                        }
                        else {
                            return singleton("text-text-muted");
                        }
                    }))))))))),
                    children: props.Title,
                })), delay(() => {
                    let elems_1;
                    const matchValue_4 = props.SubTitle;
                    if (matchValue_4 == null) {
                        return empty();
                    }
                    else {
                        const subTitle = matchValue_4;
                        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["text-primary", "stroke-2", "h-4", "w-4", "mx-2"])], (elems_1 = [Arrow_Right_3()], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => singleton(createElement("div", {
                            "data-test-id": props.SubTestId,
                            className: join(" ", ["font-ubuntu", "font-medium", "text-2xl"]),
                            children: subTitle,
                        }))));
                    }
                }))));
            })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => append(singleton(createElement("div", {
                className: join(" ", ["grow"]),
            })), delay(() => append(singleton(createElement(Notifications, null)), delay(() => append(isFeatureEnabled(new Feature(10, [])) ? singleton(createElement(SupportButton, {
                CurrentUser: props.CurrentUser,
            })) : empty(), delay(() => {
                let elems_3;
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["p-3", "mr-10", "flex", "items-center"])], (elems_3 = [createElement(DropdownButton, {
                    Classes: singleton_1("z-[11]"),
                    ComponentState: "enabled",
                    DisplayArrow: true,
                    Elements: toList(delay(() => append(singleton({
                        DisplayName: t("navbar.profile"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("profile"), 1, 1);
                        },
                    }), delay(() => {
                        let matchValue_5;
                        return append((matchValue_5 = props.UserData.Role, (matchValue_5 === "system") ? singleton({
                            DisplayName: t("navbar.customerdata"),
                            OnClick: () => {
                                RouterModule_nav(ofArray(["customerdata"]), 1, 1);
                            },
                        }) : ((matchValue_5 === "toolManager") ? (empty()) : ((matchValue_5 === "user") ? (empty()) : singleton({
                            DisplayName: t("navbar.customerdata"),
                            OnClick: () => {
                                RouterModule_nav(ofArray(["customerdata"]), 1, 1);
                            },
                        })))), delay(() => append(((props.Configuration.Tenants != null) && (props.UserData.Tenants != null)) ? singleton({
                            DisplayName: "Mandant wechseln",
                            OnClick: () => {
                                setIsTenantDialogOpen(true);
                            },
                        }) : empty(), delay(() => singleton({
                            DisplayName: t("navbar.logout"),
                            OnClick: props.OnLogout,
                        })))));
                    })))),
                    Icon: User_Square_Single(),
                    Label: props.Username,
                    TestId: "",
                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
            }))))))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function NavigationElement(props) {
    let elems_6;
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? singleton("min-h-[40px]") : empty()))))], (elems_6 = toList(delay(() => {
        let elems_3;
        return append(singleton(createElement("div", createObj(ofArray([["data-test-id", `${props.Target}-navigation-element`], ["className", join(" ", toList(delay(() => append(props.IsActive ? singleton("bg-background") : empty(), delay(() => append(singleton("p-2"), delay(() => append(props.NavigationMinimized ? append(singleton("pl-[15px]"), delay(() => singleton("group-hover:pl-2"))) : empty(), delay(() => append(singleton("rounded"), delay(() => append(singleton("flex"), delay(() => append(singleton("content-center"), delay(() => append(singleton("items-center"), delay(() => append(singleton("gap-2"), delay(() => singleton("cursor-pointer"))))))))))))))))))))], ["onClick", (_arg) => {
            if (props.SubNavigationElements == null) {
                props.SetShowSubNavigation(false);
                RouterModule_nav(singleton_1(props.Target), 1, 1);
            }
            else {
                props.SetShowSubNavigation(!props.ShowSubNavigation);
            }
        }], (elems_3 = toList(delay(() => append(singleton(createElement("div", {
            className: join(" ", toList(delay(() => append(singleton("h-5"), delay(() => append(singleton("w-5"), delay(() => (!props.NavigationMinimized ? singleton("mr-2.5") : empty())))))))),
            children: reactApi.Children.toArray([props.Icon]),
        })), delay(() => append(singleton(createElement("div", {
            className: join(" ", toList(delay(() => append(props.NavigationMinimized ? append(singleton("hidden"), delay(() => singleton("group-hover:flex"))) : singleton("flex"), delay(() => append(singleton("items-center"), delay(() => singleton("font-medium")))))))),
            "data-target": "#main-navbar",
            children: props.Label,
        })), delay(() => {
            let elems_1, elems_2;
            return (props.SubNavigationElements == null) ? singleton(defaultOf()) : (props.ShowSubNavigation ? singleton(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(props.NavigationMinimized ? append(singleton("hidden"), delay(() => singleton("group-hover:block"))) : empty(), delay(() => append(singleton("ml-2.5"), delay(() => append(singleton("h-3"), delay(() => singleton("w-3"))))))))))], (elems_1 = [Arrow_Up_3()], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : singleton(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(props.NavigationMinimized ? append(singleton("hidden"), delay(() => singleton("group-hover:block"))) : empty(), delay(() => append(singleton("ml-2.5"), delay(() => append(singleton("h-3"), delay(() => singleton("w-3"))))))))))], (elems_2 = [Arrow_Down_3()], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))));
        })))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
            let elems_5;
            return props.ShowSubNavigation ? singleton(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("pt-0.5"), delay(() => (props.NavigationMinimized ? append(singleton("hidden"), delay(() => singleton("group-hover:block"))) : empty()))))))], (elems_5 = toList(delay(() => {
                let matchValue_4;
                return (matchValue_4 = props.SubNavigationElements, (matchValue_4 == null) ? empty_1() : map((element) => {
                    let elems_4;
                    return createElement("div", createObj(ofArray([["data-test-id", `${element.Label}-sub-element-navigation`], ["className", join(" ", toList(delay(() => append(element.Active ? singleton("bg-background") : empty(), delay(() => append(singleton("h-8"), delay(() => append(singleton("pl-[2.9rem]"), delay(() => append(singleton("flex"), delay(() => append(singleton("text-sm"), delay(() => append(singleton("font-medium"), delay(() => singleton("cursor-pointer"))))))))))))))))], ["onClick", (_arg_1) => {
                        RouterModule_nav(singleton_1(element.Target), 1, 1);
                    }], (elems_4 = [createElement("div", {
                        className: join(" ", ["place-self-center"]),
                        children: element.Label,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
                }, matchValue_4));
            })), ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : empty();
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])));
}

export function Navigation(props) {
    let elems_18;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState("");
    const showSubNavigation = patternInput_1[0];
    const setShowSubNavigation = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsDialogOpen = patternInput_2[1];
    const isDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(false);
    const setIsSearchCodeDialogOpen = patternInput_3[1];
    const isSearchCodeDialogOpen = patternInput_3[0];
    const patternInput_4 = reactApi.useState(false);
    const setIsImportDataDialogOpen = patternInput_4[1];
    const navigationMinimized = props.NavigationMinimized;
    const patternInput_5 = reactApi.useState(false);
    const demoDataImportAvailable = useFeliz_React__React_useDeferred_Static_Z241A641(Async_fetchWithDecoder(0, addPrefix("/api/onboarding/import-data-available"), "", new HttpMethod(0, []), "application/json", (response) => singleton_2.Delay(() => {
        let resp;
        const matchValue = response.content;
        switch (matchValue.tag) {
            case 0: {
                throw new Error("not implemented - text response");
                break;
            }
            case 1: {
                throw new Error("not implemented - blob response");
                break;
            }
            case 3: {
                resp = matchValue.fields[0];
                break;
            }
            default:
                resp = (new Uint8Array(matchValue.fields[0]));
        }
        return singleton_2.Return(Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(resp), OnboardingAvailable_$reflection()));
    })), []);
    const onboardingAvailable_1 = (demoDataImportAvailable.tag === 1) ? false : ((demoDataImportAvailable.tag === 0) ? false : ((demoDataImportAvailable.tag === 2) && demoDataImportAvailable.fields[0].Value));
    const isTestDataImportImpossible = compare(toLocalTime(date(utcNow())), toLocalTime(date(props.IsInTestphase[1]))) > 0;
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("row-start-1"), delay(() => append(singleton("row-span-2"), delay(() => append(singleton("px-4"), delay(() => append(singleton("grid"), delay(() => append(singleton("justify-items-center"), delay(() => append(singleton("print:hidden"), delay(() => (props.NavigationMinimized ? append(singleton("absolute"), delay(() => append(singleton("left-0"), delay(() => append(singleton("w-20"), delay(() => append(singleton("h-full"), delay(() => append(singleton("group"), delay(() => append(singleton("hover:min-w-64"), delay(() => append(singleton("hover:z-20"), delay(() => append(singleton("bg-white"), delay(() => append(singleton("hover:border-r-2"), delay(() => singleton("border-divider"))))))))))))))))))) : singleton("min-w-64")))))))))))))))))], (elems_18 = toList(delay(() => append(isDialogOpen ? singleton(createElement(SearchQRCodeDialog, {
        IsOpen: isDialogOpen,
        OnClose: () => {
            setIsDialogOpen(false);
        },
    })) : empty(), delay(() => append(isSearchCodeDialogOpen ? singleton(createElement(SearchQRCodeDialog_1, {
        IsOpen: isSearchCodeDialogOpen,
        OnClose: () => {
            setIsSearchCodeDialogOpen(false);
        },
    })) : empty(), delay(() => {
        let elems_17, elems_16, elems, elems_15, elems_1, elems_14;
        return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["fixed", "h-screen", "flex", "flex-col"])], (elems_17 = [createElement(DemoDataImportDialog, {
            IsOpen: patternInput_4[0],
            OnClose: () => {
                setIsImportDataDialogOpen(false);
            },
            SuccessCallback: () => {
                patternInput_5[1](true);
                document.location.reload(true);
            },
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "h-full"])], (elems_16 = [createElement("img", {
            onClick: (_arg_4) => {
                const matchValue_1 = props.UserData.Role;
                switch (matchValue_1) {
                    case "user": {
                        RouterModule_nav(ofArray(["tools"]), 1, 1);
                        break;
                    }
                    default:
                        RouterModule_nav(ofArray(["dashboard"]), 1, 1);
                }
            },
            className: join(" ", toList(delay(() => append(singleton("cursor-pointer"), delay(() => append(singleton("mt-5"), delay(() => (props.NavigationMinimized ? append(singleton("hidden"), delay(() => append(singleton("group-hover:inline"), delay(() => singleton("group-hover:w-52"))))) : append((Variant_8.tag === 2) ? (empty()) : ((Variant_8.tag === 0) ? singleton("mt-10") : (empty())), delay(() => singleton("w-52"))))))))))),
            src: (Variant_8.tag === 2) ? "assets/images/eagletrackmate-logo.png" : ((Variant_8.tag === 0) ? "assets/images/mwk_logo.png" : "assets/images/inventory-one-logo.png"),
        }), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("mt-5"), delay(() => (props.NavigationMinimized ? append(singleton("h-[83px]"), delay(() => singleton("group-hover:hidden"))) : singleton("hidden")))))))], (elems = [createElement("img", {
            onClick: (_arg_5) => {
                const matchValue_3 = props.UserData.Role;
                switch (matchValue_3) {
                    case "user": {
                        RouterModule_nav(ofArray(["tools"]), 1, 1);
                        break;
                    }
                    default:
                        RouterModule_nav(ofArray(["dashboard"]), 1, 1);
                }
            },
            className: join(" ", ["cursor-pointer", "h-[55px]", "mt-2"]),
            src: (Variant_8.tag === 2) ? "assets/images/eagletrackmate-logo-small.png" : ((Variant_8.tag === 0) ? "assets/images/mwk_logo_small.png" : "assets/images/inventory-one-logo-small.png"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "justify-between", "grow", "overflow-y-auto"])], (elems_15 = [createElement("div", createObj(ofArray([["className", join(" ", ["mt-12", "flex", "flex-col", "gap-[0.9rem]", "pb-[0.9rem]"])], (elems_1 = toList(delay(() => {
            let matchValue_4, Label;
            return append((matchValue_4 = props.UserData.Role, (matchValue_4 === "system") ? singleton(createElement(NavigationElement, (Label = t("navbar.dashboard"), {
                Icon: Dashboard_Square(),
                IsActive: equals(props.CurrentUrl, new AuthorizedUrl(0, [])),
                Label: Label,
                NavigationMinimized: navigationMinimized,
                SetShowSubNavigation: (_arg_6) => {
                    setShowSubNavigation("");
                },
                ShowSubNavigation: false,
                Target: "dashboard",
            }))) : ((matchValue_4 === "toolManager") ? singleton(createElement(NavigationElement, (Label = t("navbar.dashboard"), {
                Icon: Dashboard_Square(),
                IsActive: equals(props.CurrentUrl, new AuthorizedUrl(0, [])),
                Label: Label,
                NavigationMinimized: navigationMinimized,
                SetShowSubNavigation: (_arg_6) => {
                    setShowSubNavigation("");
                },
                ShowSubNavigation: false,
                Target: "dashboard",
            }))) : ((matchValue_4 === "user") ? (empty()) : singleton(createElement(NavigationElement, (Label = t("navbar.dashboard"), {
                Icon: Dashboard_Square(),
                IsActive: equals(props.CurrentUrl, new AuthorizedUrl(0, [])),
                Label: Label,
                NavigationMinimized: navigationMinimized,
                SetShowSubNavigation: (_arg_6) => {
                    setShowSubNavigation("");
                },
                ShowSubNavigation: false,
                Target: "dashboard",
            })))))), delay(() => {
                let Label_1;
                return append(singleton(createElement(NavigationElement, (Label_1 = t("navbar.tools"), {
                    Icon: Shipping_Box_2(),
                    IsActive: (showSubNavigation !== "inventory") && contains(props.CurrentUrl, ofArray([new AuthorizedUrl(7, []), new AuthorizedUrl(16, []), new AuthorizedUrl(67, [])]), {
                        Equals: equals,
                        GetHashCode: safeHash,
                    }),
                    Label: Label_1,
                    NavigationMinimized: navigationMinimized,
                    SetShowSubNavigation: (showSubNavigation_1) => {
                        if (showSubNavigation_1) {
                            setShowSubNavigation("inventory");
                        }
                        else {
                            setShowSubNavigation("");
                        }
                    },
                    ShowSubNavigation: showSubNavigation === "inventory",
                    SubNavigationElements: toList(delay(() => {
                        let Label_6, Target_6, Label_9, Target_9, Label_2, Target_2;
                        const matchValue_5 = props.UserData.Role;
                        switch (matchValue_5) {
                            case "toolManager":
                                return append(singleton((Label_6 = t("navbar.tools"), (Target_6 = RouterModule_encodeParts(singleton_1("tools"), 1), {
                                    Active: equals(props.CurrentUrl, new AuthorizedUrl(7, [])),
                                    Label: Label_6,
                                    Target: Target_6,
                                }))), delay(() => {
                                    let Label_7, Target_7;
                                    return append(singleton((Label_7 = t("navbar.consumables"), (Target_7 = RouterModule_encodeParts(singleton_1("consumables"), 1), {
                                        Active: equals(props.CurrentUrl, new AuthorizedUrl(16, [])),
                                        Label: Label_7,
                                        Target: Target_7,
                                    }))), delay(() => {
                                        let Label_8, Target_8;
                                        return isFeatureEnabled(new Feature(0, [])) ? singleton((Label_8 = t("navbar.dispolist"), (Target_8 = RouterModule_encodeParts(singleton_1("inventory-lists"), 1), {
                                            Active: equals(props.CurrentUrl, new AuthorizedUrl(67, [])),
                                            Label: Label_8,
                                            Target: Target_8,
                                        }))) : empty();
                                    }));
                                }));
                            case "user":
                                return append(singleton((Label_9 = t("navbar.tools"), (Target_9 = RouterModule_encodeParts(singleton_1("tools"), 1), {
                                    Active: equals(props.CurrentUrl, new AuthorizedUrl(7, [])),
                                    Label: Label_9,
                                    Target: Target_9,
                                }))), delay(() => {
                                    let Label_10, Target_10;
                                    return singleton((Label_10 = t("navbar.consumables"), (Target_10 = RouterModule_encodeParts(singleton_1("consumables"), 1), {
                                        Active: equals(props.CurrentUrl, new AuthorizedUrl(16, [])),
                                        Label: Label_10,
                                        Target: Target_10,
                                    })));
                                }));
                            default:
                                return append(singleton((Label_2 = t("navbar.tools"), (Target_2 = RouterModule_encodeParts(singleton_1("tools"), 1), {
                                    Active: equals(props.CurrentUrl, new AuthorizedUrl(7, [])),
                                    Label: Label_2,
                                    Target: Target_2,
                                }))), delay(() => {
                                    let Label_3, Target_3;
                                    return append(singleton((Label_3 = t("navbar.consumables"), (Target_3 = RouterModule_encodeParts(singleton_1("consumables"), 1), {
                                        Active: equals(props.CurrentUrl, new AuthorizedUrl(16, [])),
                                        Label: Label_3,
                                        Target: Target_3,
                                    }))), delay(() => {
                                        let Label_4, Target_4;
                                        return append(isFeatureEnabled(new Feature(0, [])) ? singleton((Label_4 = t("navbar.dispolist"), (Target_4 = RouterModule_encodeParts(singleton_1("inventory-lists"), 1), {
                                            Active: equals(props.CurrentUrl, new AuthorizedUrl(67, [])),
                                            Label: Label_4,
                                            Target: Target_4,
                                        }))) : empty(), delay(() => {
                                            let Label_5, Target_5;
                                            return isFeatureEnabled(new Feature(4, [])) ? singleton((Label_5 = t("stocktaking.stocktaking"), (Target_5 = RouterModule_encodeParts(singleton_1("stocktaking"), 1), {
                                                Active: equals(props.CurrentUrl, new AuthorizedUrl(1, [])),
                                                Label: Label_5,
                                                Target: Target_5,
                                            }))) : empty();
                                        }));
                                    }));
                                }));
                        }
                    })),
                    Target: "tools",
                }))), delay(() => {
                    let matchValue_6, Label_11;
                    return append((matchValue_6 = props.UserData.Role, (matchValue_6 === "system") ? singleton(createElement(NavigationElement, (Label_11 = t("navbar.toolbox"), {
                        Icon: User_Multiple_Group(),
                        IsActive: contains(props.CurrentUrl, ofArray([new AuthorizedUrl(93, []), new AuthorizedUrl(29, []), new AuthorizedUrl(32, []), new AuthorizedUrl(41, []), new AuthorizedUrl(35, []), new AuthorizedUrl(50, []), new AuthorizedUrl(44, []), new AuthorizedUrl(47, []), new AuthorizedUrl(38, [])]), {
                            Equals: equals,
                            GetHashCode: safeHash,
                        }),
                        Label: Label_11,
                        NavigationMinimized: navigationMinimized,
                        SetShowSubNavigation: (_arg_7) => {
                            setShowSubNavigation("");
                        },
                        ShowSubNavigation: false,
                        Target: "receivers",
                    }))) : ((matchValue_6 === "toolManager") ? singleton(createElement(NavigationElement, (Label_11 = t("navbar.toolbox"), {
                        Icon: User_Multiple_Group(),
                        IsActive: contains(props.CurrentUrl, ofArray([new AuthorizedUrl(93, []), new AuthorizedUrl(29, []), new AuthorizedUrl(32, []), new AuthorizedUrl(41, []), new AuthorizedUrl(35, []), new AuthorizedUrl(50, []), new AuthorizedUrl(44, []), new AuthorizedUrl(47, []), new AuthorizedUrl(38, [])]), {
                            Equals: equals,
                            GetHashCode: safeHash,
                        }),
                        Label: Label_11,
                        NavigationMinimized: navigationMinimized,
                        SetShowSubNavigation: (_arg_7) => {
                            setShowSubNavigation("");
                        },
                        ShowSubNavigation: false,
                        Target: "receivers",
                    }))) : ((matchValue_6 === "user") ? singleton(createElement(NavigationElement, (Label_11 = t("navbar.toolbox"), {
                        Icon: User_Multiple_Group(),
                        IsActive: contains(props.CurrentUrl, ofArray([new AuthorizedUrl(93, []), new AuthorizedUrl(29, []), new AuthorizedUrl(32, []), new AuthorizedUrl(41, []), new AuthorizedUrl(35, []), new AuthorizedUrl(50, []), new AuthorizedUrl(44, []), new AuthorizedUrl(47, []), new AuthorizedUrl(38, [])]), {
                            Equals: equals,
                            GetHashCode: safeHash,
                        }),
                        Label: Label_11,
                        NavigationMinimized: navigationMinimized,
                        SetShowSubNavigation: (_arg_7) => {
                            setShowSubNavigation("");
                        },
                        ShowSubNavigation: false,
                        Target: "receivers",
                    }))) : singleton(createElement(NavigationElement, (Label_11 = t("navbar.toolbox"), {
                        Icon: User_Multiple_Group(),
                        IsActive: contains(props.CurrentUrl, ofArray([new AuthorizedUrl(93, []), new AuthorizedUrl(29, []), new AuthorizedUrl(32, []), new AuthorizedUrl(41, []), new AuthorizedUrl(35, []), new AuthorizedUrl(50, []), new AuthorizedUrl(44, []), new AuthorizedUrl(47, []), new AuthorizedUrl(38, [])]), {
                            Equals: equals,
                            GetHashCode: safeHash,
                        }),
                        Label: Label_11,
                        NavigationMinimized: navigationMinimized,
                        SetShowSubNavigation: (_arg_7) => {
                            setShowSubNavigation("");
                        },
                        ShowSubNavigation: false,
                        Target: "receivers",
                    })))))), delay(() => {
                        let Label_12, Label_13, Target_13, Label_14, Target_14;
                        return append(singleton(createElement(NavigationElement, (Label_12 = t("navbar.planning"), {
                            Icon: Calendar_Add(),
                            IsActive: (showSubNavigation !== "planning") && contains(props.CurrentUrl, ofArray([new AuthorizedUrl(94, []), new AuthorizedUrl(95, [])]), {
                                Equals: equals,
                                GetHashCode: safeHash,
                            }),
                            Label: Label_12,
                            NavigationMinimized: navigationMinimized,
                            SetShowSubNavigation: (showSubNavigation_2) => {
                                if (showSubNavigation_2) {
                                    setShowSubNavigation("planning");
                                }
                                else {
                                    setShowSubNavigation("");
                                }
                            },
                            ShowSubNavigation: showSubNavigation === "planning",
                            SubNavigationElements: ofArray([(Label_13 = t("navbar.calendar"), (Target_13 = RouterModule_encodeParts(singleton_1("calendar"), 1), {
                                Active: equals(props.CurrentUrl, new AuthorizedUrl(94, [])),
                                Label: Label_13,
                                Target: Target_13,
                            })), (Label_14 = t("navbar.reservation"), (Target_14 = RouterModule_encodeParts(singleton_1("reservations"), 1), {
                                Active: equals(props.CurrentUrl, new AuthorizedUrl(95, [])),
                                Label: Label_14,
                                Target: Target_14,
                            }))]),
                            Target: "planning",
                        }))), delay(() => {
                            let matchValue_7, Label_15;
                            return append((matchValue_7 = props.UserData.Role, (matchValue_7 === "system") ? singleton(createElement(NavigationElement, (Label_15 = t("navbar.reports"), {
                                Icon: File_Report(),
                                IsActive: equals(props.CurrentUrl, new AuthorizedUrl(101, [])),
                                Label: Label_15,
                                NavigationMinimized: navigationMinimized,
                                SetShowSubNavigation: (_arg_8) => {
                                    setShowSubNavigation("");
                                },
                                ShowSubNavigation: false,
                                Target: "reports",
                            }))) : ((matchValue_7 === "toolManager") ? singleton(createElement(NavigationElement, (Label_15 = t("navbar.reports"), {
                                Icon: File_Report(),
                                IsActive: equals(props.CurrentUrl, new AuthorizedUrl(101, [])),
                                Label: Label_15,
                                NavigationMinimized: navigationMinimized,
                                SetShowSubNavigation: (_arg_8) => {
                                    setShowSubNavigation("");
                                },
                                ShowSubNavigation: false,
                                Target: "reports",
                            }))) : ((matchValue_7 === "user") ? (empty()) : singleton(createElement(NavigationElement, (Label_15 = t("navbar.reports"), {
                                Icon: File_Report(),
                                IsActive: equals(props.CurrentUrl, new AuthorizedUrl(101, [])),
                                Label: Label_15,
                                NavigationMinimized: navigationMinimized,
                                SetShowSubNavigation: (_arg_8) => {
                                    setShowSubNavigation("");
                                },
                                ShowSubNavigation: false,
                                Target: "reports",
                            })))))), delay(() => {
                                let matchValue_8, Label_16;
                                return append((matchValue_8 = props.UserData.Role, (matchValue_8 === "system") ? singleton(createElement(NavigationElement, (Label_16 = t("navbar.location_storages"), {
                                    Icon: Warehouse_1(),
                                    IsActive: equals(props.CurrentUrl, new AuthorizedUrl(21, [])) ? true : equals(props.CurrentUrl, new AuthorizedUrl(25, [])),
                                    Label: Label_16,
                                    NavigationMinimized: navigationMinimized,
                                    SetShowSubNavigation: (_arg_9) => {
                                        setShowSubNavigation("");
                                    },
                                    ShowSubNavigation: false,
                                    Target: "storages",
                                }))) : ((matchValue_8 === "toolManager") ? (empty()) : ((matchValue_8 === "user") ? (empty()) : singleton(createElement(NavigationElement, (Label_16 = t("navbar.location_storages"), {
                                    Icon: Warehouse_1(),
                                    IsActive: equals(props.CurrentUrl, new AuthorizedUrl(21, [])) ? true : equals(props.CurrentUrl, new AuthorizedUrl(25, [])),
                                    Label: Label_16,
                                    NavigationMinimized: navigationMinimized,
                                    SetShowSubNavigation: (_arg_9) => {
                                        setShowSubNavigation("");
                                    },
                                    ShowSubNavigation: false,
                                    Target: "storages",
                                })))))), delay(() => {
                                    let Label_17;
                                    const matchValue_9 = props.UserData.Role;
                                    switch (matchValue_9) {
                                        case "toolManager":
                                        case "user": {
                                            return empty();
                                        }
                                        default:
                                            return singleton(createElement(NavigationElement, (Label_17 = t("navbar.settings"), {
                                                Icon: Cog(),
                                                IsActive: (showSubNavigation !== "settings") && contains(props.CurrentUrl, ofArray([new AuthorizedUrl(59, []), new AuthorizedUrl(60, []), new AuthorizedUrl(61, []), new AuthorizedUrl(62, []), new AuthorizedUrl(63, []), new AuthorizedUrl(75, []), new AuthorizedUrl(76, [])]), {
                                                    Equals: equals,
                                                    GetHashCode: safeHash,
                                                }),
                                                Label: Label_17,
                                                NavigationMinimized: navigationMinimized,
                                                SetShowSubNavigation: (showSubNavigation_3) => {
                                                    if (showSubNavigation_3) {
                                                        setShowSubNavigation("settings");
                                                    }
                                                    else {
                                                        setShowSubNavigation("");
                                                    }
                                                },
                                                ShowSubNavigation: showSubNavigation === "settings",
                                                SubNavigationElements: toList(delay(() => {
                                                    let Label_18, Target_18;
                                                    const matchValue_10 = props.UserData.Role;
                                                    switch (matchValue_10) {
                                                        case "toolManager":
                                                        case "user": {
                                                            return empty();
                                                        }
                                                        default:
                                                            return append(isFeatureEnabled(new Feature(7, [])) ? singleton((Label_18 = t("navbar.users"), (Target_18 = RouterModule_encodeParts(ofArray(["settings", "users"]), 1), {
                                                                Active: equals(props.CurrentUrl, new AuthorizedUrl(63, [])),
                                                                Label: Label_18,
                                                                Target: Target_18,
                                                            }))) : empty(), delay(() => {
                                                                let Label_19, Target_19;
                                                                return append(singleton((Label_19 = t("navbar.categories"), (Target_19 = RouterModule_encodeParts(ofArray(["settings", "categories"]), 1), {
                                                                    Active: equals(props.CurrentUrl, new AuthorizedUrl(62, [])),
                                                                    Label: Label_19,
                                                                    Target: Target_19,
                                                                }))), delay(() => {
                                                                    let Label_20, Target_20;
                                                                    return append(singleton((Label_20 = t("navbar.custom_fields"), (Target_20 = RouterModule_encodeParts(ofArray(["settings", "custom-properties"]), 1), {
                                                                        Active: equals(props.CurrentUrl, new AuthorizedUrl(61, [])),
                                                                        Label: Label_20,
                                                                        Target: Target_20,
                                                                    }))), delay(() => {
                                                                        let Label_21, Target_21;
                                                                        return append(singleton((Label_21 = t("navbar.systemsettings"), (Target_21 = RouterModule_encodeParts(ofArray(["settings", "permissions"]), 1), {
                                                                            Active: equals(props.CurrentUrl, new AuthorizedUrl(60, [])),
                                                                            Label: Label_21,
                                                                            Target: Target_21,
                                                                        }))), delay(() => {
                                                                            let Label_22, Target_22;
                                                                            return append(isFeatureEnabled(new Feature(2, [])) ? singleton((Label_22 = t("navbar.importer"), (Target_22 = RouterModule_encodeParts(singleton_1("importer"), 1), {
                                                                                Active: equals(props.CurrentUrl, new AuthorizedUrl(76, [])),
                                                                                Label: Label_22,
                                                                                Target: Target_22,
                                                                            }))) : empty(), delay(() => {
                                                                                let Label_23, Target_23;
                                                                                return singleton((Label_23 = t("navbar.shop"), (Target_23 = RouterModule_encodeParts(singleton_1("shop"), 1), {
                                                                                    Active: equals(props.CurrentUrl, new AuthorizedUrl(75, [])),
                                                                                    Label: Label_23,
                                                                                    Target: Target_23,
                                                                                })));
                                                                            }));
                                                                        }));
                                                                    }));
                                                                }));
                                                            }));
                                                    }
                                                })),
                                                Target: "settings",
                                            })));
                                    }
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        })), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_14 = toList(delay(() => {
            let elems_4, elems_2, Label_24, elems_3;
            return append((onboardingAvailable_1 && (!patternInput_5[0] ? true : !isTestDataImportImpossible)) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "justify-stretch", "mb-2"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? append(singleton("hidden"), delay(() => singleton("group-hover:grid"))) : append(singleton("grid"), delay(() => singleton("justify-stretch")))))))], (elems_2 = [createElement(Button, (Label_24 = t("demo_data_dialog.import"), {
                ComponentState: "enabled",
                Icon: Multiple_File_1(),
                Label: Label_24,
                OnClick: () => {
                    setIsImportDataDialogOpen(true);
                },
                TestId: "import-demo-data-btn-test-id",
                Variant: "primary",
            }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? singleton("group-hover:hidden") : singleton("hidden")))))], (elems_3 = [createElement(Button, {
                ComponentState: "enabled",
                Icon: Multiple_File_1(),
                Label: "",
                OnClick: () => {
                    setIsImportDataDialogOpen(true);
                },
                TestId: "import-demo-data-btn-test-id",
                Variant: "primary",
            })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))) : empty(), delay(() => {
                let elems_7, elems_5, Label_26, elems_6;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("grid"), delay(() => append(singleton("justify-stretch"), delay(() => {
                    const matchValue_11 = props.UserData.Role;
                    switch (matchValue_11) {
                        case "user":
                            return singleton("mb-4");
                        default:
                            return singleton("mb-2");
                    }
                })))))))], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? append(singleton("hidden"), delay(() => singleton("group-hover:grid"))) : append(singleton("grid"), delay(() => singleton("justify-stretch")))))))], (elems_5 = [createElement(Button, (Label_26 = t("scan_qr_code_dialog.btn_text"), {
                    ComponentState: "enabled",
                    Icon: Qr_Code(),
                    Label: Label_26,
                    OnClick: () => {
                        setIsDialogOpen(true);
                    },
                    TestId: "scan-qr-code-btn-test-id",
                    Variant: "secondary",
                }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? singleton("group-hover:hidden") : singleton("hidden")))))], (elems_6 = [createElement(Button, {
                    ComponentState: "enabled",
                    Icon: Qr_Code(),
                    Label: "",
                    OnClick: () => {
                        setIsDialogOpen(true);
                    },
                    TestId: "scan-qr-code-btn-test-id",
                    Variant: "secondary",
                })], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => {
                    let elems_10, elems_8, Label_28, elems_9;
                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("grid"), delay(() => append(singleton("justify-stretch"), delay(() => {
                        const matchValue_12 = props.UserData.Role;
                        switch (matchValue_12) {
                            case "user":
                                return singleton("mb-4");
                            default:
                                return singleton("mb-2");
                        }
                    })))))))], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? append(singleton("hidden"), delay(() => singleton("group-hover:grid"))) : append(singleton("grid"), delay(() => singleton("justify-stretch")))))))], (elems_8 = [createElement(Button, (Label_28 = t("search_qr_code_dialog.title"), {
                        ComponentState: "enabled",
                        Icon: Magnifying_Glass(),
                        Label: Label_28,
                        OnClick: () => {
                            setIsSearchCodeDialogOpen(true);
                        },
                        TestId: "search-qr-code-btn-test-id",
                        Variant: "secondary",
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? singleton("group-hover:hidden") : singleton("hidden")))))], (elems_9 = [createElement(Button, {
                        ComponentState: "enabled",
                        Icon: Magnifying_Glass(),
                        Label: "",
                        OnClick: () => {
                            setIsSearchCodeDialogOpen(true);
                        },
                        TestId: "search-qr-code-btn-test-id",
                        Variant: "secondary",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))), delay(() => {
                        let elems_13, elems_11, Label_30, elems_12;
                        const matchValue_13 = props.UserData.Role;
                        switch (matchValue_13) {
                            case "user": {
                                return empty();
                            }
                            default:
                                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "justify-stretch", "mb-4"])], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? append(singleton("hidden"), delay(() => singleton("group-hover:grid"))) : append(singleton("grid"), delay(() => singleton("justify-stretch")))))))], (elems_11 = [createElement(Button, (Label_30 = t("navbar.create_new_tool"), {
                                    ComponentState: "enabled",
                                    Icon: Shipment_Add(),
                                    Label: Label_30,
                                    OnClick: () => {
                                        RouterModule_nav(ofArray(["tools", "new"]), 1, 1);
                                    },
                                    TestId: "create-tool-btn-test-id",
                                    Variant: "secondary",
                                }))], ["children", reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (props.NavigationMinimized ? singleton("group-hover:hidden") : singleton("hidden")))))], (elems_12 = [createElement(Button, {
                                    ComponentState: "enabled",
                                    Icon: Shipment_Add(),
                                    Label: "",
                                    OnClick: () => {
                                        RouterModule_nav(ofArray(["tools", "new"]), 1, 1);
                                    },
                                    TestId: "create-tool-btn-test-id",
                                    Variant: "secondary",
                                })], ["children", reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", reactApi.Children.toArray(Array.from(elems_13))])]))));
                        }
                    }));
                }));
            }));
        })), ["children", reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", reactApi.Children.toArray(Array.from(elems_17))])]))));
    })))))), ["children", reactApi.Children.toArray(Array.from(elems_18))])])));
}

