import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { addPrefix, token as token_2 } from "../CommunicationV2.js";
import { ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";

export function createAssignmentProtocol(assignmentType, entityName, inventoryNumber, senderName, receiverName, storageName, comment, timestamp, returnDate, signatureUrl, successMsg, errorMsg) {
    let signatureUrl_1;
    const url = `/api/files/generate?assignmentType=${assignmentType}&entityName=${entityName}&inventoryNumber=${inventoryNumber}&senderName=${senderName}&receiverName=${receiverName}&comment=${comment}&timestamp=${timestamp}`;
    const url_1 = (storageName == null) ? url : (`${url}&storageName=${storageName}`);
    const url_2 = (returnDate == null) ? url_1 : (`${url_1}&returnDate=${returnDate}`);
    return Cmd_OfPromise_either((tupledArg) => {
        const body_1 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_1;
                return append((matchValue_1 = token_2(undefined), (matchValue_1 == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_1]))), delay(() => singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"]))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => singleton(new Types_HttpRequestHeaders(11, ["Content-Disposition"]))));
            })), 0)])]);
            return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, [(signatureUrl == null) ? url_2 : ((signatureUrl_1 = signatureUrl, `${url_2}&signatureUrl=${window.encodeURIComponent(signatureUrl_1)}`)), "protocol.pdf", undefined], successMsg, errorMsg);
}

