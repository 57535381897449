import { compare, utcNow } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { FormState__startLoading, FormState__setValidationResponse_5219762A, ConsumableId__get_unwrap, FormState_get_empty, RequestedValue$1 } from "../../Shared/Shared.js";
import { find, map, exists, choose, contains, filter, append, ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Msg, State, FormData as FormData_16 } from "./Types.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getCustomerConfiguration } from "../../Requests/Configuration.js";
import { getConsumables } from "../../Requests/Consumable.js";
import { Cmd_getTools } from "../../Requests/Tool.js";
import { Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { addPrefix, fetchWithDecoder } from "../../Communication.js";
import { Types_RequestProperties } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type, string_type } from "../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ReceiverTypeName, Receiver_$reflection } from "../../Shared/User.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../../CommunicationV2.js";
import { PropertyConfiguration_$reflection } from "../../Shared/PropertyConfiguration.js";
import { ValidationOnGetRequest } from "../../ErrorHandling.js";
import { getReservation } from "../../Requests/Reservation.js";
import { List_distinct } from "../../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { structuralHash, equals, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Cmd_successToast, Cmd_errorToast } from "../../Components/Toast.js";
import { Cmd_ofEffect } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { MultiReservationCreateDto_$reflection, MultiReservationCreateDto, MultiReservationUpdateDto_$reflection, MultiReservationUpdateDto, ToolWithQuantityReservation, ConsumableReservation } from "../../Shared/MultiOperation.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";

export function initNew(userData) {
    const now = utcNow();
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), userData, FormState_get_empty(), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new FormData_16("", "", now, now, "", empty(), empty(), empty(), empty(), undefined)), Cmd_batch(ofArray([getCustomerConfiguration((Item) => (new Msg(20, [Item])), (Item_1) => (new Msg(26, [Item_1]))), getConsumables((Item_2) => (new Msg(17, [Item_2])), (Item_3) => (new Msg(26, [Item_3]))), Cmd_getTools((Item_4) => (new Msg(16, [Item_4])), (Item_5) => (new Msg(26, [Item_5]))), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/reservations/name-suggestions", (Item_6) => (new Msg(18, [Item_6])), (Item_7) => (new Msg(26, [Item_7]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_8) => (new Msg(19, [Item_8])), (Item_9) => (new Msg(26, [Item_9]))), getCustomerConfiguration((Item_10) => (new Msg(20, [Item_10])), (Item_11) => (new Msg(26, [Item_11]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_12) => (new Msg(21, [Item_12])), (Item_13) => (new Msg(26, [Item_13])))]))];
}

export function initEdit(userData, reservationId) {
    const now = utcNow();
    return [new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), userData, FormState_get_empty(), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new FormData_16("", "", now, now, "", empty(), empty(), empty(), empty(), undefined)), Cmd_batch(ofArray([getCustomerConfiguration((Item) => (new Msg(20, [Item])), (Item_1) => (new Msg(26, [Item_1]))), getConsumables((Item_2) => (new Msg(17, [Item_2])), (Item_3) => (new Msg(26, [Item_3]))), Cmd_getTools((Item_4) => (new Msg(16, [Item_4])), (Item_5) => (new Msg(26, [Item_5]))), Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
    })))), "/api/tools/reservations/name-suggestions", (Item_6) => (new Msg(18, [Item_6])), (Item_7) => (new Msg(26, [Item_7]))), Cmd_OfPromise_either((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(Receiver_$reflection())));
    })))), "/api/receivers/active", (Item_8) => (new Msg(19, [Item_8])), (Item_9) => (new Msg(26, [Item_9]))), getReservation(reservationId, (Item_10) => (new Msg(23, [Item_10])), (Item_11) => (new Msg(26, [Item_11]))), Cmd_OfPromise_either((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_8;
        return ((url_8 = addPrefix_1(url_6), (refreshOn) => fetchWithDecoder_1(url_8, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
            const pr_2 = response_2.arrayBuffer();
            return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(PropertyConfiguration_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_3 = _arg;
            return (result_3.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_3.fields[0]);
            })())) : (Promise.resolve(result_3.fields[0]));
        });
    })), "/api/property-configurations", (Item_12) => (new Msg(21, [Item_12])), (Item_13) => (new Msg(26, [Item_13])))]))];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040_7, bind$0040_8, bind$0040_9, bind$0040_10, bind$0040_11, bind$0040_12, bind$0040_13, bind$0040_14, bind$0040_15, xs;
    switch (msg.tag) {
        case 1:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040 = state.FormData, new FormData_16(bind$0040.Name, bind$0040.Description, bind$0040.StartDate, bind$0040.EndDate, bind$0040.ReceiverId, List_distinct(append(msg.fields[0], state.FormData.ToolIds), {
                Equals: (x_2, y) => (x_2 === y),
                GetHashCode: stringHash,
            }), bind$0040.ToolsWithQuantity, bind$0040.SetIds, bind$0040.Consumables, bind$0040.ReservationDetailDto))), Cmd_none()];
        case 2:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_1 = state.FormData, new FormData_16(bind$0040_1.Name, bind$0040_1.Description, bind$0040_1.StartDate, bind$0040_1.EndDate, bind$0040_1.ReceiverId, filter((tId) => !contains(tId, msg.fields[0], {
                Equals: (x_3, y_1) => (x_3 === y_1),
                GetHashCode: stringHash,
            }), state.FormData.ToolIds), bind$0040_1.ToolsWithQuantity, bind$0040_1.SetIds, bind$0040_1.Consumables, bind$0040_1.ReservationDetailDto))), Cmd_none()];
        case 3:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_2 = state.FormData, new FormData_16(bind$0040_2.Name, bind$0040_2.Description, bind$0040_2.StartDate, bind$0040_2.EndDate, bind$0040_2.ReceiverId, bind$0040_2.ToolIds, bind$0040_2.ToolsWithQuantity, List_distinct(append(msg.fields[0], state.FormData.SetIds), {
                Equals: (x_4, y_2) => (x_4 === y_2),
                GetHashCode: stringHash,
            }), bind$0040_2.Consumables, bind$0040_2.ReservationDetailDto))), Cmd_none()];
        case 4:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_3 = state.FormData, new FormData_16(bind$0040_3.Name, bind$0040_3.Description, bind$0040_3.StartDate, bind$0040_3.EndDate, bind$0040_3.ReceiverId, bind$0040_3.ToolIds, bind$0040_3.ToolsWithQuantity, filter((tId_1) => !contains(tId_1, msg.fields[0], {
                Equals: (x_5, y_3) => (x_5 === y_3),
                GetHashCode: stringHash,
            }), state.FormData.SetIds), bind$0040_3.Consumables, bind$0040_3.ReservationDetailDto))), Cmd_none()];
        case 5:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_4 = state.FormData, new FormData_16(bind$0040_4.Name, bind$0040_4.Description, bind$0040_4.StartDate, bind$0040_4.EndDate, bind$0040_4.ReceiverId, bind$0040_4.ToolIds, List_distinct(append(choose((updatedTool) => {
                if (exists((tool) => {
                    if (updatedTool.Id === tool.Tool.Id) {
                        return updatedTool.StorageId === tool.Tool.StorageId;
                    }
                    else {
                        return false;
                    }
                }, state.FormData.ToolsWithQuantity)) {
                    return undefined;
                }
                else {
                    return {
                        Quantity: 0,
                        Tool: updatedTool,
                    };
                }
            }, msg.fields[0]), state.FormData.ToolsWithQuantity), {
                Equals: equals,
                GetHashCode: structuralHash,
            }), bind$0040_4.SetIds, bind$0040_4.Consumables, bind$0040_4.ReservationDetailDto))), Cmd_none()];
        case 6:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_5 = state.FormData, new FormData_16(bind$0040_5.Name, bind$0040_5.Description, bind$0040_5.StartDate, bind$0040_5.EndDate, bind$0040_5.ReceiverId, bind$0040_5.ToolIds, choose((toolWithQuantity) => {
                if (exists((removedTool) => {
                    if (removedTool.Id === toolWithQuantity.Tool.Id) {
                        return removedTool.StorageId === toolWithQuantity.Tool.StorageId;
                    }
                    else {
                        return false;
                    }
                }, msg.fields[0])) {
                    return undefined;
                }
                else {
                    return toolWithQuantity;
                }
            }, state.FormData.ToolsWithQuantity), bind$0040_5.SetIds, bind$0040_5.Consumables, bind$0040_5.ReservationDetailDto))), Cmd_none()];
        case 8:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_6 = state.FormData, new FormData_16(bind$0040_6.Name, bind$0040_6.Description, bind$0040_6.StartDate, bind$0040_6.EndDate, bind$0040_6.ReceiverId, bind$0040_6.ToolIds, bind$0040_6.ToolsWithQuantity, bind$0040_6.SetIds, List_distinct(append(choose((addedConsumable) => {
                if (exists((consumable) => {
                    if (equals(addedConsumable.Id, consumable.Consumable.Id)) {
                        return addedConsumable.StorageId === consumable.Consumable.StorageId;
                    }
                    else {
                        return false;
                    }
                }, state.FormData.Consumables)) {
                    return undefined;
                }
                else {
                    return {
                        Consumable: addedConsumable,
                        Quantity: 0,
                    };
                }
            }, msg.fields[0]), state.FormData.Consumables), {
                Equals: equals,
                GetHashCode: structuralHash,
            }), bind$0040_6.ReservationDetailDto))), Cmd_none()];
        case 7: {
            const updatedToolWithQuantity = msg.fields[0];
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_7 = state.FormData, new FormData_16(bind$0040_7.Name, bind$0040_7.Description, bind$0040_7.StartDate, bind$0040_7.EndDate, bind$0040_7.ReceiverId, bind$0040_7.ToolIds, map((toolWithQuantity_1) => {
                if ((updatedToolWithQuantity.Id === toolWithQuantity_1.Tool.Id) && (updatedToolWithQuantity.StorageId === toolWithQuantity_1.Tool.StorageId)) {
                    return {
                        Quantity: defaultArg(msg.fields[1], 0),
                        Tool: toolWithQuantity_1.Tool,
                    };
                }
                else {
                    return toolWithQuantity_1;
                }
            }, state.FormData.ToolsWithQuantity), bind$0040_7.SetIds, bind$0040_7.Consumables, bind$0040_7.ReservationDetailDto))), Cmd_none()];
        }
        case 9:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_8 = state.FormData, new FormData_16(bind$0040_8.Name, bind$0040_8.Description, bind$0040_8.StartDate, bind$0040_8.EndDate, bind$0040_8.ReceiverId, bind$0040_8.ToolIds, bind$0040_8.ToolsWithQuantity, bind$0040_8.SetIds, choose((consumable_1) => {
                if (exists((removedConsumable) => {
                    if (equals(removedConsumable.Id, consumable_1.Consumable.Id)) {
                        return removedConsumable.StorageId === consumable_1.Consumable.StorageId;
                    }
                    else {
                        return false;
                    }
                }, msg.fields[0])) {
                    return undefined;
                }
                else {
                    return consumable_1;
                }
            }, state.FormData.Consumables), bind$0040_8.ReservationDetailDto))), Cmd_none()];
        case 10: {
            const updatedConsumable = msg.fields[0];
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_9 = state.FormData, new FormData_16(bind$0040_9.Name, bind$0040_9.Description, bind$0040_9.StartDate, bind$0040_9.EndDate, bind$0040_9.ReceiverId, bind$0040_9.ToolIds, bind$0040_9.ToolsWithQuantity, bind$0040_9.SetIds, map((consumable_2) => {
                if (equals(updatedConsumable.Id, consumable_2.Consumable.Id) && (updatedConsumable.StorageId === consumable_2.Consumable.StorageId)) {
                    return {
                        Consumable: consumable_2.Consumable,
                        Quantity: defaultArg(msg.fields[1], 0),
                    };
                }
                else {
                    return consumable_2;
                }
            }, state.FormData.Consumables), bind$0040_9.ReservationDetailDto))), Cmd_none()];
        }
        case 13:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_10 = state.FormData, new FormData_16(bind$0040_10.Name, msg.fields[0], bind$0040_10.StartDate, bind$0040_10.EndDate, bind$0040_10.ReceiverId, bind$0040_10.ToolIds, bind$0040_10.ToolsWithQuantity, bind$0040_10.SetIds, bind$0040_10.Consumables, bind$0040_10.ReservationDetailDto))), Cmd_none()];
        case 11:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_11 = state.FormData, new FormData_16(bind$0040_11.Name, bind$0040_11.Description, bind$0040_11.StartDate, bind$0040_11.EndDate, msg.fields[0], bind$0040_11.ToolIds, bind$0040_11.ToolsWithQuantity, bind$0040_11.SetIds, bind$0040_11.Consumables, bind$0040_11.ReservationDetailDto))), Cmd_none()];
        case 12:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_12 = state.FormData, new FormData_16(msg.fields[0], bind$0040_12.Description, bind$0040_12.StartDate, bind$0040_12.EndDate, bind$0040_12.ReceiverId, bind$0040_12.ToolIds, bind$0040_12.ToolsWithQuantity, bind$0040_12.SetIds, bind$0040_12.Consumables, bind$0040_12.ReservationDetailDto))), Cmd_none()];
        case 14: {
            const value_11 = msg.fields[0];
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_13 = state.FormData, new FormData_16(bind$0040_13.Name, bind$0040_13.Description, value_11, (compare(value_11, state.FormData.EndDate) > 0) ? value_11 : state.FormData.EndDate, bind$0040_13.ReceiverId, bind$0040_13.ToolIds, bind$0040_13.ToolsWithQuantity, bind$0040_13.SetIds, bind$0040_13.Consumables, bind$0040_13.ReservationDetailDto))), Cmd_none()];
        }
        case 15:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_14 = state.FormData, new FormData_16(bind$0040_14.Name, bind$0040_14.Description, bind$0040_14.StartDate, msg.fields[0], bind$0040_14.ReceiverId, bind$0040_14.ToolIds, bind$0040_14.ToolsWithQuantity, bind$0040_14.SetIds, bind$0040_14.Consumables, bind$0040_14.ReservationDetailDto))), Cmd_none()];
        case 20:
            return [new State(state.Consumables, state.Tools, new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, state.FormData), Cmd_batch(ofArray([singleton((dispatch) => {
                dispatch(new Msg(25, []));
            }), singleton((dispatch_1) => {
                dispatch_1(new Msg(24, []));
            })]))];
        case 21:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, new RequestedValue$1(1, [filter((propertyConfiguration) => {
                const matchValue_1 = propertyConfiguration.Visibility;
                switch (matchValue_1.tag) {
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        return false;
                    default:
                        return true;
                }
            }, msg.fields[0])]), state.FormData), singleton((dispatch_2) => {
                dispatch_2(new Msg(24, []));
            })];
        case 17:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, state.FormData), singleton((dispatch_3) => {
                dispatch_3(new Msg(24, []));
            })];
        case 16:
            return [new State(state.Consumables, new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, state.FormData), singleton((dispatch_4) => {
                dispatch_4(new Msg(24, []));
            })];
        case 18:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, new RequestedValue$1(1, [msg.fields[0]]), state.Receivers, state.PropertyConfigurations, state.FormData), Cmd_none()];
        case 19:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, new RequestedValue$1(1, [msg.fields[0]]), state.PropertyConfigurations, state.FormData), Cmd_batch(ofArray([singleton((dispatch_5) => {
                dispatch_5(new Msg(24, []));
            }), singleton((dispatch_6) => {
                dispatch_6(new Msg(25, []));
            })]))];
        case 23:
            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, (bind$0040_15 = state.FormData, new FormData_16(bind$0040_15.Name, bind$0040_15.Description, bind$0040_15.StartDate, bind$0040_15.EndDate, bind$0040_15.ReceiverId, bind$0040_15.ToolIds, bind$0040_15.ToolsWithQuantity, bind$0040_15.SetIds, bind$0040_15.Consumables, msg.fields[0]))), singleton((dispatch_7) => {
                dispatch_7(new Msg(24, []));
            })];
        case 24: {
            const matchValue_2 = state.Consumables;
            const matchValue_3 = state.Tools;
            const matchValue_4 = state.FormData.ReservationDetailDto;
            let matchResult, consumables, dto_4, tools_1;
            if (matchValue_2.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_3.tag === 0) {
                matchResult = 1;
            }
            else if (matchValue_4 == null) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                consumables = matchValue_2.fields[0];
                dto_4 = matchValue_4;
                tools_1 = matchValue_3.fields[0];
            }
            switch (matchResult) {
                case 0:
                    return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, new FormData_16(dto_4.Name, dto_4.Description, dto_4.StartDate, dto_4.EndDate, dto_4.ReceiverInformation.ReceiverId, map((information) => information.Id, dto_4.ToolsInformation), map((information_1) => ({
                        Quantity: information_1.RawQuantity,
                        Tool: find((tool_1) => (tool_1.Id === information_1.Id), tools_1),
                    }), dto_4.ToolsWithQuantityInformation), map((information_2) => information_2.Id, dto_4.SetsInformation), map((information_3) => ({
                        Consumable: find((consumable_3) => (ConsumableId__get_unwrap(consumable_3.Id) === information_3.Id), consumables),
                        Quantity: information_3.RawQuantity,
                    }), dto_4.ConsumablesInformation), dto_4)), Cmd_none()];
                default:
                    return [state, Cmd_none()];
            }
        }
        case 22: {
            const response_8 = msg.fields[0];
            if (response_8.tag === 1) {
                return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, FormState__setValidationResponse_5219762A(state.FormState, response_8.fields[0]), state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, state.FormData), Cmd_errorToast("validation.toast")];
            }
            else {
                return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, FormState_get_empty(), state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, state.FormData), Cmd_batch(ofArray([(xs = ["reservations", response_8.fields[0]], Cmd_ofEffect((_arg) => {
                    RouterModule_nav(ofArray(xs), 1, 1);
                })), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 25: {
            const matchValue_6 = state.Receivers;
            const matchValue_7 = state.CustomerConfiguration;
            let matchResult_1, configuration, receivers;
            if (matchValue_6.tag === 0) {
                matchResult_1 = 1;
            }
            else if (matchValue_7.tag === 0) {
                matchResult_1 = 1;
            }
            else {
                matchResult_1 = 0;
                configuration = matchValue_7.fields[0];
                receivers = matchValue_6.fields[0];
            }
            switch (matchResult_1) {
                case 0: {
                    const matchValue_9 = state.UserData.Role;
                    switch (matchValue_9) {
                        case "user":
                            if (!configuration.IsSelfServiceReservationForUserActive) {
                                return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, new RequestedValue$1(1, [empty()]), state.PropertyConfigurations, state.FormData), Cmd_none()];
                            }
                            else {
                                return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, new RequestedValue$1(1, [filter((receiver) => {
                                    if ((((((((((receiver.Id === state.UserData.UserId) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.Employees && equals(receiver.ReceiverType, new ReceiverTypeName(1, [])))) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.Projects && equals(receiver.ReceiverType, new ReceiverTypeName(5, [])))) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.Rooms && equals(receiver.ReceiverType, new ReceiverTypeName(4, [])))) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.Students && equals(receiver.ReceiverType, new ReceiverTypeName(7, [])))) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.Subcontractos && equals(receiver.ReceiverType, new ReceiverTypeName(3, [])))) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.Users && equals(receiver.ReceiverType, new ReceiverTypeName(0, [])))) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.Vehicles && equals(receiver.ReceiverType, new ReceiverTypeName(9, [])))) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.ConstructionSites && equals(receiver.ReceiverType, new ReceiverTypeName(6, [])))) ? true : (configuration.ReceiverCreateReservationAllowedConfiguration.ExternalPersons && equals(receiver.ReceiverType, new ReceiverTypeName(2, [])))) {
                                        return true;
                                    }
                                    else if (configuration.ReceiverCreateReservationAllowedConfiguration.SchoolClasses) {
                                        return equals(receiver.ReceiverType, new ReceiverTypeName(8, []));
                                    }
                                    else {
                                        return false;
                                    }
                                }, receivers)]), state.PropertyConfigurations, state.FormData), Cmd_none()];
                            }
                        default:
                            return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, state.FormState, state.ReservationNameSuggestions, new RequestedValue$1(1, [receivers]), state.PropertyConfigurations, state.FormData), Cmd_none()];
                    }
                }
                default:
                    return [state, Cmd_none()];
            }
        }
        case 26:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.FormData.ReservationDetailDto;
            if (matchValue != null) {
                const reservationDto = matchValue;
                const dto_2 = new MultiReservationUpdateDto(state.FormData.Description, state.FormData.Name, state.FormData.StartDate, reservationDto.Id, reservationDto.GroupId, state.FormData.EndDate, undefined, state.FormData.ReceiverId, map((c_1) => (new ConsumableReservation(ConsumableId__get_unwrap(c_1.Consumable.Id), c_1.Consumable.StorageId, c_1.Quantity)), state.FormData.Consumables), map((twq_1) => (new ToolWithQuantityReservation(twq_1.Tool.Id, twq_1.Tool.StorageId, ~~twq_1.Quantity)), state.FormData.ToolsWithQuantity), state.FormData.SetIds, state.FormData.ToolIds);
                return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, FormState__startLoading(state.FormState), state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, state.FormData), Cmd_OfPromise_either((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_5;
                    return ((url_5 = addPrefix_1(tupledArg_1[0]), (refreshOn_1) => fetchWithDecoder_1(url_5, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                        const pr_1 = response_1.arrayBuffer();
                        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), string_type));
                    }, refreshOn_1)))(true);
                })), ["/api/multi-operations/reservation", toString(0, Auto_generateBoxedEncoder_437914C6(MultiReservationUpdateDto_$reflection(), undefined, undefined, undefined)(dto_2))], (Item_2) => (new Msg(22, [Item_2])), (Item_3) => (new Msg(26, [Item_3])))];
            }
            else {
                const dto = new MultiReservationCreateDto(state.FormData.Description, state.FormData.Name, state.FormData.StartDate, state.FormData.EndDate, undefined, state.FormData.ReceiverId, map((c) => (new ConsumableReservation(ConsumableId__get_unwrap(c.Consumable.Id), c.Consumable.StorageId, c.Quantity)), state.FormData.Consumables), map((twq) => (new ToolWithQuantityReservation(twq.Tool.Id, twq.Tool.StorageId, ~~twq.Quantity)), state.FormData.ToolsWithQuantity), state.FormData.SetIds, state.FormData.ToolIds);
                return [new State(state.Consumables, state.Tools, state.CustomerConfiguration, state.UserData, FormState__startLoading(state.FormState), state.ReservationNameSuggestions, state.Receivers, state.PropertyConfigurations, state.FormData), Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let url_2;
                    return ((url_2 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                        const pr = response.arrayBuffer();
                        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
                    }, refreshOn)))(true);
                })), ["/api/multi-operations/reservation", toString(0, Auto_generateBoxedEncoder_437914C6(MultiReservationCreateDto_$reflection(), undefined, undefined, undefined)(dto))], (Item) => (new Msg(22, [Item])), (Item_1) => (new Msg(26, [Item_1])))];
            }
        }
    }
}

