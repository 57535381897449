import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, record_type, class_type, option_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { FormState__startLoading, FormState_get_empty, FormState_$reflection } from "../Shared/Shared.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createAssignmentProtocol } from "../Requests/PDF.js";
import { toLocalISOString } from "../Common.js";
import { map } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { onBlobReceived, getBlob } from "../CommunicationV2.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Variant as Variant_2 } from "../Variant.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { SectionHeader, PageHeader } from "../Components/Text.js";
import { PropertyList } from "./PropertyListWidget.js";
import { parse, toLocalTime, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Dialog } from "../Components/Dialog.js";
import { TextButton } from "../Components/Button.js";
import Printer from "../../public/assets/icons/Printer.svg";

export class AssignmentProtocolParams extends Record {
    constructor(AssignmentType, EntityName, InventoryNumber, SenderName, ReceiverName, StorageName, Comment$, ReturnDate, SignatureUrl, Timestamp) {
        super();
        this.AssignmentType = AssignmentType;
        this.EntityName = EntityName;
        this.InventoryNumber = InventoryNumber;
        this.SenderName = SenderName;
        this.ReceiverName = ReceiverName;
        this.StorageName = StorageName;
        this.Comment = Comment$;
        this.ReturnDate = ReturnDate;
        this.SignatureUrl = SignatureUrl;
        this.Timestamp = Timestamp;
    }
}

export function AssignmentProtocolParams_$reflection() {
    return record_type("Widgets.AssignmentProtocol.AssignmentProtocolParams", [], AssignmentProtocolParams, () => [["AssignmentType", string_type], ["EntityName", string_type], ["InventoryNumber", string_type], ["SenderName", string_type], ["ReceiverName", string_type], ["StorageName", option_type(string_type)], ["Comment", string_type], ["ReturnDate", option_type(class_type("System.DateTime"))], ["SignatureUrl", option_type(string_type)], ["Timestamp", class_type("System.DateTime")]]);
}

class State extends Record {
    constructor(FormState, AssignmentProtocolParams) {
        super();
        this.FormState = FormState;
        this.AssignmentProtocolParams = AssignmentProtocolParams;
    }
}

function State_$reflection() {
    return record_type("Widgets.AssignmentProtocol.State", [], State, () => [["FormState", FormState_$reflection()], ["AssignmentProtocolParams", option_type(AssignmentProtocolParams_$reflection())]]);
}

class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetAssignmentProtocolParams", "GeneratePDF", "PDFGenerated", "BlobReceived", "FetchError"];
    }
}

function Msg_$reflection() {
    return union_type("Widgets.AssignmentProtocol.Msg", [], Msg, () => [[["Item", option_type(AssignmentProtocolParams_$reflection())]], [], [["Item1", class_type("Fetch.Types.Response")], ["Item2", string_type]], [["Item1", class_type("Browser.Types.Blob", undefined)], ["Item2", string_type]], [["Item", class_type("System.Exception")]]]);
}

function init(assignmentProtocolParams) {
    return [new State(FormState_get_empty(), assignmentProtocolParams), Cmd_none()];
}

function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const matchValue = state.AssignmentProtocolParams;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const assignmentProtocolParams_1 = matchValue;
                return [new State(FormState__startLoading(state.FormState), state.AssignmentProtocolParams), createAssignmentProtocol(assignmentProtocolParams_1.AssignmentType, assignmentProtocolParams_1.EntityName, assignmentProtocolParams_1.InventoryNumber, assignmentProtocolParams_1.SenderName, assignmentProtocolParams_1.ReceiverName, assignmentProtocolParams_1.StorageName, assignmentProtocolParams_1.Comment, toLocalISOString(assignmentProtocolParams_1.Timestamp), map(toLocalISOString, assignmentProtocolParams_1.ReturnDate), assignmentProtocolParams_1.SignatureUrl, (tupledArg) => (new Msg(2, [tupledArg[0], tupledArg[1]])), (Item_2) => (new Msg(4, [Item_2])))];
            }
        }
        case 2:
            return [state, getBlob(msg.fields[0], msg.fields[1], (tupledArg_1) => (new Msg(3, [tupledArg_1[0], tupledArg_1[1]])), (Item_5) => (new Msg(4, [Item_5])))];
        case 3: {
            onBlobReceived(msg.fields[0], msg.fields[1]);
            return [new State(FormState_get_empty(), state.AssignmentProtocolParams), Cmd_none()];
        }
        case 4:
            return [new State(FormState_get_empty(), state.AssignmentProtocolParams), Cmd_none()];
        default:
            return [new State(state.FormState, msg.fields[0]), Cmd_none()];
    }
}

export function AssignmentProtocol(props) {
    let elems_5, elems_4;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "assignment-protocol"], ["className", join(" ", ["max-w-2xl", "print:max-w-none", "print:w-screen"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_4 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center"])], (elems = [createElement("img", {
            className: join(" ", ["w-60"]),
            src: (Variant_2.tag === 0) ? "assets/images/mwk_logo.png" : ((Variant_2.tag === 2) ? "assets/images/eagletrackmate-logo.png" : "assets/images/inventory-one-logo.png"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "items-center"])], (elems_1 = [createElement(PageHeader, {
                Text: t("assignment_protocol.title"),
            }), createElement(SectionHeader, {
                Text: props.AssignmentProtocolParams.AssignmentType,
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => append(singleton(createElement(PropertyList, {
                Properties: toList(delay(() => append(singleton({
                    Label: t("general.tool"),
                    TestId: "assignment-protocol-entity-name",
                    Value: props.AssignmentProtocolParams.EntityName,
                }), delay(() => append(singleton({
                    Label: t("general.inventory_number"),
                    TestId: "assignment-protocol-inventory-number",
                    Value: props.AssignmentProtocolParams.InventoryNumber,
                }), delay(() => append(singleton({
                    Label: t("general.sender"),
                    TestId: "assignment-protocol-sender-name",
                    Value: props.AssignmentProtocolParams.SenderName,
                }), delay(() => append(singleton({
                    Label: t("general.receiver"),
                    TestId: "assignment-protocol-receiver-name",
                    Value: props.AssignmentProtocolParams.ReceiverName,
                }), delay(() => {
                    let matchValue, storageName;
                    return append((matchValue = props.AssignmentProtocolParams.StorageName, (matchValue == null) ? (empty()) : ((storageName = matchValue, singleton({
                        Label: t("general.storage"),
                        TestId: "assignment-protocol-storage",
                        Value: storageName,
                    })))), delay(() => append(singleton({
                        Label: t("assignment_protocol.timestamp"),
                        TestId: "assignment-protocol-receiver-name",
                        Value: toString(toLocalTime(props.AssignmentProtocolParams.Timestamp), "dd.MM.yyyy HH:mm    "),
                    }), delay(() => {
                        let matchValue_1, returnDate;
                        return append((matchValue_1 = props.AssignmentProtocolParams.ReturnDate, (matchValue_1 == null) ? (empty()) : ((returnDate = matchValue_1, singleton({
                            Label: t("tool.planned_return_date"),
                            TestId: "assignment-protocol-return-date",
                            Value: toString(toLocalTime(returnDate), "dd.MM.yyyy"),
                        })))), delay(() => singleton({
                            Label: t("general.comment"),
                            TestId: "assignment-protocol-comment",
                            Value: props.AssignmentProtocolParams.Comment,
                        })));
                    }))));
                })))))))))),
            })), delay(() => {
                let elems_3, elems_2;
                const matchValue_2 = props.AssignmentProtocolParams.SignatureUrl;
                if (matchValue_2 == null) {
                    return empty();
                }
                else {
                    const signatureUrl = matchValue_2;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "border-t", "border-divider"])], (elems_3 = [createElement("div", {
                        className: join(" ", ["flex", "items-end", "mr-4", "font-semibold"]),
                        children: t("general.signature"),
                    }), createElement("div", createObj(ofArray([["className", join(" ", ["border-b", "grow"])], (elems_2 = [createElement("img", {
                        src: signatureUrl,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
                }
            }))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function AssignmentProtocolDialog(props) {
    let IsOpen, elems, Label_1;
    const t = useTranslation()[0];
    let patternInput_1;
    const init_1 = init(props.AssignmentProtocolParams);
    patternInput_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, undefined);
    const dispatch = patternInput_1[1];
    const dependencies_2 = [props.AssignmentProtocolParams];
    reactApi.useEffect(() => {
        dispatch(new Msg(0, [props.AssignmentProtocolParams]));
    }, dependencies_2);
    return createElement(Dialog, (IsOpen = (props.AssignmentProtocolParams != null), {
        Body: toList(delay(() => {
            const matchValue = props.AssignmentProtocolParams;
            if (matchValue == null) {
                return empty();
            }
            else {
                return singleton(createElement(AssignmentProtocol, {
                    AssignmentProtocolParams: matchValue,
                }));
            }
        })),
        Controls: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-9", "print:hidden"])], (elems = [createElement(TextButton, {
            ComponentState: "enabled",
            Label: t("general.close"),
            OnClick: props.OnClose,
            TestId: "",
            Variant: "default",
        }), createElement(TextButton, (Label_1 = t("assignment_protocol.print"), {
            ComponentState: patternInput_1[0].FormState.IsLoading ? "disabled" : "enabled",
            Icon: Printer(),
            Label: Label_1,
            OnClick: () => {
                dispatch(new Msg(1, []));
            },
            TestId: "",
            Variant: "blueButton",
        }))], ["children", reactApi.Children.toArray(Array.from(elems))])])))),
        IsOpen: IsOpen,
        OnClose: props.OnClose,
        Title: "",
    }));
}

export function AssignmentProtocolView(props) {
    const timestamp = parse(props.Timestamp);
    return createElement(AssignmentProtocol, {
        AssignmentProtocolParams: new AssignmentProtocolParams(props.AssignmentType, props.EntityName, props.InventoryNumber, props.SenderName, props.ReceiverName, props.StorageName, props.Comment, map(parse, props.ReturnDate), props.SignatureUrl, timestamp),
    });
}

