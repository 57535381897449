import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { singleton as singleton_1, contains, reduce, append, filter, ofArray, find, map, empty } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { curry2, compare, safeHash, int32ToString, createObj, structuralHash, equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { PropertyConfigurationId__get_unwrap, FormState__getValidation, PropertyConfigurationEnumValueId__get_unwrap, PropertyConfigurationId, PropertyConfigurationEnumValueId_Parse_Z721C83C5 } from "../Shared/Shared.js";
import { join, split } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { Select, MultiSelect } from "../Components/Select.js";
import { map as map_1, flatten, unwrap, defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { singleton, empty as empty_1, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { CustomProperty } from "../Shared/PropertyConfiguration.js";
import { CurrencyInput, Input } from "../Components/Input.js";
import { toString, utcNow, parse as parse_1 } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { DatePicker } from "../Components/DatePicker.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Checkbox } from "../Components/Checkbox.js";
import { parse as parse_2 } from "../fable_modules/fable-library-js.4.19.2/Int32.js";
import { LabeledValue } from "../Components/Text.js";
import { ofSeq, FSharpMap__TryFind } from "../fable_modules/fable-library-js.4.19.2/Map.js";
import { parse as parse_3 } from "../fable_modules/fable-library-js.4.19.2/Double.js";
import { ColumnDefinition$1, CellContent } from "./Table/Table.js";
import { priceToString } from "../Common.js";

export function MultiEnumProperty(props) {
    const t = useTranslation()[0];
    const requiredLabel = t("general.required");
    let enumValues;
    const matchValue = props.Property.Type;
    enumValues = ((matchValue.tag === 2) ? matchValue.fields[0] : empty());
    let value_2;
    const matchValue_1 = props.Value;
    value_2 = ((matchValue_1 == null) ? empty() : map((valueId_1) => ({
        label: find((enumValue) => equals(enumValue.Id, valueId_1), enumValues).Name,
        value: valueId_1,
    }), map(PropertyConfigurationEnumValueId_Parse_Z721C83C5, ofArray(split(matchValue_1.Value, [","], undefined, 0)))));
    return createElement(MultiSelect, {
        ComponentState: "enabled",
        IsClearable: true,
        Label: props.Property.Required ? (`${props.Property.Name} (${requiredLabel})`) : props.Property.Name,
        NoOptionsMessage: "",
        OnChange: (option) => {
            let list_9;
            const option_2 = defaultArg(option, []);
            props.OnSetCustomProperties((list_9 = filter((property) => !equals(new PropertyConfigurationId(parse(property.Id)), props.Property.Id), props.CustomProperties), append(toList(delay(() => {
                if (option_2.length === 0) {
                    return empty_1();
                }
                else {
                    return singleton(new CustomProperty(props.Property.Id.fields[0], reduce((e1, e2) => (`${e1},${e2}`), map((e) => PropertyConfigurationEnumValueId__get_unwrap(e.value), ofArray(option_2)))));
                }
            })), list_9)));
        },
        Options: filter((enumValue_2) => !contains(enumValue_2, value_2, {
            Equals: equals,
            GetHashCode: structuralHash,
        }), map((value_3) => ({
            label: value_3.Name,
            value: value_3.Id,
        }), enumValues)),
        PlaceholderKey: "",
        TestId: props.TestId,
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `custom-property-${PropertyConfigurationId__get_unwrap(props.Property.Id)}`)),
        Value: value_2,
    });
}

export function StringProperty(props) {
    let Label, Value, matchValue;
    const t = useTranslation()[0];
    return createElement(Input, (Label = (props.Property.Required ? (`${props.Property.Name} (${t("general.required")})`) : props.Property.Name), (Value = ((matchValue = props.Value, (matchValue == null) ? "" : matchValue.Value)), {
        ComponentState: "enabled",
        Label: unwrap(Label),
        OnChange: (value_1) => {
            let list_2;
            props.OnSetCustomProperties((list_2 = filter((property) => !equals(new PropertyConfigurationId(parse(property.Id)), props.Property.Id), props.CustomProperties), append(toList(delay(() => singleton(new CustomProperty(props.Property.Id.fields[0], value_1)))), list_2)));
        },
        TestId: props.TestId,
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `custom-property-${PropertyConfigurationId__get_unwrap(props.Property.Id)}`)),
        Value: Value,
    })));
}

export function DateProperty(props) {
    let elems, children;
    const t = useTranslation()[0];
    const requiredLabel = t("general.required");
    let value_1;
    const matchValue = props.Value;
    value_1 = ((matchValue != null) ? parse_1(matchValue.Value) : undefined);
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [(children = singleton_1(createElement(DatePicker, {
        ComponentState: "enabled",
        IsClearable: !props.Property.Required,
        Label: props.Property.Required ? (`${props.Property.Name} (${requiredLabel})`) : props.Property.Name,
        MinDate: utcNow(),
        OnChange: (value_3) => {
            let list_2;
            props.OnSetCustomProperties((list_2 = filter((property) => !equals(new PropertyConfigurationId(parse(property.Id)), props.Property.Id), props.CustomProperties), append(toList(delay(() => {
                if (value_3 != null) {
                    return singleton(new CustomProperty(props.Property.Id.fields[0], toString(value_3, "yyyy-MM-dd")));
                }
                else {
                    return empty_1();
                }
            })), list_2)));
        },
        TestId: props.TestId,
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `custom-property-${PropertyConfigurationId__get_unwrap(props.Property.Id)}`)),
        Value: unwrap(value_1),
    })), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function BoolProperty(props) {
    let elems, matchValue;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [Checkbox({
        ComponentState: "enabled",
        IsChecked: (matchValue = props.Value, (matchValue == null) ? false : (matchValue.Value === "true")),
        Label: props.Property.Name,
        OnCheck: (value_2) => {
            let list_2;
            props.OnSetCustomProperties((list_2 = filter((property) => !equals(new PropertyConfigurationId(parse(property.Id)), props.Property.Id), props.CustomProperties), append(toList(delay(() => singleton(new CustomProperty(props.Property.Id.fields[0], value_2 ? "true" : "false")))), list_2)));
        },
        TestId: props.TestId,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function CurrencyProperty(props) {
    let Label, Value_1;
    const t = useTranslation()[0];
    return createElement(CurrencyInput, (Label = (props.Property.Required ? (`${props.Property.Name} (${t("general.required")})`) : props.Property.Name), (Value_1 = flatten(map_1((property) => {
        if (property.Value === "") {
            return undefined;
        }
        else {
            return parse_2(property.Value, 511, false, 32);
        }
    }, props.Value)), {
        ComponentState: "enabled",
        Label: unwrap(Label),
        OnChange: (value_1) => {
            let list_2;
            props.OnSetCustomProperties((list_2 = filter((property_1) => !equals(new PropertyConfigurationId(parse(property_1.Id)), props.Property.Id), props.CustomProperties), append(toList(delay(() => {
                if (value_1 != null) {
                    return singleton(new CustomProperty(props.Property.Id.fields[0], int32ToString(value_1)));
                }
                else {
                    return empty_1();
                }
            })), list_2)));
        },
        TestId: "",
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `custom-property-${PropertyConfigurationId__get_unwrap(props.Property.Id)}`)),
        Value: unwrap(Value_1),
    })));
}

export function EnumProperty(props) {
    const t = useTranslation()[0];
    let enumValues;
    const matchValue = props.Property.Type;
    enumValues = ((matchValue.tag === 1) ? matchValue.fields[0] : empty());
    const requiredLabel = t("general.required");
    let value_1;
    const matchValue_1 = props.Value;
    if (matchValue_1 == null) {
        value_1 = undefined;
    }
    else {
        const value = matchValue_1;
        value_1 = {
            label: find((enumValue) => equals(enumValue.Id, PropertyConfigurationEnumValueId_Parse_Z721C83C5(value.Value)), enumValues).Name,
            value: value.Id,
        };
    }
    return createElement(Select, {
        ComponentState: "enabled",
        IsClearable: true,
        Label: props.Property.Required ? (`${props.Property.Name} (${requiredLabel})`) : props.Property.Name,
        NoOptionsMessage: "",
        OnChange: (option) => {
            let list_4;
            props.OnSetCustomProperties((list_4 = filter((property) => !equals(new PropertyConfigurationId(parse(property.Id)), props.Property.Id), props.CustomProperties), append(toList(delay(() => {
                if (option == null) {
                    return empty_1();
                }
                else {
                    return singleton(new CustomProperty(props.Property.Id.fields[0], option.value));
                }
            })), list_4)));
        },
        Options: map((value_2) => ({
            label: value_2.Name,
            value: PropertyConfigurationEnumValueId__get_unwrap(value_2.Id),
        }), enumValues),
        PlaceholderKey: "",
        TestId: props.TestId,
        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, `custom-property-${PropertyConfigurationId__get_unwrap(props.Property.Id)}`)),
        Value: unwrap(value_1),
    });
}

export function View(props) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-3"])], (elems_1 = toList(delay(() => map((globalProperty) => {
        let elems;
        const matchValue = globalProperty.Type;
        switch (matchValue.tag) {
            case 2:
                return LabeledValue({
                    Label: globalProperty.Name,
                    Value: defaultArg(map_1((property_1) => reduce((a, b) => (`${a}, ${b}`), map((valueId_1) => find((enumValue_2) => equals(enumValue_2.Id, PropertyConfigurationEnumValueId_Parse_Z721C83C5(valueId_1)), matchValue.fields[0]).Name, ofArray(split(property_1.Value, [","], undefined, 0)))), FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)), ""),
                });
            case 0:
                return LabeledValue({
                    Label: globalProperty.Name,
                    Value: defaultArg(map_1((property_2) => property_2.Value, FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)), ""),
                });
            case 3:
                return LabeledValue({
                    Label: globalProperty.Name,
                    Value: defaultArg(map_1((property_3) => toString(parse_1(property_3.Value), "dd.MM.yyyy"), FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)), ""),
                });
            case 4:
                return createElement("div", createObj(singleton_1((elems = [createElement("label", {
                    className: join(" ", ["mt-auto mb-auto mr-3"]),
                    children: globalProperty.Name,
                }), Checkbox({
                    ComponentState: "enabled",
                    IsChecked: defaultArg(map_1((property_4) => (property_4.Value === "true"), FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)), false),
                    Label: "",
                    OnCheck: (value_9) => {
                    },
                    TestId: "",
                })], ["children", reactApi.Children.toArray(Array.from(elems))]))));
            case 5:
                return LabeledValue({
                    Label: globalProperty.Name,
                    Value: defaultArg(map_1((property_5) => property_5.Value, FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)), ""),
                });
            case 6:
                return LabeledValue({
                    Label: globalProperty.Name,
                    Value: defaultArg(map_1((property_6) => {
                        if (property_6.Value === "") {
                            return "";
                        }
                        else {
                            const value_13 = parse_3(property_6.Value) / 100;
                            return value_13.toString();
                        }
                    }, FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)), ""),
                });
            default:
                return LabeledValue({
                    Label: globalProperty.Name,
                    Value: defaultArg(map_1((property) => find((enumValue) => equals(enumValue.Id, PropertyConfigurationEnumValueId_Parse_Z721C83C5(property.Value)), matchValue.fields[0]).Name, FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)), ""),
                });
        }
    }, props.GlobalProperties))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Form(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-5"])], (elems = toList(delay(() => map((globalProperty) => {
        const matchValue = globalProperty.Type;
        switch (matchValue.tag) {
            case 2:
                return createElement(MultiEnumProperty, {
                    CustomProperties: props.CustomProperties,
                    FormState: props.FormState,
                    OnSetCustomProperties: props.OnSetCustomProperties,
                    Property: globalProperty,
                    TestId: globalProperty.Name,
                    Value: unwrap(FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)),
                });
            case 0:
                return createElement(StringProperty, {
                    CustomProperties: props.CustomProperties,
                    FormState: props.FormState,
                    OnSetCustomProperties: props.OnSetCustomProperties,
                    Property: globalProperty,
                    TestId: globalProperty.Name,
                    Value: unwrap(FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)),
                });
            case 3:
                return createElement(DateProperty, {
                    CustomProperties: props.CustomProperties,
                    FormState: props.FormState,
                    OnSetCustomProperties: props.OnSetCustomProperties,
                    Property: globalProperty,
                    TestId: globalProperty.Name,
                    Value: unwrap(FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)),
                });
            case 4:
                return createElement(BoolProperty, {
                    CustomProperties: props.CustomProperties,
                    FormState: props.FormState,
                    OnSetCustomProperties: props.OnSetCustomProperties,
                    Property: globalProperty,
                    TestId: globalProperty.Name,
                    Value: unwrap(FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)),
                });
            case 5:
                return createElement(StringProperty, {
                    CustomProperties: props.CustomProperties,
                    FormState: props.FormState,
                    OnSetCustomProperties: props.OnSetCustomProperties,
                    Property: globalProperty,
                    TestId: globalProperty.Name,
                    Value: unwrap(FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)),
                });
            case 6:
                return createElement(CurrencyProperty, {
                    CustomProperties: props.CustomProperties,
                    FormState: props.FormState,
                    OnSetCustomProperties: props.OnSetCustomProperties,
                    Property: globalProperty,
                    TestId: globalProperty.Name,
                    Value: unwrap(FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)),
                });
            default:
                return createElement(EnumProperty, {
                    CustomProperties: props.CustomProperties,
                    FormState: props.FormState,
                    OnSetCustomProperties: props.OnSetCustomProperties,
                    Property: globalProperty,
                    TestId: globalProperty.Name,
                    Value: unwrap(FSharpMap__TryFind(props.CustomPropertyValues, globalProperty.Id)),
                });
        }
    }, props.GlobalProperties))), ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function NewInventoryCustomProperties(props) {
    let elems;
    const globalProperties = filter((configuration) => {
        const matchValue = configuration.Visibility;
        switch (matchValue.tag) {
            case 1:
                return contains(matchValue.fields[0], props.LabelIds, {
                    Equals: equals,
                    GetHashCode: safeHash,
                });
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
                return false;
            default:
                return true;
        }
    }, props.PropertyConfigurations);
    const customPropertyValues = ofSeq(map((customProperty) => [new PropertyConfigurationId(parse(customProperty.Id)), customProperty], props.CustomProperties), {
        Compare: compare,
    });
    return createElement("div", createObj(ofArray([["className", join(" ", ["mb-5"])], (elems = [createElement(Form, {
        CustomProperties: props.CustomProperties,
        CustomPropertyValues: customPropertyValues,
        FormState: props.FormState,
        GlobalProperties: globalProperties,
        OnSetCustomProperties: props.OnSetCustomProperty,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function NewReceiverCustomProperties(props) {
    let elems;
    const globalProperties = filter((configuration) => equals(configuration.Visibility, props.PropertyConfigurationType), props.PropertyConfigurations);
    const customPropertyValues = ofSeq(map((customProperty) => [new PropertyConfigurationId(parse(customProperty.Id)), customProperty], props.CustomProperties), {
        Compare: compare,
    });
    return createElement("div", createObj(ofArray([["className", join(" ", ["mb-5"])], (elems = [createElement(Form, {
        CustomProperties: props.CustomProperties,
        CustomPropertyValues: customPropertyValues,
        FormState: props.FormState,
        GlobalProperties: globalProperties,
        OnSetCustomProperties: props.OnSetCustomProperty,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function Table_customPropertyColumnDefinitions(propertyConfigurations, groupName, getCustomProperties, translate) {
    return map((propertyConfiguration) => {
        const customPropertyValues = (customProperties) => ofSeq(map((customProperty) => [new PropertyConfigurationId(parse(customProperty.Id)), customProperty], customProperties), {
            Compare: compare,
        });
        const getEnumValue = (enumValues, valueId) => find((enumValue) => equals(enumValue.Id, PropertyConfigurationEnumValueId_Parse_Z721C83C5(valueId)), enumValues).Name;
        let id;
        const matchValue = propertyConfiguration.Visibility;
        id = ((matchValue.tag === 1) ? (`category-${matchValue.fields[0]}-${propertyConfiguration.Name}`) : ((matchValue.tag === 2) ? (`user-${propertyConfiguration.Name}`) : ((matchValue.tag === 3) ? (`employee-${propertyConfiguration.Name}`) : ((matchValue.tag === 4) ? (`external-person-${propertyConfiguration.Name}`) : ((matchValue.tag === 5) ? (`subcontractor-${propertyConfiguration.Name}`) : ((matchValue.tag === 6) ? (`room-${propertyConfiguration.Name}`) : ((matchValue.tag === 7) ? (`project-${propertyConfiguration.Name}`) : ((matchValue.tag === 8) ? (`construction-site-${propertyConfiguration.Name}`) : ((matchValue.tag === 9) ? (`vehicle-${propertyConfiguration.Name}`) : ((matchValue.tag === 10) ? (`student-${propertyConfiguration.Name}`) : ((matchValue.tag === 11) ? (`school-class-${propertyConfiguration.Name}`) : (`global-${propertyConfiguration.Name}`))))))))))));
        const matchValue_1 = propertyConfiguration.Type;
        switch (matchValue_1.tag) {
            case 1:
                return new ColumnDefinition$1(id, groupName, propertyConfiguration.Name, (dto_1) => (new CellContent(0, [defaultArg(map_1((property_1) => getEnumValue(matchValue_1.fields[0], property_1.Value), FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_1)), propertyConfiguration.Id)), "")])), "select", "text");
            case 2:
                return new ColumnDefinition$1(id, groupName, propertyConfiguration.Name, (dto_2) => (new CellContent(0, [defaultArg(map_1((property_2) => {
                    let list_2;
                    return reduce((a, b) => (`${a}, ${b}`), (list_2 = ofArray(split(property_2.Value, [","], undefined, 0)), map(curry2(getEnumValue)(matchValue_1.fields[0]), list_2)));
                }, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_2)), propertyConfiguration.Id)), "")])), "select", "text");
            case 4:
                return new ColumnDefinition$1(id, groupName, propertyConfiguration.Name, (dto_3) => (new CellContent(0, [defaultArg(map_1((property_3) => {
                    if (property_3.Value === "true") {
                        return translate("general.yes");
                    }
                    else {
                        return translate("general.no");
                    }
                }, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_3)), propertyConfiguration.Id)), translate("general.no"))])), "select", "text");
            case 0:
                return new ColumnDefinition$1(id, groupName, propertyConfiguration.Name, (dto_4) => (new CellContent(0, [defaultArg(map_1((property_4) => property_4.Value, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_4)), propertyConfiguration.Id)), "")])), "text", "text");
            case 5:
                return new ColumnDefinition$1(id, groupName, propertyConfiguration.Name, (dto_5) => (new CellContent(0, [defaultArg(map_1((property_5) => property_5.Value, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_5)), propertyConfiguration.Id)), "")])), "text", "text");
            case 6:
                return new ColumnDefinition$1(id, groupName, propertyConfiguration.Name, (dto_6) => (new CellContent(0, [defaultArg(map_1((property_6) => priceToString(parse_2(property_6.Value, 511, false, 32)), FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_6)), propertyConfiguration.Id)), "")])), "text", "text");
            default:
                return new ColumnDefinition$1(id, groupName, propertyConfiguration.Name, (dto) => (new CellContent(5, [map_1((property) => parse_1(property.Value), FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto)), propertyConfiguration.Id))])), "dateRange", "date");
        }
    }, propertyConfigurations);
}

export function Table_customPropertyValueDtoColumnDefinitions(propertyConfigurations, groupName, getCustomProperties, translate) {
    return map((propertyConfiguration) => {
        const customPropertyValues = (customProperties) => ofSeq(map((customProperty) => [new PropertyConfigurationId(parse(customProperty.PropertyConfigurationId)), customProperty], customProperties), {
            Compare: compare,
        });
        const matchValue = propertyConfiguration.Type;
        switch (matchValue.tag) {
            case 1:
                return new ColumnDefinition$1(propertyConfiguration.Name, groupName, propertyConfiguration.Name, (dto_1) => (new CellContent(0, [defaultArg(flatten(map_1((value_1) => value_1.TextValue, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_1)), propertyConfiguration.Id))), "")])), "select", "text");
            case 2:
                return new ColumnDefinition$1(propertyConfiguration.Name, groupName, propertyConfiguration.Name, (dto_2) => (new CellContent(0, [defaultArg(flatten(map_1((value_3) => value_3.TextValue, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_2)), propertyConfiguration.Id))), "")])), "select", "text");
            case 4:
                return new ColumnDefinition$1(propertyConfiguration.Name, groupName, propertyConfiguration.Name, (dto_3) => (new CellContent(0, [defaultArg(map_1((value_6) => {
                    if (value_6) {
                        return translate("general.yes");
                    }
                    else {
                        return translate("general.no");
                    }
                }, flatten(map_1((value_5) => value_5.BoolValue, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_3)), propertyConfiguration.Id)))), translate("general.no"))])), "select", "text");
            case 0:
                return new ColumnDefinition$1(propertyConfiguration.Name, groupName, propertyConfiguration.Name, (dto_4) => (new CellContent(0, [defaultArg(flatten(map_1((value_8) => value_8.TextValue, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_4)), propertyConfiguration.Id))), "")])), "text", "text");
            case 5:
                return new ColumnDefinition$1(propertyConfiguration.Name, groupName, propertyConfiguration.Name, (dto_5) => (new CellContent(0, [defaultArg(flatten(map_1((value_10) => value_10.TextValue, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_5)), propertyConfiguration.Id))), "")])), "text", "text");
            case 6:
                return new ColumnDefinition$1(propertyConfiguration.Name, groupName, propertyConfiguration.Name, (dto_6) => (new CellContent(0, [defaultArg(flatten(map_1((value_12) => value_12.TextValue, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto_6)), propertyConfiguration.Id))), "")])), "text", "text");
            default:
                return new ColumnDefinition$1(propertyConfiguration.Name, groupName, propertyConfiguration.Name, (dto) => (new CellContent(5, [flatten(map_1((value) => value.DateValue, FSharpMap__TryFind(customPropertyValues(getCustomProperties(dto)), propertyConfiguration.Id)))])), "dateRange", "date");
        }
    }, propertyConfigurations);
}

