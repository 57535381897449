import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { token as token_2, addPrefix, fetchWithDecoder } from "../Communication.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { ofArray, map, reduce, isEmpty, singleton } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { DataTransfer_SetInfo_$reflection, DataTransfer_UnassignSetDto_$reflection, DataTransfer_AssignSetNotificationDto_$reflection, DataTransfer_AssignSetDto_$reflection, DataTransfer_ChangeStorageDto_$reflection, DataTransfer_UpdateStateDto_$reflection, DataTransfer_AssignedTool_$reflection, DataTransfer_DetailSet_$reflection, DataTransfer_OverviewSet_$reflection } from "../Shared/Set.js";
import { string_type, list_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { DataTransfer_ReleaseDateDto_$reflection, UpdateReturnDateDto_$reflection, DataTransfer_UpdateReservationFormDto_$reflection, DataTransfer_CreateReservationFormDto_$reflection, ImageDeletedResponse_$reflection, SetId__get_unwrap } from "../Shared/Shared.js";
import { ofNullable } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton as singleton_1, empty, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { unwrapNoticeId } from "../Shared/Helper.js";
import { fetchWithDecoder as fetchWithDecoder_1, addPrefix as addPrefix_1 } from "../CommunicationV2.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ToolConfirmReminderDto_$reflection, UpdateReminderDto_$reflection, AddReminderDto_$reflection } from "../Shared/Tool.js";
import { ValidationOnGetRequest } from "../ErrorHandling.js";

export function getSets(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(DataTransfer_OverviewSet_$reflection())));
    })))), "/api/sets/overview", successMsg, errorMsg);
}

export function getSelectedSets(setIds, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(DataTransfer_DetailSet_$reflection())));
    })))), `/api/sets/selected?ids=${isEmpty(setIds) ? "" : reduce((id1, id2) => (`${id1},${id2}`), map(SetId__get_unwrap, setIds))}`, successMsg, errorMsg);
}

export function getAssignableTools(successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(DataTransfer_AssignedTool_$reflection())));
    })))), "/api/sets/assignable-tools", successMsg, errorMsg);
}

export function getAssignableToolsWithSetId(successMsg, errorMsg, setId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(DataTransfer_AssignedTool_$reflection())));
    })))), `/api/sets/assignable-tools/${setId}`, successMsg, errorMsg);
}

export function downloadFileCmd(setId, documentId, fileName, success, error) {
    return Cmd_OfPromise_either((tupledArg) => {
        const body_1 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const tenant = ofNullable(localStorage.getItem("tenant"));
            const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_3]);
                    }
                }))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_1 = tenant;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_1]);
                    }
                }))));
            })), 0)])]);
            return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, [`/api/sets/${SetId__get_unwrap(setId)}/files/${documentId.fields[0]}`, fileName, undefined], success, error);
}

export function deleteNoticeCmd(setId, noticeId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    })))), `/api/sets/${SetId__get_unwrap(setId)}/notices/${unwrapNoticeId(noticeId)}`, successMsg, errorMsg);
}

export function saveImageCmd(setId, formData, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    })))), [`/api/sets/${SetId__get_unwrap(setId)}/image`, formData], successMsg, errorMsg);
}

export function deleteImageCmd(setId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ImageDeletedResponse_$reflection()));
    })))), `/api/sets/${SetId__get_unwrap(setId)}/image`, successMsg, errorMsg);
}

export function postReservationCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/reservations`, toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_CreateReservationFormDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function putReservationCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/reservations`, toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_UpdateReservationFormDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function deleteReservationCmd(setId, reservationId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(url_1), (refreshOn) => fetchWithDecoder_1(url_3, singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), `/api/sets/${SetId__get_unwrap(setId)}/reservations/${reservationId}`, successMsg, errorMsg);
}

export function postReminderCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    })))), [`/api/sets/${SetId__get_unwrap(setId)}/reminder`, toString(0, Auto_generateBoxedEncoder_437914C6(AddReminderDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function putReminderCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    })))), [`/api/sets/${SetId__get_unwrap(setId)}/reminder`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateReminderDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function confirmReminderCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    })))), [`/api/sets/${SetId__get_unwrap(setId)}/reminder/confirm`, toString(0, Auto_generateBoxedEncoder_437914C6(ToolConfirmReminderDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function deleteReminderCmd(setId, reminderId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_1), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
    })))), `/api/sets/${SetId__get_unwrap(setId)}/reminder/${reminderId.fields[0]}`, successMsg, errorMsg);
}

export function putStateCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/state`, toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_UpdateStateDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function putChangeStorageCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/storage`, toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_ChangeStorageDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function assignCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/assign`, toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_AssignSetDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function assignWithNotificationCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/assign/notify`, toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_AssignSetNotificationDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function unassignCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/unassign`, toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_UnassignSetDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function updateReturnDateCmd(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/return-date`, toString(0, Auto_generateBoxedEncoder_437914C6(UpdateReturnDateDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function setReleaseDate(setId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix_1(tupledArg[0]), (refreshOn) => fetchWithDecoder_1(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/sets/${SetId__get_unwrap(setId)}/release-date`, toString(0, Auto_generateBoxedEncoder_437914C6(DataTransfer_ReleaseDateDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function getSetInfo(setId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix_1(url), (refreshOn) => fetchWithDecoder_1(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), DataTransfer_SetInfo_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), `/api/sets/${SetId__get_unwrap(setId)}/info`, successMsg, errorMsg);
}

