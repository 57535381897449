import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { token as token_2, addPrefix, fetchWithDecoder } from "../../../Communication.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { map, empty as empty_1, ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../../../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type, string_type } from "../../../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { State, Msg } from "./Types.js";
import { NoticeDto_$reflection, ToolNoticeResponse_$reflection, NoticeDto, UnassignQRCode, AssignQRCode_$reflection, AssignQRCodeResult_$reflection, AssignQRCode, ProblemReport_$reflection } from "../../../Shared/Tool.js";
import { ToolId, DocumentDetail_$reflection, RequestedValue$1, ImageDeletedResponse_$reflection, PostResponse$1_$reflection, EmptyResponse_$reflection, ToolId__get_unwrap } from "../../../Shared/Shared.js";
import { ofNullable } from "../../../fable_modules/fable-library-js.4.19.2/Option.js";
import { keyValueList } from "../../../fable_modules/fable-library-js.4.19.2/MapUtil.js";
import { singleton as singleton_1, empty, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { fetch$ } from "../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { printf, toText } from "../../../fable_modules/fable-library-js.4.19.2/String.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise as promise_1 } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { Cmd_downloadFileCmd, Cmd_deleteProblemReportCmd, Cmd_deleteQrCodes, Cmd_getToolHistoryDto, Cmd_getToolDetailDto } from "../../../Requests/Tool.js";
import { Cmd_OfPromise_either as Cmd_OfPromise_either_1 } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { UserConfiguration as UserConfiguration_1, QuickOverviewConfiguration as QuickOverviewConfiguration_1, UserConfiguration_$reflection } from "../../../Shared/User.js";
import { unwrapToolId } from "../../../Shared/Helper.js";
import { getCustomerConfiguration } from "../../../Requests/Configuration.js";
import { Storage_$reflection } from "../../../Shared/Storage.js";
import { getToolNotifications } from "../../../Requests/Notification.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../../../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { Cmd_successToast, Cmd_errorToast } from "../../../Components/Toast.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { unwrapNoticeId } from "../../../Shared/Helper.js";
import { Cmd_ofEffect } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { RouterModule_nav } from "../../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";

export const getManufacturersCmd = Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
    const pr = response.arrayBuffer();
    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(string_type)));
})))), "/api/tools/manufacturers", (Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(34, [Item_1])));

export function getProblemReportsCmd(toolId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ProblemReport_$reflection())));
    })))), `/api/tools/${ToolId__get_unwrap(toolId)}/problemreports`, (Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(34, [Item_1])));
}

export function downloadFileCmd(toolId, documentId, fileName, success, error) {
    return Cmd_OfPromise_either((tupledArg) => {
        const body_1 = tupledArg[2];
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const tenant = ofNullable(localStorage.getItem("tenant"));
            const props = (body_1 == null) ? ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue_2;
                return append((matchValue_2 = token_2(undefined), (matchValue_2 == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue_2]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_3 = tenant;
                    if (matchValue_3 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_3]);
                    }
                }))));
            })), 0)])]) : ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [body_1]), new Types_RequestProperties(1, [keyValueList(toList(delay(() => {
                let matchValue;
                return append((matchValue = token_2(undefined), (matchValue == null) ? (empty()) : singleton_1(new Types_HttpRequestHeaders(5, ["Bearer " + matchValue]))), delay(() => append(singleton_1(new Types_HttpRequestHeaders(11, ["Content-Disposition"])), delay(() => {
                    const matchValue_1 = tenant;
                    if (matchValue_1 == null) {
                        return empty();
                    }
                    else {
                        return singleton_1(["X-ONE-Tenant", matchValue_1]);
                    }
                }))));
            })), 0)])]);
            return fetch$(addPrefix(tupledArg[0]), props).then((_arg) => (Promise.resolve([_arg, tupledArg[1]])));
        }));
    }, [toText(printf("/api/tools/%s/files/%s"))(toolId.fields[0])(documentId.fields[0]), fileName, undefined], success, error);
}

export function saveImageCmd(toolId, formData) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), undefined, (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), [`/api/tools/${toolId.fields[0]}/image`, formData], (Item) => (new Msg(20, [Item])), (Item_1) => (new Msg(34, [Item_1])));
}

export function deleteImageCmd(toolId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ImageDeletedResponse_$reflection())));
    })))), `/api/tools/${toolId.fields[0]}/image`, (Item) => (new Msg(19, [Item])), (Item_1) => (new Msg(34, [Item_1])));
}

export function extractBlob(response, filename) {
    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response.blob().then((_arg) => (Promise.resolve([_arg, filename]))))));
}

export function getBlob(response, filename) {
    return Cmd_OfPromise_either((tupledArg) => extractBlob(tupledArg[0], tupledArg[1]), [response, filename], (tupledArg_1) => (new Msg(21, [tupledArg_1[0], tupledArg_1[1]])), (Item_2) => (new Msg(34, [Item_2])));
}

export function initState(userData) {
    return new State(new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), userData, userData.Customer, false, empty_1(), {
        IsLoading: false,
        IsOpen: false,
    }, new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []), new RequestedValue$1(0, []));
}

export function initViewTool(toolId, userData) {
    return [initState(userData), Cmd_batch(ofArray([Cmd_getToolDetailDto((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(34, [Item_1])), toolId), Cmd_OfPromise_either_1((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), UserConfiguration_$reflection()));
    })))), "/api/users/configuration", (Item_2) => (new Msg(23, [Item_2])), (Item_3) => (new Msg(34, [Item_3]))), getProblemReportsCmd(toolId), Cmd_OfPromise_either_1((url_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_3), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_1) => {
        const pr_1 = response_1.arrayBuffer();
        return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), list_type(DocumentDetail_$reflection())));
    })))), `/api/tools/${unwrapToolId(toolId)}/documents`, (Item_4) => (new Msg(29, [Item_4])), (Item_5) => (new Msg(34, [Item_5]))), getCustomerConfiguration((Item_6) => (new Msg(30, [Item_6])), (Item_7) => (new Msg(34, [Item_7]))), Cmd_OfPromise_either_1((url_6) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_6), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_2) => {
        const pr_2 = response_2.arrayBuffer();
        return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), list_type(Storage_$reflection())));
    })))), "/api/storages", (Item_8) => (new Msg(31, [Item_8])), (Item_9) => (new Msg(34, [Item_9]))), Cmd_getToolHistoryDto((Item_10) => (new Msg(32, [Item_10])), (Item_11) => (new Msg(34, [Item_11])), toolId), getToolNotifications(toolId, (Item_12) => (new Msg(33, [Item_12])), (Item_13) => (new Msg(34, [Item_13])))]))];
}

export function update(msg, state) {
    let matchValue_2, userConfiguration_1, bind$0040_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.Tool, state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, {
                IsLoading: state.QuickOverviewDialog.IsLoading,
                IsOpen: !state.QuickOverviewDialog.IsOpen,
            }, state.Documents, state.CustomerConfiguration, state.History, state.Notifications), Cmd_none()];
        case 9: {
            const matchValue_1 = state.Tool;
            if (matchValue_1.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const t = matchValue_1.fields[0];
                const dto = new AssignQRCode(t.Id, msg.fields[0], t.StorageInformation.StorageId);
                return [state, Cmd_OfPromise_either_1((tupledArg_1) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_1[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_1[1]])]), "application/json", (response_1) => {
                    const pr_1 = response_1.arrayBuffer();
                    return pr_1.then((blob_1) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_1)), PostResponse$1_$reflection(AssignQRCodeResult_$reflection())));
                })))), ["/api/tools/assignqrcode", toString(0, Auto_generateBoxedEncoder_437914C6(AssignQRCode_$reflection(), undefined, undefined, undefined)(dto))], (Item_2) => (new Msg(10, [Item_2])), (Item_3) => (new Msg(34, [Item_3])))];
            }
        }
        case 10:
            return [state, (matchValue_2 = msg.fields[0].Result, (matchValue_2.tag === 1) ? Cmd_errorToast("assign_qr_code_dialog.qr_code_not_valid") : ((matchValue_2.tag === 2) ? Cmd_batch(ofArray([Cmd_successToast("tool.dialog_assign_qr_code"), singleton((dispatch) => {
                dispatch(new Msg(2, []));
            })])) : Cmd_errorToast("assign_qr_code_dialog.qr_code_assigned_to_another_tool")))];
        case 11: {
            const matchValue_3 = state.Tool;
            if (matchValue_3.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const tool = matchValue_3.fields[0];
                return [state, Cmd_deleteQrCodes((Item_4) => (new Msg(12, [Item_4])), (Item_5) => (new Msg(34, [Item_5])), new UnassignQRCode(tool.Id, map((code) => [code, tool.StorageInformation.StorageId], msg.fields[0])))];
            }
        }
        case 12: {
            const matchValue_4 = state.Tool;
            if (matchValue_4.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_batch(toList(delay(() => append(singleton_1(Cmd_getToolDetailDto((Item_6) => (new Msg(6, [Item_6])), (Item_7) => (new Msg(34, [Item_7])), new ToolId(parse(matchValue_4.fields[0].Id)))), delay(() => {
                    if (msg.fields[0].Result.tag === 1) {
                        throw new Error("todo");
                        return empty();
                    }
                    else {
                        return singleton_1(Cmd_successToast("tool.dialog_tool_qr_delete_success"));
                    }
                })))))];
            }
        }
        case 13: {
            const matchValue_6 = state.Tool;
            if (matchValue_6.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dto_3 = new NoticeDto("00000000-0000-0000-0000-000000000000", matchValue_6.fields[0].Id, "", msg.fields[0], msg.fields[1]);
                return [state, Cmd_OfPromise_either_1((tupledArg_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_2[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg_2[1]])]), "application/json", (response_4) => {
                    const pr_2 = response_4.arrayBuffer();
                    return pr_2.then((blob_2) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_2)), PostResponse$1_$reflection(ToolNoticeResponse_$reflection())));
                })))), ["/api/tools/notices", toString(0, Auto_generateBoxedEncoder_437914C6(NoticeDto_$reflection(), undefined, undefined, undefined)(dto_3))], (Item_9) => (new Msg(14, [Item_9])), (Item_10) => (new Msg(34, [Item_10])))];
            }
        }
        case 14:
            if (msg.fields[0].Result.tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                return [state, singleton((dispatch_1) => {
                    dispatch_1(new Msg(2, []));
                })];
            }
        case 15: {
            const notice = msg.fields[0];
            const matchValue_8 = state.Tool;
            if (matchValue_8.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const dto_4 = new NoticeDto(unwrapNoticeId(notice.Id), matchValue_8.fields[0].Id, "", notice.Message, notice.IsVisibleForUser);
                return [state, Cmd_OfPromise_either_1((tupledArg_3) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg_3[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg_3[1]])]), "application/json", (response_6) => {
                    const pr_3 = response_6.arrayBuffer();
                    return pr_3.then((blob_3) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_3)), PostResponse$1_$reflection(ToolNoticeResponse_$reflection())));
                })))), ["/api/tools/notices", toString(0, Auto_generateBoxedEncoder_437914C6(NoticeDto_$reflection(), undefined, undefined, undefined)(dto_4))], (Item_11) => (new Msg(16, [Item_11])), (Item_12) => (new Msg(34, [Item_12])))];
            }
        }
        case 16:
            if (msg.fields[0].Result.tag === 1) {
                return [state, singleton((dispatch_3) => {
                    dispatch_3(new Msg(2, []));
                })];
            }
            else {
                return [state, singleton((dispatch_2) => {
                    dispatch_2(new Msg(2, []));
                })];
            }
        case 18: {
            const matchValue_10 = state.Tool;
            if (matchValue_10.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, deleteImageCmd(new ToolId(parse(matchValue_10.fields[0].Id)))];
            }
        }
        case 19:
            return [state, Cmd_batch(ofArray([(msg.fields[0].Result.tag === 1) ? Cmd_errorToast("general.a_error_occured") : Cmd_successToast("delete_photo_dialog.delete_image_success"), singleton((dispatch_4) => {
                dispatch_4(new Msg(2, []));
            })]))];
        case 17: {
            const file = msg.fields[0];
            const matchValue_12 = state.Tool;
            if (matchValue_12.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const formData = new FormData();
                formData.append(file.name, file);
                return [state, saveImageCmd(new ToolId(parse(matchValue_12.fields[0].Id)), formData)];
            }
        }
        case 20:
            return [state, Cmd_batch(ofArray([Cmd_successToast("general.upload_image_success"), singleton((dispatch_5) => {
                dispatch_5(new Msg(2, []));
            })]))];
        case 28: {
            const matchValue_13 = state.Tool;
            if (matchValue_13.tag === 0) {
                return [state, Cmd_none()];
            }
            else if (msg.fields[0].tag === 1) {
                return [state, Cmd_errorToast("validation.toast")];
            }
            else {
                return [state, Cmd_batch(ofArray([getProblemReportsCmd(new ToolId(parse(matchValue_13.fields[0].Id))), Cmd_successToast("general.saved_successfully")]))];
            }
        }
        case 2: {
            const matchValue_14 = state.Tool;
            if (matchValue_14.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const t_6 = matchValue_14.fields[0];
                return [state, Cmd_batch(ofArray([Cmd_getToolDetailDto((Item_16) => (new Msg(6, [Item_16])), (Item_17) => (new Msg(34, [Item_17])), new ToolId(parse(t_6.Id))), getProblemReportsCmd(new ToolId(parse(t_6.Id))), Cmd_getToolHistoryDto((Item_20) => (new Msg(32, [Item_20])), (Item_21) => (new Msg(34, [Item_21])), new ToolId(parse(t_6.Id)))]))];
            }
        }
        case 4:
            return [state, Cmd_batch(ofArray([Cmd_ofEffect((_arg) => {
                RouterModule_nav(singleton("/tools"), 1, 1);
            }), Cmd_successToast("general.deleted_successfully")]))];
        case 5: {
            const matchValue_15 = state.Tool;
            if (matchValue_15.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_deleteProblemReportCmd(matchValue_15.fields[0].Id, msg.fields[0].Id, (Item_23) => (new Msg(28, [Item_23])), (Item_24) => (new Msg(34, [Item_24])))];
            }
        }
        case 6:
            return [new State(new RequestedValue$1(1, [msg.fields[0]]), state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.History, state.Notifications), singleton((dispatch_6) => {
                dispatch_6(new Msg(3, []));
            })];
        case 7:
            return [new State(state.Tool, state.Storages, state.UserConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.History, state.Notifications), Cmd_none()];
        case 8:
            return [new State(state.Tool, state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, msg.fields[0], state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.History, state.Notifications), Cmd_none()];
        case 27: {
            const matchValue_16 = state.Tool;
            if (matchValue_16.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_batch(ofArray([singleton((dispatch_7) => {
                    dispatch_7(new Msg(2, []));
                }), getProblemReportsCmd(new ToolId(parse(matchValue_16.fields[0].Id)))]))];
            }
        }
        case 21: {
            const url_12 = window.URL.createObjectURL(msg.fields[0]);
            const element = document.createElement("a");
            element.setAttribute("href", url_12);
            element.setAttribute("download", msg.fields[1]);
            document.body.appendChild(element);
            element.click();
            window.URL.revokeObjectURL(url_12);
            element.remove();
            return [state, Cmd_none()];
        }
        case 22:
            return [new State(state.Tool, state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, {
                IsLoading: false,
                IsOpen: false,
            }, state.Documents, state.CustomerConfiguration, state.History, state.Notifications), Cmd_batch(ofArray([Cmd_OfPromise_either_1((url_13) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_13), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_13) => {
                const pr_4 = response_13.arrayBuffer();
                return pr_4.then((blob_5) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_5)), UserConfiguration_$reflection()));
            })))), "/api/users/configuration", (Item_26) => (new Msg(23, [Item_26])), (Item_27) => (new Msg(34, [Item_27]))), Cmd_successToast("general.saved_successfully")]))];
        case 23:
            return [new State(state.Tool, state.Storages, new RequestedValue$1(1, [msg.fields[0]]), state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.History, state.Notifications), Cmd_none()];
        case 24: {
            const matchValue_17 = state.Tool;
            if (matchValue_17.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_OfPromise_either_1((url_16) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(url_16), singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response_14) => {
                    const pr_5 = response_14.arrayBuffer();
                    return pr_5.then((blob_6) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob_6)), list_type(DocumentDetail_$reflection())));
                })))), `/api/tools/${unwrapToolId(new ToolId(parse(matchValue_17.fields[0].Id)))}/documents`, (Item_29) => (new Msg(29, [Item_29])), (Item_30) => (new Msg(34, [Item_30])))];
            }
        }
        case 3: {
            const matchValue_18 = state.Tool;
            if (matchValue_18.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, getToolNotifications(new ToolId(parse(matchValue_18.fields[0].Id)), (Item_32) => (new Msg(33, [Item_32])), (Item_33) => (new Msg(34, [Item_33])))];
            }
        }
        case 25: {
            const matchValue_19 = state.Tool;
            if (matchValue_19.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                return [state, Cmd_batch(ofArray([Cmd_successToast("general.download_started"), Cmd_downloadFileCmd(new ToolId(parse(matchValue_19.fields[0].Id)), msg.fields[0], msg.fields[1], (tupledArg_4) => (new Msg(26, [tupledArg_4[0], tupledArg_4[1]])), (Item_37) => (new Msg(34, [Item_37])))]))];
            }
        }
        case 26:
            return [state, getBlob(msg.fields[0], msg.fields[1])];
        case 29:
            return [new State(state.Tool, state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, state.QuickOverviewDialog, new RequestedValue$1(1, [msg.fields[0]]), state.CustomerConfiguration, state.History, state.Notifications), Cmd_none()];
        case 31:
            return [new State(state.Tool, new RequestedValue$1(1, [msg.fields[0]]), state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.History, state.Notifications), Cmd_none()];
        case 30:
            return [new State(state.Tool, state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, state.QuickOverviewDialog, state.Documents, new RequestedValue$1(1, [msg.fields[0]]), state.History, state.Notifications), Cmd_none()];
        case 32:
            return [new State(state.Tool, state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, new RequestedValue$1(1, [msg.fields[0]]), state.Notifications), Cmd_none()];
        case 33:
            return [new State(state.Tool, state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, state.QuickOverviewDialog, state.Documents, state.CustomerConfiguration, state.History, new RequestedValue$1(1, [msg.fields[0]])), Cmd_none()];
        case 34:
            return [state, Cmd_none()];
        default: {
            const matchValue = state.UserConfiguration;
            if (matchValue.tag === 0) {
                return [state, Cmd_none()];
            }
            else {
                const userConfiguration = matchValue.fields[0];
                return [new State(state.Tool, state.Storages, state.UserConfiguration, state.ProblemReports, state.UserData, state.Customer, state.ImageViewerOpen, state.Manufacturers, {
                    IsLoading: true,
                    IsOpen: state.QuickOverviewDialog.IsOpen,
                }, state.Documents, state.CustomerConfiguration, state.History, state.Notifications), (userConfiguration_1 = (new UserConfiguration_1(userConfiguration.TableConfiguration, (bind$0040_1 = userConfiguration.QuickOverviewConfiguration, new QuickOverviewConfiguration_1(bind$0040_1.ConsumableViewVisibleFieldKeys, bind$0040_1.ToolWithQuantityViewVisibleFieldKeys, msg.fields[0])))), Cmd_OfPromise_either_1((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder(0, addPrefix(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
                    const pr = response.arrayBuffer();
                    return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), EmptyResponse_$reflection()));
                })))), ["/api/users/configuration", toString(0, Auto_generateBoxedEncoder_437914C6(UserConfiguration_$reflection(), undefined, undefined, undefined)(userConfiguration_1))], (Item) => (new Msg(22, [Item])), (Item_1) => (new Msg(34, [Item_1]))))];
            }
        }
    }
}

