import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { toArray, singleton as singleton_1, take, length, map, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { createObj } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { TextValue, BlockHeader } from "../Components/Text.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { toLocalTime, toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { TextButton } from "../Components/Button.js";
import { RouterModule_encodeQueryString, RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { unwrapConsumableId, unwrapToolId } from "../Shared/Helper.js";
import { SetId__get_unwrap, ToolId__get_unwrap } from "../Shared/Shared.js";
import { Helper_unitToTranslationKey } from "../Shared/Consumable.js";
import { defaultArg } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { Feature, isFeatureEnabled } from "../FeatureFlip.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { getDashboardData } from "../Requests/Dashboard.js";
import { Skeleton } from "../Components/Skeleton.js";

function ProblemReportsCard(props) {
    let elems_7;
    const t = useTranslation()[0];
    const columnClasses = ofArray(["border-border", "border-b"]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "p-8", "rounded"])], (elems_7 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(BlockHeader, {
            Text: t("dashboard.problem_reports"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "border-border", "border-b-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.date"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.description"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.tool"),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let problems;
                return append(map((problem) => {
                    let elems_5, elems_2, elems_3, elems_4;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "mt-2"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_2 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: toString(toLocalTime(problem.Date), "dd.MM.yyyy HH:mm"),
                    }), createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: problem.SenderName,
                        OnClick: () => {
                            RouterModule_nav(ofArray(["receivers", problem.SenderId]), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => columnClasses)))], (elems_3 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: problem.Description,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_4 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: problem.ToolTitle,
                        OnClick: () => {
                            RouterModule_nav(ofArray(["tools", unwrapToolId(problem.ToolId)]), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    }), createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: problem.ToolSubtitle,
                        OnClick: () => {
                            RouterModule_nav(ofArray(["tools", unwrapToolId(problem.ToolId)]), 1, 1);
                        },
                        TestId: "",
                        Variant: "default",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
                }, (problems = props.Problems, (length(problems) > 5) ? take(5, problems) : problems)), delay(() => {
                    let elems_6;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_6 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("dashboard.to_reports"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("reports" + RouterModule_encodeQueryString(singleton_1(["report_id", "problemreports"]))), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
}

function ReminderCard(props) {
    let elems_7;
    const t = useTranslation()[0];
    const columnClasses = ofArray(["border-border", "border-b"]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "p-8", "rounded"])], (elems_7 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(BlockHeader, {
            Text: t("general.appointments"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "border-border", "border-b-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.designation"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("dashboard.assigned_to"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.tool"),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let reminders;
                return append(map((reminder) => {
                    let elems_5, elems_2, elems_3, elems_4;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "mt-2"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_2 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: toString(toLocalTime(reminder.Date), "dd.MM.yyyy"),
                    }), createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: reminder.Description,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_3 = toList(delay(() => {
                        const matchValue = reminder.CurrentAssignment;
                        if (matchValue == null) {
                            return empty();
                        }
                        else {
                            const matchValue_1 = matchValue;
                            if (matchValue_1.tag === 0) {
                                return singleton(createElement(TextValue, {
                                    FontWeight: "small",
                                    TestId: "",
                                    Text: matchValue_1.fields[0],
                                }));
                            }
                            else {
                                const receiver = matchValue_1.fields[0];
                                return singleton(createElement(TextButton, {
                                    ComponentState: "enabled",
                                    Label: receiver.ReceiverName,
                                    OnClick: () => {
                                        RouterModule_nav(ofArray(["receivers", receiver.ReceiverId]), 1, 1);
                                    },
                                    TestId: "",
                                    Variant: "blueButton",
                                }));
                            }
                        }
                    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_4 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: reminder.ToolTitle,
                        OnClick: () => {
                            const matchValue_2 = reminder.ReminderDateInfoType;
                            switch (matchValue_2.tag) {
                                case 0: {
                                    RouterModule_nav(ofArray(["tools", ToolId__get_unwrap(matchValue_2.fields[0])]), 1, 1);
                                    break;
                                }
                                case 1: {
                                    RouterModule_nav(ofArray(["toolswithquantity", ToolId__get_unwrap(matchValue_2.fields[0])]), 1, 1);
                                    break;
                                }
                                case 2: {
                                    RouterModule_nav(ofArray(["sets", SetId__get_unwrap(matchValue_2.fields[0])]), 1, 1);
                                    break;
                                }
                                default:
                                    RouterModule_nav(ofArray(["consumables", unwrapConsumableId(matchValue_2.fields[0])]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "blueButton",
                    }), createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: reminder.ToolSubtitle,
                        OnClick: () => {
                            const matchValue_3 = reminder.ReminderDateInfoType;
                            switch (matchValue_3.tag) {
                                case 0: {
                                    RouterModule_nav(ofArray(["tools", ToolId__get_unwrap(matchValue_3.fields[0])]), 1, 1);
                                    break;
                                }
                                case 1: {
                                    RouterModule_nav(ofArray(["toolswithquantity", ToolId__get_unwrap(matchValue_3.fields[0])]), 1, 1);
                                    break;
                                }
                                case 2: {
                                    RouterModule_nav(ofArray(["sets", SetId__get_unwrap(matchValue_3.fields[0])]), 1, 1);
                                    break;
                                }
                                default:
                                    RouterModule_nav(ofArray(["consumables", unwrapConsumableId(matchValue_3.fields[0])]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "default",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
                }, (reminders = props.ReminderDates, (length(reminders) > 5) ? take(5, reminders) : reminders)), delay(() => {
                    let elems_6;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_6 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("dashboard.to_reports"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("reports" + RouterModule_encodeQueryString(singleton_1(["report_id", "appointments"]))), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
}

function MinimumQuantityCard(props) {
    let elems_9;
    const t = useTranslation()[0];
    const columnClasses = ofArray(["border-border", "border-b"]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "p-8", "rounded"])], (elems_9 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(BlockHeader, {
            Text: t("dashboard.minimum_stock"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-5", "mb-2", "border-border", "border-b-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.stock"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.minimum_quantity"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.unit"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.storage"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.tool"),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let reminders;
                return append(map((reminder) => {
                    let elems_7, elems_2, elems_3, elems_4, elems_5, elems_6;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-5", "mb-2", "mt-2"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_2 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: (reminder.tag === 1) ? reminder.fields[0].CurrentStock : reminder.fields[0].CurrentStock,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_3 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: (reminder.tag === 1) ? reminder.fields[0].MinimumQuantityStock : reminder.fields[0].MinimumQuantityStock,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_4 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: (reminder.tag === 1) ? t("unit.piece") : t(Helper_unitToTranslationKey(reminder.fields[0].Unit)),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_5 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: (reminder.tag === 1) ? reminder.fields[0].StorageName : reminder.fields[0].StorageName,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_6 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: (reminder.tag === 1) ? reminder.fields[0].DisplayName : reminder.fields[0].DisplayName,
                        OnClick: () => {
                            if (reminder.tag === 1) {
                                RouterModule_nav(ofArray(["toolswithquantity", unwrapToolId(reminder.fields[0].Id)]), 1, 1);
                            }
                            else {
                                RouterModule_nav(ofArray(["consumables", unwrapConsumableId(reminder.fields[0].Id)]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
                }, (reminders = props.MinimumQuantityReminders, (length(reminders) > 5) ? take(5, reminders) : reminders)), delay(() => {
                    let elems_8;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_8 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("dashboard.to_reports"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("reports" + RouterModule_encodeQueryString(singleton_1(["report_id", "minimumstockquantity"]))), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_9))])])));
}

function PlannedReturnDateCard(props) {
    let elems_7;
    const t = useTranslation()[0];
    const columnClasses = ofArray(["border-border", "border-b"]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "p-8", "rounded"])], (elems_7 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(BlockHeader, {
            Text: t("dashboard.tool_return_date_title"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "border-border", "border-b-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.date"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.user"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.tool"),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let returnDates;
                return append(map((returnDate) => {
                    let elems_5, elems_2, elems_3, elems_4;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "mt-2"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_2 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: toString(toLocalTime(returnDate.ReturnDate), "dd.MM.yyyy"),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_3 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: returnDate.ReceiverName,
                        OnClick: () => {
                            RouterModule_nav(ofArray(["receivers", returnDate.ReceiverId]), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_4 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: returnDate.Title,
                        OnClick: () => {
                            const matchValue = returnDate.Id;
                            if (matchValue.tag === 1) {
                                RouterModule_nav(ofArray(["sets", SetId__get_unwrap(matchValue.fields[0])]), 1, 1);
                            }
                            else {
                                RouterModule_nav(ofArray(["tools", ToolId__get_unwrap(matchValue.fields[0])]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "blueButton",
                    }), createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: returnDate.Subtitle,
                        OnClick: () => {
                            const matchValue_1 = returnDate.Id;
                            if (matchValue_1.tag === 1) {
                                RouterModule_nav(ofArray(["sets", SetId__get_unwrap(matchValue_1.fields[0])]), 1, 1);
                            }
                            else {
                                RouterModule_nav(ofArray(["tools", ToolId__get_unwrap(matchValue_1.fields[0])]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "default",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
                }, (returnDates = props.ToolReturnDates, (length(returnDates) > 5) ? take(5, returnDates) : returnDates)), delay(() => {
                    let elems_6;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_6 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("dashboard.to_reports"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("reports" + RouterModule_encodeQueryString(singleton_1(["report_id", "plannedreturndate"]))), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
}

function ReleaseDateCard(props) {
    let elems_7;
    const t = useTranslation()[0];
    const columnClasses = ofArray(["border-border", "border-b"]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "p-8", "rounded"])], (elems_7 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(BlockHeader, {
            Text: t("dashboard.release_dates"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "border-border", "border-b-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.date"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.user"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.tool"),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let releaseDates;
                return append(map((releaseDate) => {
                    let elems_5, elems_2, elems_3, elems_4;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "mt-2"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_2 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: toString(toLocalTime(releaseDate.ReleaseDate), "dd.MM.yyyy"),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_3 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: releaseDate.ReceiverName,
                        OnClick: () => {
                            RouterModule_nav(ofArray(["receivers", releaseDate.ReceiverId]), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_4 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: releaseDate.Title,
                        OnClick: () => {
                            const matchValue = releaseDate.Id;
                            if (matchValue.tag === 1) {
                                RouterModule_nav(ofArray(["sets", SetId__get_unwrap(matchValue.fields[0])]), 1, 1);
                            }
                            else {
                                RouterModule_nav(ofArray(["tools", ToolId__get_unwrap(matchValue.fields[0])]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "blueButton",
                    }), createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: releaseDate.Subtitle,
                        OnClick: () => {
                            const matchValue_1 = releaseDate.Id;
                            if (matchValue_1.tag === 1) {
                                RouterModule_nav(ofArray(["sets", SetId__get_unwrap(matchValue_1.fields[0])]), 1, 1);
                            }
                            else {
                                RouterModule_nav(ofArray(["tools", ToolId__get_unwrap(matchValue_1.fields[0])]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "default",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
                }, (releaseDates = props.ReleaseDates, (length(releaseDates) > 5) ? take(5, releaseDates) : releaseDates)), delay(() => {
                    let elems_6;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_6 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("dashboard.to_reports"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("reports" + RouterModule_encodeQueryString(singleton_1(["report_id", "releasedates"]))), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
}

function NextReservationCard(props) {
    let elems_10;
    const t = useTranslation()[0];
    const columnClasses = ofArray(["border-border", "border-b"]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "p-8", "rounded"])], (elems_10 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(BlockHeader, {
            Text: t("dashboard.tool_next_reservations_title"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-6", "mb-2", "border-border", "border-b-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.start_date"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.end_date"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.name"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.user"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.tool"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.quantity"),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let reservations;
                return append(map((reservation) => {
                    let elems_8, elems_2, elems_3, elems_4, elems_5, elems_6, elems_7;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-6", "mb-2", "mt-2"])], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_2 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: toString(toLocalTime(reservation.StartDate), "dd.MM.yyyy"),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_3 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: toString(toLocalTime(reservation.EndDate), "dd.MM.yyyy"),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_4 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: reservation.Name,
                        OnClick: () => {
                            let matchValue;
                            RouterModule_nav(ofArray(["reservations", (matchValue = reservation.GroupId, (matchValue == null) ? reservation.Id : matchValue)]), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_5 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: reservation.ReceiverInformation.ReceiverName,
                        OnClick: () => {
                            RouterModule_nav(ofArray(["receivers", reservation.ReceiverInformation.ReceiverId]), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_6 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: reservation.ToolInformation.Name,
                        OnClick: () => {
                            let matchValue_1;
                            RouterModule_nav(ofArray(toArray((matchValue_1 = reservation.ToolInformation.Type, (matchValue_1.tag === 1) ? ofArray(["toolswithquantity", reservation.ToolInformation.Id]) : ((matchValue_1.tag === 2) ? ofArray(["consumables", reservation.ToolInformation.Id]) : ((matchValue_1.tag === 3) ? ofArray(["sets", reservation.ToolInformation.Id]) : ((matchValue_1.tag === 4) ? ofArray(["reservations", defaultArg(reservation.GroupId, "")]) : ofArray(["tools", reservation.ToolInformation.Id]))))))), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_7 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: defaultArg(reservation.ToolInformation.Quantity, ""),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])));
                }, (reservations = props.ReservationInfos, (length(reservations) > 5) ? take(5, reservations) : reservations)), delay(() => {
                    let elems_9;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_9 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("dashboard.to_reports"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("reports" + RouterModule_encodeQueryString(singleton_1(["report_id", "reservations"]))), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_10))])])));
}

function InMaintenanceCard(props) {
    let elems_7;
    const t = useTranslation()[0];
    const columnClasses = ofArray(["border-border", "border-b"]);
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "p-8", "rounded"])], (elems_7 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(BlockHeader, {
            Text: t("general.in_maintenance"),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "border-border", "border-b-2"])], (elems_1 = [createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.date"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.tool"),
            }), createElement(TextValue, {
                FontWeight: "small",
                TestId: "",
                Text: t("general.user"),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let maintenanceInfos;
                return append(map((row) => {
                    let elems_5, elems_2, elems_3, elems_4;
                    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "mb-2", "mt-2"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_2 = [createElement(TextValue, {
                        FontWeight: "small",
                        TestId: "",
                        Text: toString(toLocalTime(row.Date), "dd.MM.yyyy"),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_3 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: row.Title,
                        OnClick: () => {
                            const matchValue = row.ToolInfoType;
                            if (matchValue.tag === 1) {
                                RouterModule_nav(ofArray(["sets", SetId__get_unwrap(matchValue.fields[0])]), 1, 1);
                            }
                            else {
                                RouterModule_nav(ofArray(["tools", ToolId__get_unwrap(matchValue.fields[0])]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "blueButton",
                    }), createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: row.Subtitle,
                        OnClick: () => {
                            const matchValue_1 = row.ToolInfoType;
                            if (matchValue_1.tag === 1) {
                                RouterModule_nav(ofArray(["sets", SetId__get_unwrap(matchValue_1.fields[0])]), 1, 1);
                            }
                            else {
                                RouterModule_nav(ofArray(["tools", ToolId__get_unwrap(matchValue_1.fields[0])]), 1, 1);
                            }
                        },
                        TestId: "",
                        Variant: "default",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", join(" ", columnClasses)], (elems_4 = toList(delay(() => {
                        const matchValue_2 = row.CurrentAssignment;
                        if (matchValue_2 == null) {
                            return empty();
                        }
                        else {
                            const matchValue_3 = matchValue_2;
                            if (matchValue_3.tag === 0) {
                                return singleton(createElement(TextValue, {
                                    FontWeight: "small",
                                    TestId: "",
                                    Text: matchValue_3.fields[0],
                                }));
                            }
                            else {
                                const receiver = matchValue_3.fields[0];
                                return singleton(createElement(TextButton, {
                                    ComponentState: "enabled",
                                    Label: receiver.ReceiverName,
                                    OnClick: () => {
                                        RouterModule_nav(ofArray(["receivers", receiver.ReceiverId]), 1, 1);
                                    },
                                    TestId: "",
                                    Variant: "blueButton",
                                }));
                            }
                        }
                    })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
                }, (maintenanceInfos = props.InInMaintenanceInfos, (length(maintenanceInfos) > 5) ? take(5, maintenanceInfos) : maintenanceInfos)), delay(() => {
                    let elems_6;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_6 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: t("dashboard.to_reports"),
                        OnClick: () => {
                            RouterModule_nav(singleton_1("reports" + RouterModule_encodeQueryString(singleton_1(["report_id", "in_maintenance"]))), 1, 1);
                        },
                        TestId: "",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
}

function Dashboard(props) {
    let elems_7, elems_2, elems_6;
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "2xl:grid-flow-col", "gap-x-8", "gap-y-4"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_2 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [createElement(ProblemReportsCard, {
            Problems: props.DashboardData.Problems,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append(isFeatureEnabled(new Feature(16, [])) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_1 = [createElement(InMaintenanceCard, {
                InInMaintenanceInfos: props.DashboardData.InMaintenanceToolsAndSets,
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty(), delay(() => singleton(createElement(ReminderCard, {
                ReminderDates: props.DashboardData.ReminderDates,
            }))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(singleton_1((elems_6 = toList(delay(() => {
        let elems_3;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_3 = [createElement(PlannedReturnDateCard, {
            ToolReturnDates: props.DashboardData.ToolReturnDates,
        })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
            let elems_4;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_4 = [createElement(MinimumQuantityCard, {
                MinimumQuantityReminders: props.DashboardData.MinimumQuantityReminder,
            })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                let elems_5;
                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_5 = [createElement(NextReservationCard, {
                    ReservationInfos: props.DashboardData.ReservationInfos,
                })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                    const matchValue = props.DashboardData.ReleaseDates;
                    if (matchValue == null) {
                        return empty();
                    }
                    else {
                        return singleton(createElement(ReleaseDateCard, {
                            ReleaseDates: matchValue,
                        }));
                    }
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_6))]))))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
}

export function DashboardView(props) {
    let elems;
    let patternInput;
    const init = init_1(props.TokenData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const dashboardData = getDashboardData();
    if (dashboardData.error != null) {
        return createElement("div", {
            children: ["Error"],
        });
    }
    else {
        const matchValue = dashboardData.data;
        if (matchValue != null) {
            const dashboardData_1 = matchValue;
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(Dashboard, {
                DashboardData: dashboardData_1,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
        }
        else {
            return createElement(Skeleton, {
                Variant: "normal",
            });
        }
    }
}

