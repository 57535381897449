import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { tuple_type, float64_type, bool_type, list_type, union_type, anonRecord_type, record_type, class_type, option_type, int32_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ToolReservationId__get_unwrap, StorageId__get_unwrap, ConsumableId_$reflection, ToolHistoryElementType_$reflection, ProblemReportId_$reflection, Label_$reflection, CustomerSpecificToolWithQuantity_$reflection, SetId_$reflection, ReminderDateDto_$reflection, PrinzingCustomerSpecificTool_$reflection, CommercialDataFormDto_$reflection, LabelId_$reflection, CustomerSpecificTool_$reflection, CommercialData_$reflection, Document$_$reflection, ReminderDate_$reflection, NoticeId_$reflection, ReservationState_$reflection, ReservationGroupId_$reflection, ToolReservationId_$reflection, ToolId_$reflection, AdditionalReminderDefinitionDto_$reflection, CustomIntervalDefinition_$reflection, ReminderInterval_$reflection, StorageId_$reflection } from "./Shared.js";
import { CustomPropertyValueDto_$reflection, CustomProperty_$reflection } from "./PropertyConfiguration.js";
import { ReceiverTypeName_$reflection } from "./User.js";
import { ReservationDto_$reflection } from "./Planning.js";
import { Consumable_$reflection } from "./Consumable.js";
import { singleton, map } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { round } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { replace } from "../fable_modules/fable-library-js.4.19.2/String.js";

export class StorageToUserAssignment extends Record {
    constructor(ReceiverId, Receiver, SenderId, Sender, StorageId, Storage, Quantity, Comment$, SignatureUrl, PlannedReturnDate, Timestamp) {
        super();
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Quantity = Quantity;
        this.Comment = Comment$;
        this.SignatureUrl = SignatureUrl;
        this.PlannedReturnDate = PlannedReturnDate;
        this.Timestamp = Timestamp;
    }
}

export function StorageToUserAssignment_$reflection() {
    return record_type("Shared.Tool.StorageToUserAssignment", [], StorageToUserAssignment, () => [["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Quantity", option_type(int32_type)], ["Comment", string_type], ["SignatureUrl", option_type(string_type)], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["Timestamp", class_type("System.DateTime")]]);
}

export class StorageToSubContractorAssignment extends Record {
    constructor(ReceiverId, Receiver, SenderId, Sender, StorageId, Storage, SignatureUrl, Comment$, Quantity, PlannedReturnDate, Timestamp) {
        super();
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.SignatureUrl = SignatureUrl;
        this.Comment = Comment$;
        this.Quantity = Quantity;
        this.PlannedReturnDate = PlannedReturnDate;
        this.Timestamp = Timestamp;
    }
}

export function StorageToSubContractorAssignment_$reflection() {
    return record_type("Shared.Tool.StorageToSubContractorAssignment", [], StorageToSubContractorAssignment, () => [["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["SignatureUrl", option_type(string_type)], ["Comment", string_type], ["Quantity", option_type(int32_type)], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["Timestamp", class_type("System.DateTime")]]);
}

export class UserToUserAssignment extends Record {
    constructor(ReceiverId, Receiver, SenderId, Sender, PlannedReturnDate, Quantity, SignatureUrl, Timestamp) {
        super();
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.PlannedReturnDate = PlannedReturnDate;
        this.Quantity = Quantity;
        this.SignatureUrl = SignatureUrl;
        this.Timestamp = Timestamp;
    }
}

export function UserToUserAssignment_$reflection() {
    return record_type("Shared.Tool.UserToUserAssignment", [], UserToUserAssignment, () => [["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["Quantity", option_type(int32_type)], ["SignatureUrl", option_type(string_type)], ["Timestamp", class_type("System.DateTime")]]);
}

export class ReturnToStorage extends Record {
    constructor(SenderId, Sender, ReceiverId, Receiver, StorageId, Storage, Comment$, Quantity, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Comment = Comment$;
        this.Quantity = Quantity;
        this.Timestamp = Timestamp;
    }
}

export function ReturnToStorage_$reflection() {
    return record_type("Shared.Tool.ReturnToStorage", [], ReturnToStorage, () => [["SenderId", string_type], ["Sender", string_type], ["ReceiverId", string_type], ["Receiver", string_type], ["StorageId", string_type], ["Storage", string_type], ["Comment", string_type], ["Quantity", option_type(int32_type)], ["Timestamp", class_type("System.DateTime")]]);
}

export class ProblemReported extends Record {
    constructor(SenderId, Sender, StorageId, Storage, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Timestamp = Timestamp;
    }
}

export function ProblemReported_$reflection() {
    return record_type("Shared.Tool.ProblemReported", [], ProblemReported, () => [["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class UpdatedStock extends Record {
    constructor(SenderId, Sender, StorageId, Storage, Quantity, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Quantity = (Quantity | 0);
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function UpdatedStock_$reflection() {
    return record_type("Shared.Tool.UpdatedStock", [], UpdatedStock, () => [["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Quantity", int32_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class UpdatedStorage extends Record {
    constructor(SenderId, Sender, StorageId, Storage, Quantity, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Quantity = Quantity;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function UpdatedStorage_$reflection() {
    return record_type("Shared.Tool.UpdatedStorage", [], UpdatedStorage, () => [["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Quantity", option_type(int32_type)], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class InMaintenance extends Record {
    constructor(SenderId, Sender, StorageId, Storage, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function InMaintenance_$reflection() {
    return record_type("Shared.Tool.InMaintenance", [], InMaintenance, () => [["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class Lost extends Record {
    constructor(SenderId, Sender, ReceiverId, Receiver, StorageId, Storage, Quantity, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Quantity = Quantity;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function Lost_$reflection() {
    return record_type("Shared.Tool.Lost", [], Lost, () => [["SenderId", string_type], ["Sender", string_type], ["ReceiverId", string_type], ["Receiver", string_type], ["StorageId", string_type], ["Storage", string_type], ["Quantity", option_type(int32_type)], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class Retired extends Record {
    constructor(SenderId, Sender, StorageId, Storage, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function Retired_$reflection() {
    return record_type("Shared.Tool.Retired", [], Retired, () => [["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class Activate extends Record {
    constructor(SenderId, Sender, StorageId, Storage, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.StorageId = StorageId;
        this.Storage = Storage;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function Activate_$reflection() {
    return record_type("Shared.Tool.Activate", [], Activate, () => [["SenderId", string_type], ["Sender", string_type], ["StorageId", string_type], ["Storage", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class AddedToSet extends Record {
    constructor(SenderId, Sender, Storage, SetId, StorageId, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.Storage = Storage;
        this.SetId = SetId;
        this.StorageId = StorageId;
        this.Timestamp = Timestamp;
    }
}

export function AddedToSet_$reflection() {
    return record_type("Shared.Tool.AddedToSet", [], AddedToSet, () => [["SenderId", string_type], ["Sender", string_type], ["Storage", string_type], ["SetId", string_type], ["StorageId", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class RemovedFromSet extends Record {
    constructor(SenderId, Sender, Storage, StorageId, SetId, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.Storage = Storage;
        this.StorageId = StorageId;
        this.SetId = SetId;
        this.Timestamp = Timestamp;
    }
}

export function RemovedFromSet_$reflection() {
    return record_type("Shared.Tool.RemovedFromSet", [], RemovedFromSet, () => [["SenderId", string_type], ["Sender", string_type], ["Storage", string_type], ["StorageId", string_type], ["SetId", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class StocktakingCorrectionBooking extends Record {
    constructor(SenderId, StorageId, Timestamp, Comment$) {
        super();
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Timestamp = Timestamp;
        this.Comment = Comment$;
    }
}

export function StocktakingCorrectionBooking_$reflection() {
    return record_type("Shared.Tool.StocktakingCorrectionBooking", [], StocktakingCorrectionBooking, () => [["SenderId", string_type], ["StorageId", StorageId_$reflection()], ["Timestamp", class_type("System.DateTime")], ["Comment", string_type]]);
}

export class StocktakingStorageCorrectionBooking extends Record {
    constructor(SenderId, StorageId, Quantity, Timestamp, Comment$) {
        super();
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
        this.Timestamp = Timestamp;
        this.Comment = Comment$;
    }
}

export function StocktakingStorageCorrectionBooking_$reflection() {
    return record_type("Shared.Tool.StocktakingStorageCorrectionBooking", [], StocktakingStorageCorrectionBooking, () => [["SenderId", string_type], ["StorageId", StorageId_$reflection()], ["Quantity", int32_type], ["Timestamp", class_type("System.DateTime")], ["Comment", string_type]]);
}

export class StocktakingReceiverCorrectionBooking extends Record {
    constructor(SenderId, ReceiverId, Quantity, Timestamp, Comment$) {
        super();
        this.SenderId = SenderId;
        this.ReceiverId = ReceiverId;
        this.Quantity = (Quantity | 0);
        this.Timestamp = Timestamp;
        this.Comment = Comment$;
    }
}

export function StocktakingReceiverCorrectionBooking_$reflection() {
    return record_type("Shared.Tool.StocktakingReceiverCorrectionBooking", [], StocktakingReceiverCorrectionBooking, () => [["SenderId", string_type], ["ReceiverId", string_type], ["Quantity", int32_type], ["Timestamp", class_type("System.DateTime")], ["Comment", string_type]]);
}

export class ReleaseDate extends Record {
    constructor(SenderId, AssignedReceiverId, StorageId, Comment$, Timestamp, ReleaseDate) {
        super();
        this.SenderId = SenderId;
        this.AssignedReceiverId = AssignedReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
        this.ReleaseDate = ReleaseDate;
    }
}

export function ReleaseDate_$reflection() {
    return record_type("Shared.Tool.ReleaseDate", [], ReleaseDate, () => [["SenderId", string_type], ["AssignedReceiverId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")], ["ReleaseDate", class_type("System.DateTime")]]);
}

export class DeleteReleaseDate extends Record {
    constructor(SenderId, AssignedReceiverId, StorageId, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.AssignedReceiverId = AssignedReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function DeleteReleaseDate_$reflection() {
    return record_type("Shared.Tool.DeleteReleaseDate", [], DeleteReleaseDate, () => [["SenderId", string_type], ["AssignedReceiverId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class ToolHistoryDto extends Record {
    constructor(Timestamp, EventType, Event$, ReceiverInformation, SenderInformation, Comment$, StorageInformation, Id) {
        super();
        this.Timestamp = Timestamp;
        this.EventType = EventType;
        this.Event = Event$;
        this.ReceiverInformation = ReceiverInformation;
        this.SenderInformation = SenderInformation;
        this.Comment = Comment$;
        this.StorageInformation = StorageInformation;
        this.Id = Id;
    }
}

export function ToolHistoryDto_$reflection() {
    return record_type("Shared.Tool.ToolHistoryDto", [], ToolHistoryDto, () => [["Timestamp", class_type("System.DateTime")], ["EventType", string_type], ["Event", option_type(string_type)], ["ReceiverInformation", option_type(anonRecord_type(["Id", string_type], ["Name", string_type], ["SignatureUrl", option_type(string_type)]))], ["SenderInformation", anonRecord_type(["Id", string_type], ["Name", string_type])], ["Comment", string_type], ["StorageInformation", option_type(anonRecord_type(["Id", string_type], ["Name", string_type]))], ["Id", string_type]]);
}

export class ToolWithQuantityHistoryDto extends Record {
    constructor(Timestamp, EventType, Event$, Quantity, ReceiverInformation, SenderInformation, Comment$, StorageInformation, Id) {
        super();
        this.Timestamp = Timestamp;
        this.EventType = EventType;
        this.Event = Event$;
        this.Quantity = (Quantity | 0);
        this.ReceiverInformation = ReceiverInformation;
        this.SenderInformation = SenderInformation;
        this.Comment = Comment$;
        this.StorageInformation = StorageInformation;
        this.Id = Id;
    }
}

export function ToolWithQuantityHistoryDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityHistoryDto", [], ToolWithQuantityHistoryDto, () => [["Timestamp", class_type("System.DateTime")], ["EventType", string_type], ["Event", option_type(string_type)], ["Quantity", int32_type], ["ReceiverInformation", option_type(anonRecord_type(["Id", string_type], ["Name", string_type], ["SignatureUrl", option_type(string_type)]))], ["SenderInformation", anonRecord_type(["Id", string_type], ["Name", string_type])], ["Comment", string_type], ["StorageInformation", option_type(anonRecord_type(["Id", string_type], ["Name", string_type]))], ["Id", string_type]]);
}

export class ToolEvent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StorageToUserAssignment", "UserToUserAssignment", "ReturnToStorage", "StorageToSubContractorAssignment", "ProblemReported", "UpdatedStock", "UpdatedStorage", "InMaintenance", "Retired", "Activate", "Lost", "AddedToSet", "RemovedFromSet", "ReleaseDate", "DeleteReleaseDate", "StocktakingStorageCorrectionBooking", "StocktakingReceiverCorrectionBooking", "StocktakingCorrectionBooking"];
    }
}

export function ToolEvent_$reflection() {
    return union_type("Shared.Tool.ToolEvent", [], ToolEvent, () => [[["Item", StorageToUserAssignment_$reflection()]], [["Item", UserToUserAssignment_$reflection()]], [["Item", ReturnToStorage_$reflection()]], [["Item", StorageToSubContractorAssignment_$reflection()]], [["Item", ProblemReported_$reflection()]], [["Item", UpdatedStock_$reflection()]], [["Item", UpdatedStorage_$reflection()]], [["Item", InMaintenance_$reflection()]], [["Item", Retired_$reflection()]], [["Item", Activate_$reflection()]], [["Item", Lost_$reflection()]], [["Item", AddedToSet_$reflection()]], [["Item", RemovedFromSet_$reflection()]], [["Item", ReleaseDate_$reflection()]], [["Item", DeleteReleaseDate_$reflection()]], [["Item", StocktakingStorageCorrectionBooking_$reflection()]], [["Item", StocktakingReceiverCorrectionBooking_$reflection()]], [["Item", StocktakingCorrectionBooking_$reflection()]]]);
}

export class AddReminderDto extends Record {
    constructor(Date$, Title, Description, Interval, CustomInterval, AdditionalReminders) {
        super();
        this.Date = Date$;
        this.Title = Title;
        this.Description = Description;
        this.Interval = Interval;
        this.CustomInterval = CustomInterval;
        this.AdditionalReminders = AdditionalReminders;
    }
}

export function AddReminderDto_$reflection() {
    return record_type("Shared.Tool.AddReminderDto", [], AddReminderDto, () => [["Date", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["Interval", option_type(ReminderInterval_$reflection())], ["CustomInterval", option_type(CustomIntervalDefinition_$reflection())], ["AdditionalReminders", option_type(list_type(AdditionalReminderDefinitionDto_$reflection()))]]);
}

export class UpdateReminderDto extends Record {
    constructor(ReminderId, Date$, Title, Description, Interval, CustomInterval, AdditionalReminders) {
        super();
        this.ReminderId = ReminderId;
        this.Date = Date$;
        this.Title = Title;
        this.Description = Description;
        this.Interval = Interval;
        this.CustomInterval = CustomInterval;
        this.AdditionalReminders = AdditionalReminders;
    }
}

export function UpdateReminderDto_$reflection() {
    return record_type("Shared.Tool.UpdateReminderDto", [], UpdateReminderDto, () => [["ReminderId", string_type], ["Date", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["Interval", option_type(ReminderInterval_$reflection())], ["CustomInterval", option_type(CustomIntervalDefinition_$reflection())], ["AdditionalReminders", option_type(list_type(AdditionalReminderDefinitionDto_$reflection()))]]);
}

export class UpdateDocumentDto extends Record {
    constructor(Name, Description, IsVisibleForUserRole) {
        super();
        this.Name = Name;
        this.Description = Description;
        this.IsVisibleForUserRole = IsVisibleForUserRole;
    }
}

export function UpdateDocumentDto_$reflection() {
    return record_type("Shared.Tool.UpdateDocumentDto", [], UpdateDocumentDto, () => [["Name", string_type], ["Description", string_type], ["IsVisibleForUserRole", bool_type]]);
}

export function ToolState__ToI18nString(this$) {
    switch (this$) {
        case "inMaintenance":
            return "general.in_maintenance";
        case "retired":
            return "general.retired";
        case "lost":
            return "general.lost";
        default:
            return "general.active";
    }
}

export class ToolStateChangedDto extends Record {
    constructor(ToolId, NewState, Comment$) {
        super();
        this.ToolId = ToolId;
        this.NewState = NewState;
        this.Comment = Comment$;
    }
}

export function ToolStateChangedDto_$reflection() {
    return record_type("Shared.Tool.ToolStateChangedDto", [], ToolStateChangedDto, () => [["ToolId", ToolId_$reflection()], ["NewState", string_type], ["Comment", string_type]]);
}

export class ToolConfirmReminderDto extends Record {
    constructor(IsConfirmed, ReminderId, CreateNewDate, SetLastInspectionDate, LastInspectionDate, ConfirmationComment) {
        super();
        this.IsConfirmed = IsConfirmed;
        this.ReminderId = ReminderId;
        this.CreateNewDate = CreateNewDate;
        this.SetLastInspectionDate = SetLastInspectionDate;
        this.LastInspectionDate = LastInspectionDate;
        this.ConfirmationComment = ConfirmationComment;
    }
}

export function ToolConfirmReminderDto_$reflection() {
    return record_type("Shared.Tool.ToolConfirmReminderDto", [], ToolConfirmReminderDto, () => [["IsConfirmed", bool_type], ["ReminderId", string_type], ["CreateNewDate", bool_type], ["SetLastInspectionDate", bool_type], ["LastInspectionDate", option_type(class_type("System.DateTime"))], ["ConfirmationComment", string_type]]);
}

export class ToolReservation extends Record {
    constructor(Id, Name, StartDate, EndDate, ReceiverId, Receiver, SenderId, Sender, ToolId, Description, GroupId, State) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ToolId = ToolId;
        this.Description = Description;
        this.GroupId = GroupId;
        this.State = State;
    }
}

export function ToolReservation_$reflection() {
    return record_type("Shared.Tool.ToolReservation", [], ToolReservation, () => [["Id", ToolReservationId_$reflection()], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["ToolId", ToolId_$reflection()], ["Description", string_type], ["GroupId", option_type(ReservationGroupId_$reflection())], ["State", ReservationState_$reflection()]]);
}

export class ToolReservationDto extends Record {
    constructor(Id, Name, StartDate, EndDate, ReceiverId, Receiver, SenderId, Sender, ToolId, Description, Quantity) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ToolId = ToolId;
        this.Description = Description;
        this.Quantity = Quantity;
    }
}

export function ToolReservationDto_$reflection() {
    return record_type("Shared.Tool.ToolReservationDto", [], ToolReservationDto, () => [["Id", ToolReservationId_$reflection()], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["ToolId", ToolId_$reflection()], ["Description", string_type], ["Quantity", option_type(int32_type)]]);
}

export class ToolReservationFormDto extends Record {
    constructor(Id, StartDate, EndDate, Name, ReceiverId, Receiver, SenderId, Sender, ToolId, NotifyUser, Description) {
        super();
        this.Id = Id;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Name = Name;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ToolId = ToolId;
        this.NotifyUser = NotifyUser;
        this.Description = Description;
    }
}

export function ToolReservationFormDto_$reflection() {
    return record_type("Shared.Tool.ToolReservationFormDto", [], ToolReservationFormDto, () => [["Id", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Name", option_type(string_type)], ["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["ToolId", string_type], ["NotifyUser", option_type(bool_type)], ["Description", string_type]]);
}

export class ToolWithQuantityReservation extends Record {
    constructor(Id, Name, StartDate, EndDate, ReceiverId, Receiver, SenderId, Sender, ToolId, Description, StorageId, Quantity, GroupId, State) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ToolId = ToolId;
        this.Description = Description;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
        this.GroupId = GroupId;
        this.State = State;
    }
}

export function ToolWithQuantityReservation_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityReservation", [], ToolWithQuantityReservation, () => [["Id", ToolReservationId_$reflection()], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["ToolId", ToolId_$reflection()], ["Description", string_type], ["StorageId", StorageId_$reflection()], ["Quantity", int32_type], ["GroupId", option_type(ReservationGroupId_$reflection())], ["State", ReservationState_$reflection()]]);
}

export class ToolWithQuantityReservationFormDto extends Record {
    constructor(Id, StartDate, EndDate, Name, ReceiverId, Receiver, SenderId, Sender, ToolId, NotifyUser, Description, StorageId, Quantity) {
        super();
        this.Id = Id;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Name = Name;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ToolId = ToolId;
        this.NotifyUser = NotifyUser;
        this.Description = Description;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
    }
}

export function ToolWithQuantityReservationFormDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityReservationFormDto", [], ToolWithQuantityReservationFormDto, () => [["Id", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Name", option_type(string_type)], ["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["ToolId", string_type], ["NotifyUser", option_type(bool_type)], ["Description", string_type], ["StorageId", string_type], ["Quantity", int32_type]]);
}

export class DeleteToolReservationNameDto extends Record {
    constructor(ToolIds, Name) {
        super();
        this.ToolIds = ToolIds;
        this.Name = Name;
    }
}

export function DeleteToolReservationNameDto_$reflection() {
    return record_type("Shared.Tool.DeleteToolReservationNameDto", [], DeleteToolReservationNameDto, () => [["ToolIds", list_type(string_type)], ["Name", string_type]]);
}

export class MultipleReservationToolWithQuantityReservationDto extends Record {
    constructor(ToolId, StorageId, Quantity) {
        super();
        this.ToolId = ToolId;
        this.StorageId = StorageId;
        this.Quantity = (Quantity | 0);
    }
}

export function MultipleReservationToolWithQuantityReservationDto_$reflection() {
    return record_type("Shared.Tool.MultipleReservationToolWithQuantityReservationDto", [], MultipleReservationToolWithQuantityReservationDto, () => [["ToolId", string_type], ["StorageId", string_type], ["Quantity", int32_type]]);
}

export class ToolsMultipleReservationDto extends Record {
    constructor(Id, StartDate, EndDate, Name, ReceiverId, SenderId, ToolIds, ReservedToolWithQuantitys, NotifyUser, Description) {
        super();
        this.Id = Id;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Name = Name;
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.ToolIds = ToolIds;
        this.ReservedToolWithQuantitys = ReservedToolWithQuantitys;
        this.NotifyUser = NotifyUser;
        this.Description = Description;
    }
}

export function ToolsMultipleReservationDto_$reflection() {
    return record_type("Shared.Tool.ToolsMultipleReservationDto", [], ToolsMultipleReservationDto, () => [["Id", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Name", string_type], ["ReceiverId", string_type], ["SenderId", string_type], ["ToolIds", list_type(string_type)], ["ReservedToolWithQuantitys", list_type(MultipleReservationToolWithQuantityReservationDto_$reflection())], ["NotifyUser", bool_type], ["Description", string_type]]);
}

export class ToolReservationResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "ReservationForDaterangeExists", "NotEnoughStockForDateRange"];
    }
}

export function ToolReservationResponse_$reflection() {
    return union_type("Shared.Tool.ToolReservationResponse", [], ToolReservationResponse, () => [[], [], [["toolIds", list_type(string_type)]], [["toolIds", list_type(string_type)]]]);
}

export class ToolWithQuantityReservationResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "NotEnoughStockForDateRange"];
    }
}

export function ToolWithQuantityReservationResponse_$reflection() {
    return union_type("Shared.Tool.ToolWithQuantityReservationResponse", [], ToolWithQuantityReservationResponse, () => [[], [], []]);
}

export class Notice extends Record {
    constructor(Id, CreatedAt, UpdatedAt, SenderId, Sender, Headline, Description, IsVisibleForUser) {
        super();
        this.Id = Id;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.Headline = Headline;
        this.Description = Description;
        this.IsVisibleForUser = IsVisibleForUser;
    }
}

export function Notice_$reflection() {
    return record_type("Shared.Tool.Notice", [], Notice, () => [["Id", NoticeId_$reflection()], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["SenderId", string_type], ["Sender", string_type], ["Headline", string_type], ["Description", string_type], ["IsVisibleForUser", bool_type]]);
}

export class NoticeDto extends Record {
    constructor(Id, ToolId, Headline, Description, IsVisibleForUser) {
        super();
        this.Id = Id;
        this.ToolId = ToolId;
        this.Headline = Headline;
        this.Description = Description;
        this.IsVisibleForUser = IsVisibleForUser;
    }
}

export function NoticeDto_$reflection() {
    return record_type("Shared.Tool.NoticeDto", [], NoticeDto, () => [["Id", string_type], ["ToolId", string_type], ["Headline", string_type], ["Description", string_type], ["IsVisibleForUser", bool_type]]);
}

export class ToolNoticeResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated"];
    }
}

export function ToolNoticeResponse_$reflection() {
    return union_type("Shared.Tool.ToolNoticeResponse", [], ToolNoticeResponse, () => [[], []]);
}

export class Tool extends Record {
    constructor(Id, DisplayName, Manufacturer, Model, SerialNumber, ImageUrl, QRCodeId, QRCodeIds, AssignedUserId, StorageId, StorageSpace, Events, ReminderDates, PlannedReturnDate, ReleaseDate, Documents, State, Comment$, CommercialData, LastInspectionDate, CustomerSpecific, LabelIds, Reservations, Notices, InventoryNumber, CustomProperties, IsDeleted, CreatedAt, UpdatedAt) {
        super();
        this.Id = Id;
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.ImageUrl = ImageUrl;
        this.QRCodeId = QRCodeId;
        this.QRCodeIds = QRCodeIds;
        this.AssignedUserId = AssignedUserId;
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.Events = Events;
        this.ReminderDates = ReminderDates;
        this.PlannedReturnDate = PlannedReturnDate;
        this.ReleaseDate = ReleaseDate;
        this.Documents = Documents;
        this.State = State;
        this.Comment = Comment$;
        this.CommercialData = CommercialData;
        this.LastInspectionDate = LastInspectionDate;
        this.CustomerSpecific = CustomerSpecific;
        this.LabelIds = LabelIds;
        this.Reservations = Reservations;
        this.Notices = Notices;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
        this.IsDeleted = IsDeleted;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function Tool_$reflection() {
    return record_type("Shared.Tool.Tool", [], Tool, () => [["Id", ToolId_$reflection()], ["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["ImageUrl", string_type], ["QRCodeId", string_type], ["QRCodeIds", list_type(string_type)], ["AssignedUserId", option_type(string_type)], ["StorageId", StorageId_$reflection()], ["StorageSpace", string_type], ["Events", list_type(ToolEvent_$reflection())], ["ReminderDates", list_type(ReminderDate_$reflection())], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["ReleaseDate", option_type(class_type("System.DateTime"))], ["Documents", list_type(Document$_$reflection())], ["State", string_type], ["Comment", string_type], ["CommercialData", CommercialData_$reflection()], ["LastInspectionDate", option_type(class_type("System.DateTime"))], ["CustomerSpecific", CustomerSpecificTool_$reflection()], ["LabelIds", list_type(LabelId_$reflection())], ["Reservations", list_type(ToolReservation_$reflection())], ["Notices", list_type(Notice_$reflection())], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())], ["IsDeleted", bool_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export function Tool__ReadableDisplayName(this$) {
    if (this$.DisplayName !== "") {
        return this$.DisplayName;
    }
    else {
        return `${this$.Manufacturer} ${this$.Model}`;
    }
}

export class ToolFormDto extends Record {
    constructor(DisplayName, Manufacturer, Model, SerialNumber, StorageId, StorageSpace, Comment$, CommercialData, LastInspectionDate, PrinzingSpecificFields, CategoryIds, QRCodeIds, InventoryNumber, CustomProperties, ImageUrl) {
        super();
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.Comment = Comment$;
        this.CommercialData = CommercialData;
        this.LastInspectionDate = LastInspectionDate;
        this.PrinzingSpecificFields = PrinzingSpecificFields;
        this.CategoryIds = CategoryIds;
        this.QRCodeIds = QRCodeIds;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
        this.ImageUrl = ImageUrl;
    }
}

export function ToolFormDto_$reflection() {
    return record_type("Shared.Tool.ToolFormDto", [], ToolFormDto, () => [["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["StorageId", string_type], ["StorageSpace", string_type], ["Comment", string_type], ["CommercialData", CommercialDataFormDto_$reflection()], ["LastInspectionDate", option_type(string_type)], ["PrinzingSpecificFields", option_type(PrinzingCustomerSpecificTool_$reflection())], ["CategoryIds", list_type(string_type)], ["QRCodeIds", list_type(string_type)], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())], ["ImageUrl", string_type]]);
}

export class ToolDetailDto extends Record {
    constructor(Id, ImageUrl, QRCodeIds, MasterData, CommercialData, StorageInformation, AssignedReceiverInformation, CustomProperties, ReminderDates, State, CustomerSpecific, Categories, Reservations, Notices, AssignedSet) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.MasterData = MasterData;
        this.CommercialData = CommercialData;
        this.StorageInformation = StorageInformation;
        this.AssignedReceiverInformation = AssignedReceiverInformation;
        this.CustomProperties = CustomProperties;
        this.ReminderDates = ReminderDates;
        this.State = State;
        this.CustomerSpecific = CustomerSpecific;
        this.Categories = Categories;
        this.Reservations = Reservations;
        this.Notices = Notices;
        this.AssignedSet = AssignedSet;
    }
}

export function ToolDetailDto_$reflection() {
    return record_type("Shared.Tool.ToolDetailDto", [], ToolDetailDto, () => [["Id", string_type], ["ImageUrl", string_type], ["QRCodeIds", list_type(string_type)], ["MasterData", anonRecord_type(["Comment", string_type], ["DisplayName", string_type], ["InventoryNumber", string_type], ["LastInspectionDate", option_type(class_type("System.DateTime"))], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type])], ["CommercialData", CommercialData_$reflection()], ["StorageInformation", anonRecord_type(["AssignedUserIds", list_type(string_type)], ["Location", option_type(anonRecord_type(["Id", string_type], ["Name", string_type]))], ["StorageId", string_type], ["StorageName", string_type], ["StorageSpace", string_type])], ["AssignedReceiverInformation", option_type(anonRecord_type(["AssignedReceiverId", string_type], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["ReceiverName", string_type], ["ReceiverTypeName", ReceiverTypeName_$reflection()], ["ReleaseDate", option_type(class_type("System.DateTime"))]))], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())], ["ReminderDates", list_type(ReminderDateDto_$reflection())], ["State", string_type], ["CustomerSpecific", CustomerSpecificTool_$reflection()], ["Categories", list_type(anonRecord_type(["CategoryId", string_type], ["CategoryName", string_type], ["ColorHex", string_type]))], ["Reservations", list_type(ReservationDto_$reflection())], ["Notices", list_type(anonRecord_type(["CreatedAt", class_type("System.DateTime")], ["CreatorInformation", anonRecord_type(["Id", string_type], ["Name", string_type])], ["Description", string_type], ["Headline", string_type], ["Id", string_type], ["IsVisibleForUser", bool_type]))], ["AssignedSet", option_type(anonRecord_type(["DisplayName", string_type], ["SetId", SetId_$reflection()]))]]);
}

export function ToolDetailDto__ReadableDisplayName(this$) {
    if (this$.MasterData.DisplayName !== "") {
        return this$.MasterData.DisplayName;
    }
    else {
        return `${this$.MasterData.Manufacturer} ${this$.MasterData.Model}`;
    }
}

export class ToolWithQuantityUserAssignment extends Record {
    constructor(UserId, Quantity) {
        super();
        this.UserId = UserId;
        this.Quantity = (Quantity | 0);
    }
}

export function ToolWithQuantityUserAssignment_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityUserAssignment", [], ToolWithQuantityUserAssignment, () => [["UserId", string_type], ["Quantity", int32_type]]);
}

export class ToolWithQuantityStockPerStorage extends Record {
    constructor(StorageId, StorageSpace, Stock, MinimumStockQuantity, QRCodeId, QRCodeIds) {
        super();
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.Stock = (Stock | 0);
        this.MinimumStockQuantity = MinimumStockQuantity;
        this.QRCodeId = QRCodeId;
        this.QRCodeIds = QRCodeIds;
    }
}

export function ToolWithQuantityStockPerStorage_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityStockPerStorage", [], ToolWithQuantityStockPerStorage, () => [["StorageId", StorageId_$reflection()], ["StorageSpace", string_type], ["Stock", int32_type], ["MinimumStockQuantity", option_type(int32_type)], ["QRCodeId", string_type], ["QRCodeIds", list_type(string_type)]]);
}

export class ToolWithQuantityUserAssignmentDto extends Record {
    constructor(ReceiverId, ReceiverName, Quantity) {
        super();
        this.ReceiverId = ReceiverId;
        this.ReceiverName = ReceiverName;
        this.Quantity = (Quantity | 0);
    }
}

export function ToolWithQuantityUserAssignmentDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityUserAssignmentDto", [], ToolWithQuantityUserAssignmentDto, () => [["ReceiverId", string_type], ["ReceiverName", string_type], ["Quantity", int32_type]]);
}

export class ToolWithQuantityStockPerStorageDto extends Record {
    constructor(StorageId, StorageName, StorageSpace, Stock, MinimumStockQuantity, QRCodeIds, Location) {
        super();
        this.StorageId = StorageId;
        this.StorageName = StorageName;
        this.StorageSpace = StorageSpace;
        this.Stock = (Stock | 0);
        this.MinimumStockQuantity = MinimumStockQuantity;
        this.QRCodeIds = QRCodeIds;
        this.Location = Location;
    }
}

export function ToolWithQuantityStockPerStorageDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityStockPerStorageDto", [], ToolWithQuantityStockPerStorageDto, () => [["StorageId", string_type], ["StorageName", string_type], ["StorageSpace", string_type], ["Stock", int32_type], ["MinimumStockQuantity", option_type(int32_type)], ["QRCodeIds", list_type(string_type)], ["Location", option_type(anonRecord_type(["Id", string_type], ["Name", string_type]))]]);
}

export class ToolWithQuantityDetailReservationDto extends Record {
    constructor(Id, Name, Description, StartDate, EndDate, Quantity, StorageName, ReceiverInformation, SenderInformation) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Quantity = (Quantity | 0);
        this.StorageName = StorageName;
        this.ReceiverInformation = ReceiverInformation;
        this.SenderInformation = SenderInformation;
    }
}

export function ToolWithQuantityDetailReservationDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityDetailReservationDto", [], ToolWithQuantityDetailReservationDto, () => [["Id", string_type], ["Name", string_type], ["Description", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["Quantity", int32_type], ["StorageName", string_type], ["ReceiverInformation", anonRecord_type(["ReceiverId", string_type], ["ReceiverName", string_type])], ["SenderInformation", anonRecord_type(["ReceiverId", string_type], ["SenderName", string_type])]]);
}

export class ToolWithQuantityDetailDto extends Record {
    constructor(Id, ImageUrl, MasterData, UserAssignments, StockPerStorage, CommercialData, CustomerSpecific, Categories, CustomProperties, Reservations, Reminders) {
        super();
        this.Id = Id;
        this.ImageUrl = ImageUrl;
        this.MasterData = MasterData;
        this.UserAssignments = UserAssignments;
        this.StockPerStorage = StockPerStorage;
        this.CommercialData = CommercialData;
        this.CustomerSpecific = CustomerSpecific;
        this.Categories = Categories;
        this.CustomProperties = CustomProperties;
        this.Reservations = Reservations;
        this.Reminders = Reminders;
    }
}

export function ToolWithQuantityDetailDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityDetailDto", [], ToolWithQuantityDetailDto, () => [["Id", string_type], ["ImageUrl", string_type], ["MasterData", anonRecord_type(["Comment", string_type], ["DisplayName", string_type], ["InventoryNumber", string_type], ["Manufacturer", string_type], ["Model", string_type])], ["UserAssignments", list_type(ToolWithQuantityUserAssignmentDto_$reflection())], ["StockPerStorage", list_type(ToolWithQuantityStockPerStorageDto_$reflection())], ["CommercialData", CommercialData_$reflection()], ["CustomerSpecific", CustomerSpecificToolWithQuantity_$reflection()], ["Categories", list_type(anonRecord_type(["CategoryId", string_type], ["CategoryName", string_type], ["ColorHex", string_type]))], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())], ["Reservations", list_type(ToolWithQuantityDetailReservationDto_$reflection())], ["Reminders", list_type(ReminderDateDto_$reflection())]]);
}

export function ToolWithQuantityDetailDto__ReadableDisplayName(this$) {
    if (this$.MasterData.DisplayName !== "") {
        return this$.MasterData.DisplayName;
    }
    else {
        return `${this$.MasterData.Manufacturer} ${this$.MasterData.Model}`;
    }
}

export class ToolWithQuantity extends Record {
    constructor(Id, DisplayName, Manufacturer, Model, ImageUrl, UserAssignments, StockPerStorage, Events, Comment$, CommercialData, CustomerSpecific, LabelIds, Documents, InventoryNumber, CustomProperties, IsDeleted, CreatedAt, UpdatedAt, Reservations, ReminderDates) {
        super();
        this.Id = Id;
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.ImageUrl = ImageUrl;
        this.UserAssignments = UserAssignments;
        this.StockPerStorage = StockPerStorage;
        this.Events = Events;
        this.Comment = Comment$;
        this.CommercialData = CommercialData;
        this.CustomerSpecific = CustomerSpecific;
        this.LabelIds = LabelIds;
        this.Documents = Documents;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
        this.IsDeleted = IsDeleted;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.Reservations = Reservations;
        this.ReminderDates = ReminderDates;
    }
}

export function ToolWithQuantity_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantity", [], ToolWithQuantity, () => [["Id", ToolId_$reflection()], ["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["ImageUrl", string_type], ["UserAssignments", list_type(ToolWithQuantityUserAssignment_$reflection())], ["StockPerStorage", list_type(ToolWithQuantityStockPerStorage_$reflection())], ["Events", list_type(ToolEvent_$reflection())], ["Comment", string_type], ["CommercialData", CommercialData_$reflection()], ["CustomerSpecific", CustomerSpecificToolWithQuantity_$reflection()], ["LabelIds", list_type(LabelId_$reflection())], ["Documents", list_type(Document$_$reflection())], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())], ["IsDeleted", bool_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["Reservations", list_type(ToolWithQuantityReservation_$reflection())], ["ReminderDates", list_type(ReminderDate_$reflection())]]);
}

export function ToolWithQuantity__ReadableDisplayName(this$) {
    if (this$.DisplayName !== "") {
        return this$.DisplayName;
    }
    else {
        return `${this$.Manufacturer} ${this$.Model}`;
    }
}

export class ToolWithQuantityCreateFormDto extends Record {
    constructor(DisplayName, Manufacturer, Model, ImageUrl, Comment$, CommercialData, CategoryIds, InventoryNumber, CustomProperties, StorageId, StorageSpace, Stock, MinimumStockQuantity, QRCodeIds) {
        super();
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.ImageUrl = ImageUrl;
        this.Comment = Comment$;
        this.CommercialData = CommercialData;
        this.CategoryIds = CategoryIds;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.Stock = (Stock | 0);
        this.MinimumStockQuantity = MinimumStockQuantity;
        this.QRCodeIds = QRCodeIds;
    }
}

export function ToolWithQuantityCreateFormDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityCreateFormDto", [], ToolWithQuantityCreateFormDto, () => [["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["ImageUrl", string_type], ["Comment", string_type], ["CommercialData", CommercialDataFormDto_$reflection()], ["CategoryIds", list_type(string_type)], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())], ["StorageId", string_type], ["StorageSpace", string_type], ["Stock", int32_type], ["MinimumStockQuantity", option_type(int32_type)], ["QRCodeIds", list_type(string_type)]]);
}

export class ToolWithQuantityCreateForm extends Record {
    constructor(DisplayName, Manufacturer, Model, ImageUrl, Comment$, CommercialData, CategoryIds, InventoryNumber, CustomProperties, StockPerStorage) {
        super();
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.ImageUrl = ImageUrl;
        this.Comment = Comment$;
        this.CommercialData = CommercialData;
        this.CategoryIds = CategoryIds;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
        this.StockPerStorage = StockPerStorage;
    }
}

export function ToolWithQuantityCreateForm_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityCreateForm", [], ToolWithQuantityCreateForm, () => [["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["ImageUrl", string_type], ["Comment", string_type], ["CommercialData", CommercialDataFormDto_$reflection()], ["CategoryIds", list_type(string_type)], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())], ["StockPerStorage", list_type(ToolWithQuantityStockPerStorage_$reflection())]]);
}

export class ToolWithQuantityUpdateFormDto extends Record {
    constructor(DisplayName, Manufacturer, Model, ImageUrl, Comment$, CommercialData, CategoryIds, InventoryNumber, CustomProperties) {
        super();
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.ImageUrl = ImageUrl;
        this.Comment = Comment$;
        this.CommercialData = CommercialData;
        this.CategoryIds = CategoryIds;
        this.InventoryNumber = InventoryNumber;
        this.CustomProperties = CustomProperties;
    }
}

export function ToolWithQuantityUpdateFormDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityUpdateFormDto", [], ToolWithQuantityUpdateFormDto, () => [["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["ImageUrl", string_type], ["Comment", string_type], ["CommercialData", CommercialDataFormDto_$reflection()], ["CategoryIds", list_type(string_type)], ["InventoryNumber", string_type], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class AssignToolNotification extends Record {
    constructor(ReceiverId, Comment$, Type, WithSignature, PlannedReturnDate) {
        super();
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
        this.Type = Type;
        this.WithSignature = WithSignature;
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function AssignToolNotification_$reflection() {
    return record_type("Shared.Tool.AssignToolNotification", [], AssignToolNotification, () => [["ReceiverId", string_type], ["Comment", string_type], ["Type", string_type], ["WithSignature", bool_type], ["PlannedReturnDate", option_type(string_type)]]);
}

export class UnassignToolNotification extends Record {
    constructor(ReceiverId, Comment$, Type) {
        super();
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
        this.Type = Type;
    }
}

export function UnassignToolNotification_$reflection() {
    return record_type("Shared.Tool.UnassignToolNotification", [], UnassignToolNotification, () => [["ReceiverId", option_type(string_type)], ["Comment", string_type], ["Type", string_type]]);
}

export class ToolTypes extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "ToolWithQuantity"];
    }
}

export function ToolTypes_$reflection() {
    return union_type("Shared.Tool.ToolTypes", [], ToolTypes, () => [[["Item", Tool_$reflection()]], [["Item", ToolWithQuantity_$reflection()]]]);
}

export function ToolTypes__get_Id(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].Id;
    }
    else {
        return this$.fields[0].Id;
    }
}

export function ToolTypes__get_DisplayName(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].DisplayName;
    }
    else {
        return this$.fields[0].DisplayName;
    }
}

export function ToolTypes__get_ImageUrl(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].ImageUrl;
    }
    else {
        return this$.fields[0].ImageUrl;
    }
}

export function ToolTypes__get_Manufacturer(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].Manufacturer;
    }
    else {
        return this$.fields[0].Manufacturer;
    }
}

export function ToolTypes__get_Model(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].Model;
    }
    else {
        return this$.fields[0].Model;
    }
}

export function ToolTypes__get_InventoryNumber(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].InventoryNumber;
    }
    else {
        return this$.fields[0].InventoryNumber;
    }
}

export function ToolTypes__get_ReminderDates(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].ReminderDates;
    }
    else {
        return this$.fields[0].ReminderDates;
    }
}

export function ToolTypes__get_CustomProperties(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].CustomProperties;
    }
    else {
        return this$.fields[0].CustomProperties;
    }
}

export function ToolTypes__get_IsDeleted(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].IsDeleted;
    }
    else {
        return this$.fields[0].IsDeleted;
    }
}

export function ToolTypes__get_isTool(this$) {
    if (this$.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

export function ToolTypes__get_isToolWithQuantity(this$) {
    if (this$.tag === 1) {
        return true;
    }
    else {
        return false;
    }
}

export function ToolTypes__ReadableDisplayName(this$) {
    if (this$.tag === 1) {
        return ToolWithQuantity__ReadableDisplayName(this$.fields[0]);
    }
    else {
        return Tool__ReadableDisplayName(this$.fields[0]);
    }
}

export class ToolOverviewReservationDto extends Record {
    constructor(Id, Name, StartDate, EndDate, ReceiverId, Receiver, SenderId, Sender, Description, Quantity) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReceiverId = ReceiverId;
        this.Receiver = Receiver;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.Description = Description;
        this.Quantity = Quantity;
    }
}

export function ToolOverviewReservationDto_$reflection() {
    return record_type("Shared.Tool.ToolOverviewReservationDto", [], ToolOverviewReservationDto, () => [["Id", string_type], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["Receiver", string_type], ["SenderId", string_type], ["Sender", string_type], ["Description", string_type], ["Quantity", option_type(int32_type)]]);
}

export class ToolDto extends Record {
    constructor(Id, DisplayName, Manufacturer, ToolOverviewType, Model, Comment$, SerialNumber, State, Storage, Location, QRCodeIds, StorageSpace, StockQuantity, MinimumStockQuantity, StorageId, InventoryNumber, ImageUrl, CurrentLocation, CheckNumber, Labels, PurchasePrice, SupplierArticleNumber, Supplier, ReleaseDate, PurchaseDate, WarrantyDate, NextReminderDate, NextReservationDate, LastInspectionDate, CustomProperties, Reservations, Reminders, LastHistoryComment) {
        super();
        this.Id = Id;
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.ToolOverviewType = ToolOverviewType;
        this.Model = Model;
        this.Comment = Comment$;
        this.SerialNumber = SerialNumber;
        this.State = State;
        this.Storage = Storage;
        this.Location = Location;
        this.QRCodeIds = QRCodeIds;
        this.StorageSpace = StorageSpace;
        this.StockQuantity = StockQuantity;
        this.MinimumStockQuantity = MinimumStockQuantity;
        this.StorageId = StorageId;
        this.InventoryNumber = InventoryNumber;
        this.ImageUrl = ImageUrl;
        this.CurrentLocation = CurrentLocation;
        this.CheckNumber = CheckNumber;
        this.Labels = Labels;
        this.PurchasePrice = PurchasePrice;
        this.SupplierArticleNumber = SupplierArticleNumber;
        this.Supplier = Supplier;
        this.ReleaseDate = ReleaseDate;
        this.PurchaseDate = PurchaseDate;
        this.WarrantyDate = WarrantyDate;
        this.NextReminderDate = NextReminderDate;
        this.NextReservationDate = NextReservationDate;
        this.LastInspectionDate = LastInspectionDate;
        this.CustomProperties = CustomProperties;
        this.Reservations = Reservations;
        this.Reminders = Reminders;
        this.LastHistoryComment = LastHistoryComment;
    }
}

export function ToolDto_$reflection() {
    return record_type("Shared.Tool.ToolDto", [], ToolDto, () => [["Id", string_type], ["DisplayName", string_type], ["Manufacturer", string_type], ["ToolOverviewType", string_type], ["Model", string_type], ["Comment", string_type], ["SerialNumber", string_type], ["State", string_type], ["Storage", string_type], ["Location", string_type], ["QRCodeIds", string_type], ["StorageSpace", string_type], ["StockQuantity", option_type(string_type)], ["MinimumStockQuantity", option_type(string_type)], ["StorageId", string_type], ["InventoryNumber", string_type], ["ImageUrl", string_type], ["CurrentLocation", string_type], ["CheckNumber", string_type], ["Labels", list_type(Label_$reflection())], ["PurchasePrice", string_type], ["SupplierArticleNumber", string_type], ["Supplier", string_type], ["ReleaseDate", option_type(class_type("System.DateTime"))], ["PurchaseDate", option_type(class_type("System.DateTime"))], ["WarrantyDate", option_type(class_type("System.DateTime"))], ["NextReminderDate", option_type(class_type("System.DateTime"))], ["NextReservationDate", option_type(class_type("System.DateTime"))], ["LastInspectionDate", option_type(class_type("System.DateTime"))], ["CustomProperties", list_type(CustomProperty_$reflection())], ["Reservations", list_type(ToolOverviewReservationDto_$reflection())], ["Reminders", list_type(ReminderDate_$reflection())], ["LastHistoryComment", string_type]]);
}

export class ToolResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Created", "Updated", "StorageIdNotSet", "PrinzingCheckNumberAlreadyExists", "TooManyToolsExists", "Error"];
    }
}

export function ToolResponse_$reflection() {
    return union_type("Shared.Tool.ToolResponse", [], ToolResponse, () => [[["Item", ToolTypes_$reflection()]], [["Item", ToolTypes_$reflection()]], [], [], [], []]);
}

export class DevicesBulkTool extends Record {
    constructor(Tool, PlannedReturnDate, Quantity, StorageId) {
        super();
        this.Tool = Tool;
        this.PlannedReturnDate = PlannedReturnDate;
        this.Quantity = Quantity;
        this.StorageId = StorageId;
    }
}

export function DevicesBulkTool_$reflection() {
    return record_type("Shared.Tool.DevicesBulkTool", [], DevicesBulkTool, () => [["Tool", ToolTypes_$reflection()], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["Quantity", option_type(int32_type)], ["StorageId", string_type]]);
}

export class DevicesBulkConsumable extends Record {
    constructor(Consumable, Quantity, CommissionNumber, StorageId) {
        super();
        this.Consumable = Consumable;
        this.Quantity = Quantity;
        this.CommissionNumber = CommissionNumber;
        this.StorageId = StorageId;
    }
}

export function DevicesBulkConsumable_$reflection() {
    return record_type("Shared.Tool.DevicesBulkConsumable", [], DevicesBulkConsumable, () => [["Consumable", Consumable_$reflection()], ["Quantity", float64_type], ["CommissionNumber", string_type], ["StorageId", string_type]]);
}

export class ProblemReportState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Open", "Closed"];
    }
}

export function ProblemReportState_$reflection() {
    return union_type("Shared.Tool.ProblemReportState", [], ProblemReportState, () => [[], []]);
}

export class ProblemReportSolvedState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Solved", "NotSolved"];
    }
}

export function ProblemReportSolvedState_$reflection() {
    return union_type("Shared.Tool.ProblemReportSolvedState", [], ProblemReportSolvedState, () => [[], []]);
}

export class ProblemReportSolvedDto extends Record {
    constructor(ProblemReportId, Comment$, State) {
        super();
        this.ProblemReportId = ProblemReportId;
        this.Comment = Comment$;
        this.State = State;
    }
}

export function ProblemReportSolvedDto_$reflection() {
    return record_type("Shared.Tool.ProblemReportSolvedDto", [], ProblemReportSolvedDto, () => [["ProblemReportId", ProblemReportId_$reflection()], ["Comment", string_type], ["State", ProblemReportSolvedState_$reflection()]]);
}

export class ProblemLogEntry extends Record {
    constructor(Comment$, SenderId, Sender, ProblemReportSolvedState, Timestamp) {
        super();
        this.Comment = Comment$;
        this.SenderId = SenderId;
        this.Sender = Sender;
        this.ProblemReportSolvedState = ProblemReportSolvedState;
        this.Timestamp = Timestamp;
    }
}

export function ProblemLogEntry_$reflection() {
    return record_type("Shared.Tool.ProblemLogEntry", [], ProblemLogEntry, () => [["Comment", string_type], ["SenderId", string_type], ["Sender", string_type], ["ProblemReportSolvedState", ProblemReportSolvedState_$reflection()], ["Timestamp", class_type("System.DateTime")]]);
}

export class ProblemReport extends Record {
    constructor(Id, Solver, SolverId, Sender, SenderId, State, Tool, StorageId, Message, Logs, ImageUrl, Timestamp) {
        super();
        this.Id = Id;
        this.Solver = Solver;
        this.SolverId = SolverId;
        this.Sender = Sender;
        this.SenderId = SenderId;
        this.State = State;
        this.Tool = Tool;
        this.StorageId = StorageId;
        this.Message = Message;
        this.Logs = Logs;
        this.ImageUrl = ImageUrl;
        this.Timestamp = Timestamp;
    }
}

export function ProblemReport_$reflection() {
    return record_type("Shared.Tool.ProblemReport", [], ProblemReport, () => [["Id", ProblemReportId_$reflection()], ["Solver", string_type], ["SolverId", string_type], ["Sender", string_type], ["SenderId", string_type], ["State", ProblemReportState_$reflection()], ["Tool", ToolTypes_$reflection()], ["StorageId", string_type], ["Message", string_type], ["Logs", list_type(ProblemLogEntry_$reflection())], ["ImageUrl", option_type(string_type)], ["Timestamp", class_type("System.DateTime")]]);
}

export class AssignQRCode extends Record {
    constructor(ToolId, QRCode, StorageId) {
        super();
        this.ToolId = ToolId;
        this.QRCode = QRCode;
        this.StorageId = StorageId;
    }
}

export function AssignQRCode_$reflection() {
    return record_type("Shared.Tool.AssignQRCode", [], AssignQRCode, () => [["ToolId", string_type], ["QRCode", string_type], ["StorageId", string_type]]);
}

export class UnassignQRCode extends Record {
    constructor(ToolId, QRCodesAndStorageId) {
        super();
        this.ToolId = ToolId;
        this.QRCodesAndStorageId = QRCodesAndStorageId;
    }
}

export function UnassignQRCode_$reflection() {
    return record_type("Shared.Tool.UnassignQRCode", [], UnassignQRCode, () => [["ToolId", string_type], ["QRCodesAndStorageId", list_type(tuple_type(string_type, string_type))]]);
}

export class ToolWithQuantityLostDto extends Record {
    constructor(ToolId, ReceiverId, Quantity, Comment$) {
        super();
        this.ToolId = ToolId;
        this.ReceiverId = ReceiverId;
        this.Quantity = (Quantity | 0);
        this.Comment = Comment$;
    }
}

export function ToolWithQuantityLostDto_$reflection() {
    return record_type("Shared.Tool.ToolWithQuantityLostDto", [], ToolWithQuantityLostDto, () => [["ToolId", string_type], ["ReceiverId", option_type(string_type)], ["Quantity", int32_type], ["Comment", string_type]]);
}

export class ToolWithQuantityLostResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoValidQuantitySet", "NoReceiverIdSet", "WrongToolType", "NoUserAssignment", "UserStockToSmall", "Error", "Successful"];
    }
}

export function ToolWithQuantityLostResponse_$reflection() {
    return union_type("Shared.Tool.ToolWithQuantityLostResponse", [], ToolWithQuantityLostResponse, () => [[], [], [], [], [], [], []]);
}

export class AssignQRCodeResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QRCodeAlreadyAssigned", "NotValid", "Assigned"];
    }
}

export function AssignQRCodeResult_$reflection() {
    return union_type("Shared.Tool.AssignQRCodeResult", [], AssignQRCodeResult, () => [[], [], []]);
}

export class DeleteQRCodeResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Deleted", "NotDeletedQRCodes"];
    }
}

export function DeleteQRCodeResult_$reflection() {
    return union_type("Shared.Tool.DeleteQRCodeResult", [], DeleteQRCodeResult, () => [[], [["Item", list_type(string_type)]]]);
}

export class ToolHistoryRow extends Record {
    constructor(Id, EventDate, EventType, ToolName, Quantity, Receiver, Comment$, Sender, Storage, CheckNumber, InventoryNumber, ToolHistoryElementType, SignatureUrl, CustomProperties) {
        super();
        this.Id = Id;
        this.EventDate = EventDate;
        this.EventType = EventType;
        this.ToolName = ToolName;
        this.Quantity = Quantity;
        this.Receiver = Receiver;
        this.Comment = Comment$;
        this.Sender = Sender;
        this.Storage = Storage;
        this.CheckNumber = CheckNumber;
        this.InventoryNumber = InventoryNumber;
        this.ToolHistoryElementType = ToolHistoryElementType;
        this.SignatureUrl = SignatureUrl;
        this.CustomProperties = CustomProperties;
    }
}

export function ToolHistoryRow_$reflection() {
    return record_type("Shared.Tool.ToolHistoryRow", [], ToolHistoryRow, () => [["Id", string_type], ["EventDate", class_type("System.DateTime")], ["EventType", string_type], ["ToolName", string_type], ["Quantity", string_type], ["Receiver", string_type], ["Comment", string_type], ["Sender", string_type], ["Storage", string_type], ["CheckNumber", option_type(string_type)], ["InventoryNumber", string_type], ["ToolHistoryElementType", ToolHistoryElementType_$reflection()], ["SignatureUrl", option_type(string_type)], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class AcceptNotificationDto extends Record {
    constructor(SignatureUrl, Comment$) {
        super();
        this.SignatureUrl = SignatureUrl;
        this.Comment = Comment$;
    }
}

export function AcceptNotificationDto_$reflection() {
    return record_type("Shared.Tool.AcceptNotificationDto", [], AcceptNotificationDto, () => [["SignatureUrl", option_type(string_type)], ["Comment", option_type(string_type)]]);
}

export class UnassignDispolist extends Record {
    constructor(DispolistId, SenderId) {
        super();
        this.DispolistId = DispolistId;
        this.SenderId = SenderId;
    }
}

export function UnassignDispolist_$reflection() {
    return record_type("Shared.Tool.UnassignDispolist", [], UnassignDispolist, () => [["DispolistId", string_type], ["SenderId", string_type]]);
}

export class ToolReminderUpdateResult extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Succesful"];
    }
}

export function ToolReminderUpdateResult_$reflection() {
    return union_type("Shared.Tool.ToolReminderUpdateResult", [], ToolReminderUpdateResult, () => [[]]);
}

export class QRCodeScanResultResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FoundTool", "FoundToolWithQuantity", "FoundReceiver", "FoundConsumable", "FoundSet", "NotFound"];
    }
}

export function QRCodeScanResultResponse_$reflection() {
    return union_type("Shared.Tool.QRCodeScanResultResponse", [], QRCodeScanResultResponse, () => [[["Item", ToolId_$reflection()]], [["Item", ToolId_$reflection()]], [["id", string_type], ["Item2", ReceiverTypeName_$reflection()]], [["Item", ConsumableId_$reflection()]], [["Item", SetId_$reflection()]], []]);
}

export class AssignToolFromUserToUserDto extends Record {
    constructor(ToolId, ReceiverId, Comment$, Type, PlannedReturnDate) {
        super();
        this.ToolId = ToolId;
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
        this.Type = Type;
        this.PlannedReturnDate = PlannedReturnDate;
    }
}

export function AssignToolFromUserToUserDto_$reflection() {
    return record_type("Shared.Tool.AssignToolFromUserToUserDto", [], AssignToolFromUserToUserDto, () => [["ToolId", string_type], ["ReceiverId", string_type], ["Comment", string_type], ["Type", string_type], ["PlannedReturnDate", option_type(string_type)]]);
}

export class AssignToolFromUserToUserResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Assigned", "ToolIsAlreadyInAssignment"];
    }
}

export function AssignToolFromUserToUserResult_$reflection() {
    return union_type("Shared.Tool.AssignToolFromUserToUserResult", [], AssignToolFromUserToUserResult, () => [[], []]);
}

export class ToolInfo extends Record {
    constructor(ActiveProblemReport, CurrentReservation) {
        super();
        this.ActiveProblemReport = ActiveProblemReport;
        this.CurrentReservation = CurrentReservation;
    }
}

export function ToolInfo_$reflection() {
    return record_type("Shared.Tool.ToolInfo", [], ToolInfo, () => [["ActiveProblemReport", option_type(anonRecord_type(["Message", string_type], ["Timestamp", class_type("System.DateTime")]))], ["CurrentReservation", option_type(anonRecord_type(["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["ReceiverName", string_type], ["StartDate", class_type("System.DateTime")]))]]);
}

export function Helper_toolId(_arg) {
    if (_arg.tag === 1) {
        return _arg.fields[0].Id;
    }
    else {
        return _arg.fields[0].Id;
    }
}

export function Helper_toolStorageIds(_arg) {
    if (_arg.tag === 1) {
        return map((s) => s.StorageId, _arg.fields[0].StockPerStorage);
    }
    else {
        return singleton(_arg.fields[0].StorageId);
    }
}

export function Helper_toolName(_arg) {
    if (_arg.tag === 1) {
        const tool_1 = _arg.fields[0];
        if ((tool_1.Manufacturer === "") && (tool_1.Model === "")) {
            return tool_1.DisplayName;
        }
        else if (tool_1.DisplayName === "") {
            return `${tool_1.Manufacturer} ${tool_1.Model}`;
        }
        else {
            return `${tool_1.DisplayName} ${tool_1.Manufacturer} ${tool_1.Model}`;
        }
    }
    else {
        const tool = _arg.fields[0];
        if ((tool.Manufacturer === "") && (tool.Model === "")) {
            return tool.DisplayName;
        }
        else if (tool.DisplayName === "") {
            return `${tool.Manufacturer} ${tool.Model}`;
        }
        else {
            return `${tool.DisplayName} ${tool.Manufacturer} ${tool.Model}`;
        }
    }
}

export function Helper_isDeleted(_arg) {
    if (_arg.tag === 1) {
        return _arg.fields[0].IsDeleted;
    }
    else {
        return _arg.fields[0].IsDeleted;
    }
}

export function Helper_timestamp(toolEvent) {
    switch (toolEvent.tag) {
        case 3:
            return toolEvent.fields[0].Timestamp;
        case 1:
            return toolEvent.fields[0].Timestamp;
        case 2:
            return toolEvent.fields[0].Timestamp;
        case 4:
            return toolEvent.fields[0].Timestamp;
        case 5:
            return toolEvent.fields[0].Timestamp;
        case 6:
            return toolEvent.fields[0].Timestamp;
        case 8:
            return toolEvent.fields[0].Timestamp;
        case 7:
            return toolEvent.fields[0].Timestamp;
        case 9:
            return toolEvent.fields[0].Timestamp;
        case 10:
            return toolEvent.fields[0].Timestamp;
        case 11:
            return toolEvent.fields[0].Timestamp;
        case 12:
            return toolEvent.fields[0].Timestamp;
        case 15:
            return toolEvent.fields[0].Timestamp;
        case 16:
            return toolEvent.fields[0].Timestamp;
        case 17:
            return toolEvent.fields[0].Timestamp;
        case 13:
            return toolEvent.fields[0].Timestamp;
        case 14:
            return toolEvent.fields[0].Timestamp;
        default:
            return toolEvent.fields[0].Timestamp;
    }
}

export function Helper_toToolHistoryEvent(toolEvent) {
    switch (toolEvent.tag) {
        case 3:
            return "storageToSubContractorAssignment";
        case 1:
            return "userToUserAssignment";
        case 2:
            return "returnToStorage";
        case 4:
            return "problemReported";
        case 5:
            return "updatedStock";
        case 6:
            return "updatedStorage";
        case 8:
            return "retired";
        case 7:
            return "inMaintenance";
        case 9:
            return "activate";
        case 10:
            return "lost";
        case 11:
            return "addedToSet";
        case 12:
            return "removedFromSet";
        case 15:
            return "stocktakingStorageCorrectionBooking";
        case 16:
            return "stocktakingReceiverCorrectionBooking";
        case 17:
            return "stocktakingCorrectionBooking";
        case 13:
            return "releaseDate";
        case 14:
            return "deleteReleaseDate";
        default:
            return "storageToUserAssignment";
    }
}

export function Helper_typeToTranslationString(_arg) {
    switch (_arg.tag) {
        case 3:
            return "event.withdrawal";
        case 1:
            return "event.transfer";
        case 2:
            return "event.return";
        case 4:
            return "event.problem_report";
        case 5:
            return "event.stock_change";
        case 6:
            return "event.storage_change";
        case 7:
            return "event.in_maintenance";
        case 8:
            return "event.retired";
        case 9:
            return "event.activated";
        case 10:
            return "event.lost";
        case 11:
            return "event.added_to_set";
        case 12:
            return "event.removed_from_set";
        case 15:
            return "event.stocktaking_storage_correction_booking";
        case 16:
            return "event.stocktaking_receiver_correction_booking";
        case 17:
            return "event.stocktaking_correction_booking";
        case 13:
            return "event.release_date";
        case 14:
            return "event.delete_release_date";
        default:
            return "event.withdrawal";
    }
}

export function Helper_historyTypeToTranslationString(_arg) {
    switch (_arg) {
        case "storageToUserAssignment":
            return "event.withdrawal";
        case "storageToSubContractorAssignment":
            return "event.withdrawal";
        case "userToUserAssignment":
            return "event.transfer";
        case "returnToStorage":
            return "event.return";
        case "problemReported":
            return "event.problem_report";
        case "updatedStock":
            return "event.stock_change";
        case "updatedStorage":
            return "event.storage_change";
        case "inMaintenance":
            return "event.in_maintenance";
        case "retired":
            return "event.retired";
        case "activate":
            return "event.activated";
        case "lost":
            return "event.lost";
        case "addedToSet":
            return "event.added_to_set";
        case "removedFromSet":
            return "event.removed_from_set";
        case "stocktakingStorageCorrectionBooking":
            return "event.stocktaking_storage_correction_booking";
        case "stocktakingReceiverCorrectionBooking":
            return "event.stocktaking_receiver_correction_booking";
        case "stocktakingCorrectionBooking":
            return "event.stocktaking_correction_booking";
        case "releaseDate":
            return "event.release_date";
        case "deleteReleaseDate":
            return "event.delete_release_date";
        default:
            return "event.change_information";
    }
}

export function Helper_storageId(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].StorageId;
        case 1:
            return undefined;
        case 2:
            return _arg.fields[0].StorageId;
        case 4:
            return _arg.fields[0].StorageId;
        case 5:
            return _arg.fields[0].StorageId;
        case 6:
            return _arg.fields[0].StorageId;
        case 7:
            return _arg.fields[0].StorageId;
        case 8:
            return _arg.fields[0].StorageId;
        case 9:
            return _arg.fields[0].StorageId;
        case 10: {
            const event_9 = _arg.fields[0];
            if (event_9.StorageId === "") {
                return undefined;
            }
            else {
                return event_9.StorageId;
            }
        }
        case 11:
        case 12:
            return undefined;
        case 13:
            return _arg.fields[0].StorageId;
        case 14:
            return _arg.fields[0].StorageId;
        case 15:
            return StorageId__get_unwrap(_arg.fields[0].StorageId);
        case 16:
            return undefined;
        case 17:
            return StorageId__get_unwrap(_arg.fields[0].StorageId);
        default:
            return _arg.fields[0].StorageId;
    }
}

export function Helper_storage(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].Storage;
        case 1:
            return undefined;
        case 2:
            return _arg.fields[0].Storage;
        case 4:
            return _arg.fields[0].Storage;
        case 5:
            return _arg.fields[0].Storage;
        case 6:
            return _arg.fields[0].Storage;
        case 7:
            return _arg.fields[0].Storage;
        case 8:
            return _arg.fields[0].Storage;
        case 9:
            return _arg.fields[0].Storage;
        case 10:
            return _arg.fields[0].Storage;
        case 13:
        case 14:
        case 11:
        case 12:
            return undefined;
        case 15:
            return undefined;
        case 16:
            return undefined;
        case 17:
            return undefined;
        default:
            return _arg.fields[0].Storage;
    }
}

export function Helper_quantity(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].Quantity;
        case 1:
            return _arg.fields[0].Quantity;
        case 2:
            return _arg.fields[0].Quantity;
        case 4:
            return undefined;
        case 5:
            return _arg.fields[0].Quantity;
        case 6:
            return _arg.fields[0].Quantity;
        case 10:
            return _arg.fields[0].Quantity;
        case 7:
            return undefined;
        case 8:
            return undefined;
        case 9:
            return undefined;
        case 11:
        case 12:
            return undefined;
        case 15:
            return _arg.fields[0].Quantity;
        case 16:
            return _arg.fields[0].Quantity;
        case 17:
            return undefined;
        case 13:
        case 14:
            return undefined;
        default:
            return _arg.fields[0].Quantity;
    }
}

export function Helper_receiverId(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].ReceiverId;
        case 1:
            return _arg.fields[0].ReceiverId;
        case 2:
            return _arg.fields[0].ReceiverId;
        case 10: {
            const event_4 = _arg.fields[0];
            if (event_4.ReceiverId === "") {
                return undefined;
            }
            else {
                return event_4.ReceiverId;
            }
        }
        case 4:
            return undefined;
        case 5:
            return undefined;
        case 6:
            return undefined;
        case 7:
            return undefined;
        case 8:
            return undefined;
        case 9:
            return undefined;
        case 11:
        case 12:
            return undefined;
        case 15:
            return undefined;
        case 16:
            return _arg.fields[0].ReceiverId;
        case 17:
            return undefined;
        case 13:
            return undefined;
        case 14:
            return undefined;
        default:
            return _arg.fields[0].ReceiverId;
    }
}

export function Helper_receiver(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].Receiver;
        case 1:
            return _arg.fields[0].Receiver;
        case 2:
            return _arg.fields[0].Receiver;
        case 10:
            return _arg.fields[0].Receiver;
        case 14:
            return undefined;
        case 13:
            return undefined;
        case 4:
            return undefined;
        case 5:
            return undefined;
        case 6:
            return undefined;
        case 7:
            return undefined;
        case 8:
            return undefined;
        case 9:
            return undefined;
        case 11:
        case 12:
            return undefined;
        case 15:
            return undefined;
        case 16:
            return undefined;
        case 17:
            return undefined;
        default:
            return _arg.fields[0].Receiver;
    }
}

export function Helper_senderId(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].SenderId;
        case 1:
            return _arg.fields[0].SenderId;
        case 2:
            return _arg.fields[0].SenderId;
        case 4:
            return _arg.fields[0].SenderId;
        case 5:
            return _arg.fields[0].SenderId;
        case 6:
            return _arg.fields[0].SenderId;
        case 7:
            return _arg.fields[0].SenderId;
        case 8:
            return _arg.fields[0].SenderId;
        case 9:
            return _arg.fields[0].SenderId;
        case 10:
            return _arg.fields[0].SenderId;
        case 11:
            return _arg.fields[0].SenderId;
        case 12:
            return _arg.fields[0].SenderId;
        case 15:
            return _arg.fields[0].SenderId;
        case 16:
            return _arg.fields[0].SenderId;
        case 17:
            return _arg.fields[0].SenderId;
        case 13:
            return _arg.fields[0].SenderId;
        case 14:
            return _arg.fields[0].SenderId;
        default:
            return _arg.fields[0].SenderId;
    }
}

export function Helper_comment(_arg) {
    switch (_arg.tag) {
        case 5:
            return _arg.fields[0].Comment;
        case 2:
            return _arg.fields[0].Comment;
        case 0:
            return _arg.fields[0].Comment;
        case 3:
            return _arg.fields[0].Comment;
        case 6:
            return _arg.fields[0].Comment;
        case 7:
            return _arg.fields[0].Comment;
        case 8:
            return _arg.fields[0].Comment;
        case 9:
            return _arg.fields[0].Comment;
        case 10:
            return _arg.fields[0].Comment;
        case 11:
        case 12:
            return undefined;
        case 15:
            return _arg.fields[0].Comment;
        case 16:
            return _arg.fields[0].Comment;
        case 17:
            return _arg.fields[0].Comment;
        case 13:
            return _arg.fields[0].Comment;
        case 14:
            return _arg.fields[0].Comment;
        default:
            return undefined;
    }
}

export function Helper_signatureUrl(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].SignatureUrl;
        case 1:
            return _arg.fields[0].SignatureUrl;
        case 2:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 15:
        case 16:
        case 17:
        case 13:
        case 14:
            return undefined;
        default:
            return _arg.fields[0].SignatureUrl;
    }
}

export function Helper_inMaintenanceEvent(toolEvent) {
    switch (toolEvent.tag) {
        case 0:
        case 3:
        case 1:
        case 2:
        case 4:
        case 5:
        case 6:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 15:
        case 16:
        case 17:
        case 13:
        case 14:
            return undefined;
        default:
            return toolEvent.fields[0];
    }
}

export function Helper_quantityAsStringWithComma(value) {
    const roundedValue = round(value, 2);
    return replace(roundedValue.toString(), ".", ",");
}

export function Helper_toolReservationToDto(toolReservation) {
    return new ToolOverviewReservationDto(ToolReservationId__get_unwrap(toolReservation.Id), toolReservation.Name, toolReservation.StartDate, toolReservation.EndDate, toolReservation.ReceiverId, toolReservation.Receiver, toolReservation.SenderId, toolReservation.Sender, toolReservation.Description, undefined);
}

export function Helper_toolWithQuantityReservationToDto(toolReservation) {
    return new ToolOverviewReservationDto(ToolReservationId__get_unwrap(toolReservation.Id), toolReservation.Name, toolReservation.StartDate, toolReservation.EndDate, toolReservation.ReceiverId, toolReservation.Receiver, toolReservation.SenderId, toolReservation.Sender, toolReservation.Description, toolReservation.Quantity);
}

