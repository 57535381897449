import { isEmpty, tryFind, ofArray, singleton, empty, contains, filter, map, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { multiSelectWithLabel, toNoOptionsMessage, CommonPropsMultiSelect$1, OptionType$1 } from "../../SharedComponents/ReactSelect.js";
import { unwrapStorageId, unwrapLabelId } from "../../Shared/Helper.js";
import { createObj, stringHash } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { Msg } from "./Types.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { NewToolModalView } from "../../Tools/Tool/Form/View.js";
import { value as value_16, map as map_1, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { SetId__get_unwrap, LabelId, FormState__getValidation, StorageId as StorageId_1 } from "../../Shared/Shared.js";
import { SelectToolModal } from "../Dialog/SelectToolModal.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import { TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import Shipment_Add from "../../../public/assets/icons/Shipment_Add.svg";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { NewCategoryDialog } from "../../Widgets/Dialogs/NewCategoryDialog.js";
import { AreaAnchorMenu } from "../../Widgets/AnchorMenu.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { InformationAlert, ErrorAlert } from "../../Components/Alert.js";
import { FormImageSection, FormSection } from "../../Widgets/FormSection.js";
import { CurrencyInput, Input, AutoSuggestInput } from "../../Components/Input.js";
import { Select } from "../../Components/Select.js";
import { TextArea } from "../../Components/TextArea.js";
import { Button, TextButton } from "../../Components/Button.js";
import { QrCodeTable } from "../../Widgets/Table/QrCodeTable.js";
import { DatePicker } from "../../Components/DatePicker.js";
import { NewInventoryCustomProperties } from "../../Widgets/CustomProperties.js";
import { initCopySetView, initNewSetView, update as update_1, initEditView } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import Arrow_Left_1 from "../../../public/assets/icons/Arrow_Left_1.svg";
import { RouterModule_encodeQueryString, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { getQueryParams } from "../../Common.js";
import { PageHeader } from "../../Components/Text.js";
import { Skeleton } from "../../Components/Skeleton.js";

function selectLabelProps(form, labels, dispatch, isDisabled) {
    const formLabelIds = form.LabelIds;
    return [new CommonPropsMultiSelect$1(0, [toArray(map((l_1) => (new OptionType$1(l_1.Name, l_1.Id)), filter((l) => !contains(unwrapLabelId(l.Id), formLabelIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), labels)))]), new CommonPropsMultiSelect$1(2, [(arg) => {
        dispatch(new Msg(8, [arg]));
    }]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [false]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(6, ["select.label_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg) => toNoOptionsMessage("select.label_no_options", _arg)]), new CommonPropsMultiSelect$1(12, ["flex-grow-1"]), new CommonPropsMultiSelect$1(7, [isDisabled]), new CommonPropsMultiSelect$1(3, [toArray(map((l_3) => (new OptionType$1(l_3.Name, l_3.Id)), filter((l_2) => contains(unwrapLabelId(l_2.Id), formLabelIds, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    }), labels)))])];
}

export function ToolTable(props) {
    let Title, TableRows, Options, matchValue;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(empty());
    const setSelectedToolIds = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsCreateToolDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(false);
    const setIsAddToolModalOpen = patternInput_3[1];
    const patternInput_4 = reactApi.useState(false);
    const setIsRemoveToolIdDialogOpen = patternInput_4[1];
    const assignedTools = filter((tool) => contains(tool.ToolId, props.ToolIds, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), props.Tools);
    const xs = [createElement(NewToolModalView, {
        IsOpen: patternInput_2[0],
        IsSelectedStorageIdNeeded: true,
        OnClose: () => {
            setIsCreateToolDialogOpen(false);
        },
        OnSuccessCallback: (toolId) => {
            setIsCreateToolDialogOpen(false);
            props.OnAssignToolId(singleton(toolId));
            props.FetchTools();
        },
        StorageId: unwrap(map_1((storageId) => (new StorageId_1(parse(storageId))), props.SelectedStorageId)),
        UserData: props.UserData,
    }), createElement(SelectToolModal, {
        IsOpen: patternInput_3[0],
        OnAddToolIds: (toolIds) => {
            props.OnAssignToolId(toolIds);
            setIsAddToolModalOpen(false);
        },
        OnClose: () => {
            setIsAddToolModalOpen(false);
        },
        SelectedStorageId: unwrap(props.SelectedStorageId),
        Tools: filter((tool_1) => !contains(tool_1.ToolId, props.ToolIds, {
            Equals: (x_1, y_1) => (x_1 === y_1),
            GetHashCode: stringHash,
        }), props.Tools),
    }), createElement(ConfirmationDialog, (Title = t("set.tool_remove_dialog.title"), {
        Description: t("set.tool_remove_dialog.description"),
        IsOpen: patternInput_4[0],
        OnClose: () => {
            setIsRemoveToolIdDialogOpen(false);
            setSelectedToolIds(empty());
        },
        OnConfirm: () => {
            props.OnRemoveToolId(patternInput_1[0]);
            setIsRemoveToolIdDialogOpen(false);
            setSelectedToolIds(empty());
        },
        Title: Title,
    })), createElement(Table, (TableRows = toArray(assignedTools), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["qrCodeId", "asc"],
        GlobalSearch: false,
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (dto) => (new CellContent(4, [dto.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => (new CellContent(0, [row_1.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text")],
        Controls: (matchValue = props.UserData.Role, (matchValue === "system") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_4) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("tool.create_new_tool"), Shipment_Add(), (_arg_5) => {
            setIsCreateToolDialogOpen(true);
        }, false, false, "create-tool-tool-table"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_2) => tool_2.ToolId, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue === "administrator") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_4) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("tool.create_new_tool"), Shipment_Add(), (_arg_5) => {
            setIsCreateToolDialogOpen(true);
        }, false, false, "create-tool-tool-table"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_2) => tool_2.ToolId, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : ((matchValue === "toolManager") ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_4) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("tool.create_new_tool"), Shipment_Add(), (_arg_5) => {
            setIsCreateToolDialogOpen(true);
        }, false, false, "create-tool-tool-table"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_2) => tool_2.ToolId, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : empty()))),
        Options: Options,
        TableRows: TableRows,
    })))];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function SetForm(props) {
    let elems_5, elems, elems_4, elems_3;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState("master_data");
    const patternInput_2 = reactApi.useState(false);
    const setIsNewCateogoryDialogOpen = patternInput_2[1];
    const componentState = props.FormState.IsLoading ? "disabled" : "enabled";
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,1fr]", "mt-12"])], (elems_5 = [createElement(NewCategoryDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsNewCateogoryDialogOpen(false);
            props.Dispatch(new Msg(24, []));
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(AreaAnchorMenu, {
        Anchors: toList(delay(() => append(singleton_1({
            Id: "master_data",
            Label: t("general.master_data"),
        }), delay(() => {
            let matchValue_1;
            return append((matchValue_1 = props.State.FormKind, (matchValue_1.tag === 2) ? append(singleton_1({
                Id: "tools",
                Label: t("general.tool"),
            }), delay(() => singleton_1({
                Id: "qr_codes",
                Label: t("general.qr_codes"),
            }))) : ((matchValue_1.tag === 1) ? (empty_1()) : append(singleton_1({
                Id: "tools",
                Label: t("general.tool"),
            }), delay(() => singleton_1({
                Id: "qr_codes",
                Label: t("general.qr_codes"),
            }))))), delay(() => append(singleton_1({
                Id: "appointments",
                Label: t("general.appointments"),
            }), delay(() => append(singleton_1({
                Id: "commercial_data",
                Label: t("tool.commercial_data"),
            }), delay(() => (props.IsCustomPropertiesEnabled ? singleton_1({
                Id: "custom_properties",
                Label: t("tool.custom_properties"),
            }) : empty_1())))))));
        })))),
        OnSelectAnchor: patternInput_1[1],
        SelectedAnchor: patternInput_1[0],
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "max-w-5xl"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-9"])], (elems_3 = toList(delay(() => {
        let matchValue_2;
        return append((matchValue_2 = FormState__getValidation(props.FormState, t, "global"), (matchValue_2 == null) ? (empty_1()) : singleton_1(createElement(ErrorAlert, {
            Label: matchValue_2,
        }))), delay(() => append(singleton_1(createElement(InformationAlert, {
            Label: t("set.form.create_information"),
        })), delay(() => {
            let Header;
            return append(singleton_1(createElement(FormSection, (Header = t("general.master_data"), {
                Content: toList(delay(() => append(!props.IsEdit ? singleton_1(createElement(FormImageSection, {
                    FormState: componentState,
                    SelectedFile: unwrap(props.State.SelectedFile),
                    SetSelectedFile: (file) => {
                        props.Dispatch(new Msg(37, [file]));
                    },
                })) : empty_1(), delay(() => {
                    let dependencies_1;
                    return append(singleton_1((dependencies_1 = [props.Set.MasterData.Manufacturer, props.State.Manufacturers, props.State.IsValidated, componentState], reactApi.useMemo(() => createElement(AutoSuggestInput, {
                        ComponentState: componentState,
                        Label: t("tool.manufacturer"),
                        OnChange: (arg) => {
                            props.Dispatch(new Msg(0, [arg]));
                        },
                        Suggestions: props.State.Manufacturers,
                        TestId: "create-tool-form-manufacturer",
                        Value: props.Set.MasterData.Manufacturer,
                    }), dependencies_1))), delay(() => append(singleton_1(createElement(Input, {
                        ComponentState: componentState,
                        Label: t("tool.model"),
                        OnChange: (arg_1) => {
                            props.Dispatch(new Msg(2, [arg_1]));
                        },
                        TestId: "create-tool-form-model",
                        Value: props.Set.MasterData.Model,
                    })), delay(() => append(singleton_1(createElement(Input, {
                        ComponentState: componentState,
                        Label: t("tool.serial_number"),
                        OnChange: (arg_2) => {
                            props.Dispatch(new Msg(3, [arg_2]));
                        },
                        TestId: "create-tool-form-serial-number",
                        Value: props.Set.MasterData.SerialNumber,
                    })), delay(() => append(singleton_1(createElement(Input, {
                        ComponentState: componentState,
                        Label: t("tool.displayname"),
                        OnChange: (arg_3) => {
                            props.Dispatch(new Msg(1, [arg_3]));
                        },
                        TestId: "create-tool-form-display-name",
                        ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "name")),
                        Value: props.Set.MasterData.Name,
                    })), delay(() => append(singleton_1(createElement(Input, {
                        ComponentState: componentState,
                        Label: t("tool.inventory_number"),
                        OnChange: (arg_4) => {
                            props.Dispatch(new Msg(4, [arg_4]));
                        },
                        TestId: "create-tool-form-inventory-number",
                        Value: props.Set.MasterData.InventoryNumber,
                    })), delay(() => {
                        let Label_5, Options, NoOptionsMessage, Value_6;
                        const storage = tryFind((s) => (unwrapStorageId(s.Id) === props.Set.LocationInformation.StorageId), props.Storages);
                        return append(singleton_1(createElement(Select, (Label_5 = t("general.storage"), (Options = map((storage_1) => ({
                            label: storage_1.Name,
                            value: unwrapStorageId(storage_1.Id),
                        }), filter((s_1) => {
                            if (props.UserData.Role === "toolManager") {
                                return contains(props.UserData.UserId, s_1.AssignedUserIds, {
                                    Equals: (x, y) => (x === y),
                                    GetHashCode: stringHash,
                                });
                            }
                            else {
                                return true;
                            }
                        }, props.Storages)), (NoOptionsMessage = t("tool.no_storage_found"), (Value_6 = ((storage == null) ? undefined : {
                            label: storage.Name,
                            value: props.Set.LocationInformation.StorageId,
                        }), {
                            ComponentState: (componentState === "enabled") ? (isEmpty(props.Set.AssignedToolIds) ? componentState : "disabled") : componentState,
                            IsClearable: false,
                            Label: unwrap(Label_5),
                            NoOptionsMessage: NoOptionsMessage,
                            OnChange: (s_2) => {
                                props.Dispatch(new Msg(5, [value_16(s_2)]));
                            },
                            Options: Options,
                            PlaceholderKey: "",
                            TestId: "create-tool-form-select",
                            ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "storage")),
                            Value: unwrap(Value_6),
                        })))))), delay(() => append(!isEmpty(props.Set.AssignedToolIds) ? singleton_1(createElement(InformationAlert, {
                            Label: t("set.form.storage_selected_information"),
                        })) : empty_1(), delay(() => append(singleton_1(createElement(Input, {
                            ComponentState: componentState,
                            Label: t("tool.storage_space"),
                            OnChange: (arg_5) => {
                                props.Dispatch(new Msg(6, [arg_5]));
                            },
                            TestId: "create-tool-form-storage-space",
                            Value: props.Set.LocationInformation.StorageSpace,
                        })), delay(() => append(singleton_1(createElement(TextArea, {
                            ComponentState: componentState,
                            Label: t("general.comment"),
                            OnChange: (arg_6) => {
                                props.Dispatch(new Msg(7, [arg_6]));
                            },
                            TestId: "create-tool-form-comment",
                            Value: props.Set.MasterData.Comment,
                        })), delay(() => {
                            let elems_1;
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-1"])], ["data-test-id", "tool-form-categories"], (elems_1 = [multiSelectWithLabel(selectLabelProps(props.Set, props.Labels, props.Dispatch, props.FormState.IsLoading), t("tool.label"), ""), createElement(TextButton, {
                                ComponentState: componentState,
                                Label: t("settings.create_new_label"),
                                OnClick: () => {
                                    setIsNewCateogoryDialogOpen(true);
                                },
                                TestId: "add-new-category-button",
                                Variant: "default",
                            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
                        }))))))));
                    }))))))))));
                })))),
                Header: Header,
                Id: "master_data",
            }))), delay(() => {
                let matchValue_3, Header_1;
                return append((matchValue_3 = props.State.FormKind, (matchValue_3.tag === 2) ? singleton_1(createElement(FormSection, (Header_1 = t("general.tool"), {
                    Content: singleton(createElement(ToolTable, {
                        FetchTools: () => {
                            props.Dispatch(new Msg(25, []));
                        },
                        OnAssignToolId: (arg_7) => {
                            props.Dispatch(new Msg(17, [arg_7]));
                        },
                        OnRemoveToolId: (arg_8) => {
                            props.Dispatch(new Msg(18, [arg_8]));
                        },
                        SelectedStorageId: unwrap((props.Set.LocationInformation.StorageId === "") ? undefined : props.Set.LocationInformation.StorageId),
                        ToolIds: props.Set.AssignedToolIds,
                        Tools: props.Tools,
                        UserData: props.UserData,
                    })),
                    Header: Header_1,
                    Id: "tools",
                }))) : ((matchValue_3.tag === 1) ? (empty_1()) : singleton_1(createElement(FormSection, (Header_1 = t("general.tool"), {
                    Content: singleton(createElement(ToolTable, {
                        FetchTools: () => {
                            props.Dispatch(new Msg(25, []));
                        },
                        OnAssignToolId: (arg_7) => {
                            props.Dispatch(new Msg(17, [arg_7]));
                        },
                        OnRemoveToolId: (arg_8) => {
                            props.Dispatch(new Msg(18, [arg_8]));
                        },
                        SelectedStorageId: unwrap((props.Set.LocationInformation.StorageId === "") ? undefined : props.Set.LocationInformation.StorageId),
                        ToolIds: props.Set.AssignedToolIds,
                        Tools: props.Tools,
                        UserData: props.UserData,
                    })),
                    Header: Header_1,
                    Id: "tools",
                }))))), delay(() => {
                    let matchValue_4, Header_2;
                    return append((matchValue_4 = props.State.FormKind, (matchValue_4.tag === 2) ? singleton_1(createElement(FormSection, (Header_2 = t("general.qr_codes"), {
                        Content: singleton(createElement(QrCodeTable, {
                            OnAssignQrCode: (arg_9) => {
                                props.Dispatch(new Msg(10, [arg_9]));
                            },
                            OnDeleteQrCodes: (arg_10) => {
                                props.Dispatch(new Msg(26, [arg_10]));
                            },
                            QrCodes: props.Set.QRCodeIds,
                            UserData: props.UserData,
                        })),
                        Header: Header_2,
                        Id: "qr_codes",
                    }))) : ((matchValue_4.tag === 1) ? (empty_1()) : singleton_1(createElement(FormSection, (Header_2 = t("general.qr_codes"), {
                        Content: singleton(createElement(QrCodeTable, {
                            OnAssignQrCode: (arg_9) => {
                                props.Dispatch(new Msg(10, [arg_9]));
                            },
                            OnDeleteQrCodes: (arg_10) => {
                                props.Dispatch(new Msg(26, [arg_10]));
                            },
                            QrCodes: props.Set.QRCodeIds,
                            UserData: props.UserData,
                        })),
                        Header: Header_2,
                        Id: "qr_codes",
                    }))))), delay(() => {
                        let Header_3;
                        return append(singleton_1(createElement(FormSection, (Header_3 = t("tool.commercial_data"), {
                            Content: ofArray([createElement(Input, {
                                ComponentState: componentState,
                                Label: t("general.supplier"),
                                OnChange: (arg_11) => {
                                    props.Dispatch(new Msg(11, [arg_11]));
                                },
                                TestId: "tool-form-supplier",
                                Value: props.Set.MasterData.CommercialData.Supplier,
                            }), createElement(Input, {
                                ComponentState: componentState,
                                Label: t("general.supplier_article_number"),
                                OnChange: (arg_12) => {
                                    props.Dispatch(new Msg(12, [arg_12]));
                                },
                                TestId: "tool-form-article-number",
                                Value: props.Set.MasterData.CommercialData.SupplierArticleNumber,
                            }), createElement(CurrencyInput, {
                                ComponentState: componentState,
                                Label: t("general.purchase_price"),
                                OnChange: (price) => {
                                    props.Dispatch(new Msg(13, [price]));
                                },
                                TestId: "tool-form-purchase-price",
                                Value: unwrap(props.Set.MasterData.CommercialData.PurchasePrice),
                            }), createElement(DatePicker, {
                                ComponentState: componentState,
                                IsClearable: true,
                                Label: t("general.purchase_date"),
                                OnChange: (date) => {
                                    props.Dispatch(new Msg(14, [date]));
                                },
                                TestId: "tool-form-purchase-date",
                                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "purchaseDate")),
                                Value: unwrap(props.PurchaseDate),
                            }), createElement(DatePicker, {
                                ComponentState: componentState,
                                IsClearable: true,
                                Label: t("general.gurantee_date"),
                                OnChange: (date_1) => {
                                    props.Dispatch(new Msg(15, [date_1]));
                                },
                                TestId: "tool-form-gurantee-date",
                                ValidationMessage: unwrap(FormState__getValidation(props.FormState, t, "warrantyDate")),
                                Value: unwrap(props.WarrantyDate),
                            }), createElement(TextArea, {
                                ComponentState: componentState,
                                Label: t("general.comment"),
                                OnChange: (arg_13) => {
                                    props.Dispatch(new Msg(16, [arg_13]));
                                },
                                TestId: "tool-form-commercial-data-comment",
                                Value: props.Set.MasterData.CommercialData.Comment,
                            })]),
                            Header: Header_3,
                            Id: "commercial_data",
                        }))), delay(() => {
                            let Header_4;
                            return append(props.IsCustomPropertiesEnabled ? singleton_1(createElement(FormSection, (Header_4 = t("tool.custom_properties"), {
                                Content: singleton(createElement(NewInventoryCustomProperties, {
                                    CustomProperties: props.Set.MasterData.CustomProperties,
                                    FormState: props.FormState,
                                    LabelIds: map((labelId) => (new LabelId(parse(labelId))), props.Set.LabelIds),
                                    OnSetCustomProperty: (arg_14) => {
                                        props.Dispatch(new Msg(9, [arg_14]));
                                    },
                                    PropertyConfigurations: props.PropertyConfigurations,
                                })),
                                Header: Header_4,
                                Id: "custom_properties",
                            }))) : empty_1(), delay(() => {
                                let matchValue_5;
                                return append((matchValue_5 = FormState__getValidation(props.FormState, t, "global"), (matchValue_5 == null) ? (empty_1()) : singleton_1(createElement(ErrorAlert, {
                                    Label: matchValue_5,
                                }))), delay(() => {
                                    let elems_2;
                                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems_2 = [createElement(Button, {
                                        ComponentState: componentState,
                                        Label: t("general.save"),
                                        OnClick: props.OnSave,
                                        TestId: "tool-form-save-button",
                                        Variant: "primary",
                                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function EditSetView(props) {
    let elems_1, elems, Label;
    let patternInput;
    const init = initEditView(props.UserData, props.SetId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.SetForm;
    const matchValue_1 = state_1.Tools;
    const matchValue_2 = state_1.Storages;
    const matchValue_3 = state_1.Labels;
    const matchValue_4 = state_1.PropertyConfigurations;
    const matchValue_5 = state_1.IsCustomPropertiesEnabled;
    const matchValue_6 = state_1.UserData;
    let matchResult, isPropertyConfigurationsEnabled, labels, propertyConfigurations, set$, storages, tools, userData;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_6 == null) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isPropertyConfigurationsEnabled = matchValue_5.fields[0];
        labels = matchValue_3.fields[0];
        propertyConfigurations = matchValue_4.fields[0];
        set$ = matchValue.fields[0];
        storages = matchValue_2.fields[0];
        tools = matchValue_1.fields[0];
        userData = matchValue_6;
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput_1[0]("general.back"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["sets", SetId__get_unwrap(props.SetId) + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
                },
                TestId: "set-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "set.form.edit_title",
            }), createElement(SetForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isPropertyConfigurationsEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                IsVisibleForUser: true,
                Labels: labels,
                OnSave: () => {
                    dispatch(new Msg(22, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                Set: set$,
                State: state_1,
                Storages: storages,
                Tools: tools,
                UserData: userData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function NewSetView(props) {
    let elems_1, elems, Label;
    let patternInput;
    const init = initNewSetView(props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.SetForm;
    const matchValue_1 = state_1.Tools;
    const matchValue_2 = state_1.Storages;
    const matchValue_3 = state_1.Labels;
    const matchValue_4 = state_1.PropertyConfigurations;
    const matchValue_5 = state_1.IsCustomPropertiesEnabled;
    const matchValue_6 = state_1.UserData;
    let matchResult, isPropertyConfigurationsEnabled, labels, propertyConfigurations, set$, storages, tools, userData;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_6 == null) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isPropertyConfigurationsEnabled = matchValue_5.fields[0];
        labels = matchValue_3.fields[0];
        propertyConfigurations = matchValue_4.fields[0];
        set$ = matchValue.fields[0];
        storages = matchValue_2.fields[0];
        tools = matchValue_1.fields[0];
        userData = matchValue_6;
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput_1[0]("general.back_to_overview"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(singleton("tools" + RouterModule_encodeQueryString(getQueryParams(undefined))), 1, 1);
                },
                TestId: "set-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "set.form.create_title",
            }), createElement(SetForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isPropertyConfigurationsEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                IsVisibleForUser: true,
                Labels: labels,
                OnSave: () => {
                    dispatch(new Msg(21, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                Set: set$,
                State: state_1,
                Storages: storages,
                Tools: tools,
                UserData: userData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

export function CopySetView(props) {
    let elems_1, elems, Label;
    let patternInput;
    const init = initCopySetView(props.UserData, props.SetId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.SetForm;
    const matchValue_1 = state_1.Tools;
    const matchValue_2 = state_1.Storages;
    const matchValue_3 = state_1.Labels;
    const matchValue_4 = state_1.PropertyConfigurations;
    const matchValue_5 = state_1.IsCustomPropertiesEnabled;
    const matchValue_6 = state_1.UserData;
    let matchResult, isPropertyConfigurationsEnabled, labels, propertyConfigurations, set$, storages, tools, userData;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_6 == null) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        isPropertyConfigurationsEnabled = matchValue_5.fields[0];
        labels = matchValue_3.fields[0];
        propertyConfigurations = matchValue_4.fields[0];
        set$ = matchValue.fields[0];
        storages = matchValue_2.fields[0];
        tools = matchValue_1.fields[0];
        userData = matchValue_6;
    }
    switch (matchResult) {
        case 0:
            return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mb-3", "flex"])], (elems = [createElement(TextButton, (Label = patternInput_1[0]("general.back"), {
                ComponentState: "enabled",
                Icon: Arrow_Left_1(),
                Label: Label,
                OnClick: () => {
                    RouterModule_nav(ofArray(["sets", SetId__get_unwrap(props.SetId) + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
                },
                TestId: "set-new-back-to-overview-button",
                Variant: "default",
            }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(PageHeader, {
                Text: "set.form.create_title",
            }), createElement(SetForm, {
                Dispatch: dispatch,
                FormState: state_1.FormState,
                IsCustomPropertiesEnabled: isPropertyConfigurationsEnabled && !isEmpty(propertyConfigurations),
                IsEdit: false,
                IsVisibleForUser: true,
                Labels: labels,
                OnSave: () => {
                    dispatch(new Msg(21, []));
                },
                PropertyConfigurations: propertyConfigurations,
                PurchaseDate: unwrap(state_1.PurchaseDate),
                Set: set$,
                State: state_1,
                Storages: storages,
                Tools: tools,
                UserData: userData,
                WarrantyDate: unwrap(state_1.WarrantyDate),
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        default:
            return createElement(Skeleton, {
                Variant: "normal",
            });
    }
}

