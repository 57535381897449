import { createElement } from "react";
import React from "react";
import * as react from "react";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { comparePrimitives, stringHash, equals, createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { isNullOrWhiteSpace, join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { mapIndexed, toArray, sortByDescending, empty as empty_1, take, length, contains, singleton as singleton_1, filter, sortBy, tryHead, ofArray, map } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { Button, TextButton, SidebarTextButton } from "../../Components/Button.js";
import { useTranslation } from "react-i18next";
import { ToolState__ToI18nString, ProblemReportState } from "../../Shared/Tool.js";
import { toLocalTime, toString, utcNow, date, compare } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { map as map_1, defaultArg, unwrap } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import Calendar_Warning from "../../../public/assets/icons/Calendar_Warning.svg";
import { ChangeStorageDialog } from "../Dialog/ChangeStorageDialog.js";
import { Msg } from "./Types.js";
import { ChangeReturnDateDialog } from "../Dialog/ChangeReturnDateDialog.js";
import { SetReleaseDateDialog } from "../Dialog/SetReleaseDateDialog.js";
import { ColoredDot } from "../../Components/ColoredDot.js";
import { TextValue } from "../../Components/Text.js";
import { RouterModule_encodeQueryString, RouterModule_encodeParts, RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Feature, isFeatureEnabled } from "../../FeatureFlip.js";
import { QuickOverviewList } from "../../Widgets/QuickOverviewList.js";
import { OpenCreateReservationDialog } from "../Dialog/CreateReservationDialog.js";
import { OpenAssignDialog } from "../Dialog/AssignDialog.js";
import { OpenUnassignDialog } from "../Dialog/UnassignDialog.js";
import { OpenAssignFromReceiverToReceiverDialog } from "../Dialog/ReceiverToReceiverAssignDialog.js";
import { OpenStateChangeDialog } from "../Dialog/StateChangeDialog.js";
import Calendar_Add from "../../../public/assets/icons/Calendar_Add.svg";
import Following from "../../../public/assets/icons/Following.svg";
import Followers from "../../../public/assets/icons/Followers.svg";
import Shipment_Warning from "../../../public/assets/icons/Shipment_Warning.svg";
import Shipment_Check from "../../../public/assets/icons/Shipment_Check.svg";
import Shipment_Delivery_Fail from "../../../public/assets/icons/Shipment_Delivery_Fail.svg";
import Shipment_Search from "../../../public/assets/icons/Shipment_Search.svg";
import Shipment_Time from "../../../public/assets/icons/Shipment_Time.svg";
import { PropertyList } from "../../Widgets/PropertyListWidget.js";
import { CustomPropertiesPropertyList } from "../../Widgets/CustomPropertiesPropertyList.js";
import { purchaseDate, price } from "../Common.js";
import { QrCodeTable } from "../../Widgets/Table/QrCodeTable.js";
import { ModalSheet } from "../../Components/ModalSheet.js";
import { DocumentTable } from "../../Widgets/Table/DocumentTable.js";
import { UploadType as UploadType_2 } from "../../Widgets/Dialogs/FileUploadDialog.js";
import { OpenEditReservationDialog } from "../Dialog/EditReservationDialog.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { StorageId as StorageId_1, NoticeId, SetId as SetId_2 } from "../../Shared/Shared.js";
import { OpenDeleteReservationDialog } from "../Dialog/DeleteReservationDialog.js";
import { OnTableRowClick$1, TableControl$1, ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import Pencil from "../../../public/assets/icons/Pencil.svg";
import Recycle_Bin_1 from "../../../public/assets/icons/Recycle_Bin_1.svg";
import { OpenCreateReminderDialog } from "../Dialog/CreateReminderDialog.js";
import { OpenEditReminderDialog } from "../Dialog/EditReminderDialog.js";
import { OpenDeleteReminderDialog } from "../Dialog/DeleteReminderDialog.js";
import { OpenConfirmReminderDialog } from "../Dialog/ConfirmReminderDialog.js";
import Check from "../../../public/assets/icons/Check.svg";
import { DataTransfer_DetailSet__ReadableDisplayName, BusinessLogic_SetEvent__get_SignatureUrl, BusinessLogic_SetEvent__get_Comment, BusinessLogic_SetEvent__ToTranslationString, BusinessLogic_SetEvent__get_Timestamp } from "../../Shared/Set.js";
import { Helper_displayHistoryComment } from "../../Shared/Changelog.js";
import { AssignmentProtocolDialog, AssignmentProtocolParams } from "../../Widgets/AssignmentProtocol.js";
import { NotificationArea } from "../../Widgets/Notification/NotificationArea.js";
import { NotificationToolType } from "../../Widgets/Notification/AcceptNotificationDialog.js";
import { ImageWidget } from "../../Widgets/ImageWidget.js";
import { ChatWidget, Message as Message_1 } from "../../Widgets/ChatWidget.js";
import { OpenNoticeDeleteDialog } from "../Dialog/DeleteNoticeDialog.js";
import { NewToolModalView } from "../../Tools/Tool/Form/View.js";
import { SelectToolModal } from "../Dialog/SelectToolModal.js";
import { ConfirmationDialog } from "../../Components/Dialog.js";
import Shipment_Add from "../../../public/assets/icons/Shipment_Add.svg";
import { DeleteDialog } from "../Dialog/DeleteDialog.js";
import { DetailViewHeader } from "../../Widgets/DetailViewHeader.js";
import { Badge } from "../../Components/Badge.js";
import { getQueryParams } from "../../Common.js";
import { TableContainer, SectionContainer } from "../../Widgets/Container.js";
import { update as update_1, initViewSet } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

export const scrollOptions = new (class {
    get behavior() {
        const browserInfo = navigator.userAgent;
        return (browserInfo.indexOf("Chrome") >= 0) ? "auto" : "smooth";
    }
    set behavior(_arg) {
    }
    get block() {
        return "center";
    }
    set block(_arg_1) {
    }
    get inline() {
        return "center";
    }
    set inline(_arg_2) {
    }
}
)();

export function AnchorMenu(props) {
    let elems_1, elems;
    const dependencies = [props.SelectedAnchorId];
    reactApi.useEffect(() => {
        document.getElementById(props.SelectedAnchorId).scrollIntoView(scrollOptions);
    }, dependencies);
    return createElement("div", createObj(ofArray([["className", join(" ", ["sticky", "top-5"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col", "gap-2", "sticky", "top-5"])], (elems = toList(delay(() => map((item) => createElement(SidebarTextButton, {
        ComponentState: "enabled",
        Label: item.DisplayName,
        OnClick: () => {
            props.SetSelectedAnchorId(item.AnchorId);
            document.getElementById(item.AnchorId).scrollIntoView(scrollOptions);
        },
        TestId: `tool-anchormenu-${item.DisplayName}-anchor`,
        Variant: (props.SelectedAnchorId === item.AnchorId) ? "blueButton" : "default",
    }), props.MenuItems))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Box(props) {
    let elems_2, elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-background", "py-3", "px-4", "flex", "flex-col", "rounded"])], (elems_2 = [createElement("div", {
        className: join(" ", ["text-sm", "font-semibold"]),
        children: props.Title,
    }), createElement("div", {
        className: join(" ", ["flex", "flex-col", "divide-y", "divide-[#e9edf3]", "gap-2", "my-4", "grow"]),
        children: reactApi.Children.toArray([props.ContentElement]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_1 = [createElement(TextButton, {
        ComponentState: "enabled",
        Label: props.AdditionalInformation,
        OnClick: props.OnClick,
        TestId: `tool-selectcard-${props.AdditionalInformation}-edit-button`,
        Variant: "blueButton",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function InformationBox(props) {
    let elems_2;
    const contextLength = (props.ContentText.length > 100) ? (`${props.ContentText.slice(undefined, 100 + 1)}[...]`) : props.ContentText;
    return createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "rounded", "drop-shadow-lg", "p-4"])], (elems_2 = toList(delay(() => {
        let matchValue, iconElement, elems_1;
        return append((matchValue = props.Icon, (matchValue != null) ? ((iconElement = matchValue, singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-between"])], (elems_1 = [createElement("div", {
            className: join(" ", ["text-sm"]),
            children: contextLength,
        }), createElement("div", {
            className: join(" ", ["text-red", "h-5", "w-5"]),
            children: reactApi.Children.toArray([iconElement]),
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))))) : singleton(createElement("div", {
            className: join(" ", ["text-sm"]),
            children: contextLength,
        }))), delay(() => {
            const matchValue_1 = props.Title;
            if (matchValue_1 == null) {
                return empty();
            }
            else {
                const title = matchValue_1;
                return singleton(createElement("div", {
                    className: join(" ", ["text-sm", "font-semibold", "mt-3"]),
                    children: title,
                }));
            }
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function NotificationsBox(props) {
    let Title_2;
    const t = useTranslation()[0];
    const problemReport = tryHead(sortBy((pr_1) => pr_1.Timestamp, filter((pr) => equals(pr.State, new ProblemReportState(0, [])), props.ProblemReports), {
        Compare: compare,
    }));
    const content = (problemReport == null) ? createElement(InformationBox, {
        ContentText: t("general.no_content"),
    }) : createElement(InformationBox, {
        ContentText: problemReport.Message,
    });
    return Box((Title_2 = t("tool.current_problem_report"), {
        AdditionalInformation: t("tool.to_problem_reports"),
        ContentElement: content,
        OnClick: () => {
            props.SetCurrentSelectedLink("problem-report-section-id");
        },
        Title: Title_2,
    }));
}

export function AppointmentBox(props) {
    let Title_2;
    const t = useTranslation()[0];
    const today = date(utcNow());
    const firstAppointment = tryHead(sortBy((a_1) => a_1.Date, filter((a) => !a.IsConfirmed, props.Set.Planning.Reminders), {
        Compare: compare,
    }));
    let informationBox;
    if (firstAppointment == null) {
        informationBox = createElement(InformationBox, {
            ContentText: t("general.no_content"),
        });
    }
    else {
        const appointment = firstAppointment;
        informationBox = createElement(InformationBox, {
            ContentText: toString(appointment.Date, "dd.MM.yyyy"),
            Icon: unwrap((compare(date(appointment.Date), today) <= 0) ? Calendar_Warning() : undefined),
            Title: appointment.Title,
        });
    }
    return Box((Title_2 = t("general.next_appointment"), {
        AdditionalInformation: t("general.to_planning"),
        ContentElement: informationBox,
        OnClick: () => {
            props.SetCurrentSelectedLink("appointments-section-id");
        },
        Title: Title_2,
    }));
}

export function ReservationBox(props) {
    let Title_2;
    const t = useTranslation()[0];
    const now = date(utcNow());
    const nextReservation = tryHead(sortBy((r_1) => r_1.StartDate, filter((r) => (compare(r.StartDate, now) >= 0), props.Set.Planning.Reservations), {
        Compare: compare,
    }));
    let informationBox;
    if (nextReservation == null) {
        informationBox = createElement(InformationBox, {
            ContentText: t("general.no_content"),
        });
    }
    else {
        const reservation = nextReservation;
        informationBox = createElement(InformationBox, {
            ContentText: `${toString(reservation.StartDate, "dd.MM.yyyy")} - ${toString(reservation.EndDate, "dd.MM.yyyy")}`,
            Title: reservation.ReceiverInformation.ReceiverName,
        });
    }
    return Box((Title_2 = t("tool.next_reservation"), {
        AdditionalInformation: t("general.to_planning"),
        ContentElement: informationBox,
        OnClick: () => {
            props.SetCurrentSelectedLink("reservations-section-id");
        },
        Title: Title_2,
    }));
}

export function SetShortDetails(props) {
    let elems_14;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsSwitchStorageDialogOpen = patternInput_1[1];
    const isSwitchStorageDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsChangeReturnDateDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(false);
    const setIsReleaseDateDialogOpen = patternInput_3[1];
    let storageLocation;
    const matchValue = props.Set.LocationInformation.LocationName;
    storageLocation = ((matchValue == null) ? "" : (`(${matchValue})`));
    return createElement("div", createObj(singleton_1((elems_14 = toList(delay(() => append(singleton(createElement(ChangeStorageDialog, {
        IsOpen: isSwitchStorageDialogOpen,
        OnClose: () => {
            setIsSwitchStorageDialogOpen(false);
        },
        Set: props.Set,
        Storages: props.Storages,
        SuccessCallback: () => {
            props.Dispatch(new Msg(29, []));
        },
        UserData: props.UserData,
    })), delay(() => append(singleton(createElement(ChangeReturnDateDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsChangeReturnDateDialogOpen(false);
        },
        Set: props.Set,
        SuccessCallback: () => {
            setIsChangeReturnDateDialogOpen(false);
            props.Dispatch(new Msg(29, []));
        },
    })), delay(() => append(singleton(createElement(SetReleaseDateDialog, {
        IsOpen: patternInput_3[0],
        OnClose: () => {
            setIsReleaseDateDialogOpen(false);
        },
        Set: props.Set,
        SuccessCallback: () => {
            setIsReleaseDateDialogOpen(false);
            props.Dispatch(new Msg(29, []));
        },
    })), delay(() => {
        let elems_2, elems_1, elems, matchValue_1, matchValue_2;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_2 = [createElement("div", {
            className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
            children: t("general.state"),
        }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mr-1"])], (elems = [createElement(ColoredDot, {
            Color: (matchValue_1 = props.Set.State, (matchValue_1 === "inMaintenance") ? "orange" : ((matchValue_1 === "retired") ? "red" : ((matchValue_1 === "lost") ? "red" : "green"))),
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(TextValue, {
            FontWeight: "normal",
            TestId: "",
            Text: (matchValue_2 = props.Set.State, (matchValue_2 === "inMaintenance") ? t("general.in_maintenance") : ((matchValue_2 === "retired") ? t("general.retired") : ((matchValue_2 === "lost") ? t("general.lost") : t("general.active")))),
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elems_4, elems_3;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_4 = [createElement("div", {
                className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                children: t("tool.assgined_to"),
            }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], ["data-test-id", "tool-assigned-to-test-id"], (elems_3 = toList(delay(() => {
                const matchValue_3 = props.Set.LocationInformation.AssignedReceiverId;
                if (matchValue_3 != null) {
                    const assignedReceiverId = matchValue_3;
                    let receiverType;
                    const matchValue_4 = props.Set.LocationInformation.ReceiverTypeName;
                    if (matchValue_4 != null) {
                        const receiverTypeName = matchValue_4;
                        receiverType = ((receiverTypeName.tag === 1) ? t("receivers.employee") : ((receiverTypeName.tag === 2) ? t("receivers.externalperson") : ((receiverTypeName.tag === 3) ? t("receivers.subcontractor") : ((receiverTypeName.tag === 4) ? t("receivers.room") : ((receiverTypeName.tag === 5) ? t("receivers.project") : ((receiverTypeName.tag === 6) ? t("receivers.constructionsite") : ((receiverTypeName.tag === 7) ? t("receivers.student") : ((receiverTypeName.tag === 8) ? t("receivers.school_class") : ((receiverTypeName.tag === 9) ? t("receivers.vehicles") : t("receivers.user"))))))))));
                    }
                    else {
                        receiverType = "";
                    }
                    const matchValue_5 = props.UserData.Role;
                    switch (matchValue_5) {
                        case "user":
                            return singleton(createElement(TextValue, {
                                FontWeight: "normal",
                                TestId: "",
                                Text: `${props.Set.LocationInformation.AssignedReceiverName} (${receiverType})`,
                            }));
                        default:
                            return singleton(createElement(TextButton, {
                                ComponentState: "enabled",
                                Label: `${props.Set.LocationInformation.AssignedReceiverName} (${receiverType})`,
                                OnClick: () => {
                                    RouterModule_nav(singleton_1(`/receivers/${assignedReceiverId}`), 1, 1);
                                },
                                TestId: "assigned-receiver-text-button-test-id",
                                Variant: "blueButton",
                            }));
                    }
                }
                else {
                    return singleton(createElement(TextValue, {
                        FontWeight: "normal",
                        TestId: "",
                        Text: t("tool.not_assigned"),
                    }));
                }
            })), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                let matchValue_6, assignedReceiverId_1, elems_6, elems_5, Label_1, matchValue_7, matchValue_8;
                return append(isFeatureEnabled(new Feature(17, [])) ? (props.CustomerConfiguration.IsReleaseDateEnabled ? ((matchValue_6 = props.Set.LocationInformation.AssignedReceiverId, (matchValue_6 != null) ? ((assignedReceiverId_1 = matchValue_6, singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_6 = [createElement("div", {
                    className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                    children: t("general.release_date"),
                }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], ["data-test-id", "tool-release-date-test-id"], (elems_5 = [createElement(TextButton, (Label_1 = ((matchValue_7 = props.Set.LocationInformation.ReleaseDate, (matchValue_7 != null) ? toString(toLocalTime(matchValue_7), "dd.MM.yyyy") : t("general.no_release_date_set"))), {
                    ComponentState: (matchValue_8 = props.UserData.Role, (matchValue_8 === "administrator") ? "enabled" : ((matchValue_8 === "toolManager") ? (((assignedReceiverId_1 === props.UserData.UserId) ? true : contains(props.UserData.UserId, props.Set.LocationInformation.AssignedStorageUserIds, {
                        Equals: (x, y) => (x === y),
                        GetHashCode: stringHash,
                    })) ? "enabled" : "disabled") : ((matchValue_8 === "user") ? ((assignedReceiverId_1 === props.UserData.UserId) ? "enabled" : "disabled") : "enabled"))),
                    Label: Label_1,
                    OnClick: () => {
                        setIsReleaseDateDialogOpen(true);
                    },
                    TestId: "release-date-receiver-text-button-test-id",
                    Variant: "blueButton",
                }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))))) : (empty()))) : empty()) : empty(), delay(() => {
                    let matchValue_9, assignedReceiver, elems_8, elems_7, matchValue_10;
                    return append((matchValue_9 = props.Set.LocationInformation.AssignedReceiverId, (matchValue_9 != null) ? ((assignedReceiver = matchValue_9, singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_8 = [createElement("div", {
                        className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                        children: t("tool.planned_return_date"),
                    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], ["data-test-id", "tool-planned-return-date-test-id"], (elems_7 = [createElement(TextButton, {
                        ComponentState: "enabled",
                        Label: (matchValue_10 = props.Set.LocationInformation.PlannedReturnDate, (matchValue_10 != null) ? toString(toLocalTime(matchValue_10), "dd.MM.yyyy") : t("general.no_planned_return_date_set")),
                        OnClick: () => {
                            setIsChangeReturnDateDialogOpen(true);
                        },
                        TestId: "planned-return-date-receiver-text-button-test-id",
                        Variant: "blueButton",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))))) : (empty())), delay(() => {
                        let elems_10;
                        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_10 = toList(delay(() => append(singleton(createElement("div", {
                            className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                            children: t("general.storage"),
                        })), delay(() => {
                            let elems_9;
                            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_9 = [createElement(TextValue, {
                                FontWeight: "normal",
                                TestId: "",
                                Text: `${props.Set.LocationInformation.StorageName} ${storageLocation}`,
                            })], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))), delay(() => {
                                const matchValue_11 = props.UserData.Role;
                                switch (matchValue_11) {
                                    case "user": {
                                        return empty();
                                    }
                                    default: {
                                        const matchValue_12 = props.Set.State;
                                        switch (matchValue_12) {
                                            case "lost":
                                            case "retired": {
                                                return empty();
                                            }
                                            default:
                                                return singleton(createElement(TextButton, {
                                                    ComponentState: "enabled",
                                                    Label: t("tool.switch_storage"),
                                                    OnClick: () => {
                                                        setIsSwitchStorageDialogOpen(!isSwitchStorageDialogOpen);
                                                    },
                                                    TestId: "switch-storage-text-button-test-id",
                                                    Variant: "blueButton",
                                                }));
                                        }
                                    }
                                }
                            }));
                        })))), ["children", reactApi.Children.toArray(Array.from(elems_10))])])))), delay(() => {
                            let elems_12, elems_11;
                            return append(!isNullOrWhiteSpace(props.Set.LocationInformation.StorageSpace) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_12 = [createElement("div", {
                                className: join(" ", ["text-sm", "font-semibold", "mb-2"]),
                                children: t("tool.storage_space"),
                            }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "items-center", "mb-2"])], (elems_11 = [createElement(TextValue, {
                                FontWeight: "normal",
                                TestId: "",
                                Text: props.Set.LocationInformation.StorageSpace,
                            })], ["children", reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", reactApi.Children.toArray(Array.from(elems_12))])])))) : empty(), delay(() => {
                                let elems_13, Label_4, Label_5, Label_6, Label_7, Label_8, Label_9;
                                return isFeatureEnabled(new Feature(3, [])) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_13 = [createElement(QuickOverviewList, {
                                    Elements: {
                                        CommercialData: props.Set.MasterData.CommercialData,
                                        Custom: ofArray([(Label_4 = t("tool.manufacturer"), {
                                            Group: t("general.master_data"),
                                            Key: "tool-manufacturer-fav-field",
                                            Label: Label_4,
                                            Value: props.Set.MasterData.Manufacturer,
                                        }), (Label_5 = t("tool.model"), {
                                            Group: t("general.master_data"),
                                            Key: "tool-model-fav-field",
                                            Label: Label_5,
                                            Value: props.Set.MasterData.Model,
                                        }), (Label_6 = t("tool.serial_number"), {
                                            Group: t("general.master_data"),
                                            Key: "tool-serialnumber-fav-field",
                                            Label: Label_6,
                                            Value: props.Set.MasterData.SerialNumber,
                                        }), (Label_7 = t("tool.displayname"), {
                                            Group: t("general.master_data"),
                                            Key: "tool-displayname-fav-field",
                                            Label: Label_7,
                                            Value: props.Set.MasterData.Name,
                                        }), (Label_8 = t("tool.inventory_number"), {
                                            Group: t("general.master_data"),
                                            Key: "tool-inventorynumber-fav-field",
                                            Label: Label_8,
                                            Value: props.Set.MasterData.InventoryNumber,
                                        }), (Label_9 = t("general.comment"), {
                                            Group: t("general.master_data"),
                                            Key: "tool-comment-fav-field",
                                            Label: Label_9,
                                            Value: props.Set.MasterData.Comment,
                                        })]),
                                        CustomProperties: props.Set.MasterData.CustomProperties,
                                    },
                                    IsDialogOpen: props.QuickOverviewDialog.IsOpen,
                                    IsLoading: props.QuickOverviewDialog.IsLoading,
                                    OnSave: (updatedVisibleKeys) => {
                                        props.Dispatch(new Msg(0, [updatedVisibleKeys]));
                                    },
                                    SetDialogOpen: (_arg_10) => {
                                        props.Dispatch(new Msg(1, []));
                                    },
                                    UserData: props.UserData,
                                    VisibleKeys: props.UserConfiguration.QuickOverviewConfiguration.ToolViewVisibleFieldKeys,
                                })], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))) : empty();
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })))))))), ["children", reactApi.Children.toArray(Array.from(elems_14))]))));
}

function SetDetailsActions(props) {
    let elems_3;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsReservationDialogOpen = patternInput_1[1];
    const isReservationDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsAssignDialogOpen = patternInput_2[1];
    const isAssignDialogOpen = patternInput_2[0];
    const patternInput_3 = reactApi.useState(false);
    const setIsUnassignDialogOpen = patternInput_3[1];
    const isUnassignDialogOpen = patternInput_3[0];
    const patternInput_4 = reactApi.useState(false);
    const patternInput_5 = reactApi.useState(false);
    const setIsInMaintenanceStateDialogOpen = patternInput_5[1];
    const patternInput_6 = reactApi.useState(false);
    const setIsActiveStateDialogOpen = patternInput_6[1];
    const patternInput_7 = reactApi.useState(false);
    const setIsLostStateDialogOpen = patternInput_7[1];
    const patternInput_8 = reactApi.useState(false);
    const setIsRetiredStateDialogOpen = patternInput_8[1];
    const patternInput_9 = reactApi.useState(false);
    const setIsAssignFromReceiverToReceiverDialogOpen = patternInput_9[1];
    const isAssignFromReceiverToReceiverDialogOpen = patternInput_9[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "gap-7", "mb-16"])], (elems_3 = toList(delay(() => append(isReservationDialogOpen ? singleton(createElement(OpenCreateReservationDialog, {
        IsOpen: isReservationDialogOpen,
        OnClose: () => {
            setIsReservationDialogOpen(false);
        },
        Set: props.Set,
        SuccessCallback: () => {
            props.SetCurrentSelectedLink("reservations-section-id");
            props.Dispatch(new Msg(29, []));
        },
        UserData: props.UserData,
    })) : empty(), delay(() => append(isAssignDialogOpen ? singleton(createElement(OpenAssignDialog, {
        IsOpen: isAssignDialogOpen,
        OnClose: () => {
            setIsAssignDialogOpen(false);
        },
        Set: props.Set,
        SuccessCallback: () => {
            props.Dispatch(new Msg(29, []));
        },
        UserData: props.UserData,
    })) : empty(), delay(() => append(isUnassignDialogOpen ? singleton(createElement(OpenUnassignDialog, {
        IsOpen: isUnassignDialogOpen,
        OnClose: () => {
            setIsUnassignDialogOpen(false);
        },
        Set: props.Set,
        SuccessCallback: () => {
            props.Dispatch(new Msg(29, []));
        },
        UserData: props.UserData,
    })) : empty(), delay(() => {
        let matchValue, assignedReceiver;
        return append((matchValue = props.Set.LocationInformation.AssignedReceiverId, (matchValue == null) ? (empty()) : ((assignedReceiver = matchValue, isAssignFromReceiverToReceiverDialogOpen ? singleton(createElement(OpenAssignFromReceiverToReceiverDialog, {
            IsOpen: isAssignFromReceiverToReceiverDialogOpen,
            OnClose: () => {
                setIsAssignFromReceiverToReceiverDialogOpen(false);
            },
            Set: props.Set,
            SuccessCallback: () => {
                props.Dispatch(new Msg(29, []));
            },
        })) : empty()))), delay(() => append(singleton(createElement(OpenStateChangeDialog, {
            IsOpen: patternInput_5[0],
            OnClose: () => {
                setIsInMaintenanceStateDialogOpen(false);
            },
            Set: props.Set,
            SetState: "inMaintenance",
            SuccessCallback: () => {
                props.Dispatch(new Msg(29, []));
            },
        })), delay(() => append(singleton(createElement(OpenStateChangeDialog, {
            IsOpen: patternInput_6[0],
            OnClose: () => {
                setIsActiveStateDialogOpen(false);
            },
            Set: props.Set,
            SetState: "active",
            SuccessCallback: () => {
                props.Dispatch(new Msg(29, []));
            },
        })), delay(() => append(singleton(createElement(OpenStateChangeDialog, {
            IsOpen: patternInput_8[0],
            OnClose: () => {
                setIsRetiredStateDialogOpen(false);
            },
            Set: props.Set,
            SetState: "retired",
            SuccessCallback: () => {
                props.Dispatch(new Msg(29, []));
            },
        })), delay(() => append(singleton(createElement(OpenStateChangeDialog, {
            IsOpen: patternInput_7[0],
            OnClose: () => {
                setIsLostStateDialogOpen(false);
            },
            Set: props.Set,
            SetState: "lost",
            SuccessCallback: () => {
                props.Dispatch(new Msg(29, []));
            },
        })), delay(() => {
            let elems_2, elems, elems_1;
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "grow", "justify-between"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4"])], (elems = toList(delay(() => {
                let Label;
                return append(((props.Set.State === "active") ? true : (props.Set.State === "inMaintenance")) ? singleton(createElement(TextButton, (Label = t("dialog.reservation.btn_title"), {
                    ComponentState: "enabled",
                    Icon: Calendar_Add(),
                    Label: Label,
                    OnClick: () => {
                        setIsReservationDialogOpen(true);
                    },
                    TestId: "tool-tooldetail-reservate-button",
                    Variant: "default",
                }))) : empty(), delay(() => {
                    let Label_3, Label_1;
                    const matchValue_1 = props.UserData.Role;
                    switch (matchValue_1) {
                        case "user": {
                            return empty();
                        }
                        default:
                            return (props.Set.LocationInformation.AssignedReceiverId == null) ? ((props.Set.State === "active") ? singleton(createElement(TextButton, (Label_3 = t("general.assign"), {
                                ComponentState: "enabled",
                                Icon: Following(),
                                Label: Label_3,
                                OnClick: () => {
                                    setIsAssignDialogOpen(true);
                                },
                                TestId: "tool-tooldetail-assign-button",
                                Variant: "default",
                            }))) : empty()) : append(singleton(createElement(TextButton, (Label_1 = t("general.take_back"), {
                                ComponentState: "enabled",
                                Icon: Followers(),
                                Label: Label_1,
                                OnClick: () => {
                                    setIsUnassignDialogOpen(true);
                                },
                                TestId: "tool-tooldetail-unassign-button",
                                Variant: "default",
                            }))), delay(() => {
                                let Label_2;
                                return ((props.Set.State !== "lost") ? true : (props.Set.State !== "retired")) ? singleton(createElement(TextButton, (Label_2 = t("general.assign"), {
                                    ComponentState: "enabled",
                                    Icon: Following(),
                                    Label: Label_2,
                                    OnClick: () => {
                                        setIsAssignFromReceiverToReceiverDialogOpen(true);
                                    },
                                    TestId: "tool-tooldetail-assign-receiver-to-receiver-button",
                                    Variant: "default",
                                }))) : empty();
                            }));
                    }
                }));
            })), ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "gap-4"])], (elems_1 = toList(delay(() => {
                let Label_12, Label_7, Label_10, Label_11, Label_4;
                const matchValue_3 = props.UserData.Role;
                switch (matchValue_3) {
                    case "user":
                        return singleton(createElement(TextButton, (Label_12 = t("dialog.problem_report.btn_title"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Warning(),
                            Label: Label_12,
                            OnClick: () => {
                                patternInput_4[1](true);
                            },
                            TestId: "tool-create-problem-report-button",
                            Variant: "default",
                        })));
                    default: {
                        const matchValue_4 = props.Set.State;
                        return (matchValue_4 === "inMaintenance") ? append(singleton(createElement(TextButton, (Label_7 = t("tool.state_active"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Check(),
                            Label: Label_7,
                            OnClick: () => {
                                setIsActiveStateDialogOpen(true);
                            },
                            TestId: "tool-tooldetail-active-state-button",
                            Variant: "default",
                        }))), delay(() => {
                            let Label_8;
                            return append(singleton(createElement(TextButton, (Label_8 = t("general.retired"), {
                                ComponentState: "enabled",
                                Icon: Shipment_Delivery_Fail(),
                                Label: Label_8,
                                OnClick: () => {
                                    setIsRetiredStateDialogOpen(true);
                                },
                                TestId: "tool-tooldetail-retired-state-button",
                                Variant: "default",
                            }))), delay(() => {
                                let Label_9;
                                return singleton(createElement(TextButton, (Label_9 = t("general.lost"), {
                                    ComponentState: "enabled",
                                    Icon: Shipment_Search(),
                                    Label: Label_9,
                                    OnClick: () => {
                                        setIsLostStateDialogOpen(true);
                                    },
                                    TestId: "tool-tooldetail-lost-state-button",
                                    Variant: "default",
                                })));
                            }));
                        })) : ((matchValue_4 === "lost") ? singleton(createElement(TextButton, (Label_10 = t("tool.state_active"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Check(),
                            Label: Label_10,
                            OnClick: () => {
                                setIsActiveStateDialogOpen(true);
                            },
                            TestId: "tool-tooldetail-active-state-button",
                            Variant: "default",
                        }))) : ((matchValue_4 === "retired") ? singleton(createElement(TextButton, (Label_11 = t("tool.state_active"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Check(),
                            Label: Label_11,
                            OnClick: () => {
                                setIsActiveStateDialogOpen(true);
                            },
                            TestId: "tool-tooldetail-active-state-button",
                            Variant: "default",
                        }))) : append(singleton(createElement(TextButton, (Label_4 = t("tool.state_in_maintenance"), {
                            ComponentState: "enabled",
                            Icon: Shipment_Time(),
                            Label: Label_4,
                            OnClick: () => {
                                setIsInMaintenanceStateDialogOpen(true);
                            },
                            TestId: "tool-tooldetail-in-maintenance-state-button",
                            Variant: "default",
                        }))), delay(() => {
                            let Label_5;
                            return append(singleton(createElement(TextButton, (Label_5 = t("general.reject"), {
                                ComponentState: "enabled",
                                Icon: Shipment_Delivery_Fail(),
                                Label: Label_5,
                                OnClick: () => {
                                    setIsRetiredStateDialogOpen(true);
                                },
                                TestId: "tool-tooldetail-retired-state-button",
                                Variant: "default",
                            }))), delay(() => {
                                let Label_6;
                                return singleton(createElement(TextButton, (Label_6 = t("general.report_lost"), {
                                    ComponentState: "enabled",
                                    Icon: Shipment_Search(),
                                    Label: Label_6,
                                    OnClick: () => {
                                        setIsLostStateDialogOpen(true);
                                    },
                                    TestId: "tool-tooldetail-lost-state-button",
                                    Variant: "default",
                                })));
                            }));
                        }))));
                    }
                }
            })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        }))))))))));
    })))))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

function MasterDataSection(props) {
    let elems_4, elems_3, elems_1, Title, elems, elems_2, Title_2;
    const t = useTranslation()[0];
    return createElement("div", createObj(singleton_1((elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "gap-20", "h-30"])], (elems_3 = [createElement("div", createObj(singleton_1((elems_1 = [createElement(PropertyList, (Title = t("general.master_data"), {
        Properties: ofArray([{
            Label: t("tool.manufacturer"),
            TestId: "master-data-manufacturer",
            Value: props.Set.MasterData.Manufacturer,
        }, {
            Label: t("tool.model"),
            TestId: "master-data-model",
            Value: props.Set.MasterData.Model,
        }, {
            Label: t("tool.serial_number"),
            TestId: "master-data-serial-number",
            Value: props.Set.MasterData.SerialNumber,
        }, {
            Label: t("tool.displayname"),
            TestId: "master-data-display-name",
            Value: props.Set.MasterData.Name,
        }, {
            Label: t("tool.inventory_number"),
            TestId: "master-data-inventory-number",
            Value: props.Set.MasterData.InventoryNumber,
        }, {
            Label: t("general.comment"),
            TestId: "master-data-comment",
            Value: props.Set.MasterData.Comment,
        }]),
        Title: unwrap(Title),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["mt-8"])], (elems = [createElement(CustomPropertiesPropertyList, {
        CustomProperties: props.Set.MasterData.CustomProperties,
        Title: t("tool.custom_properties"),
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])))), createElement("div", createObj(singleton_1((elems_2 = [createElement(PropertyList, (Title_2 = t("tool.commercial_data"), {
        Properties: ofArray([{
            Label: t("general.supplier"),
            TestId: "commercial-data-supplier",
            Value: props.Set.MasterData.CommercialData.Supplier,
        }, {
            Label: t("general.supplier_article_number"),
            TestId: "commercial-data-supplier-article-number",
            Value: props.Set.MasterData.CommercialData.SupplierArticleNumber,
        }, {
            Label: t("general.purchase_price"),
            TestId: "commercial-data-purchase-price",
            Value: price(props.Set.MasterData.CommercialData.PurchasePrice),
        }, {
            Label: t("general.purchase_date"),
            TestId: "commercial-data-purchase-date",
            Value: purchaseDate(props.Set.MasterData.CommercialData.PurchaseDate),
        }, {
            Label: t("general.gurantee_date"),
            TestId: "commercial-data-guarantee-date",
            Value: purchaseDate(props.Set.MasterData.CommercialData.WarrantyDate),
        }, {
            Label: t("general.comment"),
            TestId: "commercial-data-comment",
            Value: props.Set.MasterData.CommercialData.Comment,
        }]),
        Title: unwrap(Title_2),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))]))));
}

function BarcodeSection(props) {
    let elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(QrCodeTable, {
        OnAssignQrCode: (arg) => {
            props.Dispatch(new Msg(3, [arg]));
        },
        OnDeleteQrCodes: (arg_1) => {
            props.Dispatch(new Msg(4, [arg_1]));
        },
        QrCodes: (length(props.QRCodeIds) > 5) ? take(5, props.QRCodeIds) : props.QRCodeIds,
        UserData: props.UserData,
    })), delay(() => {
        let elems;
        return (length(props.QRCodeIds) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: patternInput[0]("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(QrCodeTable, {
                OnAssignQrCode: (qrCode) => {
                    props.Dispatch(new Msg(3, [qrCode]));
                },
                OnDeleteQrCodes: (qrCode_1) => {
                    props.Dispatch(new Msg(4, [qrCode_1]));
                },
                QrCodes: props.QRCodeIds,
                UserData: props.UserData,
            }),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function DocumentSection(props) {
    let elems_1;
    const patternInput = useTranslation();
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(DocumentTable, {
        Documents: (length(props.Documents) > 5) ? take(5, props.Documents) : props.Documents,
        OnDownloadFile: (tupledArg) => {
            props.Dispatch(new Msg(18, [tupledArg[0], tupledArg[1]]));
        },
        UploadSuccessCallback: () => {
            props.Dispatch(new Msg(17, []));
        },
        UploadType: new UploadType_2(3, [props.SetId]),
        UserData: props.UserData,
    })), delay(() => {
        let elems;
        return (length(props.Documents) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: patternInput[0]("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: createElement(DocumentTable, {
                Documents: props.Documents,
                OnDownloadFile: (tupledArg_1) => {
                    props.Dispatch(new Msg(18, [tupledArg_1[0], tupledArg_1[1]]));
                },
                UploadSuccessCallback: () => {
                    props.Dispatch(new Msg(17, []));
                },
                UploadType: new UploadType_2(3, [props.SetId]),
                UserData: props.UserData,
            }),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ReservationSection(props) {
    const t = useTranslation()[0];
    const now = date(utcNow());
    const patternInput_1 = reactApi.useState(false);
    const setIsReservationDialogOpen = patternInput_1[1];
    const isReservationDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsModalOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(undefined);
    const setSelectedReservation = patternInput_3[1];
    const selectedReservation = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedDeleteReservation = patternInput_4[1];
    const selectedDeleteReservation = patternInput_4[0];
    const reservations = sortByDescending((r) => r.StartDate, props.Set.Planning.Reservations, {
        Compare: compare,
    });
    const xs_2 = toList(delay(() => append(isReservationDialogOpen ? singleton(createElement(OpenCreateReservationDialog, {
        IsOpen: isReservationDialogOpen,
        OnClose: () => {
            setIsReservationDialogOpen(false);
        },
        Set: props.Set,
        SuccessCallback: () => {
            props.Dispatch(new Msg(29, []));
        },
        UserData: props.UserData,
    })) : empty(), delay(() => {
        let matchValue;
        return append((matchValue = selectedReservation, (matchValue == null) ? (empty()) : singleton(createElement(OpenEditReservationDialog, {
            IsOpen: selectedReservation != null,
            OnClose: () => {
                setSelectedReservation(undefined);
            },
            Reservation: matchValue,
            SetId: new SetId_2(parse(props.Set.Id)),
            SuccessCallback: () => {
                props.Dispatch(new Msg(29, []));
            },
            UserData: props.UserData,
        }))), delay(() => {
            let matchValue_1, reservation_1;
            return append((matchValue_1 = selectedDeleteReservation, (matchValue_1 == null) ? (empty()) : ((reservation_1 = matchValue_1, singleton(createElement(OpenDeleteReservationDialog, {
                IsOpen: selectedDeleteReservation != null,
                OnClose: () => {
                    setSelectedDeleteReservation(undefined);
                },
                Reservation: unwrap(selectedDeleteReservation),
                Set: props.Set,
                SuccessCallback: () => {
                    props.Dispatch(new Msg(29, []));
                },
                UserData: props.UserData,
            }))))), delay(() => {
                let elems_1;
                const table = (rows) => {
                    let TableRows, Options;
                    return createElement(Table, (TableRows = toArray(rows), (Options = {
                        BorderStyle: "row",
                        CSVDownload: false,
                        ConfigurableColumns: false,
                        DefaultSortColumn: ["startDate", "asc"],
                        GlobalSearch: false,
                        RowSelection: "singleRow",
                    }, {
                        ColumnDefinitions: [new ColumnDefinition$1("startDate", "", t("general.start_date"), (dto) => (new CellContent(5, [dto.StartDate])), "dateRange", "date"), new ColumnDefinition$1("endDate", "", t("general.end_date"), (dto_1) => (new CellContent(5, [dto_1.EndDate])), "dateRange", "date"), new ColumnDefinition$1("receiverName", "", t("general.receiver"), (dto_2) => (new CellContent(0, [dto_2.ReceiverInformation.ReceiverName])), "none", "text"), new ColumnDefinition$1("reservationName", "", t("general.name"), (dto_3) => (new CellContent(0, [dto_3.Name])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_4) => (new CellContent(0, [dto_4.Description])), "none", "text")],
                        Controls: toList(delay(() => {
                            let matchValue_2;
                            return append((matchValue_2 = props.Set.State, (matchValue_2 === "active") ? append(singleton(new TableControl$1(t("dialog.reservation.btn_title"), Calendar_Add(), (_arg_6) => {
                                setIsReservationDialogOpen(true);
                            }, false, false, "")), delay(() => singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                                const selectedRow = tryHead(rows_1);
                                if (selectedRow == null) {
                                }
                                else {
                                    const planning = selectedRow;
                                    const matchValue_3 = props.UserData.Role;
                                    switch (matchValue_3) {
                                        case "user": {
                                            if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                                setSelectedReservation(planning);
                                            }
                                            break;
                                        }
                                        default:
                                            setSelectedReservation(planning);
                                    }
                                }
                            }, true, false, "")))) : ((matchValue_2 === "lost") ? (empty()) : ((matchValue_2 === "retired") ? (empty()) : append(singleton(new TableControl$1(t("dialog.reservation.btn_title"), Calendar_Add(), (_arg_6) => {
                                setIsReservationDialogOpen(true);
                            }, false, false, "")), delay(() => singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                                const selectedRow = tryHead(rows_1);
                                if (selectedRow == null) {
                                }
                                else {
                                    const planning = selectedRow;
                                    const matchValue_3 = props.UserData.Role;
                                    switch (matchValue_3) {
                                        case "user": {
                                            if (planning.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                                setSelectedReservation(planning);
                                            }
                                            break;
                                        }
                                        default:
                                            setSelectedReservation(planning);
                                    }
                                }
                            }, true, false, ""))))))), delay(() => singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_2) => {
                                const selectedRow_1 = tryHead(rows_2);
                                if (selectedRow_1 == null) {
                                }
                                else {
                                    const planning_1 = selectedRow_1;
                                    if (props.Set.AccessInformation.IsUserResponsible) {
                                        setSelectedDeleteReservation(planning_1);
                                    }
                                    else if (planning_1.ReceiverInformation.ReceiverId === props.UserData.UserId) {
                                        setSelectedDeleteReservation(planning_1);
                                    }
                                }
                            }, true, false, ""))));
                        })),
                        Options: Options,
                        TableRows: TableRows,
                    })));
                };
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(reservations) > 5) ? take(5, reservations) : reservations)), delay(() => {
                    let elems;
                    return (length(reservations) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
                        ComponentState: "enabled",
                        Label: t("general.show_more_data"),
                        OnClick: () => {
                            setIsModalOpen(true);
                        },
                        TestId: "set-reservations-showmoredata-button",
                        Variant: "blueButton",
                    }), createElement(ModalSheet, {
                        CloseButtonTestId: "",
                        Content: table(reservations),
                        Controls: empty_1(),
                        IsModalOpen: patternInput_2[0],
                        OnClose: () => {
                            setIsModalOpen(false);
                        },
                    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
                })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
            }));
        }));
    }))));
    return react.createElement(react.Fragment, {}, ...xs_2);
}

function AppointmentsSection(props) {
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsReminderDialogOpen = patternInput_1[1];
    const isReminderDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(undefined);
    const setSelectedReminderToConfirm = patternInput_2[1];
    const selectedReminderToConfirm = patternInput_2[0];
    const patternInput_3 = reactApi.useState(undefined);
    const setSelectedReminder = patternInput_3[1];
    const selectedReminder = patternInput_3[0];
    const patternInput_4 = reactApi.useState(undefined);
    const setSelectedDeleteReminder = patternInput_4[1];
    const selectedDeleteReminder = patternInput_4[0];
    const now = utcNow();
    const patternInput_5 = reactApi.useState(false);
    const setIsModalOpen = patternInput_5[1];
    const reminders = sortByDescending((r) => r.Date, props.Set.Planning.Reminders, {
        Compare: compare,
    });
    const xs_3 = toList(delay(() => append(isReminderDialogOpen ? singleton(createElement(OpenCreateReminderDialog, {
        IsOpen: isReminderDialogOpen,
        OnClose: () => {
            setIsReminderDialogOpen(false);
        },
        SetId: new SetId_2(parse(props.Set.Id)),
        SuccessCallback: () => {
            props.Dispatch(new Msg(29, []));
        },
    })) : empty(), delay(() => append((selectedReminder != null) ? singleton(createElement(OpenEditReminderDialog, {
        IsOpen: selectedReminder != null,
        OnClose: () => {
            setSelectedReminder(undefined);
        },
        Reminder: unwrap(selectedReminder),
        SetId: new SetId_2(parse(props.Set.Id)),
        SuccessCallback: () => {
            props.Dispatch(new Msg(29, []));
        },
    })) : empty(), delay(() => append((selectedDeleteReminder != null) ? singleton(createElement(OpenDeleteReminderDialog, {
        IsOpen: selectedDeleteReminder != null,
        OnClose: () => {
            setSelectedDeleteReminder(undefined);
        },
        Reminder: unwrap(selectedDeleteReminder),
        SetId: new SetId_2(parse(props.Set.Id)),
        SuccessCallback: () => {
            props.Dispatch(new Msg(29, []));
        },
    })) : empty(), delay(() => {
        let matchValue;
        return append((matchValue = selectedReminderToConfirm, (matchValue == null) ? (empty()) : singleton(createElement(OpenConfirmReminderDialog, {
            IsOpen: selectedReminderToConfirm != null,
            OnClose: () => {
                setSelectedReminderToConfirm(undefined);
            },
            Reminder: matchValue,
            SetId: new SetId_2(parse(props.Set.Id)),
            SuccessCallback: () => {
                props.Dispatch(new Msg(29, []));
            },
        }))), delay(() => {
            let elems_2;
            const table = (rows) => {
                let TableRows, Options;
                return createElement(Table, (TableRows = toArray(rows), (Options = {
                    BorderStyle: "row",
                    CSVDownload: false,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["startDate", "asc"],
                    GlobalSearch: false,
                    RowSelection: "singleRow",
                }, {
                    ColumnDefinitions: [new ColumnDefinition$1("date", "", t("general.date"), (dto) => (new CellContent(5, [dto.Date])), "dateRange", "date"), new ColumnDefinition$1("state", "", t("general.done"), (reminderDate) => {
                        let elems;
                        return reminderDate.IsConfirmed ? (new CellContent(1, [createElement(ColoredDot, {
                            Color: "green",
                        })])) : ((compare(reminderDate.Date, now) > 0) ? (new CellContent(1, [createElement(ColoredDot, {
                            Color: "orange",
                        })])) : (new CellContent(1, [createElement("div", createObj(ofArray([["className", join(" ", ["text-red", "h-5", "w-5"])], (elems = [Calendar_Warning()], ["children", reactApi.Children.toArray(Array.from(elems))])])))])));
                    }, "none", "text"), new ColumnDefinition$1("name", "", t("general.name"), (dto_1) => (new CellContent(0, [dto_1.Title])), "none", "text"), new ColumnDefinition$1("description", "", t("general.description"), (dto_2) => (new CellContent(0, [dto_2.Description])), "none", "text")],
                    Controls: toList(delay(() => {
                        let matchValue_1, matchValue_2;
                        return append((matchValue_1 = props.Set.State, (matchValue_1 === "active") ? append((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id")) : ((matchValue_2 === "toolManager") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id")) : ((matchValue_2 === "user") ? (empty()) : singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id"))))), delay(() => append(singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_3 = props.UserData.Role;
                                switch (matchValue_3) {
                                    case "user": {
                                        break;
                                    }
                                    default:
                                        setSelectedReminder(planning);
                                }
                            }
                        }, true, false, "")), delay(() => (props.Set.AccessInformation.IsUserResponsible ? singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                            const selectedRow_1 = tryHead(rows_2);
                            if (selectedRow_1 != null) {
                                setSelectedReminderToConfirm(selectedRow_1);
                            }
                        }, false, false, "")) : empty()))))) : ((matchValue_1 === "lost") ? (empty()) : ((matchValue_1 === "retired") ? (empty()) : append((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id")) : ((matchValue_2 === "toolManager") ? singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id")) : ((matchValue_2 === "user") ? (empty()) : singleton(new TableControl$1(t("dialog.reminder.btn_title"), Calendar_Add(), (_arg_8) => {
                            setIsReminderDialogOpen(true);
                        }, false, false, "tool-new-reminder-test-id"))))), delay(() => append(singleton(new TableControl$1(t("general.edit"), Pencil(), (rows_1) => {
                            const selectedRow = tryHead(rows_1);
                            if (selectedRow == null) {
                            }
                            else {
                                const planning = selectedRow;
                                const matchValue_3 = props.UserData.Role;
                                switch (matchValue_3) {
                                    case "user": {
                                        break;
                                    }
                                    default:
                                        setSelectedReminder(planning);
                                }
                            }
                        }, true, false, "")), delay(() => (props.Set.AccessInformation.IsUserResponsible ? singleton(new TableControl$1(t("general.mark_as_done"), Check(), (rows_2) => {
                            const selectedRow_1 = tryHead(rows_2);
                            if (selectedRow_1 != null) {
                                setSelectedReminderToConfirm(selectedRow_1);
                            }
                        }, false, false, "")) : empty())))))))), delay(() => singleton(new TableControl$1(t("general.delete"), Recycle_Bin_1(), (rows_3) => {
                            const selectedRow_3 = tryHead(rows_3);
                            if (selectedRow_3 == null) {
                            }
                            else if (props.Set.AccessInformation.IsUserResponsible) {
                                setSelectedDeleteReminder(selectedRow_3);
                            }
                        }, true, false, ""))));
                    })),
                    Options: Options,
                    TableRows: TableRows,
                })));
            };
            return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_2 = toList(delay(() => append(singleton(table((length(reminders) > 5) ? take(5, reminders) : reminders)), delay(() => {
                let elems_1;
                return (length(reminders) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems_1 = [createElement(Button, {
                    ComponentState: "enabled",
                    Label: t("general.show_more_data"),
                    OnClick: () => {
                        setIsModalOpen(true);
                    },
                    TestId: "set-reminders-showmoredata-button",
                    Variant: "blueButton",
                }), createElement(ModalSheet, {
                    CloseButtonTestId: "",
                    Content: table(reminders),
                    Controls: empty_1(),
                    IsModalOpen: patternInput_5[0],
                    OnClose: () => {
                        setIsModalOpen(false);
                    },
                })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
            })))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        }));
    }))))))));
    return react.createElement(react.Fragment, {}, ...xs_3);
}

function HistorySection(props) {
    let elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const patternInput_2 = reactApi.useState(undefined);
    const setAssignmentProtocolParams = patternInput_2[1];
    const table = (rows) => {
        let TableRows;
        return createElement(Table, (TableRows = toArray(rows), {
            ColumnDefinitions: [new ColumnDefinition$1("timestamp", "", t("general.date"), (dto) => (new CellContent(6, [BusinessLogic_SetEvent__get_Timestamp(dto.Event)])), "none", "dateTime"), new ColumnDefinition$1("eventType", "", t("general.event"), (dto_1) => (new CellContent(0, [t(BusinessLogic_SetEvent__ToTranslationString(dto_1.Event))])), "select", "text"), new ColumnDefinition$1("comment", "", t("general.comment"), (dto_2) => {
                const matchValue = dto_2.Event;
                switch (matchValue.tag) {
                    case 11:
                        return new CellContent(0, [Helper_displayHistoryComment(t(matchValue.fields[0].Event), BusinessLogic_SetEvent__get_Comment(dto_2.Event))]);
                    default:
                        return new CellContent(0, [t(BusinessLogic_SetEvent__get_Comment(dto_2.Event))]);
                }
            }, "none", "text"), new ColumnDefinition$1("receiver", "", t("general.receiver"), (dto_3) => (new CellContent(0, [defaultArg(dto_3.Receiver, "")])), "none", "text"), new ColumnDefinition$1("sender", "", t("general.sender"), (dto_4) => (new CellContent(0, [dto_4.Sender])), "none", "text"), new ColumnDefinition$1("signatureUrl", "", t("general.signature"), (dto_5) => (new CellContent(4, [defaultArg(BusinessLogic_SetEvent__get_SignatureUrl(dto_5.Event), "")])), "none", "image"), new ColumnDefinition$1("storage", "", t("general.storage"), (dto_6) => (new CellContent(0, [defaultArg(dto_6.Storage, "")])), "none", "text"), new ColumnDefinition$1("action", "", "", (dto_7) => {
                const matchValue_1 = dto_7.Event;
                switch (matchValue_1.tag) {
                    case 1:
                        return new CellContent(2, [t("assignment_protocol.open_assignment_protocol"), () => {
                            setAssignmentProtocolParams(new AssignmentProtocolParams(t(BusinessLogic_SetEvent__ToTranslationString(dto_7.Event)), props.SetName, props.InventoryNumber, dto_7.Sender, defaultArg(dto_7.Receiver, ""), dto_7.Storage, "", undefined, undefined, matchValue_1.fields[0].Timestamp));
                        }]);
                    case 2: {
                        const event_2 = matchValue_1.fields[0];
                        return new CellContent(2, [t("assignment_protocol.open_assignment_protocol"), () => {
                            setAssignmentProtocolParams(new AssignmentProtocolParams(t(BusinessLogic_SetEvent__ToTranslationString(dto_7.Event)), props.SetName, props.InventoryNumber, dto_7.Sender, defaultArg(dto_7.Receiver, ""), dto_7.Storage, event_2.Comment, undefined, undefined, event_2.Timestamp));
                        }]);
                    }
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                        return new CellContent(0, [""]);
                    default: {
                        const event = matchValue_1.fields[0];
                        return new CellContent(2, [t("assignment_protocol.open_assignment_protocol"), () => {
                            setAssignmentProtocolParams(new AssignmentProtocolParams(t(BusinessLogic_SetEvent__ToTranslationString(dto_7.Event)), props.SetName, props.InventoryNumber, dto_7.Sender, defaultArg(dto_7.Receiver, ""), dto_7.Storage, event.Comment, undefined, event.SignatureUrl, event.Timestamp));
                        }]);
                    }
                }
            }, "none", "text")],
            Controls: empty_1(),
            Options: {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["timestamp", "desc"],
                GlobalSearch: false,
                RowSelection: "disabled",
            },
            TableRows: TableRows,
        }));
    };
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(createElement(AssignmentProtocolDialog, {
        AssignmentProtocolParams: unwrap(patternInput_2[0]),
        OnClose: () => {
            setAssignmentProtocolParams(undefined);
        },
    })), delay(() => append(singleton(table((length(props.HistoryRows) > 5) ? take(5, props.HistoryRows) : props.HistoryRows)), delay(() => {
        let elems;
        return (length(props.HistoryRows) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: table(props.HistoryRows),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ProblemReportSection(props) {
    let elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsModalOpen = patternInput_1[1];
    const table = (rows) => {
        let TableRows, matchValue_1;
        return createElement(Table, (TableRows = toArray(sortByDescending((r) => equals(r.State, new ProblemReportState(0, [])), rows, {
            Compare: comparePrimitives,
        })), {
            ColumnDefinitions: [new ColumnDefinition$1("timestamp", "", t("tool.problem_report_registered_on"), (dto) => (new CellContent(6, [dto.Timestamp])), "none", "dateTime"), new ColumnDefinition$1("sender", "", t("tool.problem_report_reported_by"), (dto_1) => (new CellContent(0, [dto_1.SenderName])), "none", "text"), new ColumnDefinition$1("comment", "", t("general.message"), (dto_2) => (new CellContent(0, [dto_2.Message])), "none", "text"), new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (dto_3) => (new CellContent(4, [dto_3.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_1) => (new CellContent(0, [row_1.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text"), new ColumnDefinition$1("state", "", t("general.state"), (dto_4) => (new CellContent(1, [(dto_4.State.tag === 0) ? createElement(ColoredDot, {
                Color: "orange",
            }) : createElement(ColoredDot, {
                Color: "green",
            })])), "none", "text")],
            Controls: empty_1(),
            Options: {
                BorderStyle: "row",
                CSVDownload: false,
                ConfigurableColumns: false,
                DefaultSortColumn: ["timestamp", "asc"],
                GlobalSearch: false,
                OnRowClick: unwrap((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? (new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", tool.ToolId]), 1)])) : ((matchValue_1 === "toolManager") ? (new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", tool.ToolId]), 1)])) : ((matchValue_1 === "user") ? undefined : (new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", tool.ToolId]), 1)])))))),
                RowSelection: "disabled",
            },
            TableRows: TableRows,
        }));
    };
    const children = singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-col"])], (elems_1 = toList(delay(() => append(singleton(table((length(props.ProblemReports) > 5) ? take(5, props.ProblemReports) : props.ProblemReports)), delay(() => {
        let elems;
        return (length(props.ProblemReports) > 5) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-center", "mt-4", "mb-4"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("general.show_more_data"),
            OnClick: () => {
                setIsModalOpen(true);
            },
            TestId: "tool-selectcard-tooldetail-showmoredata-button",
            Variant: "blueButton",
        }), createElement(ModalSheet, {
            CloseButtonTestId: "",
            Content: table(props.ProblemReports),
            Controls: empty_1(),
            IsModalOpen: patternInput_1[0],
            OnClose: () => {
                setIsModalOpen(false);
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))) : empty();
    })))), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function SetDetailsArea(props) {
    let elems_3, elems_1, elems, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"])], (elems_3 = [createElement(SetDetailsActions, {
        Dispatch: props.Dispatch,
        Set: props.Set,
        SetCurrentSelectedLink: props.SetCurrentSelectedLink,
        UserData: props.UserData,
        UserId: props.UserData.UserId,
    }), createElement(NotificationArea, {
        NotificationToolType: new NotificationToolType(2, []),
        Notifications: props.Notifications,
        OnUpdate: () => {
            props.Dispatch(new Msg(29, []));
        },
        UserData: props.UserData,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "mb-8"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["mr-16"])], (elems = [createElement(ImageWidget, {
        CanEditImage: props.Set.AccessInformation.IsUserResponsible,
        ImageUrl: props.Set.ImageUrl,
        OnDeleteImage: () => {
            props.Dispatch(new Msg(9, []));
        },
        OnUploadImage: (file) => {
            props.Dispatch(new Msg(8, [file]));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(SetShortDetails, {
        CustomerConfiguration: props.CustomerConfiguration,
        Dispatch: props.Dispatch,
        QuickOverviewDialog: props.QuickOverviewDialog,
        Set: props.Set,
        Storages: props.Storages,
        UserConfiguration: props.UserConfiguration,
        UserData: props.UserData,
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "gap-5", "mt-3"])], (elems_2 = [createElement(AppointmentBox, {
        Set: props.Set,
        SetCurrentSelectedLink: props.SetCurrentSelectedLink,
    }), createElement(ReservationBox, {
        Set: props.Set,
        SetCurrentSelectedLink: props.SetCurrentSelectedLink,
    }), createElement(NotificationsBox, {
        ProblemReports: props.ProblemReports,
        SetCurrentSelectedLink: props.SetCurrentSelectedLink,
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

function NoticeSection(props) {
    let elems;
    const messages = map((n) => (new Message_1(new NoticeId(parse(n.Id)), n.Description, undefined, n.CreatedAt, n.CreatorInformation.Name, n.CreatorInformation.Id, n.IsVisibleForUser)), props.Set.Notices);
    const patternInput = reactApi.useState(true);
    const setIsVisibleForUser = patternInput[1];
    const isVisibleForUser = patternInput[0];
    const patternInput_1 = reactApi.useState(undefined);
    const setSelectedNoticeIdToDelete = patternInput_1[1];
    const selectedNoticeIdToDelete = patternInput_1[0];
    return createElement("div", createObj(singleton_1((elems = toList(delay(() => {
        let matchValue;
        return append((matchValue = selectedNoticeIdToDelete, (matchValue == null) ? (empty()) : singleton(createElement(OpenNoticeDeleteDialog, {
            IsOpen: selectedNoticeIdToDelete != null,
            NoticeId: matchValue,
            OnClose: () => {
                setSelectedNoticeIdToDelete(undefined);
            },
            SetId: new SetId_2(parse(props.Set.Id)),
            SuccessCallback: () => {
                props.Dispatch(new Msg(29, []));
            },
        }))), delay(() => singleton(createElement(ChatWidget, {
            CustomerConfiguration: props.CustomerConfiguration,
            MaxContentHeigth: "max-h-96",
            Messages: messages,
            OnDeleteClicked: (message_1) => {
                setSelectedNoticeIdToDelete(message_1.Id);
            },
            OnEditClicked: (message) => {
                props.Dispatch(new Msg(7, [message]));
            },
            OnSubmit: (msg) => {
                props.Dispatch(new Msg(5, [msg, isVisibleForUser]));
                setIsVisibleForUser(true);
            },
            UserData: props.UserData,
            UserId: props.UserId,
            UserVisibility: {
                IsVisibleForUserChecked: isVisibleForUser,
                OnVisibleForUserChecked: setIsVisibleForUser,
            },
        }))));
    })), ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

export function ToolTable(props) {
    let Title, TableRows, Options;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(empty_1());
    const setSelectedToolIds = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsCreateToolDialogOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(false);
    const setIsAddToolModalOpen = patternInput_3[1];
    const patternInput_4 = reactApi.useState(false);
    const setIsRemoveToolIdDialogOpen = patternInput_4[1];
    const xs_1 = [createElement(NewToolModalView, {
        IsOpen: patternInput_2[0],
        IsSelectedStorageIdNeeded: true,
        OnClose: () => {
            setIsCreateToolDialogOpen(false);
        },
        OnSuccessCallback: (toolId) => {
            setIsCreateToolDialogOpen(false);
            props.OnAssignToolId(singleton_1(toolId));
            props.FetchTools();
        },
        StorageId: unwrap(map_1((storageId) => (new StorageId_1(parse(storageId))), props.SelectedStorageId)),
        UserData: props.UserData,
    }), createElement(SelectToolModal, {
        IsOpen: patternInput_3[0],
        OnAddToolIds: (toolIds) => {
            props.OnAssignToolId(toolIds);
            setIsAddToolModalOpen(false);
        },
        OnClose: () => {
            setIsAddToolModalOpen(false);
        },
        SelectedStorageId: unwrap(props.SelectedStorageId),
        Tools: filter((tool) => !contains(tool.ToolId, map((tool_1) => tool_1.ToolId, props.AssignedTools), {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        }), props.Tools),
    }), createElement(ConfirmationDialog, (Title = t("set.tool_remove_dialog.title"), {
        Description: t("set.tool_remove_dialog.description"),
        IsOpen: patternInput_4[0],
        OnClose: () => {
            setIsRemoveToolIdDialogOpen(false);
            setSelectedToolIds(empty_1());
        },
        OnConfirm: () => {
            props.OnRemoveToolId(patternInput_1[0]);
            setIsRemoveToolIdDialogOpen(false);
            setSelectedToolIds(empty_1());
        },
        Title: Title,
    })), createElement(Table, (TableRows = toArray(props.AssignedTools), (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        OnRowClick: new OnTableRowClick$1(1, [(tool_2) => RouterModule_encodeParts(ofArray(["tools", tool_2.ToolId]), 1)]),
        RowSelection: "multiRow",
    }, {
        ColumnDefinitions: [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (dto) => (new CellContent(4, [dto.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.DisplayName])), "text", "text"), new ColumnDefinition$1("state", "", t("general.state"), (row_1) => (new CellContent(0, [t(ToolState__ToI18nString(row_1.State))])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_5) => (new CellContent(0, [row_5.SerialNumber])), "text", "text")],
        Controls: props.Set.AccessInformation.IsUserResponsible ? ofArray([new TableControl$1(t("general.add"), Shipment_Add(), (_arg_4) => {
            setIsAddToolModalOpen(true);
        }, false, false, "add-tool"), new TableControl$1(t("tool.create_new_tool"), Shipment_Add(), (_arg_5) => {
            setIsCreateToolDialogOpen(true);
        }, false, false, "create-tool-tool-table"), new TableControl$1(t("general.remove"), Recycle_Bin_1(), (tools) => {
            setSelectedToolIds(map((tool_3) => tool_3.ToolId, tools));
            setIsRemoveToolIdDialogOpen(true);
        }, true, false, "")]) : empty_1(),
        Options: Options,
        TableRows: TableRows,
    })))];
    return react.createElement(react.Fragment, {}, ...xs_1);
}

function SetDetail(props) {
    let elems_14, elems_13, elems, Title, SubTitle, matchValue, matchValue_1, elems_1, elems_12;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState("overview-section-id");
    const setCurrentSelectedLink = patternInput_1[1];
    const patternInput_2 = reactApi.useState(false);
    const setIsDeleteDialogOpen = patternInput_2[1];
    return createElement("div", createObj(ofArray([["className", "flex grow flex-col"], (elems_14 = [createElement(DeleteDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsDeleteDialogOpen(false);
        },
        SetId: new SetId_2(parse(props.Set.Id)),
        SuccessCallback: () => {
            props.Dispatch(new Msg(33, []));
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-[10rem,min(calc(100vw-16rem-2px-28px-11rem),80rem)]", "print:flex", "print:flex-col"])], (elems_13 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems = [createElement(DetailViewHeader, (Title = ((props.Set.MasterData.Name !== "") ? props.Set.MasterData.Name : (`${props.Set.MasterData.Manufacturer} ${props.Set.MasterData.Model}`)), (SubTitle = ((props.Set.MasterData.Name !== "") ? (`${props.Set.MasterData.Manufacturer} ${props.Set.MasterData.Model}`) : undefined), {
        Badges: mapIndexed((badgeIndex, category) => createElement(Badge, {
            Color: category.ColorHex,
            Label: category.Name,
            TestId: `${badgeIndex}-${category}-badge-id`,
        }), props.Set.AssignedLabel),
        OnBack: () => {
            RouterModule_nav(singleton_1("tools" + RouterModule_encodeQueryString(getQueryParams(undefined))), 1, 1);
        },
        OnDelete: unwrap((props.UserData.Role === "administrator") ? (() => {
            setIsDeleteDialogOpen(true);
        }) : undefined),
        OnDuplicate: unwrap((matchValue = props.UserData.Role, (matchValue === "system") ? (() => {
            RouterModule_nav(ofArray(["sets", props.Set.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue === "toolManager") ? (() => {
            RouterModule_nav(ofArray(["sets", props.Set.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue === "user") ? undefined : (() => {
            RouterModule_nav(ofArray(["sets", props.Set.Id, "copy" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }))))),
        OnEdit: unwrap((matchValue_1 = props.UserData.Role, (matchValue_1 === "system") ? (() => {
            RouterModule_nav(ofArray(["sets", props.Set.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_1 === "toolManager") ? (() => {
            RouterModule_nav(ofArray(["sets", props.Set.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }) : ((matchValue_1 === "user") ? undefined : (() => {
            RouterModule_nav(ofArray(["sets", props.Set.Id, "edit" + RouterModule_encodeQueryString(getQueryParams(undefined))]), 1, 1);
        }))))),
        SubTitle: unwrap(SubTitle),
        Title: Title,
    })))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["print:hidden"])], (elems_1 = [createElement(AnchorMenu, {
        MenuItems: toList(delay(() => append(singleton({
            AnchorId: "overview-section-id",
            DisplayName: t("general.overview"),
        }), delay(() => append(singleton({
            AnchorId: "master-data-section-id",
            DisplayName: t("general.master_data"),
        }), delay(() => append(singleton({
            AnchorId: "tools-section-id",
            DisplayName: t("navbar.tools"),
        }), delay(() => append(singleton({
            AnchorId: "documents-section-id",
            DisplayName: t("general.documents"),
        }), delay(() => append(singleton({
            AnchorId: "appointments-section-id",
            DisplayName: t("general.appointments"),
        }), delay(() => append(singleton({
            AnchorId: "reservations-section-id",
            DisplayName: t("navbar.reservation"),
        }), delay(() => append(singleton({
            AnchorId: "notices-section-id",
            DisplayName: t("notice.notices"),
        }), delay(() => {
            let matchValue_2;
            return append((matchValue_2 = props.UserData.Role, (matchValue_2 === "system") ? singleton({
                AnchorId: "problem-report-section-id",
                DisplayName: t("tool.reported_problems"),
            }) : ((matchValue_2 === "toolManager") ? singleton({
                AnchorId: "problem-report-section-id",
                DisplayName: t("tool.reported_problems"),
            }) : ((matchValue_2 === "user") ? (empty()) : singleton({
                AnchorId: "problem-report-section-id",
                DisplayName: t("tool.reported_problems"),
            })))), delay(() => append(singleton({
                AnchorId: "barcodes-section-id",
                DisplayName: t("general.qr_code"),
            }), delay(() => {
                const matchValue_3 = props.UserData.Role;
                switch (matchValue_3) {
                    case "user": {
                        return empty();
                    }
                    default:
                        return singleton({
                            AnchorId: "history-section-id",
                            DisplayName: t("general.history"),
                        });
                }
            }))));
        })))))))))))))))),
        SelectedAnchorId: patternInput_1[0],
        SetSelectedAnchorId: setCurrentSelectedLink,
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(singleton_1((elems_12 = toList(delay(() => {
        let elems_2;
        return append(singleton(createElement("section", createObj(ofArray([["id", "overview-section-id"], (elems_2 = [createElement(SetDetailsArea, {
            CustomerConfiguration: props.CustomerConfiguration,
            Dispatch: props.Dispatch,
            Notifications: props.Notifications,
            ProblemReports: props.ProblemReports,
            QuickOverviewDialog: props.QuickOverviewDialog,
            Set: props.Set,
            SetCurrentSelectedLink: setCurrentSelectedLink,
            Storages: props.Storages,
            UserConfiguration: props.UserConfiguration,
            UserData: props.UserData,
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
            let elems_3, Title_1;
            return append(singleton(createElement("section", createObj(ofArray([["id", "master-data-section-id"], (elems_3 = [createElement(SectionContainer, (Title_1 = t("general.master_data"), {
                Content: createElement(MasterDataSection, {
                    Customer: props.UserData.Customer,
                    Set: props.Set,
                }),
                Title: unwrap(Title_1),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                let elems_4, Title_2;
                return append(singleton(createElement("section", createObj(ofArray([["id", "tools-section-id"], (elems_4 = [createElement(TableContainer, (Title_2 = t("navbar.tools"), {
                    Content: createElement(ToolTable, {
                        AssignedTools: props.Set.AssignedTools,
                        FetchTools: () => {
                            props.Dispatch(new Msg(30, []));
                        },
                        OnAssignToolId: (toolIds) => {
                            props.Dispatch(new Msg(13, [toolIds]));
                        },
                        OnRemoveToolId: (toolIds_1) => {
                            props.Dispatch(new Msg(14, [toolIds_1]));
                        },
                        SelectedStorageId: props.Set.LocationInformation.StorageId,
                        Set: props.Set,
                        Tools: props.Tools,
                        UserData: props.UserData,
                    }),
                    Title: unwrap(Title_2),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                    let elems_5, Title_3;
                    return append(singleton(createElement("section", createObj(ofArray([["id", "documents-section-id"], (elems_5 = [createElement(TableContainer, (Title_3 = t("general.documents"), {
                        Content: createElement(DocumentSection, {
                            Dispatch: props.Dispatch,
                            Documents: props.Documents,
                            IsVisibleForUser: props.Set.AccessInformation.IsUserResponsible,
                            SetId: new SetId_2(parse(props.Set.Id)),
                            UserData: props.UserData,
                        }),
                        Title: unwrap(Title_3),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                        let elems_6, Title_4;
                        return append(singleton(createElement("section", createObj(ofArray([["id", "appointments-section-id"], (elems_6 = [createElement(TableContainer, (Title_4 = t("general.appointments"), {
                            Content: createElement(AppointmentsSection, {
                                Dispatch: props.Dispatch,
                                Set: props.Set,
                                UserData: props.UserData,
                            }),
                            Title: unwrap(Title_4),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => {
                            let elems_7, Title_5;
                            return append(singleton(createElement("section", createObj(ofArray([["id", "reservations-section-id"], (elems_7 = [createElement(TableContainer, (Title_5 = t("navbar.reservation"), {
                                Content: createElement(ReservationSection, {
                                    Dispatch: props.Dispatch,
                                    Set: props.Set,
                                    UserData: props.UserData,
                                }),
                                Title: unwrap(Title_5),
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))), delay(() => {
                                let elems_8, Title_6;
                                return append(singleton(createElement("section", createObj(ofArray([["id", "notices-section-id"], (elems_8 = [createElement(SectionContainer, (Title_6 = t("notice.notices"), {
                                    Content: createElement(NoticeSection, {
                                        CustomerConfiguration: props.CustomerConfiguration,
                                        Dispatch: props.Dispatch,
                                        Set: props.Set,
                                        UserData: props.UserData,
                                        UserId: props.UserData.UserId,
                                    }),
                                    Title: unwrap(Title_6),
                                }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
                                    let matchValue_4, elems_9, Title_7;
                                    return append((matchValue_4 = props.UserData.Role, (matchValue_4 === "system") ? singleton(createElement("section", createObj(ofArray([["id", "problem-report-section-id"], (elems_9 = [createElement(TableContainer, (Title_7 = t("tool.reported_problems"), {
                                        Content: createElement(ProblemReportSection, {
                                            Dispatch: props.Dispatch,
                                            IsVisibleForUser: props.Set.AccessInformation.IsUserResponsible,
                                            ProblemReports: props.ProblemReports,
                                            Set: props.Set,
                                            UserData: props.UserData,
                                            UserId: props.UserData.UserId,
                                        }),
                                        Title: unwrap(Title_7),
                                    }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))) : ((matchValue_4 === "toolManager") ? singleton(createElement("section", createObj(ofArray([["id", "problem-report-section-id"], (elems_9 = [createElement(TableContainer, (Title_7 = t("tool.reported_problems"), {
                                        Content: createElement(ProblemReportSection, {
                                            Dispatch: props.Dispatch,
                                            IsVisibleForUser: props.Set.AccessInformation.IsUserResponsible,
                                            ProblemReports: props.ProblemReports,
                                            Set: props.Set,
                                            UserData: props.UserData,
                                            UserId: props.UserData.UserId,
                                        }),
                                        Title: unwrap(Title_7),
                                    }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))) : ((matchValue_4 === "user") ? (empty()) : singleton(createElement("section", createObj(ofArray([["id", "problem-report-section-id"], (elems_9 = [createElement(TableContainer, (Title_7 = t("tool.reported_problems"), {
                                        Content: createElement(ProblemReportSection, {
                                            Dispatch: props.Dispatch,
                                            IsVisibleForUser: props.Set.AccessInformation.IsUserResponsible,
                                            ProblemReports: props.ProblemReports,
                                            Set: props.Set,
                                            UserData: props.UserData,
                                            UserId: props.UserData.UserId,
                                        }),
                                        Title: unwrap(Title_7),
                                    }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))))))), delay(() => {
                                        let elems_10, Title_8;
                                        return append(singleton(createElement("section", createObj(ofArray([["id", "barcodes-section-id"], (elems_10 = [createElement(TableContainer, (Title_8 = t("general.qr_code"), {
                                            Content: createElement(BarcodeSection, {
                                                Dispatch: props.Dispatch,
                                                QRCodeIds: props.Set.QRCodeIds,
                                                UserData: props.UserData,
                                            }),
                                            Title: unwrap(Title_8),
                                        }))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))), delay(() => {
                                            let elems_11, Title_9;
                                            const matchValue_5 = props.UserData.Role;
                                            switch (matchValue_5) {
                                                case "user": {
                                                    return empty();
                                                }
                                                default:
                                                    return singleton(createElement("section", createObj(ofArray([["id", "history-section-id"], (elems_11 = [createElement(TableContainer, (Title_9 = t("general.history"), {
                                                        Content: createElement(HistorySection, {
                                                            HistoryRows: props.Set.Events,
                                                            InventoryNumber: props.Set.MasterData.InventoryNumber,
                                                            SetName: DataTransfer_DetailSet__ReadableDisplayName(props.Set),
                                                        }),
                                                        Title: unwrap(Title_9),
                                                    }))], ["children", reactApi.Children.toArray(Array.from(elems_11))])]))));
                                            }
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_12))]))))], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", reactApi.Children.toArray(Array.from(elems_14))])])));
}

export function SetDetailView(props) {
    let patternInput;
    const init = initViewSet(props.SetId, props.UserData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const patternInput_1 = useTranslation();
    const matchValue = state_1.Set;
    const matchValue_1 = state_1.UserConfiguration;
    const matchValue_2 = state_1.CustomerConfiguration;
    const matchValue_3 = state_1.Storages;
    const matchValue_4 = state_1.Documents;
    const matchValue_5 = state_1.Tools;
    const matchValue_6 = state_1.ProblemReports;
    const matchValue_7 = state_1.Notifications;
    let matchResult, customerConfiguration, documents, notifications, problemReports, set$, storages, tools, userConfiguration;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_2.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_3.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_4.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_5.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_6.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_7.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        customerConfiguration = matchValue_2.fields[0];
        documents = matchValue_4.fields[0];
        notifications = matchValue_7.fields[0];
        problemReports = matchValue_6.fields[0];
        set$ = matchValue.fields[0];
        storages = matchValue_3.fields[0];
        tools = matchValue_5.fields[0];
        userConfiguration = matchValue_1.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(SetDetail, {
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                Documents: documents,
                Notifications: notifications,
                ProblemReports: problemReports,
                QuickOverviewDialog: state_1.QuickOverviewDialog,
                Set: set$,
                Storages: storages,
                Tools: tools,
                UserConfiguration: userConfiguration,
                UserData: state_1.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

