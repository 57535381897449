import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { anonRecord_type, bool_type, list_type, union_type, record_type, class_type, option_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { SetReservationId__get_unwrap, DocumentDetail_$reflection, AdditionalReminderDefinition_$reflection, CustomIntervalDefinition_$reflection, ReminderInterval_$reflection, CommercialDataFormDto_$reflection, Document$_$reflection, LabelId_$reflection, ToolId_$reflection, ReminderDate_$reflection, ReservationState_$reflection, ReservationGroupId_$reflection, SetId_$reflection, SetReservationId_$reflection, StorageId_$reflection, CommercialData_$reflection } from "./Shared.js";
import { CustomPropertyValueDto_$reflection, CustomProperty_$reflection } from "./PropertyConfiguration.js";
import { ToolOverviewReservationDto, ProblemReportState_$reflection, Notice_$reflection } from "./Tool.js";
import { ReceiverTypeName_$reflection } from "./User.js";
import { ReservationDto_$reflection } from "./Planning.js";

export class BusinessLogic_StorageToReceiverAssignment extends Record {
    constructor(ReceiverId, SenderId, StorageId, Comment$, SignatureUrl, PlannedReturnDate, Timestamp) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.SignatureUrl = SignatureUrl;
        this.PlannedReturnDate = PlannedReturnDate;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_StorageToReceiverAssignment_$reflection() {
    return record_type("Shared.Set.BusinessLogic.StorageToReceiverAssignment", [], BusinessLogic_StorageToReceiverAssignment, () => [["ReceiverId", string_type], ["SenderId", string_type], ["StorageId", string_type], ["Comment", string_type], ["SignatureUrl", option_type(string_type)], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_UserToUserAssignment extends Record {
    constructor(ReceiverId, SenderId, Timestamp) {
        super();
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_UserToUserAssignment_$reflection() {
    return record_type("Shared.Set.BusinessLogic.UserToUserAssignment", [], BusinessLogic_UserToUserAssignment, () => [["ReceiverId", string_type], ["SenderId", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_ReturnToStorage extends Record {
    constructor(SenderId, ReceiverId, StorageId, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_ReturnToStorage_$reflection() {
    return record_type("Shared.Set.BusinessLogic.ReturnToStorage", [], BusinessLogic_ReturnToStorage, () => [["SenderId", string_type], ["ReceiverId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_ProblemReported extends Record {
    constructor(SenderId, StorageId, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_ProblemReported_$reflection() {
    return record_type("Shared.Set.BusinessLogic.ProblemReported", [], BusinessLogic_ProblemReported, () => [["SenderId", string_type], ["StorageId", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_UpdatedStorage extends Record {
    constructor(SenderId, StorageId, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_UpdatedStorage_$reflection() {
    return record_type("Shared.Set.BusinessLogic.UpdatedStorage", [], BusinessLogic_UpdatedStorage, () => [["SenderId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_InMaintenance extends Record {
    constructor(SenderId, StorageId, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_InMaintenance_$reflection() {
    return record_type("Shared.Set.BusinessLogic.InMaintenance", [], BusinessLogic_InMaintenance, () => [["SenderId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_Lost extends Record {
    constructor(SenderId, ReceiverId, StorageId, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.ReceiverId = ReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_Lost_$reflection() {
    return record_type("Shared.Set.BusinessLogic.Lost", [], BusinessLogic_Lost, () => [["SenderId", string_type], ["ReceiverId", option_type(string_type)], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_Retired extends Record {
    constructor(SenderId, StorageId, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_Retired_$reflection() {
    return record_type("Shared.Set.BusinessLogic.Retired", [], BusinessLogic_Retired, () => [["SenderId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_Activate extends Record {
    constructor(SenderId, StorageId, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_Activate_$reflection() {
    return record_type("Shared.Set.BusinessLogic.Activate", [], BusinessLogic_Activate, () => [["SenderId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_ToolAdded extends Record {
    constructor(ToolId, SenderId, Timestamp) {
        super();
        this.ToolId = ToolId;
        this.SenderId = SenderId;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_ToolAdded_$reflection() {
    return record_type("Shared.Set.BusinessLogic.ToolAdded", [], BusinessLogic_ToolAdded, () => [["ToolId", string_type], ["SenderId", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_ToolRemoved extends Record {
    constructor(ToolId, SenderId, Timestamp) {
        super();
        this.ToolId = ToolId;
        this.SenderId = SenderId;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_ToolRemoved_$reflection() {
    return record_type("Shared.Set.BusinessLogic.ToolRemoved", [], BusinessLogic_ToolRemoved, () => [["ToolId", string_type], ["SenderId", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_ChangeInformation extends Record {
    constructor(SenderId, Comment$, Event$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.Comment = Comment$;
        this.Event = Event$;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_ChangeInformation_$reflection() {
    return record_type("Shared.Set.BusinessLogic.ChangeInformation", [], BusinessLogic_ChangeInformation, () => [["SenderId", string_type], ["Comment", string_type], ["Event", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_ReleaseDate extends Record {
    constructor(SenderId, AssignedReceiverId, StorageId, Comment$, Timestamp, ReleaseDate) {
        super();
        this.SenderId = SenderId;
        this.AssignedReceiverId = AssignedReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
        this.ReleaseDate = ReleaseDate;
    }
}

export function BusinessLogic_ReleaseDate_$reflection() {
    return record_type("Shared.Set.BusinessLogic.ReleaseDate", [], BusinessLogic_ReleaseDate, () => [["SenderId", string_type], ["AssignedReceiverId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")], ["ReleaseDate", class_type("System.DateTime")]]);
}

export class BusinessLogic_DeleteReleaseDate extends Record {
    constructor(SenderId, AssignedReceiverId, StorageId, Comment$, Timestamp) {
        super();
        this.SenderId = SenderId;
        this.AssignedReceiverId = AssignedReceiverId;
        this.StorageId = StorageId;
        this.Comment = Comment$;
        this.Timestamp = Timestamp;
    }
}

export function BusinessLogic_DeleteReleaseDate_$reflection() {
    return record_type("Shared.Set.BusinessLogic.DeleteReleaseDate", [], BusinessLogic_DeleteReleaseDate, () => [["SenderId", string_type], ["AssignedReceiverId", string_type], ["StorageId", string_type], ["Comment", string_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class BusinessLogic_SetEvent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StorageToReceiverAssignment", "UserToUserAssignment", "ReturnToStorage", "ProblemReported", "UpdatedStorage", "InMaintenance", "Retired", "Activate", "Lost", "ToolAdded", "ToolRemoved", "ChangeInformation", "ReleaseDate", "DeleteReleaseDate"];
    }
}

export function BusinessLogic_SetEvent_$reflection() {
    return union_type("Shared.Set.BusinessLogic.SetEvent", [], BusinessLogic_SetEvent, () => [[["Item", BusinessLogic_StorageToReceiverAssignment_$reflection()]], [["Item", BusinessLogic_UserToUserAssignment_$reflection()]], [["Item", BusinessLogic_ReturnToStorage_$reflection()]], [["Item", BusinessLogic_ProblemReported_$reflection()]], [["Item", BusinessLogic_UpdatedStorage_$reflection()]], [["Item", BusinessLogic_InMaintenance_$reflection()]], [["Item", BusinessLogic_Retired_$reflection()]], [["Item", BusinessLogic_Activate_$reflection()]], [["Item", BusinessLogic_Lost_$reflection()]], [["Item", BusinessLogic_ToolAdded_$reflection()]], [["Item", BusinessLogic_ToolRemoved_$reflection()]], [["Item", BusinessLogic_ChangeInformation_$reflection()]], [["Item", BusinessLogic_ReleaseDate_$reflection()]], [["Item", BusinessLogic_DeleteReleaseDate_$reflection()]]]);
}

export function BusinessLogic_SetEvent__get_Timestamp(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0].Timestamp;
        case 2:
            return this$.fields[0].Timestamp;
        case 3:
            return this$.fields[0].Timestamp;
        case 4:
            return this$.fields[0].Timestamp;
        case 5:
            return this$.fields[0].Timestamp;
        case 6:
            return this$.fields[0].Timestamp;
        case 7:
            return this$.fields[0].Timestamp;
        case 8:
            return this$.fields[0].Timestamp;
        case 9:
            return this$.fields[0].Timestamp;
        case 10:
            return this$.fields[0].Timestamp;
        case 11:
            return this$.fields[0].Timestamp;
        case 12:
            return this$.fields[0].Timestamp;
        case 13:
            return this$.fields[0].Timestamp;
        default:
            return this$.fields[0].Timestamp;
    }
}

export function BusinessLogic_SetEvent__get_Comment(this$) {
    switch (this$.tag) {
        case 1:
            return "";
        case 2:
            return this$.fields[0].Comment;
        case 3:
            return "";
        case 4:
            return this$.fields[0].Comment;
        case 5:
            return this$.fields[0].Comment;
        case 6:
            return this$.fields[0].Comment;
        case 7:
            return this$.fields[0].Comment;
        case 8:
            return this$.fields[0].Comment;
        case 11:
            return this$.fields[0].Comment;
        case 9:
            return "";
        case 10:
            return "";
        case 12:
            return this$.fields[0].Comment;
        case 13:
            return this$.fields[0].Comment;
        default:
            return this$.fields[0].Comment;
    }
}

export function BusinessLogic_SetEvent__get_SenderId(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0].SenderId;
        case 2:
            return this$.fields[0].SenderId;
        case 3:
            return this$.fields[0].SenderId;
        case 4:
            return this$.fields[0].SenderId;
        case 5:
            return this$.fields[0].SenderId;
        case 6:
            return this$.fields[0].SenderId;
        case 7:
            return this$.fields[0].SenderId;
        case 8:
            return this$.fields[0].SenderId;
        case 9:
            return this$.fields[0].SenderId;
        case 10:
            return this$.fields[0].SenderId;
        case 11:
            return this$.fields[0].SenderId;
        case 12:
            return this$.fields[0].SenderId;
        case 13:
            return this$.fields[0].SenderId;
        default:
            return this$.fields[0].SenderId;
    }
}

export function BusinessLogic_SetEvent__get_ReceiverId(this$) {
    switch (this$.tag) {
        case 1:
            return this$.fields[0].ReceiverId;
        case 2:
            return this$.fields[0].ReceiverId;
        case 3:
            return undefined;
        case 4:
            return undefined;
        case 5:
            return undefined;
        case 6:
            return undefined;
        case 7:
            return undefined;
        case 8:
            return this$.fields[0].ReceiverId;
        case 9:
            return undefined;
        case 10:
            return undefined;
        case 11:
            return undefined;
        case 12:
            return undefined;
        case 13:
            return undefined;
        default:
            return this$.fields[0].ReceiverId;
    }
}

export function BusinessLogic_SetEvent__get_StorageId(this$) {
    switch (this$.tag) {
        case 1:
            return undefined;
        case 2:
            return this$.fields[0].StorageId;
        case 3:
            return this$.fields[0].StorageId;
        case 4:
            return this$.fields[0].StorageId;
        case 5:
            return this$.fields[0].StorageId;
        case 6:
            return this$.fields[0].StorageId;
        case 7:
            return this$.fields[0].StorageId;
        case 8:
            return this$.fields[0].StorageId;
        case 12:
            return this$.fields[0].StorageId;
        case 13:
            return this$.fields[0].StorageId;
        case 9:
            return undefined;
        case 10:
            return undefined;
        case 11:
            return undefined;
        default:
            return this$.fields[0].StorageId;
    }
}

export function BusinessLogic_SetEvent__get_SignatureUrl(this$) {
    switch (this$.tag) {
        case 1:
            return undefined;
        case 2:
            return undefined;
        case 3:
            return undefined;
        case 4:
            return undefined;
        case 5:
            return undefined;
        case 6:
            return undefined;
        case 7:
            return undefined;
        case 8:
            return undefined;
        case 9:
            return undefined;
        case 10:
            return undefined;
        case 11:
            return undefined;
        case 12:
            return undefined;
        case 13:
            return undefined;
        default:
            return this$.fields[0].SignatureUrl;
    }
}

export function BusinessLogic_SetEvent__ToTranslationString(this$) {
    switch (this$.tag) {
        case 1:
            return "event.transfer";
        case 2:
            return "event.return";
        case 3:
            return "event.problem_report";
        case 4:
            return "event.storage_change";
        case 5:
            return "event.in_maintenance";
        case 6:
            return "event.retired";
        case 7:
            return "event.activated";
        case 8:
            return "event.lost";
        case 9:
            return "event.set_tool_added";
        case 10:
            return "event.set_tool_removed";
        case 11:
            return "event.change_information";
        case 12:
            return "event.release_date";
        case 13:
            return "event.delete_release_date";
        default:
            return "event.withdrawal";
    }
}

export class BusinessLogic_MasterData extends Record {
    constructor(Name, Manufacturer, Model, SerialNumber, Comment$, InventoryNumber, CommercialData, CustomProperties) {
        super();
        this.Name = Name;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.Comment = Comment$;
        this.InventoryNumber = InventoryNumber;
        this.CommercialData = CommercialData;
        this.CustomProperties = CustomProperties;
    }
}

export function BusinessLogic_MasterData_$reflection() {
    return record_type("Shared.Set.BusinessLogic.MasterData", [], BusinessLogic_MasterData, () => [["Name", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["Comment", string_type], ["InventoryNumber", string_type], ["CommercialData", CommercialData_$reflection()], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class BusinessLogic_LocationInformation extends Record {
    constructor(StorageId, StorageSpace, AssignedReceiverId, PlannedReturnDate, ReleaseDate) {
        super();
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.AssignedReceiverId = AssignedReceiverId;
        this.PlannedReturnDate = PlannedReturnDate;
        this.ReleaseDate = ReleaseDate;
    }
}

export function BusinessLogic_LocationInformation_$reflection() {
    return record_type("Shared.Set.BusinessLogic.LocationInformation", [], BusinessLogic_LocationInformation, () => [["StorageId", StorageId_$reflection()], ["StorageSpace", string_type], ["AssignedReceiverId", option_type(string_type)], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["ReleaseDate", option_type(class_type("System.DateTime"))]]);
}

export class BusinessLogic_SetReservation extends Record {
    constructor(Id, Name, StartDate, EndDate, ReceiverId, SenderId, SetId, Description, GroupId, State) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.ReceiverId = ReceiverId;
        this.SenderId = SenderId;
        this.SetId = SetId;
        this.Description = Description;
        this.GroupId = GroupId;
        this.State = State;
    }
}

export function BusinessLogic_SetReservation_$reflection() {
    return record_type("Shared.Set.BusinessLogic.SetReservation", [], BusinessLogic_SetReservation, () => [["Id", SetReservationId_$reflection()], ["Name", string_type], ["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["SenderId", string_type], ["SetId", SetId_$reflection()], ["Description", string_type], ["GroupId", option_type(ReservationGroupId_$reflection())], ["State", ReservationState_$reflection()]]);
}

export class BusinessLogic_PlanningInformation extends Record {
    constructor(ReminderDates, Reservations) {
        super();
        this.ReminderDates = ReminderDates;
        this.Reservations = Reservations;
    }
}

export function BusinessLogic_PlanningInformation_$reflection() {
    return record_type("Shared.Set.BusinessLogic.PlanningInformation", [], BusinessLogic_PlanningInformation, () => [["ReminderDates", list_type(ReminderDate_$reflection())], ["Reservations", list_type(BusinessLogic_SetReservation_$reflection())]]);
}

export class BusinessLogic_MetaData extends Record {
    constructor(IsDeleted, CreatedAt, UpdatedAt, CreatedBy, UpdatedBy) {
        super();
        this.IsDeleted = IsDeleted;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.CreatedBy = CreatedBy;
        this.UpdatedBy = UpdatedBy;
    }
}

export function BusinessLogic_MetaData_$reflection() {
    return record_type("Shared.Set.BusinessLogic.MetaData", [], BusinessLogic_MetaData, () => [["IsDeleted", bool_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["CreatedBy", string_type], ["UpdatedBy", string_type]]);
}

export class BusinessLogic_Set extends Record {
    constructor(Id, MasterData, LocationInformation, AssignedToolIds, QRCodeIds, ImageUrl, LabelIds, State, Events, Planning, Documents, Notices, MetaData) {
        super();
        this.Id = Id;
        this.MasterData = MasterData;
        this.LocationInformation = LocationInformation;
        this.AssignedToolIds = AssignedToolIds;
        this.QRCodeIds = QRCodeIds;
        this.ImageUrl = ImageUrl;
        this.LabelIds = LabelIds;
        this.State = State;
        this.Events = Events;
        this.Planning = Planning;
        this.Documents = Documents;
        this.Notices = Notices;
        this.MetaData = MetaData;
    }
}

export function BusinessLogic_Set_$reflection() {
    return record_type("Shared.Set.BusinessLogic.Set", [], BusinessLogic_Set, () => [["Id", SetId_$reflection()], ["MasterData", BusinessLogic_MasterData_$reflection()], ["LocationInformation", BusinessLogic_LocationInformation_$reflection()], ["AssignedToolIds", list_type(ToolId_$reflection())], ["QRCodeIds", list_type(string_type)], ["ImageUrl", string_type], ["LabelIds", list_type(LabelId_$reflection())], ["State", string_type], ["Events", list_type(BusinessLogic_SetEvent_$reflection())], ["Planning", BusinessLogic_PlanningInformation_$reflection()], ["Documents", list_type(Document$_$reflection())], ["Notices", list_type(Notice_$reflection())], ["MetaData", BusinessLogic_MetaData_$reflection()]]);
}

export function BusinessLogic_Set__ReadableDisplayName(this$) {
    if (this$.MasterData.Name !== "") {
        return this$.MasterData.Name;
    }
    else {
        return `${this$.MasterData.Manufacturer} ${this$.MasterData.Model}`;
    }
}

export class DataTransfer_SetInfo extends Record {
    constructor(ActiveProblemReports, CurrentReservation) {
        super();
        this.ActiveProblemReports = ActiveProblemReports;
        this.CurrentReservation = CurrentReservation;
    }
}

export function DataTransfer_SetInfo_$reflection() {
    return record_type("Shared.Set.DataTransfer.SetInfo", [], DataTransfer_SetInfo, () => [["ActiveProblemReports", list_type(anonRecord_type(["Message", string_type], ["Timestamp", class_type("System.DateTime")], ["ToolId", string_type], ["ToolName", string_type]))], ["CurrentReservation", option_type(anonRecord_type(["EndDate", class_type("System.DateTime")], ["ReceiverId", string_type], ["ReceiverName", string_type], ["StartDate", class_type("System.DateTime")]))]]);
}

export class DataTransfer_CreateOrUpdateMasterData extends Record {
    constructor(Name, Manufacturer, Model, SerialNumber, Comment$, InventoryNumber, CommercialData, CustomProperties) {
        super();
        this.Name = Name;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.Comment = Comment$;
        this.InventoryNumber = InventoryNumber;
        this.CommercialData = CommercialData;
        this.CustomProperties = CustomProperties;
    }
}

export function DataTransfer_CreateOrUpdateMasterData_$reflection() {
    return record_type("Shared.Set.DataTransfer.CreateOrUpdateMasterData", [], DataTransfer_CreateOrUpdateMasterData, () => [["Name", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["Comment", string_type], ["InventoryNumber", string_type], ["CommercialData", CommercialDataFormDto_$reflection()], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class DataTransfer_CreateOrUpdateLocationInformation extends Record {
    constructor(StorageId, StorageSpace) {
        super();
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
    }
}

export function DataTransfer_CreateOrUpdateLocationInformation_$reflection() {
    return record_type("Shared.Set.DataTransfer.CreateOrUpdateLocationInformation", [], DataTransfer_CreateOrUpdateLocationInformation, () => [["StorageId", string_type], ["StorageSpace", string_type]]);
}

export class DataTransfer_CreateOrUpdateSet extends Record {
    constructor(ImageUrl, MasterData, LocationInformation, AssignedToolIds, QRCodeIds, LabelIds) {
        super();
        this.ImageUrl = ImageUrl;
        this.MasterData = MasterData;
        this.LocationInformation = LocationInformation;
        this.AssignedToolIds = AssignedToolIds;
        this.QRCodeIds = QRCodeIds;
        this.LabelIds = LabelIds;
    }
}

export function DataTransfer_CreateOrUpdateSet_$reflection() {
    return record_type("Shared.Set.DataTransfer.CreateOrUpdateSet", [], DataTransfer_CreateOrUpdateSet, () => [["ImageUrl", string_type], ["MasterData", DataTransfer_CreateOrUpdateMasterData_$reflection()], ["LocationInformation", DataTransfer_CreateOrUpdateLocationInformation_$reflection()], ["AssignedToolIds", list_type(string_type)], ["QRCodeIds", list_type(string_type)], ["LabelIds", list_type(string_type)]]);
}

export class DataTransfer_MasterData extends Record {
    constructor(Name, Manufacturer, Model, SerialNumber, Comment$, InventoryNumber, CommercialData, CustomProperties) {
        super();
        this.Name = Name;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.Comment = Comment$;
        this.InventoryNumber = InventoryNumber;
        this.CommercialData = CommercialData;
        this.CustomProperties = CustomProperties;
    }
}

export function DataTransfer_MasterData_$reflection() {
    return record_type("Shared.Set.DataTransfer.MasterData", [], DataTransfer_MasterData, () => [["Name", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["Comment", string_type], ["InventoryNumber", string_type], ["CommercialData", CommercialData_$reflection()], ["CustomProperties", list_type(CustomProperty_$reflection())]]);
}

export class DataTransfer_LocationInformation extends Record {
    constructor(StorageName, StorageId, StorageSpace, AssignedStorageUserIds, AssignedReceiverName, AssignedReceiverId, PlannedReturnDate, LocationId, LocationName, CurrentLocation, ReceiverTypeName, ReleaseDate) {
        super();
        this.StorageName = StorageName;
        this.StorageId = StorageId;
        this.StorageSpace = StorageSpace;
        this.AssignedStorageUserIds = AssignedStorageUserIds;
        this.AssignedReceiverName = AssignedReceiverName;
        this.AssignedReceiverId = AssignedReceiverId;
        this.PlannedReturnDate = PlannedReturnDate;
        this.LocationId = LocationId;
        this.LocationName = LocationName;
        this.CurrentLocation = CurrentLocation;
        this.ReceiverTypeName = ReceiverTypeName;
        this.ReleaseDate = ReleaseDate;
    }
}

export function DataTransfer_LocationInformation_$reflection() {
    return record_type("Shared.Set.DataTransfer.LocationInformation", [], DataTransfer_LocationInformation, () => [["StorageName", string_type], ["StorageId", string_type], ["StorageSpace", string_type], ["AssignedStorageUserIds", list_type(string_type)], ["AssignedReceiverName", string_type], ["AssignedReceiverId", option_type(string_type)], ["PlannedReturnDate", option_type(class_type("System.DateTime"))], ["LocationId", option_type(string_type)], ["LocationName", option_type(string_type)], ["CurrentLocation", string_type], ["ReceiverTypeName", option_type(ReceiverTypeName_$reflection())], ["ReleaseDate", option_type(class_type("System.DateTime"))]]);
}

export class DataTransfer_AssignedTool extends Record {
    constructor(ToolId, DisplayName, Manufacturer, Model, SerialNumber, InventoryNumber, ImageUrl, StorageId, State) {
        super();
        this.ToolId = ToolId;
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.InventoryNumber = InventoryNumber;
        this.ImageUrl = ImageUrl;
        this.StorageId = StorageId;
        this.State = State;
    }
}

export function DataTransfer_AssignedTool_$reflection() {
    return record_type("Shared.Set.DataTransfer.AssignedTool", [], DataTransfer_AssignedTool, () => [["ToolId", string_type], ["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["InventoryNumber", string_type], ["ImageUrl", string_type], ["StorageId", string_type], ["State", string_type]]);
}

export class DataTransfer_AssignedLabel extends Record {
    constructor(Id, Name, ColorHex) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.ColorHex = ColorHex;
    }
}

export function DataTransfer_AssignedLabel_$reflection() {
    return record_type("Shared.Set.DataTransfer.AssignedLabel", [], DataTransfer_AssignedLabel, () => [["Id", string_type], ["Name", string_type], ["ColorHex", string_type]]);
}

export class DataTransfer_DetailMasterData extends Record {
    constructor(Name, Manufacturer, Model, SerialNumber, Comment$, InventoryNumber, CommercialData, CustomProperties) {
        super();
        this.Name = Name;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.Comment = Comment$;
        this.InventoryNumber = InventoryNumber;
        this.CommercialData = CommercialData;
        this.CustomProperties = CustomProperties;
    }
}

export function DataTransfer_DetailMasterData_$reflection() {
    return record_type("Shared.Set.DataTransfer.DetailMasterData", [], DataTransfer_DetailMasterData, () => [["Name", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["Comment", string_type], ["InventoryNumber", string_type], ["CommercialData", CommercialData_$reflection()], ["CustomProperties", list_type(CustomPropertyValueDto_$reflection())]]);
}

export class DataTransfer_AccessInformation extends Record {
    constructor(IsUserResponsible) {
        super();
        this.IsUserResponsible = IsUserResponsible;
    }
}

export function DataTransfer_AccessInformation_$reflection() {
    return record_type("Shared.Set.DataTransfer.AccessInformation", [], DataTransfer_AccessInformation, () => [["IsUserResponsible", bool_type]]);
}

export class DataTransfer_SetReminder extends Record {
    constructor(Id, Date$, Title, Description, IsConfirmed, ConfirmationComment, Interval, CustomInterval, AdditionalReminders) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.Title = Title;
        this.Description = Description;
        this.IsConfirmed = IsConfirmed;
        this.ConfirmationComment = ConfirmationComment;
        this.Interval = Interval;
        this.CustomInterval = CustomInterval;
        this.AdditionalReminders = AdditionalReminders;
    }
}

export function DataTransfer_SetReminder_$reflection() {
    return record_type("Shared.Set.DataTransfer.SetReminder", [], DataTransfer_SetReminder, () => [["Id", string_type], ["Date", class_type("System.DateTime")], ["Title", string_type], ["Description", string_type], ["IsConfirmed", bool_type], ["ConfirmationComment", string_type], ["Interval", option_type(ReminderInterval_$reflection())], ["CustomInterval", option_type(CustomIntervalDefinition_$reflection())], ["AdditionalReminders", list_type(AdditionalReminderDefinition_$reflection())]]);
}

export class DataTransfer_DetailSetEvent extends Record {
    constructor(Event$, Sender, Receiver, Storage) {
        super();
        this.Event = Event$;
        this.Sender = Sender;
        this.Receiver = Receiver;
        this.Storage = Storage;
    }
}

export function DataTransfer_DetailSetEvent_$reflection() {
    return record_type("Shared.Set.DataTransfer.DetailSetEvent", [], DataTransfer_DetailSetEvent, () => [["Event", BusinessLogic_SetEvent_$reflection()], ["Sender", string_type], ["Receiver", option_type(string_type)], ["Storage", option_type(string_type)]]);
}

export class DataTransfer_DetailSet extends Record {
    constructor(Id, MasterData, LocationInformation, ImageUrl, AssignedTools, QRCodeIds, AssignedLabel, State, Events, Planning, Documents, Notices, AccessInformation) {
        super();
        this.Id = Id;
        this.MasterData = MasterData;
        this.LocationInformation = LocationInformation;
        this.ImageUrl = ImageUrl;
        this.AssignedTools = AssignedTools;
        this.QRCodeIds = QRCodeIds;
        this.AssignedLabel = AssignedLabel;
        this.State = State;
        this.Events = Events;
        this.Planning = Planning;
        this.Documents = Documents;
        this.Notices = Notices;
        this.AccessInformation = AccessInformation;
    }
}

export function DataTransfer_DetailSet_$reflection() {
    return record_type("Shared.Set.DataTransfer.DetailSet", [], DataTransfer_DetailSet, () => [["Id", string_type], ["MasterData", DataTransfer_DetailMasterData_$reflection()], ["LocationInformation", DataTransfer_LocationInformation_$reflection()], ["ImageUrl", string_type], ["AssignedTools", list_type(DataTransfer_AssignedTool_$reflection())], ["QRCodeIds", list_type(string_type)], ["AssignedLabel", list_type(DataTransfer_AssignedLabel_$reflection())], ["State", string_type], ["Events", list_type(DataTransfer_DetailSetEvent_$reflection())], ["Planning", anonRecord_type(["Reminders", list_type(DataTransfer_SetReminder_$reflection())], ["Reservations", list_type(ReservationDto_$reflection())])], ["Documents", list_type(DocumentDetail_$reflection())], ["Notices", list_type(anonRecord_type(["CreatedAt", class_type("System.DateTime")], ["CreatorInformation", anonRecord_type(["Id", string_type], ["Name", string_type])], ["Description", string_type], ["Headline", string_type], ["Id", string_type], ["IsVisibleForUser", bool_type]))], ["AccessInformation", DataTransfer_AccessInformation_$reflection()]]);
}

export function DataTransfer_DetailSet__ReadableDisplayName(this$) {
    if (this$.MasterData.Name !== "") {
        return this$.MasterData.Name;
    }
    else {
        return `${this$.MasterData.Manufacturer} ${this$.MasterData.Model}`;
    }
}

export class DataTransfer_OverviewSet extends Record {
    constructor(Id, MasterData, LocationInformation, ImageUrl, QRCodeIds, AssignedLabel, State) {
        super();
        this.Id = Id;
        this.MasterData = MasterData;
        this.LocationInformation = LocationInformation;
        this.ImageUrl = ImageUrl;
        this.QRCodeIds = QRCodeIds;
        this.AssignedLabel = AssignedLabel;
        this.State = State;
    }
}

export function DataTransfer_OverviewSet_$reflection() {
    return record_type("Shared.Set.DataTransfer.OverviewSet", [], DataTransfer_OverviewSet, () => [["Id", string_type], ["MasterData", DataTransfer_MasterData_$reflection()], ["LocationInformation", DataTransfer_LocationInformation_$reflection()], ["ImageUrl", string_type], ["QRCodeIds", string_type], ["AssignedLabel", list_type(DataTransfer_AssignedLabel_$reflection())], ["State", string_type]]);
}

export class DataTransfer_ProblemReportTool extends Record {
    constructor(ToolId, DisplayName, Manufacturer, Model, SerialNumber, InventoryNumber, ImageUrl, StorageId, Message, SenderName, State, Timestamp) {
        super();
        this.ToolId = ToolId;
        this.DisplayName = DisplayName;
        this.Manufacturer = Manufacturer;
        this.Model = Model;
        this.SerialNumber = SerialNumber;
        this.InventoryNumber = InventoryNumber;
        this.ImageUrl = ImageUrl;
        this.StorageId = StorageId;
        this.Message = Message;
        this.SenderName = SenderName;
        this.State = State;
        this.Timestamp = Timestamp;
    }
}

export function DataTransfer_ProblemReportTool_$reflection() {
    return record_type("Shared.Set.DataTransfer.ProblemReportTool", [], DataTransfer_ProblemReportTool, () => [["ToolId", string_type], ["DisplayName", string_type], ["Manufacturer", string_type], ["Model", string_type], ["SerialNumber", string_type], ["InventoryNumber", string_type], ["ImageUrl", string_type], ["StorageId", string_type], ["Message", string_type], ["SenderName", string_type], ["State", ProblemReportState_$reflection()], ["Timestamp", class_type("System.DateTime")]]);
}

export class DataTransfer_UpdateStateDto extends Record {
    constructor(NewState, Comment$) {
        super();
        this.NewState = NewState;
        this.Comment = Comment$;
    }
}

export function DataTransfer_UpdateStateDto_$reflection() {
    return record_type("Shared.Set.DataTransfer.UpdateStateDto", [], DataTransfer_UpdateStateDto, () => [["NewState", string_type], ["Comment", string_type]]);
}

export class DataTransfer_ChangeStorageDto extends Record {
    constructor(NewStorageId, NewStorageSpace) {
        super();
        this.NewStorageId = NewStorageId;
        this.NewStorageSpace = NewStorageSpace;
    }
}

export function DataTransfer_ChangeStorageDto_$reflection() {
    return record_type("Shared.Set.DataTransfer.ChangeStorageDto", [], DataTransfer_ChangeStorageDto, () => [["NewStorageId", string_type], ["NewStorageSpace", string_type]]);
}

export class DataTransfer_UpdateQrCodesDto extends Record {
    constructor(QrCodes) {
        super();
        this.QrCodes = QrCodes;
    }
}

export function DataTransfer_UpdateQrCodesDto_$reflection() {
    return record_type("Shared.Set.DataTransfer.UpdateQrCodesDto", [], DataTransfer_UpdateQrCodesDto, () => [["QrCodes", list_type(string_type)]]);
}

export class DataTransfer_AssignSetDto extends Record {
    constructor(AffectedReceiverId, Comment$, PlannedReturnDate, SignatureUrl) {
        super();
        this.AffectedReceiverId = AffectedReceiverId;
        this.Comment = Comment$;
        this.PlannedReturnDate = PlannedReturnDate;
        this.SignatureUrl = SignatureUrl;
    }
}

export function DataTransfer_AssignSetDto_$reflection() {
    return record_type("Shared.Set.DataTransfer.AssignSetDto", [], DataTransfer_AssignSetDto, () => [["AffectedReceiverId", string_type], ["Comment", string_type], ["PlannedReturnDate", option_type(string_type)], ["SignatureUrl", option_type(string_type)]]);
}

export class DataTransfer_UnassignSetDto extends Record {
    constructor(Comment$) {
        super();
        this.Comment = Comment$;
    }
}

export function DataTransfer_UnassignSetDto_$reflection() {
    return record_type("Shared.Set.DataTransfer.UnassignSetDto", [], DataTransfer_UnassignSetDto, () => [["Comment", string_type]]);
}

export class DataTransfer_AssignSetNotificationDto extends Record {
    constructor(ReceiverId, Comment$, WithSignature, PlannedReturnDate, Type) {
        super();
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
        this.WithSignature = WithSignature;
        this.PlannedReturnDate = PlannedReturnDate;
        this.Type = Type;
    }
}

export function DataTransfer_AssignSetNotificationDto_$reflection() {
    return record_type("Shared.Set.DataTransfer.AssignSetNotificationDto", [], DataTransfer_AssignSetNotificationDto, () => [["ReceiverId", string_type], ["Comment", string_type], ["WithSignature", bool_type], ["PlannedReturnDate", option_type(string_type)], ["Type", string_type]]);
}

export class DataTransfer_UnassignSetNotificationDto extends Record {
    constructor(ReceiverId, Comment$, Type) {
        super();
        this.ReceiverId = ReceiverId;
        this.Comment = Comment$;
        this.Type = Type;
    }
}

export function DataTransfer_UnassignSetNotificationDto_$reflection() {
    return record_type("Shared.Set.DataTransfer.UnassignSetNotificationDto", [], DataTransfer_UnassignSetNotificationDto, () => [["ReceiverId", option_type(string_type)], ["Comment", string_type], ["Type", string_type]]);
}

export class DataTransfer_Response_UpdateSetStateResponse extends Record {
    constructor(Type, AssignedToolIds, DeletedToolIds) {
        super();
        this.Type = Type;
        this.AssignedToolIds = AssignedToolIds;
        this.DeletedToolIds = DeletedToolIds;
    }
}

export function DataTransfer_Response_UpdateSetStateResponse_$reflection() {
    return record_type("Shared.Set.DataTransfer.Response.UpdateSetStateResponse", [], DataTransfer_Response_UpdateSetStateResponse, () => [["Type", list_type(string_type)], ["AssignedToolIds", list_type(string_type)], ["DeletedToolIds", list_type(string_type)]]);
}

export class DataTransfer_Response_ChangeStorageResponse extends Record {
    constructor(ValidationResult, NotAuthorizedToolIds) {
        super();
        this.ValidationResult = ValidationResult;
        this.NotAuthorizedToolIds = NotAuthorizedToolIds;
    }
}

export function DataTransfer_Response_ChangeStorageResponse_$reflection() {
    return record_type("Shared.Set.DataTransfer.Response.ChangeStorageResponse", [], DataTransfer_Response_ChangeStorageResponse, () => [["ValidationResult", string_type], ["NotAuthorizedToolIds", list_type(string_type)]]);
}

export class DataTransfer_Response_AssignSetResponse extends Record {
    constructor(ValidationResults, NotAuthorizedToolIds, NotActiveToolIds, AssignedToolIds) {
        super();
        this.ValidationResults = ValidationResults;
        this.NotAuthorizedToolIds = NotAuthorizedToolIds;
        this.NotActiveToolIds = NotActiveToolIds;
        this.AssignedToolIds = AssignedToolIds;
    }
}

export function DataTransfer_Response_AssignSetResponse_$reflection() {
    return record_type("Shared.Set.DataTransfer.Response.AssignSetResponse", [], DataTransfer_Response_AssignSetResponse, () => [["ValidationResults", list_type(string_type)], ["NotAuthorizedToolIds", list_type(string_type)], ["NotActiveToolIds", list_type(string_type)], ["AssignedToolIds", list_type(string_type)]]);
}

export function Helper_stateToTranslationString(_arg) {
    switch (_arg) {
        case "inMaintenance":
            return "general.in_maintenance";
        case "retired":
            return "general.retired";
        case "lost":
            return "general.lost";
        default:
            return "general.active";
    }
}

export function Helper_titleAndSubtitle(set$) {
    if ((set$.MasterData.Manufacturer === "") && (set$.MasterData.Model === "")) {
        return [set$.MasterData.Name, ""];
    }
    else if (set$.MasterData.Name === "") {
        return [`${set$.MasterData.Manufacturer} ${set$.MasterData.Model}`, ""];
    }
    else {
        return [set$.MasterData.Name, `${set$.MasterData.Manufacturer} ${set$.MasterData.Model}`];
    }
}

export function Helper_comment(_arg) {
    switch (_arg.tag) {
        case 11:
            return _arg.fields[0].Comment;
        case 2:
            return _arg.fields[0].Comment;
        case 0:
            return _arg.fields[0].Comment;
        case 4:
            return _arg.fields[0].Comment;
        case 5:
            return _arg.fields[0].Comment;
        case 6:
            return _arg.fields[0].Comment;
        case 7:
            return _arg.fields[0].Comment;
        case 8:
            return _arg.fields[0].Comment;
        case 12:
            return _arg.fields[0].Comment;
        case 13:
            return _arg.fields[0].Comment;
        case 9:
        case 10:
            return undefined;
        default:
            return undefined;
    }
}

export function Helper_timestamp(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].Timestamp;
        case 2:
            return _arg.fields[0].Timestamp;
        case 0:
            return _arg.fields[0].Timestamp;
        case 4:
            return _arg.fields[0].Timestamp;
        case 5:
            return _arg.fields[0].Timestamp;
        case 6:
            return _arg.fields[0].Timestamp;
        case 7:
            return _arg.fields[0].Timestamp;
        case 8:
            return _arg.fields[0].Timestamp;
        case 9:
            return _arg.fields[0].Timestamp;
        case 10:
            return _arg.fields[0].Timestamp;
        case 11:
            return _arg.fields[0].Timestamp;
        case 12:
            return _arg.fields[0].Timestamp;
        case 13:
            return _arg.fields[0].Timestamp;
        default:
            return _arg.fields[0].Timestamp;
    }
}

export function Helper_senderId(_arg) {
    switch (_arg.tag) {
        case 3:
            return _arg.fields[0].SenderId;
        case 2:
            return _arg.fields[0].SenderId;
        case 0:
            return _arg.fields[0].SenderId;
        case 4:
            return _arg.fields[0].SenderId;
        case 5:
            return _arg.fields[0].SenderId;
        case 6:
            return _arg.fields[0].SenderId;
        case 7:
            return _arg.fields[0].SenderId;
        case 8:
            return _arg.fields[0].SenderId;
        case 9:
            return _arg.fields[0].SenderId;
        case 10:
            return _arg.fields[0].SenderId;
        case 11:
            return _arg.fields[0].SenderId;
        case 12:
            return _arg.fields[0].SenderId;
        case 13:
            return _arg.fields[0].SenderId;
        default:
            return _arg.fields[0].SenderId;
    }
}

export function Helper_receiverId(_arg) {
    switch (_arg.tag) {
        case 2:
            return _arg.fields[0].ReceiverId;
        case 0:
            return _arg.fields[0].ReceiverId;
        case 11:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 3:
        case 12:
        case 13:
            return undefined;
        default:
            return _arg.fields[0].ReceiverId;
    }
}

export function Helper_inMaintenanceEvent(_arg) {
    switch (_arg.tag) {
        case 11:
        case 1:
        case 2:
        case 0:
        case 4:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 3:
        case 12:
        case 13:
            return undefined;
        default:
            return _arg.fields[0];
    }
}

export function Helper_reservationToDto(reservation) {
    return new ToolOverviewReservationDto(SetReservationId__get_unwrap(reservation.Id), reservation.Name, reservation.StartDate, reservation.EndDate, reservation.ReceiverId, "", reservation.SenderId, "", reservation.Description, undefined);
}

