import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useTranslation } from "react-i18next";
import Followers from "../../../public/assets/icons/Followers.svg";
import { singleton as singleton_1, append as append_1, choose, ofArray, empty as empty_1, toArray, map, isEmpty } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { ColumnDefinition$1, CellContent, Table, TableControl$1 } from "../../Widgets/Table/Table.js";
import { append, singleton, empty, delay, toList } from "../../fable_modules/fable-library-js.4.19.2/Seq.js";
import { Helper_unitToTranslationKey } from "../../Shared/Consumable.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Helper_stateToTranslationKey, ReservationDto, ReservationToolType } from "../../Shared/Planning.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { DeleteReservationDialog } from "../../Widgets/Dialogs/DeleteReservationDialog.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { AssignReservationDialog } from "../Dialogs/AssignReservationDialog.js";
import { unwrap, map as map_1, defaultArg } from "../../fable_modules/fable-library-js.4.19.2/Option.js";
import { Msg } from "./Types.js";
import { CompleteReservationDialog } from "../Dialogs/CompleteReservationDialog.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { DetailViewHeader } from "../../Widgets/DetailViewHeader.js";
import { TextButton } from "../../Components/Button.js";
import Check from "../../../public/assets/icons/Check.svg";
import Following from "../../../public/assets/icons/Following.svg";
import Printer from "../../../public/assets/icons/Printer.svg";
import { CustomField, PropertyList } from "../../Widgets/PropertyListWidget.js";
import { toString } from "../../fable_modules/fable-library-js.4.19.2/Date.js";
import { PredefinedQuantityType, MultiAssignModalSheet } from "../../Widgets/Modals/MultiAssignModal.js";
import { ConsumableId, SetId, ToolId, ConsumableId__get_unwrap, ToolId__get_unwrap } from "../../Shared/Shared.js";
import { TableContainer } from "../../Widgets/Container.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.2/Guid.js";
import { update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { Skeleton } from "../../Components/Skeleton.js";

function ToolTable(props) {
    let TableRows, ColumnDefinitions, Options;
    const t = useTranslation()[0];
    const assignAction = new TableControl$1(t("general.assign"), Followers(), (selectedTools) => {
        if (isEmpty(selectedTools)) {
        }
        else {
            props.SetToolIds(map((tool) => tool.Id, selectedTools));
        }
    }, true, false, "");
    const xs = [createElement(Table, (TableRows = toArray(map((toolInformation) => ({
        CurrentLocation: toolInformation.CurrentLocation,
        DisplayName: toolInformation.DisplayName,
        Id: toolInformation.Id,
        ImageUrl: toolInformation.ImageUrl,
        InventoryNumber: toolInformation.InventoryNumber,
        Location: toolInformation.Location,
        Manufacturer: toolInformation.Manufacturer,
        Model: toolInformation.Model,
        QRCodeIds: toolInformation.QRCodeIds,
        SerialNumber: toolInformation.SerialNumber,
        Storage: toolInformation.Storage,
        StorageSpace: toolInformation.StorageSpace,
    }), props.Reservation.ToolsInformation)), (ColumnDefinitions = [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row_1) => (new CellContent(0, [row_1.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_5) => (new CellContent(0, [row_5.SerialNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_6) => (new CellContent(0, [row_6.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (row_7) => (new CellContent(0, [row_7.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_8) => (new CellContent(0, [row_8.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_9) => (new CellContent(0, [row_9.StorageSpace])), "text", "text"), new ColumnDefinition$1("currentLocation", t("general.master_data"), t("tool.assgined_to"), (row_10) => (new CellContent(0, [t(row_10.CurrentLocation)])), "text", "text")], (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: (props.Reservation.State.tag === 1) ? "disabled" : "multiRow",
    }, {
        ColumnDefinitions: ColumnDefinitions,
        Controls: toList(delay(() => {
            if (props.Reservation.State.tag === 1) {
                return empty();
            }
            else {
                const matchValue_2 = props.UserData.Role;
                switch (matchValue_2) {
                    case "user": {
                        return empty();
                    }
                    default:
                        return singleton(assignAction);
                }
            }
        })),
        Options: Options,
        TableRows: TableRows,
    }))))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ToolWithQuantityTable(props) {
    let TableRows, ColumnDefinitions, Options;
    const t = useTranslation()[0];
    const rows = map((toolInformation) => ({
        CurrentLocation: toolInformation.CurrentLocation,
        DisplayName: toolInformation.DisplayName,
        Id: toolInformation.Id,
        ImageUrl: toolInformation.ImageUrl,
        InventoryNumber: toolInformation.InventoryNumber,
        Location: toolInformation.Location,
        Manufacturer: toolInformation.Manufacturer,
        Model: toolInformation.Model,
        QRCodeIds: toolInformation.QRCodeIds,
        Quantity: toolInformation.Quantity,
        RawQuantity: toolInformation.RawQuantity,
        SerialNumber: toolInformation.SerialNumber,
        Storage: toolInformation.Storage,
        StorageSpace: toolInformation.StorageSpace,
        Unit: toolInformation.Unit,
    }), props.Reservation.ToolsWithQuantityInformation);
    const assignAction = new TableControl$1(t("general.assign"), Followers(), (selectedTools) => {
        if (isEmpty(selectedTools)) {
        }
        else {
            props.SetToolIds(map((tool) => [tool.Id, tool.RawQuantity], selectedTools));
        }
    }, true, false, "");
    const xs = [createElement(Table, (TableRows = toArray(rows), (ColumnDefinitions = [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_1) => (new CellContent(0, [row_1.Quantity])), "text", "text"), new ColumnDefinition$1("unit", "", t("general.unit"), (row_2) => (new CellContent(0, [t(Helper_unitToTranslationKey(row_2.Unit))])), "text", "text"), new ColumnDefinition$1("name", "", t("general.name"), (row_3) => (new CellContent(0, [row_3.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_4) => (new CellContent(0, [row_4.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_5) => (new CellContent(0, [row_5.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_6) => (new CellContent(0, [row_6.Model])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_7) => (new CellContent(0, [row_7.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (row_8) => (new CellContent(0, [row_8.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_9) => (new CellContent(0, [row_9.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_10) => (new CellContent(0, [row_10.StorageSpace])), "text", "text")], (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: (props.Reservation.State.tag === 1) ? "disabled" : "multiRow",
    }, {
        ColumnDefinitions: ColumnDefinitions,
        Controls: toList(delay(() => {
            if (props.Reservation.State.tag === 1) {
                return empty();
            }
            else {
                const matchValue_2 = props.UserData.Role;
                switch (matchValue_2) {
                    case "user": {
                        return empty();
                    }
                    default:
                        return singleton(assignAction);
                }
            }
        })),
        Options: Options,
        TableRows: TableRows,
    }))))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ConsumableTable(props) {
    let TableRows, ColumnDefinitions, Options;
    const t = useTranslation()[0];
    const rows = map((consumableReservation) => ({
        Id: consumableReservation.Id,
        ImageUrl: consumableReservation.ImageUrl,
        InventoryNumber: consumableReservation.InventoryNumber,
        Name: consumableReservation.Name,
        QRCodeIds: consumableReservation.QRCodeIds,
        Quantity: consumableReservation.Quantity,
        RawQuantity: consumableReservation.RawQuantity,
        Storage: consumableReservation.Storage,
        StorageSpace: consumableReservation.StorageSpace,
        Unit: consumableReservation.Unit,
    }), props.Reservation.ConsumablesInformation);
    const assignAction = new TableControl$1(t("general.assign"), Followers(), (selectedConsumables) => {
        if (isEmpty(selectedConsumables)) {
        }
        else {
            props.SetConsumableIds(map((consumable) => [consumable.Id, consumable.RawQuantity], selectedConsumables));
        }
    }, true, false, "");
    const xs = [createElement(Table, (TableRows = toArray(rows), (ColumnDefinitions = [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("quantity", "", t("general.quantity_3"), (row_1) => (new CellContent(0, [row_1.Quantity])), "none", "text"), new ColumnDefinition$1("unit", "", t("general.unit"), (consumable_1) => (new CellContent(0, [t(Helper_unitToTranslationKey(consumable_1.Unit))])), "text", "text"), new ColumnDefinition$1("name", "", t("general.name"), (row_2) => (new CellContent(0, [row_2.Name])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_3) => (new CellContent(0, [row_3.InventoryNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_4) => (new CellContent(0, [row_4.QRCodeIds])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_5) => (new CellContent(0, [row_5.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_6) => (new CellContent(0, [row_6.StorageSpace])), "text", "text")], (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: (props.Reservation.State.tag === 1) ? "disabled" : "multiRow",
    }, {
        ColumnDefinitions: ColumnDefinitions,
        Controls: toList(delay(() => {
            if (props.Reservation.State.tag === 1) {
                return empty();
            }
            else {
                const matchValue_2 = props.UserData.Role;
                switch (matchValue_2) {
                    case "user": {
                        return empty();
                    }
                    default:
                        return singleton(assignAction);
                }
            }
        })),
        Options: Options,
        TableRows: TableRows,
    }))))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function SetTable(props) {
    let TableRows, ColumnDefinitions, Options;
    const t = useTranslation()[0];
    const rows = map((setInformation) => ({
        CurrentLocation: setInformation.CurrentLocation,
        DisplayName: setInformation.DisplayName,
        Id: setInformation.Id,
        ImageUrl: setInformation.ImageUrl,
        InventoryNumber: setInformation.InventoryNumber,
        Location: setInformation.Location,
        Manufacturer: setInformation.Manufacturer,
        Model: setInformation.Model,
        QRCodeIds: setInformation.QRCodeIds,
        SerialNumber: setInformation.SerialNumber,
        Storage: setInformation.Storage,
        StorageSpace: setInformation.StorageSpace,
    }), props.Reservation.SetsInformation);
    const assignAction = new TableControl$1(t("general.assign"), Followers(), (selectedSets) => {
        if (isEmpty(selectedSets)) {
        }
        else {
            props.SetSetIds(map((set$) => set$.Id, selectedSets));
        }
    }, true, false, "");
    const xs = [createElement(Table, (TableRows = toArray(rows), (ColumnDefinitions = [new ColumnDefinition$1("image", t("general.master_data"), t("general.image"), (row) => (new CellContent(4, [row.ImageUrl])), "none", "image"), new ColumnDefinition$1("name", "", t("general.name"), (row_1) => (new CellContent(0, [row_1.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_2) => (new CellContent(0, [row_2.InventoryNumber])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_5) => (new CellContent(0, [row_5.SerialNumber])), "text", "text"), new ColumnDefinition$1("qrCodeIds", t("general.master_data"), t("general.qr_code"), (row_6) => (new CellContent(0, [row_6.QRCodeIds])), "text", "text"), new ColumnDefinition$1("location", t("general.master_data"), t("general.location"), (row_7) => (new CellContent(0, [row_7.Location])), "text", "text"), new ColumnDefinition$1("storage", t("general.master_data"), t("general.storage"), (row_8) => (new CellContent(0, [row_8.Storage])), "text", "text"), new ColumnDefinition$1("storageSpace", t("general.master_data"), t("tool.storage_space"), (row_9) => (new CellContent(0, [row_9.StorageSpace])), "text", "text"), new ColumnDefinition$1("currentLocation", t("general.master_data"), t("tool.assgined_to"), (row_10) => (new CellContent(0, [t(row_10.CurrentLocation)])), "text", "text")], (Options = {
        BorderStyle: "row",
        CSVDownload: false,
        ConfigurableColumns: false,
        DefaultSortColumn: ["name", "asc"],
        GlobalSearch: false,
        RowSelection: (props.Reservation.State.tag === 1) ? "disabled" : "multiRow",
    }, {
        ColumnDefinitions: ColumnDefinitions,
        Controls: toList(delay(() => {
            if (props.Reservation.State.tag === 1) {
                return empty();
            }
            else {
                const matchValue_2 = props.UserData.Role;
                switch (matchValue_2) {
                    case "user": {
                        return empty();
                    }
                    default:
                        return singleton(assignAction);
                }
            }
        })),
        Options: Options,
        TableRows: TableRows,
    }))))];
    return react.createElement(react.Fragment, {}, ...xs);
}

function ReservationDetails(props) {
    let elems_10, elems_9;
    const t = useTranslation()[0];
    const patternInput_1 = reactApi.useState(false);
    const setIsDeleteDialogOpen = patternInput_1[1];
    const isDeleteDialogOpen = patternInput_1[0];
    const patternInput_2 = reactApi.useState(false);
    const setIsAssignModalSheetOpen = patternInput_2[1];
    const patternInput_3 = reactApi.useState(false);
    const setIsCompleteDialogOpen = patternInput_3[1];
    const patternInput_4 = reactApi.useState([false, [empty_1(), empty_1(), empty_1()]]);
    const setIsMultiAssignModalSheetOpen = patternInput_4[1];
    const isMultiAssignModalSheetOpen = patternInput_4[0];
    const reservationDataDto = new ReservationDto(props.Reservation.Id, props.Reservation.GroupId, props.Reservation.State, "", "", props.Reservation.StartDate, props.Reservation.EndDate, {
        Id: "",
        Name: "",
        Type: new ReservationToolType(4, []),
        Unit: "",
    }, {
        ReceiverId: "",
        ReceiverName: "",
    }, {
        ReceiverId: "",
        SenderName: "",
    });
    let reservationId;
    const matchValue = props.Reservation.GroupId;
    reservationId = ((matchValue == null) ? props.Reservation.Id : matchValue);
    return createElement("div", createObj(singleton_1((elems_10 = [createElement(DeleteReservationDialog, {
        IsOpen: isDeleteDialogOpen,
        OnClose: () => {
            setIsDeleteDialogOpen(false);
        },
        Reservation: reservationDataDto,
        SuccessCallback: () => {
            RouterModule_nav(ofArray(["reservations"]), 1, 1);
        },
        UserData: props.UserData,
    }), createElement(AssignReservationDialog, {
        IsOpen: patternInput_2[0],
        OnClose: () => {
            setIsAssignModalSheetOpen(false);
        },
        ReservationId: defaultArg(map_1((x) => x, props.Reservation.GroupId), props.Reservation.Id),
        SuccessCallback: () => {
            props.Dispatch(new Msg(0, []));
        },
    }), createElement(CompleteReservationDialog, {
        IsOpen: patternInput_3[0],
        OnClose: () => {
            setIsCompleteDialogOpen(false);
        },
        ReservationId: defaultArg(map_1((x_1) => x_1, props.Reservation.GroupId), props.Reservation.Id),
        SuccessCallback: () => {
            props.Dispatch(new Msg(0, []));
        },
    }), createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[75rem]", "print:max-w-full"])], (elems_9 = toList(delay(() => {
        let elems, matchValue_1;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["print:hidden"])], (elems = [createElement(DetailViewHeader, {
            Badges: empty_1(),
            OnBack: () => {
                RouterModule_nav(ofArray(["reservations"]), 1, 1);
            },
            OnDelete: unwrap((matchValue_1 = props.UserData.Role, (matchValue_1 === "toolManager") ? (() => {
                setIsDeleteDialogOpen(!isDeleteDialogOpen);
            }) : ((matchValue_1 === "administrator") ? (() => {
                setIsDeleteDialogOpen(!isDeleteDialogOpen);
            }) : ((matchValue_1 === "user") ? undefined : (() => {
                setIsDeleteDialogOpen(!isDeleteDialogOpen);
            }))))),
            OnEdit: () => {
                RouterModule_nav(ofArray(["reservations", reservationId, "edit"]), 1, 1);
            },
            Title: props.Reservation.Name,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_4;
            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-shrink-0", "flex-col", "mb-3", "bg-white", "rounded", "px-12", "py-9"])], (elems_4 = toList(delay(() => {
                let elems_1;
                return append((props.Reservation.State.tag === 1) ? (empty()) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-end", "mb-5", "gap-5", "print:hidden"])], (elems_1 = toList(delay(() => {
                    let matchValue_3, Label;
                    return append((matchValue_3 = props.UserData.Role, (matchValue_3 === "toolManager") ? append(singleton(createElement(TextButton, (Label = t("reservation.dialogs.assign_dialog.complete_reservation"), {
                        ComponentState: "enabled",
                        Icon: Check(),
                        Label: Label,
                        OnClick: () => {
                            setIsCompleteDialogOpen(true);
                        },
                        TestId: "reservation-complete-button",
                        Variant: "default",
                    }))), delay(() => {
                        let Label_1;
                        return singleton(createElement(TextButton, (Label_1 = t("general.assign"), {
                            ComponentState: "enabled",
                            Icon: Following(),
                            Label: Label_1,
                            OnClick: () => {
                                setIsAssignModalSheetOpen(true);
                            },
                            TestId: "reservation-assign-button",
                            Variant: "default",
                        })));
                    })) : ((matchValue_3 === "administrator") ? append(singleton(createElement(TextButton, (Label = t("reservation.dialogs.assign_dialog.complete_reservation"), {
                        ComponentState: "enabled",
                        Icon: Check(),
                        Label: Label,
                        OnClick: () => {
                            setIsCompleteDialogOpen(true);
                        },
                        TestId: "reservation-complete-button",
                        Variant: "default",
                    }))), delay(() => {
                        let Label_1;
                        return singleton(createElement(TextButton, (Label_1 = t("general.assign"), {
                            ComponentState: "enabled",
                            Icon: Following(),
                            Label: Label_1,
                            OnClick: () => {
                                setIsAssignModalSheetOpen(true);
                            },
                            TestId: "reservation-assign-button",
                            Variant: "default",
                        })));
                    })) : ((matchValue_3 === "user") ? (empty()) : append(singleton(createElement(TextButton, (Label = t("reservation.dialogs.assign_dialog.complete_reservation"), {
                        ComponentState: "enabled",
                        Icon: Check(),
                        Label: Label,
                        OnClick: () => {
                            setIsCompleteDialogOpen(true);
                        },
                        TestId: "reservation-complete-button",
                        Variant: "default",
                    }))), delay(() => {
                        let Label_1;
                        return singleton(createElement(TextButton, (Label_1 = t("general.assign"), {
                            ComponentState: "enabled",
                            Icon: Following(),
                            Label: Label_1,
                            OnClick: () => {
                                setIsAssignModalSheetOpen(true);
                            },
                            TestId: "reservation-assign-button",
                            Variant: "default",
                        })));
                    }))))), delay(() => singleton(createElement(TextButton, {
                        ComponentState: "enabled",
                        Icon: Printer(),
                        Label: "",
                        OnClick: () => {
                            window.print();
                        },
                        TestId: "reservation-print-button",
                        Variant: "default",
                    }))));
                })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                    let elems_3, elems_2;
                    return singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "print:block"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["basis-1/2"])], (elems_2 = [createElement(PropertyList, {
                        Properties: ofArray([{
                            Label: t("general.name"),
                            TestId: "reservation-name",
                            Value: props.Reservation.Name,
                        }, {
                            Label: t("general.description"),
                            TestId: "reservation-description",
                            Value: props.Reservation.Description,
                        }, {
                            Label: t("general.state"),
                            TestId: "reservation-state",
                            Value: t(Helper_stateToTranslationKey(props.Reservation.State)),
                        }, {
                            CustomField: new CustomField(1, [`/receivers/${props.Reservation.ReceiverInformation.ReceiverId}`]),
                            Label: t("general.user"),
                            TestId: "reservation-user",
                            Value: props.Reservation.ReceiverInformation.ReceiverName,
                        }, {
                            Label: t("general.start_date"),
                            TestId: "reservation-start-date",
                            Value: toString(props.Reservation.StartDate, "dd.MM.yyyy"),
                        }, {
                            Label: t("general.end_date"),
                            TestId: "reservation-end-date",
                            Value: toString(props.Reservation.EndDate, "dd.MM.yyyy"),
                        }]),
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
                }));
            })), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                let ToolIds, tupledArg_3, twq;
                return append(singleton(createElement(MultiAssignModalSheet, {
                    AssignProps: (ToolIds = map((tuple_2) => tuple_2[0], isMultiAssignModalSheetOpen[1][0]), {
                        ConsumableIds: map((tuple_4) => tuple_4[0], isMultiAssignModalSheetOpen[1][2]),
                        SetIds: isMultiAssignModalSheetOpen[1][1],
                        ToolIds: ToolIds,
                    }),
                    CustomerConfiguration: props.CustomerConfiguration,
                    IsOpen: isMultiAssignModalSheetOpen[0],
                    OnClose: () => {
                        setIsMultiAssignModalSheetOpen([false, [empty_1(), empty_1(), empty_1()]]);
                    },
                    PredefinedQuantities: (tupledArg_3 = isMultiAssignModalSheetOpen[1], (twq = choose((tupledArg_4) => {
                        const quantity = tupledArg_4[1];
                        if (quantity == null) {
                            return undefined;
                        }
                        else {
                            const quantity_1 = quantity;
                            return {
                                Id: ToolId__get_unwrap(tupledArg_4[0]),
                                PredefinedQuantityType: quantity_1,
                            };
                        }
                    }, tupledArg_3[0]), append_1(map((tupledArg_5) => ({
                        Id: ConsumableId__get_unwrap(tupledArg_5[0]),
                        PredefinedQuantityType: tupledArg_5[1],
                    }), tupledArg_3[2]), twq))),
                    ReceiverId: props.Reservation.ReceiverInformation.ReceiverId,
                    SuccessCallback: () => {
                        props.Dispatch(new Msg(0, []));
                    },
                    UserData: props.UserData,
                })), delay(() => {
                    let elems_5, Title_2;
                    return append(!isEmpty(props.Reservation.ToolsInformation) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["print:scale-[0.85]", "print:origin-top-left"])], (elems_5 = [createElement(TableContainer, (Title_2 = t("general.tool"), {
                        Content: createElement(ToolTable, {
                            Reservation: props.Reservation,
                            SetToolIds: (toolIds_2) => {
                                setIsMultiAssignModalSheetOpen([true, [map((toolId_1) => [new ToolId(parse(toolId_1)), undefined], toolIds_2), empty_1(), empty_1()]]);
                            },
                            UserData: props.UserData,
                        }),
                        Title: unwrap(Title_2),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : empty(), delay(() => {
                        let elems_6, Title_3;
                        return append(!isEmpty(props.Reservation.ToolsWithQuantityInformation) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["print:scale-90", "print:origin-top-left"])], (elems_6 = [createElement(TableContainer, (Title_3 = t("general.tool_with_quantity"), {
                            Content: createElement(ToolWithQuantityTable, {
                                Reservation: props.Reservation,
                                SetToolIds: (toolIdsWithQuantity) => {
                                    setIsMultiAssignModalSheetOpen([true, [map((toolIdWithQuantity) => [new ToolId(parse(toolIdWithQuantity[0])), new PredefinedQuantityType(1, [toolIdWithQuantity[1]])], toolIdsWithQuantity), empty_1(), empty_1()]]);
                                },
                                UserData: props.UserData,
                            }),
                            Title: unwrap(Title_3),
                        }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))) : empty(), delay(() => {
                            let elems_7, Title_4;
                            return append(!isEmpty(props.Reservation.SetsInformation) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["print:scale-[0.85]", "print:origin-top-left"])], (elems_7 = [createElement(TableContainer, (Title_4 = t("general.set"), {
                                Content: createElement(SetTable, {
                                    Reservation: props.Reservation,
                                    SetSetIds: (setIds_1) => {
                                        setIsMultiAssignModalSheetOpen([true, [empty_1(), map((setId) => (new SetId(parse(setId))), setIds_1), empty_1()]]);
                                    },
                                    UserData: props.UserData,
                                }),
                                Title: unwrap(Title_4),
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))) : empty(), delay(() => {
                                let elems_8, Title_5;
                                return !isEmpty(props.Reservation.ConsumablesInformation) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", ["print:scale-90", "print:origin-top-left"])], (elems_8 = [createElement(TableContainer, (Title_5 = t("general.consumable"), {
                                    Content: createElement(ConsumableTable, {
                                        Reservation: props.Reservation,
                                        SetConsumableIds: (consumableIdsWithQuantity) => {
                                            setIsMultiAssignModalSheetOpen([true, [empty_1(), empty_1(), map((tupledArg_6) => [new ConsumableId(parse(tupledArg_6[0])), new PredefinedQuantityType(0, [tupledArg_6[1]])], consumableIdsWithQuantity)]]);
                                        },
                                        UserData: props.UserData,
                                    }),
                                    Title: unwrap(Title_5),
                                }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))) : empty();
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))]))));
}

export function DetailReservationView(props) {
    let patternInput;
    const init = init_1(props.UserData, props.ReservationId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const matchValue = state_1.Reservation;
    const matchValue_1 = state_1.CustomerConfiguration;
    let matchResult, customerConfiguration, reservation;
    if (matchValue.tag === 0) {
        matchResult = 1;
    }
    else if (matchValue_1.tag === 0) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        customerConfiguration = matchValue_1.fields[0];
        reservation = matchValue.fields[0];
    }
    switch (matchResult) {
        case 0:
            return createElement(ReservationDetails, {
                CustomerConfiguration: customerConfiguration,
                Dispatch: patternInput[1],
                Reservation: reservation,
                UserData: props.UserData,
            });
        default:
            return createElement(Skeleton, {
                Variant: "detail",
            });
    }
}

