import { Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { fetchWithDecoder, addPrefix } from "../CommunicationV2.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { Reader_$ctor_Z3F6BC7B1, Reader__Read_24524716 } from "../fable_modules/Fable.Remoting.MsgPack.1.24.0/Read.fs.js";
import { list_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { ToolWithQuantityDetailDto_$reflection, ToolWithQuantityHistoryDto_$reflection, ToolWithQuantityReservationFormDto_$reflection, ToolWithQuantityReservationResponse_$reflection, ToolWithQuantityUpdateFormDto_$reflection, ToolWithQuantityCreateFormDto_$reflection } from "../Shared/Tool.js";
import { ToolReservationId__get_unwrap, StorageId__get_unwrap, AssignToolWithQuantityWithNotificationDto_$reflection, UnassignToolWithQuantityWithoutNotificationDto_$reflection, AssignToolWithQuantityWithoutNotificationDto_$reflection, EmptyResponse_$reflection, PostResponse$1_$reflection, UpdateToolWithQuantityStorageDto_$reflection, ToolId__get_unwrap } from "../Shared/Shared.js";
import { addPrefix as addPrefix_1, fetchWithDecoder as fetchWithDecoder_1 } from "../Communication.js";
import { unwrapStorageId, unwrapToolReservationId, unwrapToolId } from "../Shared/Helper.js";
import { toISOString } from "../Common.js";
import { ValidationOnGetRequest } from "../ErrorHandling.js";

export function createToolWithQuantity(toolWithQuantityForm, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), ["/api/tools-with-quantity", toString(0, Auto_generateBoxedEncoder_437914C6(ToolWithQuantityCreateFormDto_$reflection(), undefined, undefined, undefined)(toolWithQuantityForm))], successMsg, errorMsg);
}

export function updateToolWithQuantity(toolId, toolWithQuantityForm, successMsg, errorMsg) {
    const body = toString(0, Auto_generateBoxedEncoder_437914C6(ToolWithQuantityUpdateFormDto_$reflection(), undefined, undefined, undefined)(toolWithQuantityForm));
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools-with-quantity/${ToolId__get_unwrap(toolId)}`, body], successMsg, errorMsg);
}

export function updateToolWithQuantityStorage(toolId, dto, successMsg, errorMsg) {
    const body = toString(0, Auto_generateBoxedEncoder_437914C6(UpdateToolWithQuantityStorageDto_$reflection(), undefined, undefined, undefined)(dto));
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_2, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools-with-quantity/${ToolId__get_unwrap(toolId)}/storage`, body], successMsg, errorMsg);
}

export function createToolWithQuantityReservation(dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ToolWithQuantityReservationResponse_$reflection())));
    })))), [`/api/tools/tool-with-quantity/${dto.ToolId}/reservations`, toString(0, Auto_generateBoxedEncoder_437914C6(ToolWithQuantityReservationFormDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function updateToolWithQuantityReservation(dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(tupledArg[0]), ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(ToolWithQuantityReservationResponse_$reflection())));
    })))), [`/api/tools/tool-with-quantity/${dto.ToolId}/reservations`, toString(0, Auto_generateBoxedEncoder_437914C6(ToolWithQuantityReservationFormDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function deleteToolWithQuantityReservation(toolId, reservationId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchWithDecoder_1(0, addPrefix_1(url), singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
        const pr = response.arrayBuffer();
        return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), PostResponse$1_$reflection(EmptyResponse_$reflection())));
    })))), `/api/tools/tool-with-quantity/${unwrapToolId(toolId)}/reservations/${unwrapToolReservationId(reservationId)}`, successMsg, errorMsg);
}

export function assignCmd(toolId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools-with-quantity/${ToolId__get_unwrap(toolId)}/assign`, toString(0, Auto_generateBoxedEncoder_437914C6(AssignToolWithQuantityWithoutNotificationDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function unassignCmd(toolId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["PUT"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools-with-quantity/${ToolId__get_unwrap(toolId)}/unassign`, toString(0, Auto_generateBoxedEncoder_437914C6(UnassignToolWithQuantityWithoutNotificationDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function deleteToolWithQuantityStorage(toolId, storageId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["DELETE"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), `/api/tools-with-quantity/${unwrapToolId(toolId)}/storage/${unwrapStorageId(storageId)}`, successMsg, errorMsg);
}

export function assignWithNotificationCmd(toolId, dto, successMsg, errorMsg) {
    return Cmd_OfPromise_either((tupledArg) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_3;
        return ((url_3 = addPrefix(tupledArg[0]), (refreshOn) => fetchWithDecoder(url_3, ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(2, [tupledArg[1]])]), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), string_type));
        }, refreshOn)))(true);
    })), [`/api/tools-with-quantity/${ToolId__get_unwrap(toolId)}/assign/notify`, toString(0, Auto_generateBoxedEncoder_437914C6(AssignToolWithQuantityWithNotificationDto_$reflection(), undefined, undefined, undefined)(dto))], successMsg, errorMsg);
}

export function checkReservationCmd(toolId, startDate, endDate, storageId, quantity, reservationId, successMsg, errorMsg) {
    const url = `/api/tools-with-quantity/${ToolId__get_unwrap(toolId)}/reservations/check?startDate=${toISOString(startDate)}&endDate=${toISOString(endDate)}&storageId=${StorageId__get_unwrap(storageId)}&quantity=${quantity}`;
    return Cmd_OfPromise_either((url_2) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_4;
        return ((url_4 = addPrefix(url_2), (refreshOn) => fetchWithDecoder(url_4, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), bool_type));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), (reservationId == null) ? url : (`${url}&reservationId=${ToolReservationId__get_unwrap(reservationId)}`), successMsg, errorMsg);
}

export function getToolWithQuantityHistoryDto(successMsg, errorMsg, toolId) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), list_type(ToolWithQuantityHistoryDto_$reflection())));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), `/api/tools-with-quantity/${unwrapToolId(toolId)}/history`, successMsg, errorMsg);
}

export function getToolWithQuantityDetailDto(toolId, successMsg, errorMsg) {
    return Cmd_OfPromise_either((url) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let url_2;
        return ((url_2 = addPrefix(url), (refreshOn) => fetchWithDecoder(url_2, singleton(new Types_RequestProperties(0, ["GET"])), "application/json", (response) => {
            const pr = response.arrayBuffer();
            return pr.then((blob) => Reader__Read_24524716(Reader_$ctor_Z3F6BC7B1(new Uint8Array(blob)), ToolWithQuantityDetailDto_$reflection()));
        }, refreshOn)))(true).then((_arg) => {
            const result_1 = _arg;
            return (result_1.tag === 1) ? (Promise.resolve((() => {
                throw new ValidationOnGetRequest(result_1.fields[0]);
            })())) : (Promise.resolve(result_1.fields[0]));
        });
    })), `/api/tools-with-quantity/${ToolId__get_unwrap(toolId)}/details`, successMsg, errorMsg);
}

